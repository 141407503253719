import dayjs from 'dayjs';
import * as Yup from 'yup';

export const EsquemaDescargar = Yup.object({
    idTipoUsuario: Yup.number()
    .test("min", "Debe seleccionar un tipo", function(value){
        if(value === -1){
            return false;
        }
        else
        {
            return true;
        }
    })
    , 
    idTipoAcceso: Yup.number()
    .test("min", "Debe seleccionar un tipo", function(value){
        if(value === -1){
            return false;
        }
        else
        {
            return true;
        }
    })
    , 
    fechaTermino: Yup.date()
    .test("min", "Fecha de termino no puede ser menor a la de inicio", function(value){
        const { fechaInicio} = this.parent
        const fd = dayjs(value)
        const fu = dayjs(fechaInicio)
            
        if(fd.isBefore(fu, 'day')){
            return false
        }
        if(fd.isSame(fu, 'day') || fd.isAfter(fu, 'day'))
        {
            return true
        }
    })
})