import { Box, Typography } from "@mui/material";
import "./../OpcionesEventos.css"
import TooltipTipoUno from "../../../../ToolTip/Tooltip";

export const ListaInvitados = ({listaInvitados}) => listaInvitados.map((item, index) => {
    let nombre = item.nombres === null ? "No informado" :  item.nombres + " " + item.apellidos
    let identificador = item.rut === null ? item.pasaporte === null ? "No informado": item.pasaporte : item.rut
    
    return (
        <Box id="BoxContenedorItemListaInvitadoEventosView" key={index}>
            <Box id="BoxNombreInvitadoListaInvitadosEventosView">
                <TooltipTipoUno titulo={nombre} ubicacion={"bottom"}>
                    <Typography id="TxtNombreApellidoInvitadoEventoView">
                        {
                            nombre
                        }
                    </Typography>
                </TooltipTipoUno>
            </Box>
            <Box id="BoxRutPasaporteInvitadoListaInvitadosEventosView">
                <TooltipTipoUno titulo={identificador} ubicacion={"bottom"}>
                    <Typography id="TxtRutPasaporteInvitadoEventoView">
                        {
                            identificador
                        }
                    </Typography>
                </TooltipTipoUno>
               
            </Box>
        </Box>
    );
})

