import { Avatar, Box, IconButton } from "@mui/material";
import "./style.css"
import { DownRow } from "../Icons/Iconos"
import { PersonalizedBag } from "./Bag";
import { useEffect, useState } from "react";
import Profile from "../../Profile/Profile";
import TooltipTipoUno from "../../ToolTip/Tooltip";
import { useSelector } from "react-redux";
import useGetUsuario from "../../../Hooks/Usuarios/useGetUsuario";
import { MenuNotificaciones } from "../../Menu/MenuNotificaciones/MenuNotificaciones";
import useNotificaciones from "../../../Hooks/Notificaciones/useNotificaciones";

function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}

function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const MenuUsuarios = () => {
    const userData = useSelector(action => action.user.authenticated)
    const {usuario} = useGetUsuario(userData.idPersona)
    const [anchorEl, setAnchorEl] = useState(null);
    const [imgPerfil, setImgPerfil] = useState(null);
    const {notificaciones} = useNotificaciones();

    const [anchorElMenuNotificaciones, setAnchorElMenuNotificaciones] = useState(null);
    const openMenuNotificaciones = Boolean(anchorElMenuNotificaciones);

    const handleOpenMenuNotificaciones = (event) => {
        setAnchorElMenuNotificaciones(event.currentTarget);
    };

    const handleCloseMenuNotificaciones = () => {
        setAnchorElMenuNotificaciones(null);
    };

    
    useEffect(() => {
        setImgPerfil(usuario === null || usuario === undefined ? null : usuario.urlImagenPerfil === null || usuario.urlImagenPerfil === undefined ? null : usuario.urlImagenPerfil)
    }, [usuario])
    
    const open = Boolean(anchorEl);
    
    const handleOpenPerfil = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePerfil = () => {
        setAnchorEl(null);
    };
    //todo: crear opciones preguntas y respuesta en el icono downrow

    return (
        <Box id="ContenerMenuOpciones">
            <Box id="ButonCampana">
                <TooltipTipoUno titulo={"Notificaciones"}>
                    <IconButton id="BotonNotificaciones" onClick={handleOpenMenuNotificaciones}>
                        <PersonalizedBag totalMensaje={notificaciones.length} />
                    </IconButton>
                </TooltipTipoUno>
                <MenuNotificaciones anchorEl={anchorElMenuNotificaciones} open={openMenuNotificaciones} handleClose={handleCloseMenuNotificaciones} notificaciones={notificaciones} />
            </Box>
            <Box id="MediaBox">
                <TooltipTipoUno titulo={"Mi Perfil"}>
                    <IconButton onClick={handleOpenPerfil} >
                        {
                            imgPerfil === null || imgPerfil === undefined ?
                            <Avatar id="profileIMG" {...stringAvatar(userData.usuario)}/>
                            :
                            <Avatar id="profileIMG" src={`data:image/jpeg;base64,${imgPerfil}`}/>
                        }
                        
                    </IconButton>
                </TooltipTipoUno>
                <Profile anchorEl={anchorEl} open={open} handleClosePerfil={handleClosePerfil}/>
            </Box>
            <Box id="BoxHelper">
                <TooltipTipoUno  titulo={"Ayuda"}>
                    <IconButton id="BotonFlechaAbajo">
                        <DownRow id="FlechaOpcionesNavegacion" />
                    </IconButton>
                </TooltipTipoUno>
            </Box>
        </Box>
    );
}

export default MenuUsuarios;