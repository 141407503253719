import Dashboard from "../../Components/Dashboard/Dashboard";
import React from 'react';
import Actuadores from "../../Components/GestionActuadores/Actuadores";

const ActuadoresPage = () => {
    return (
        <Dashboard>
            <Actuadores />
        </Dashboard>
    );
}

export default ActuadoresPage;
