import axios from "axios";
import { useEffect, useState } from "react";
import { ListaUsuariosEmpresa } from "../../Service/API/Api";


export default function useGetAll()
{
    const [listaUsuarios, setListaUsuarios] = useState([]);
    

    useEffect(() => {
        axios.get(ListaUsuariosEmpresa)
        .then(
            (response) =>
            {
                var listaUsuariosFormada = []
                listaUsuariosFormada.push({idPersona:-1, nombreRut:"Usuarios", rut: null, pasaporte: null})
                response.data.forEach(element => {
                    var persona = { idPersona: element.idPersona, nombreRut: element.nombreRut, rut: element.rut, pasaporte: element.pasaporte}
                    listaUsuariosFormada.push(persona)
                });
                setListaUsuarios(listaUsuariosFormada);
            }
        )
        .catch(
            (response) => {
                console.log(response.response)
            }
        );
    }, [])


    return {
        listaUsuarios
    }
}
