import * as Yup from 'yup';
import { cleanRut, validateRut } from '../../../../../Helper/ValidarRut';

export const Validation = Yup.object({
    nombres: Yup.string()
      .required('* Campo requerido'),
    apellidos: Yup.string()
      .required('* Campo requerido'),
    rut: Yup.string()
      .test("min", "rut no valido", function(value){
        return (validateRut(cleanRut(value)));
      })
      .nullable(),
    pasaporte: Yup.string()
      .nullable(),
    idTipoPersona: Yup.number()
    .test('min', "Valor no permitido", function(value){
      if(value > 0 ){
        return true;
      }
      else{
        return false;
      }
    })
    .required('* Campo requerido'),
    correo: Yup.string().email("Debe ingresar un correo valido")
    .required('* Campo requerido'),
    telefono: Yup.string()
    .required('* Campo requerido'),
})