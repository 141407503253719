import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import "./Boton.css";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import TooltipTipoUno from "../../ToolTip/Tooltip";


export const ListItemTipo1 = ({openM, itemId, linkComp, to, isSelected, iconButon, textButon, submenu, smState, closeSM, openSM}) => {


    return(
        <TooltipTipoUno titulo={textButon} ubicacion={"right"}>
            <ListItem id="ListItemMenu"
                disablePadding 
                sx={{
                    marginBottom:"4px",
                    color: "#626262",
                    backgroundColor: "#fff",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px", /* 150% */
                    letterSpacing: "0.5px",
                    "& .MuiListItemIcon-root": {
                        color: "#626262"
                    },
                    ":hover":{
                        borderRadius: "4px",
                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                        color: "#FFF",
                        backgroundColor: "#1F7098",
                        fontWeight: 500,
                        "& .MuiListItemIcon-root": {
                            color: "#B7CBD5",
                        }
                    }

                }}
                >
                <ListItemButton itemID={itemId} 
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    "&.MuiListItemButton-root.Mui-selected":{
                        borderRadius: "4px",
                        color: "#FFF",
                        backgroundColor: "#154E6B",
                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                        "& .MuiListItemIcon-root": {
                            color: "#FFF",
                        }
                    }
                }}
                onClick={smState ? closeSM : openSM}
                LinkComponent={linkComp}
                href={to}
                selected={isSelected}            
                >
                    <ListItemIcon sx={{ minWidth: "35px", justifyContent:"center"}}>
                    {
                        iconButon
                    }
                    </ListItemIcon>
                    {
                        openM ?
                        <ListItemText  sx={{ width:"130px", WebkitFlex:"none", display:"flex", justifyContent:"left", paddingLeft:"7px"}}>
                            {
                                textButon
                            }
                        </ListItemText>
                        :
                        null
                    }
                    {
                    submenu ?
                    smState  ? <ExpandLess id ="iconoExpandirCerrar"/> : <ExpandMore id ="iconoExpandirCerrar" />
                    :
                    null
                    }
                </ListItemButton>
                
            </ListItem>
        </TooltipTipoUno>
    );
}


export const SubMenuTipo1 = ({openM, itemId, linkComp, to, isSelected, textButon, submenu, smState, closeSM, openSM}) => {


    return(
        <TooltipTipoUno titulo={textButon} ubicacion={"right"}>
            <ListItem id="SubMenuLeftside"
                disablePadding 
                sx={{height:"48px", paddingBottom:"4px"}}
                >
                <ListItemButton itemID={itemId} 
                sx={{
                    display:"flex",
                    width: "248px",
                    height: "48px",
                    padding: "10px 16px 10px 64px",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    background: "#fff",
                    ":hover":{
                        borderRadius: "4px",
                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                        color: "#175676",
                        background: "rgba(23, 86, 118, 0.16)",
                        fontWeight: 500,
                        "& #TxtSubMenu":{
                            color: "#175676",
                        },
                    },
                    "&.MuiListItemButton-root.Mui-selected":{
                        borderRadius: "4px",
                        background: "rgba(23, 86, 118, 0.16)",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",                      
                        
                        "& #TextoSubMEnu":{
                            color: "#175676",
                        }
                    },
                    
                }}
                onClick={smState ? closeSM : openSM}
                LinkComponent={linkComp}
                href={to}
                selected={isSelected}            
                >
                    {
                        openM ?
                        <Typography id="TextoSubMEnu" >
                            {
                                textButon
                            }
                        </Typography>
                        :
                        null
                    }
                    {
                    submenu ?
                    smState  ? <ExpandLess id ="iconoExpandirCerrar"/> : <ExpandMore id ="iconoExpandirCerrar" />
                    :
                    null
                    }
                </ListItemButton>
                
            </ListItem>
        </TooltipTipoUno>
    );
}


export const SubMenuTipo2 = ({itemId, linkComp, to, isSelected, textButon}) => {


    return(
        <TooltipTipoUno titulo={textButon} ubicacion={"right"}>
            <ListItem id="SubMenuLeftside"
                disablePadding 
                sx={{height:"48px", paddingBottom:"4px"}}
                >
                <ListItemButton itemID={itemId} 
                sx={{
                    display:"flex",
                    width: "184px",
                    height: "48px",
                    padding: "10px 24px",
                    alignItems: "stretch",
                    gap: "8px",
                    borderRadius: "4px",
                    background: "#fff",
                    ":hover":{
                        borderRadius: "4px",
                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                        color: "#175676",
                        background: "rgba(23, 86, 118, 0.16)",
                        fontWeight: 500,
                        "& #TxtSubMenu":{
                            color: "#175676",
                        },
                    },
                    "&.MuiListItemButton-root.Mui-selected":{
                        borderRadius: "4px",
                        background: "rgba(23, 86, 118, 0.16)",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",                      
                        
                        "& #TextoSubMEnu":{
                            color: "#175676",
                        }
                    },
                    
                }}
                LinkComponent={linkComp}
                href={to}
                selected={isSelected}            
                >
                    <Typography id="TextoSubMEnu" >
                        {
                            textButon
                        }
                    </Typography>
                </ListItemButton>
                
            </ListItem>
        </TooltipTipoUno>
    );
}