import { Box, Modal } from "@mui/material";
import { ModalLoading } from "../ModalLoding/ModalLoading";
import { RespuestaCreacionEvento } from "../../Eventos/NuevoEvento/RespuestaNuevoEvento/RespuestaEvento";

const RespuestaNuevoEvento = ({open, setOpen, msj, id, loading, respuesta, nuevoEvento}) => {

    const handleClose = () => 
    { 
        setOpen(false);
    };

    return(
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading === true ?
                    <Box>
                        <ModalLoading msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaCreacionEvento 
                        setOpen={setOpen} 
                        msj={respuesta === 201 ? "Tu evento ha sido agendado con éxito! Envía la invitación ahora o hazlo más tarde": "Ocurrio un erro al agendar, verifique que la fecha no este ocupada" } 
                        titulo={respuesta === 201 ? "Evento agendado" : "Error al agendar"} 
                        respuesta={respuesta}
                        nuevoEvento={nuevoEvento}
                        />
                    </Box>
            }
            </Modal>
    );
}


export default RespuestaNuevoEvento;