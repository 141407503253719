import { useState } from 'react';
import { Box} from '@mui/material';
import "./GenerarQREvento.css";
import { ValidarEventoExistente } from './ValidacionEvento';
import { HandlerQRDinamico } from './QRDinamico';



const GenerarQREvento = () => {
    const [opcion, setOpcion]  = useState(1);
    const [infoEvento, setInfoEvento]  = useState({idVisitaVisitante:''});
    return (
        <Box id="BoxValidarGenerarQRVisita" >
            {
                opcion === 1 ?
                <ValidarEventoExistente 
                setOpcion={setOpcion}
                setInfoEvento={setInfoEvento}
                />
                :
                <HandlerQRDinamico
                infoEvento={infoEvento}
                />
            }
        </Box>
    );
}

export default GenerarQREvento;


