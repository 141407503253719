import { Box, Typography } from "@mui/material";
import "./VerMotivo.css";

export const VerMotivo = ({motivo}) => {
    return(
        <Box id="BoxMotivoListaNegra">
            <Box id="BoxListaNegraDetalleMotivo">
                <Box id="BoxTituloMotivoIngreso">
                    <Typography id="TxtTituloMotivoIngresoListaNegra">
                        {
                            "Motivo"
                        }
                    </Typography>
                </Box>                    
                <Box id="TextoMotivoIngresoListaNegra">
                    <Typography id="TxtMotivoIngresoListaNegra">
                        {
                            motivo
                        }
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}