import "./Horario.css"
import { HorariosTablaGU } from "./TablaHorario";
import { LayoutMenuOpcion } from "../../../../Box/OpcionMenuGestionUsuario/LayoutMenuGU";
import { useEffect, useState } from "react";
import { AsignarHorario } from "./AsignarHorario";
import { NoData } from "../../../../Box/NoDataMenuGestionUsuario/NoData";
import useAsignacionesUsuario from "../../../../../Hooks/Asignaciones/useAsignacionesUsuario";
import useHorarioEmpresa from "../../../../../Hooks/Horarios/useHorariosEmpresa";
import { EditarAsignacion } from "./EditarHorario";


export const GestionUsuariosHorario = ({userData}) =>{
    const [asignar, setAsignar] = useState(Boolean(false));
    const [editar, setEditar] = useState(Boolean(false));
    const [asignacionToEdit, setAsignacionToEdit] = useState({});
    const {asignaciones} = useAsignacionesUsuario(userData.idPersona); 
    const {horarios} = useHorarioEmpresa();
    const [rows, setRows] = useState([]);

    useEffect(() => {
      setRows(asignaciones);
    }, [asignaciones])
    

    return (
        asignar ?
        <AsignarHorario setOpenAsignar={setAsignar} horariosAsignar={horarios} setRows={setRows} user={userData.idPersona}/>
        :
        editar === true?
        <EditarAsignacion setOpen={setEditar} asignaciones={asignaciones} setRows={setRows} user={userData.idPersona} horariosAsignar={horarios} asignacionToEdit={asignacionToEdit}/>
        :
        <LayoutMenuOpcion titulo={"Horarios"} showIcon={true} handleIcon={setAsignar}>
            {
                rows.length === 0 ?
                <NoData 
                mensaje={"Actualmente, no hay horarios asignados a este usuario.Establecer un horario facilitará la gestión y el control de los accesos permitidos durante tiempos específicos.Utiliza el botón “Agregar horario” para comenzar."}
                textoBtn={"Asignar horario"}
                event={setAsignar}
                /> 
                :
                <HorariosTablaGU horarios={rows} setRows={setRows} editar={setEditar} setAsignacionToEdit={setAsignacionToEdit} />
            }
        </LayoutMenuOpcion>
    );
}