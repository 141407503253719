
import React from 'react';
import Dashboard from '../../Components/Dashboard/Dashboard';
import { LiveStreamCamaras } from '../../Components/Camaras/Live';

const CamarasPage = () => {
    return (
        <Dashboard>
            <LiveStreamCamaras />
        </Dashboard>
    );
}

export default CamarasPage;