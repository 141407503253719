import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { esES } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';

export const SeleccionFecha = ({ valueSelected, handleDate, labelName, name, variante, helperText }) => {

    
    return (
        <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs}>
            <DatePicker name={name}
                localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                slotProps={{
                    actionBar: { actions: ["cancel", "accept"] },
                    popper: { sx: { "& .MuiPaper-root": { borderRadius: "8px", boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);" } } },
                    textField: {
                        variant: variante === "out" ? "outlined":"standard",
                        InputProps: { disableUnderline: true},
                        required: true,
                        sx: {
                            borderBottom: "1px solid black",
                            width: "100%",
                            height: "100%",
                            "& .MuiButtonBase-root": { marginRight: "0px" },
                            "& .MuiInputBase-input": { marginLeft: "10px" },
                            "& .MuiFormLabel-root": { marginLeft: "10px" },
                        },
                        helperText:helperText
                    },
                    
                }}
                label={labelName}
                value={dayjs(valueSelected)}
                onChange={handleDate}
                format={'DD/MM/YYYY'}
            />
        </LocalizationProvider>
    );
}


export const SeleccionFechaDos = ({ valueSelected, handleDate, labelName, name, variante, helperText }) => {

    
    return (
        <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs}>
            <DatePicker name={name}
                localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                
                slotProps={{
                    actionBar: { actions: ["cancel", "accept"] },
                    popper: { sx: {"& .MuiPaper-root": { borderRadius: "20px", boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);" } } },
                    textField: {
                        variant: variante === "out" ? "outlined":"standard",
                        required: true,
                        sx: {
                            width: "90%",
                            marginLeft: "10px",
                            marginRight: "10px",
                            display: "flex",
                            flexdirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        },
                        InputProps: { disableUnderline: true, sx: { height: "29px", marginLeft: "15px", paddingRight:"-15px"}},                        
                        helperText:helperText
                    },
                }}
                label={labelName}
                value={dayjs(valueSelected)}
                onChange={handleDate}
                format={'DD/MM/YYYY'}
            />
        </LocalizationProvider>
    );
}