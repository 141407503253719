import { Box, Collapse, List, ListItem, ListItemButton, Typography } from "@mui/material"
import "./OpcionesMenuGestionUsuario.css";
import { useState } from "react";
import { OpcionesGA, Registros } from "./Opciones";
import { IconoUsuarioMenuGU } from "../MenuGestionUsuarioIcon";
import { ListItemMenuGU } from "../../../Botones/BotonesMenu/MenuGU";
import { v4 as uuidv4 } from 'uuid';

export const GestionRapidaUsuarioMenu = ({dataUsuario, opcion, setOpcionMenu}) =>{
    const [openSMGA, setOpenSMGA] = useState(false);
    const [openSMOMA, setOpenSMOMA] = useState(false);

    const setOpcionCero = () => {
        setOpcionMenu(0);
    }
    const setOpcionSiete = () => {
        setOpcionMenu(7);
    }

    const setOpcionOcho = () => {
        setOpcionMenu(8);
    }


    const handleSMGA = () => {
        setOpenSMOMA(false);
        setOpenSMGA(!openSMGA);
    }
    const handleSMOMA = () => {
        setOpenSMGA(false);
        setOpenSMOMA(!openSMOMA);
    }


    const nombreUsuarioSeleccionado = dataUsuario.nombres.split(" ")[0] + " " + dataUsuario.apellidos.split(" ")[0]

    return (
        <Box id="BoxLayoutMenuGestionRapida">
            <List id="ListaSubMenu" disablePadding>
                <Box id="BoxOpcion0MenuGestionRapidas" key={0} >
                    <ListItem key={0} itemID={0} id="MenuOpcion0DataUsuario"
                    >
                        <ListItemButton 
                        selected={opcion === 0 ? true : false}
                        key={0} 
                        itemID={0} 
                        id="MenuBotonDataUsuario" 
                        onClick={setOpcionCero}
                        sx={{
                            ":hover":{
                                borderRadius: "4px",
                                boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                                color: "#FFF",
                                backgroundColor: "#1F7098",
                                fontWeight: 500,
                                "& .MuiListItemIcon-root": {
                                    color: "#B7CBD5",
                                },
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                            },
                            "&.MuiListItemButton-root.Mui-selected":{
                                borderRadius: "4px",
                                background: "#154E6B",
                                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",                      
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                                "& #IconoUsuarioMGU":{
                                    color: "#fff",
                                }
                            },
                            
                        }}
                        >
                            <IconoUsuarioMenuGU id="IconoUsuarioMGU" />
                            <Typography id="MenuGUTxtNombreUsuario" noWrap={true} >
                                {
                                    nombreUsuarioSeleccionado
                                }
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                </Box>
                <Box id="BoxOpcionesMenuGestionRapidas" key={1}>
                    <ListItem key={1} itemID={1} id="MenuBotonOpcionDisponible">
                        <ListItemButton 
                        selected={opcion === 1 || opcion === 2? true : false} 
                        key={1} 
                        itemID={1} 
                        id="MenuBotonDisponible" 
                        onClick={handleSMGA}
                        sx={{
                            ":hover":{
                                borderRadius: "4px",
                                boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                                color: "#FFF",
                                backgroundColor: "#1F7098",
                                fontWeight: 500,
                                "& .MuiListItemIcon-root": {
                                    color: "#B7CBD5",
                                },
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                            },
                            "&.MuiListItemButton-root.Mui-selected":{
                                borderRadius: "4px",
                                background: "#154E6B",
                                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",                      
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                                "& #IconoUsuarioMGU":{
                                    color: "#fff",
                                }
                            },
                            
                        }}
                        >
                            <Typography id="MenuGUTxtNombreUsuario">
                                {
                                    "Configuración de Accesos"
                                }
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                    
                </Box>
                {
                    OpcionesGA.length > 0 ?
                        <Collapse id="subMenuGAGU" in={openSMGA} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                <MapSubMenu items={OpcionesGA} opcion={opcion} setOpcion={setOpcionMenu} />
                            </List>
                        </Collapse>
                        :
                        null
                }
                <Box id="BoxOpcionesMenuGestionRapidas" key={2}>
                    <ListItem key={2} itemID={2} id="MenuBotonOpcionDisponible">
                        <ListItemButton 
                        id="MenuBotonDisponible" 
                        selected={opcion === 3 || opcion === 4 || opcion === 5 || opcion === 6 ? true : false} 
                        key={2} 
                        itemID={2}  
                        onClick={handleSMOMA}
                        sx={{
                            ":hover":{
                                borderRadius: "4px",
                                boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                                color: "#FFF",
                                backgroundColor: "#1F7098",
                                fontWeight: 500,
                                "& .MuiListItemIcon-root": {
                                    color: "#B7CBD5",
                                },
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                            },
                            "&.MuiListItemButton-root.Mui-selected":{
                                borderRadius: "4px",
                                background: "#154E6B",
                                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",                      
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                                "& #IconoUsuarioMGU":{
                                    color: "#fff",
                                }
                            },
                            
                        }}
                        >
                            <Typography id="MenuGUTxtNombreUsuario" >
                                {
                                    "Métodos de identificación"
                                }
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                    
                </Box>
                {
                    Registros.length > 0 ?
                        <Collapse id="subMenuGAGU" in={openSMOMA} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                <MapSubMenu items={Registros} opcion={opcion} setOpcion={setOpcionMenu} />
                            </List>
                        </Collapse>
                        :
                        null
                }
                <Box id="BoxOpcionesMenuGestionRapidas" key={7}>
                    <ListItem key={7} itemID={7} id="MenuBotonOpcionDisponible">
                        <ListItemButton 
                        selected={opcion === 7 ? true : false} 
                        key={7} itemID={7} 
                        id="MenuBotonDisponible" 
                        onClick={setOpcionSiete}
                        sx={{
                            ":hover":{
                                borderRadius: "4px",
                                boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                                color: "#FFF",
                                backgroundColor: "#1F7098",
                                fontWeight: 500,
                                "& .MuiListItemIcon-root": {
                                    color: "#B7CBD5",
                                },
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                            },
                            "&.MuiListItemButton-root.Mui-selected":{
                                borderRadius: "4px",
                                background: "#154E6B",
                                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",                      
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                                "& #IconoUsuarioMGU":{
                                    color: "#fff",
                                }
                            },
                            
                        }}
                        >
                            <Typography id="MenuGUTxtNombreUsuario" >
                                {
                                    "Lista Negra"
                                }
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                </Box>
                <Box id="BoxOpcionesMenuGestionRapidas" key={8}>
                    <ListItem key={8} itemID={8} id="MenuBotonOpcionDisponible">
                        <ListItemButton 
                        selected={opcion === 8 ? true : false} 
                        key={8} itemID={8} 
                        id="MenuBotonDisponible" 
                        onClick={setOpcionOcho}
                        sx={{
                            ":hover":{
                                borderRadius: "4px",
                                boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                                color: "#FFF",
                                backgroundColor: "#1F7098",
                                fontWeight: 500,
                                "& .MuiListItemIcon-root": {
                                    color: "#B7CBD5",
                                },
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                            },
                            "&.MuiListItemButton-root.Mui-selected":{
                                borderRadius: "4px",
                                background: "#154E6B",
                                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",                      
                                "& #MenuGUTxtNombreUsuario":{
                                    color: "#fff",
                                },
                                "& #IconoUsuarioMGU":{
                                    color: "#fff",
                                }
                            },
                            
                        }}
                        >
                            <Typography id="MenuGUTxtNombreUsuario" >
                                {
                                    "Ubicacion"
                                }
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                </Box>
            </List>
        </Box>
    );
}




const MapSubMenu = ({items, opcion, setOpcion}) => items.map((item)=>{
    return (
        <ListItemMenuGU
            key={item.id}
            optionSelected={opcion}
            itemId={uuidv4()}
            titulo={item.titulo}
            setOptionMenu={setOpcion}
            toOption={item.opcion}
        />
    );
})
