import { Box, IconButton, Typography } from "@mui/material";
import "./RadioRecurrencia.css";


export const RadioRecurrencia = ({ id, dia, estado, handle }) => {
    return (
        <IconButton id={id} onClick={handle}
        sx={{ 
            backgroundColor: estado ? "rgba(23, 86, 118, 0.16)" : "#FFF",
            boxShadow: estado ?  "none" : "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
            width: "30px",
            borderRadius: "25px",
            height: "30px",
            marginRight: "12px"
            }}>
            <Box id="ContenedorTexto">
                <Typography id="TextoDiaRecurrencia">
                    {
                        dia
                    }
                </Typography>
            </Box>
        </IconButton>
    );
}