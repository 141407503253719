import { SvgIcon } from "@mui/material";

export function CandadoGestionZonas (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="28" viewBox="0 0 22 28" fill="none">
                <path d="M5.85714 12.6667V7.33333C5.85714 5.55556 6.88571 2 11 2C13.4561 2 14.8125 3.2671 15.507 4.66667M5.85714 12.6667H2V26H20V12.6667H5.85714Z" stroke="#333333" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function IconCerrarAccesos(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M11 1L1 11M1.00001 1L11 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    );
}