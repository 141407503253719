import { Checkbox, FormControl, FormHelperText, ListItemText, MenuItem, Select, Typography } from "@mui/material";


export const FiltroSelect = ({id, listaOpciones, nombre, value, handler}) => {
    
    return(
        <FormControl sx={{width:"inherit", height:"40px"}}>
            <Select
            id={id} 
            value={value}
            label={nombre}
            variant="standard"
            disableUnderline
            sx={{
                width:"180px",
                backgroundColor: "#F8F8F8",
                height: "inherit",
                borderRadius:"25px",
                border: "1.5px solid #333",
                opacity: "0.8",
                padding: "0px",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                color: "#333",
                textAlign: "center",
                fontFamily: "inherit",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.15px",
            }}            
            onChange={handler}
            >
                {
                    listaOpciones.length === 0 ?
                    <MenuItem key={0} value={0}>
                        {
                            "Obteniendo..."
                        }
                    </MenuItem>
                    :
                    listaOpciones.map((opcion, index)=>{
                        return(
                            <MenuItem key={index} value={opcion.id}>
                                <Typography sx={{ color:" #333",fontFamily: "inherit",fontSize: "16px",fontStyle: "normal",fontWeight: 500, lineHeight: "24px", letterSpacing: "0.15px"}}>
                                    {
                                        opcion.tipo
                                    }
                                </Typography>                            
                            </MenuItem>
                        );
                    })                   
                }
            </Select>
        </FormControl>
    );
}




export const FiltroSelectTres = ({id, listaOpciones, nombre, value, handler}) => {
    
    return(
        <FormControl sx={{width:"inherit", height:"40px"}}>
            <Select
            id={id} 
            value={value}
            label={nombre}
            variant="standard"
            disableUnderline
            sx={{
                width:"180px",
                backgroundColor: "#F8F8F8",
                height: "inherit",
                borderRadius:"25px",
                border: "1.5px solid #333",
                opacity: "0.8",
                padding: "0px",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                color: "#333",
                textAlign: "center",
                fontFamily: "inherit",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.15px",
            }}            
            onChange={handler}
            >
                {
                    listaOpciones.length === 0 ?
                    <MenuItem key={-1} value={-1}>
                    </MenuItem>
                    :
                    listaOpciones.map((opcion, index)=>{
                        return(
                            <MenuItem key={index} value={opcion.id}>
                                <Typography sx={{ color:" #333",fontFamily: "inherit",fontSize: "16px",fontStyle: "normal",fontWeight: 500, lineHeight: "24px", letterSpacing: "0.15px"}}>
                                    {
                                        opcion.tipo
                                    }
                                </Typography>                            
                            </MenuItem>
                        );
                    })                   
                }
            </Select>
        </FormControl>
    );
}



export const FiltroSelectCuatro = ({id, name, listaOpciones, value, handler, helper}) => {
    
    return(
        <FormControl sx={{width:"inherit", height:"48px"}}>
            <Select
            id={id} 
            value={value}
            name={name}         
            variant="standard"
            disableUnderline
            sx={{
                width:"inherit",
                backgroundColor: "#F8F8F8",
                height: "inherit",
                borderRadius:"20px",
                border: "1.5px solid #333",
                opacity: "0.8",                
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                padding: "0px",
                textAlign: "center"
            }}            
            onChange={handler}
            >
                {
                    listaOpciones.map((opcion, index)=>{
                        return(
                            <MenuItem key={index} value={opcion.id}>
                                <Typography sx={{ color:" #333",fontFamily: "inherit",fontSize: "16px",fontStyle: "normal",fontWeight: 450, lineHeight: "24px", letterSpacing: "0.15px"}}>
                                    {
                                        opcion.tipo
                                    }
                                </Typography>                            
                            </MenuItem>
                        );
                    })                   
                }
            </Select>
            <FormHelperText>
                {
                    helper
                }
            </FormHelperText>
        </FormControl>
    );
}


export const FiltroSelectDos = ({id, listaOpciones, name, value, handler, helper}) => {
    return(
        <FormControl sx={{width:"inherit", height:"48px"}}>
            <Select
            id={id} 
            multiple={ true}
            displayEmpty
            value={value}
            name={name}         
            variant="standard"
            disableUnderline
            renderValue={(selected) => {
                if (selected.length === 0) {
                  return    <MenuItem key={0} value={0}>
                                <Typography >
                                    {"Todas las Zonas"}
                                </Typography>
                            </MenuItem>
                }
                return selected.join(',');
            }}
            sx={{
                width:"inherit",
                backgroundColor: "#F8F8F8",
                height: "inherit",
                borderRadius:"20px",
                border: "1.5px solid #333",
                opacity: "0.8",                
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                padding: "0px",
                textAlign: "center"
            }}           
            onChange={handler}
            >
                {
                    listaOpciones.map((opcion)=>{
                        return(
                            <MenuItem key={opcion.nombreZona} value={opcion.nombreZona}>
                                <Checkbox checked={value.indexOf(opcion.nombreZona) > -1} />
                                <ListItemText primary={opcion.nombreZona} />
                            </MenuItem>
                        );
                    })
                            
                }
            </Select>
            <FormHelperText>
                {
                    helper
                }
            </FormHelperText>
        </FormControl>
    );
}