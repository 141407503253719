import React from 'react'
import Dashboard from "../../Components/Dashboard/Dashboard";
import GestionEstacionamientos from '../../Components/GestionEstacionamientos/GestionEstacionamientos';

export default function GestionEstacionamientosPage() {
  return (
    <Dashboard>
        <GestionEstacionamientos />
    </Dashboard>
  )
}



