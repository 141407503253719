import axios from "axios";
import { useEffect, useState } from "react";
import { BuscarEmpresa } from "../../Service/API/Api";

function useMapUsuario(idEmpresa) {
    const [urlMapa, setUrlMapa] = useState();
    const [loading, setLoading] = useState();


    useEffect(() => {
        setLoading(true)
        axios.get(BuscarEmpresa + idEmpresa)
        .then((response) => 
            {
                setUrlMapa(response.data.urlMapa)
                setLoading(false)
            }
        )
        .catch((error) => 
            {
                setUrlMapa(null);
                setLoading(false)
            }
        );
    }, [idEmpresa])
    

    return {
        urlMapa,
        loading,
        setLoading
    }
}


export default useMapUsuario;