import * as Yup from 'yup';

export const ValidationSchema = Yup.object({
    nombres: Yup.string()
      .required('* Campo requerido'),
    apellidos: Yup.string()
      .required('* Campo requerido'),
    rut: Yup.string()
    .nullable(),
    pasaporte: Yup.string()
    .nullable(),
    correo: Yup.string()
      .email('formato: ejemplo@dominio.cl')
      .required('* Campo requerido')
      ,
    patente: Yup.string()
  })