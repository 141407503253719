import { Box, IconButton, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import usePersonaUbicacion from "../../../Hooks/PersonaUbicacion/usePersonasUbicaciones";
import { SwitchTablas } from "../../Switch/switch";
import { CerrarModalNuevoUsuario } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import "./VerMiembros.css";
import TooltipTipoUno from "../../ToolTip/Tooltip";
import { IconoVerMas } from "../../GestionActuadores/IconosGestionActuadores";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import useUsuarios from "../../../Hooks/Usuarios/useUsuarios";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";
import { MiembroInformacion } from "./UsuarioInformacion/MiembroInformacion";
import { PapeleroIconA } from "../IconosGestionUbicaciones";
import usePersonaEnUbicacion from "../../../Hooks/PersonaUbicacion/usePersonaEnUbicacion";
import { IconoAgregarNuevaRow } from "../../CabeceraTablasGestion/IconosCabecera";
import { NuevoMiembro } from "./NuevoMiembro";
import {PopOverTipoUno } from "../../Popover/PopoverTipoUno";

const style = {
   
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    width: "1024px",
    height: "680px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};


export const VerMiembrosUbicacion = ({setOpen, idUbicacion}) => {
    const [rows, setRows] = useState([]);
    const [ConfirmationDialog, confirm ]= useConfirm();
    const {ActivarDesactivarUsuario, usuario, BuscarUsuarioExistente} = useUsuarios();
    const {personasUbicacion, loading} = usePersonaUbicacion(idUbicacion);
    const {EliminarUsuarioDeUbicacion} = usePersonaEnUbicacion();
    const [confirmMessage, setConfirmMessage] = useState({titulo: "", mensaje: ""});
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [openTarjetaInformacion, setOpenTarjetaInformacion ]= useState(Boolean(false));
    const [anchorEl, setAnchorEl ]= useState(null);
    const openNM = Boolean(anchorEl);

    const handleOpenNuevoMiembro = (e) => {
        setAnchorEl(e.currentTarget)
    }
   
    const handlerClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        setRows(personasUbicacion)
    }, [personasUbicacion])
    
    const handleEstadoUsuario = (id) => async () => {
        var findRow = personasUbicacion.find(u => u.idUsuario === id);
        if(findRow.estado){
            setConfirmMessage({ titulo: "¿Desactivar?", mensaje: "Estás a punto de desactivar al usuario ¿Deseas continuar?"}) 
        }
        else{
            setConfirmMessage({ titulo: "¿Activar?", mensaje: "Estás a punto de activar al usuario ¿Deseas continuar?"}) 
        }

        var response = await confirm();
        if(response)
        {
            await ActivarDesactivarUsuario(findRow.idUsuario, findRow.estado)
            .then(
                (resolve) => {
                    findRow.estado = !findRow.estado;
                    setRows(rows.map((row) => (row.idUsuario === findRow.idUsuario ? findRow : row)));

                    setSnackbarSeverity("success")
                    setSnackMsg(findRow.estado === true ? 
                        "Usuario Activado exitosamente!"
                        :
                        "Usuario desactivado exitosamente!"
                    )
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error en la solicitud, reintente")
                    setSnackbar(true);
                }
            )

            
        }
        else{
            console.log("Rechaza modificacion");
        }
    }

    const handleMiembroInfo = (id) => () => {
        BuscarUsuarioExistente(id);        
        setOpenTarjetaInformacion(true);
    };
    const handleEliminarAsociacion = (id) => async () => {
        var findRow = rows.find(u => u.idUsuario === id);
        setConfirmMessage({ titulo: "¿Eliminar?", mensaje: "Estás a punto de eliminar al usuario de la ubicacion ¿Deseas continuar?"}) 

        var response = await confirm();
        if(response)
        {
            await EliminarUsuarioDeUbicacion(findRow.idPersonaUbicacion)
            .then(
                (resolve) => {                    
                    setRows(rows.filter((row) => row.idUsuario !== id));
                    setSnackbarSeverity("success")
                    setSnackMsg("Usuario eliminado exitosamente de la ubicacion!")
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error en la solicitud, reintente")
                    setSnackbar(true);
                }
            )

            
        }
        else{
            console.log("Rechaza modificacion");
        }
    }

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.idUsuario === newRow.idUsuario ? updatedRow : row)));
        return updatedRow;
    };


    const colNames = [
        {   field: 'idUsuario', headerName: 'ID', width: 80, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'nombre', headerName: 'Nombre miembro', flex: 0.5, minWidth: 80, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'tipoUsuario', headerName: 'Tipo', flex: 0.5, minWidth: 80, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'estado', headerName: 'Estado', type:'actions', width: 90, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions', 
            getActions:({id, row})=>{
                return [
                    <SwitchTablas
                    id={"SwitchEstadoEstacionamientoTabla"}
                    value={row.estado}
                    handler={handleEstadoUsuario(id)}
                    disabled={null} />
                    ]
            }
        }
        ,
        {
            field: 'verMas', headerName: 'Más ', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({row}) => {
                return [
                    <TooltipTipoUno titulo={"Ver más"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<IconoVerMas  id='iconoEnTablaUsuarios'/>}
                        label="Ver más"
                        onClick={handleMiembroInfo(row.idUsuario)}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
        ,
        {
            field: 'Eliminar', headerName: 'Eliminar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id}) => {
                return [

                    <TooltipTipoUno titulo={"Eliminar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        label="Eliminar"
                        onClick={handleEliminarAsociacion(id)}
                        icon={<Box
                            id="iconoEditarBox"
                            sx={{
                            display: 'flex',
                            padding: '6px',
                            alignItems: 'flex-start',
                            gap: '10px',
                            borderRadius: '25px',
                            background: '#FFF',
                            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                            '& .icono-papelero .fondo-icono-papelero':{
                                fill: 'white',
                            },
                            '& .icono-papelero .trazo-icono-papelero':{
                                stroke: '#626262',
                                strokeWidth: 1.5,
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                            },
                            '&:hover':  {
                                backgroundColor: '#FAE9E8',
                                '.icono-papelero .fondo-icono-papelero': {
                                fill: '#FAE9E8',
                                },
                                '.icono-papelero .trazo-icono-papelero': {
                                stroke: 'red',
                                },
                            },
                            '&:active':  {
                                backgroundColor: 'red',
                                '.icono-papelero .fondo-icono-papelero': {
                                fill: 'red',
                                },
                                '.icono-papelero .trazo-icono-papelero': {
                                stroke: 'white',
                                },
                            }
                            }}
                        >
                            <PapeleroIconA id='iconoEditar'/> 
                        </Box>}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
    
    ]


    return(
        <Box sx={{...style}}>
                <Box id="BoxCabeceraMiembrosUbicacion">
                    <IconButton onClick={handlerClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>                
                <Box id="BoxInformacionMiembrosUbicacion" sx={{gap:"48px"}}>
                    <Box id="BoxTituloOpcionMiembrosUbicacion">
                        <Typography id="TxtTituloOpcionNuevaUbicacion">
                            {
                                "Miembros de la ubicacion"
                            }
                        </Typography>
                        <Box id="BoxContenedorIconoOpcion">
                            <TooltipTipoUno titulo={"Agregar usuario a la ubicacion"} >
                                <IconButton onClick={(e) => handleOpenNuevoMiembro(e) } id="btnIconoAccionCabecera">
                                    <IconoAgregarNuevaRow id="iconoCabecera"  />
                                </IconButton>
                            </TooltipTipoUno>
                        </Box> 
                    </Box>                    
                    <Box id="BoxTablaMiembros">
                        <DataGrid id='TablaMiembrosUbicacion'
                        style={{ width:'100%', height:"100%"}}
                        sx={{ 
                            overflowX:"hidden",
                            overflowY:"hidden",
                            '& .MuiDataGrid-columnHeaderTitle': {
                            color:'#175676',
                        },
                            '& .MuiDataGrid-columnHeader': {
                            borderBottom: '2px solid #94B1C0',
                        },
                        '& .MuiDataGrid-row':{
                            borderBottom:'1px solid #94B1C0'
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                            width: "7.024px",
                            background: "transparent"
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                            background: "transparent"
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                            background: "#C9C9C9",
                            borderRadius: "100px"
                        }
                        }}
                        getRowId={(row) => row.idUsuario}
                        columns={colNames} 
                        rows={rows}
                        initialState={{
                                pagination: {
                                    paginationModel: {
                                    page: 0,
                                    pageSize: 10,
                                    },
                                },
                            }}
                        processRowUpdate={processRowUpdate}
                        checkboxSelection={false}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        disableVirtualization={true}
                        showCellVerticalBorder={false}
                        showColumnVerticalBorder={false}
                        hideFooterSelectedRowCount={true}
                        rowSelection
                        loading={loading}
                        disableRowSelectionOnClick 
                        pageSizeOptions={[10, 20]}
                        />
                    </Box>         
                    <ConfirmationDialog
                    icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
                    titulo={confirmMessage.titulo}
                    mensaje={confirmMessage.mensaje}
                    />    
                    <SnackBarTipoUno 
                    open={snackbar}
                    handler={handleCloseSnackbar}
                    duration={6000}
                    severity={snackbarSeverity}
                    msg={snackMsg}
                    />       
                    <PopOverTipoUno
                    open={openNM}
                    anchorEl={anchorEl}
                    setOpen={setAnchorEl}
                    children={<NuevoMiembro setRows={setRows} ubicacion={idUbicacion} />}
                    />
                    <MiembroInformacion
                    open={openTarjetaInformacion}
                    setOpen={setOpenTarjetaInformacion}
                    usuario={usuario}
                    />
                </Box>                
            </Box>
    );
}