import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import "./Accessos.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { ValidationNuevoAcceso } from "./Validation";
import useAcceso from "../../../../../Hooks/Acceso/useAcceso";
import { useState } from "react";
import { LoadingMasRespuestaCuatro } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";


export const NuevoAcceso = ({setCrearOpen, setRows, userData, zonas}) =>{
    const {loading, setLoading, response, CrearNuevoAcceso} = useAcceso();
    const [openLoading, setOpenLoading] = useState(false);

    const handleClose = () =>{
        setCrearOpen(false);
    }

    const handleOtro = () =>{
        formik.resetForm();
        setOpenLoading(false);
    }

    const AgregarRow = async (idZona, nuevaId) => {
        let zona = zonas.find((t) => t.id === idZona);
        setRows((oldRows) => [...oldRows, 
            {
                idAcceso: nuevaId, 
                nombreZona: zona.tipo,
                estado: true,
                isNew: true 
            }
        ]);
    }

    const formik = useFormik({
        initialValues:{idZona:0, nombreZona: "Zonas"},
        validationSchema:ValidationNuevoAcceso,
        onSubmit: 
        async () =>{
            setLoading(true);
            setOpenLoading(true);
            await CrearNuevoAcceso(formik.values.idZona, userData.idPersona)
            .then(
                (resolve) => {
                    AgregarRow(formik.values.idZona, resolve)
                }
                ,
                (reject) => {
                    console.log(reject);
                }
            );
        }
    })
    
    

    return (
        <LayoutCrearNuevo titulo={"Agregar acceso"}>
            <Box id="BoxLayoutSelectAgregarAccesoGU">
                <Box id="BoxSelectAgregarAccesoGU">
                    <Box id="BoxTxtInformacionCreacionGU">
                        <Typography id="TxtInformacionCreacionGU">
                            {
                                "Información de acceso"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxSelectEstadoZonaAgregarAccesoGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <Box id="BoxContenedorSelectAgregarAccesoGU">
                            <FormControl fullWidth>
                                <Autocomplete
                                disablePortal
                                options={zonas}
                                fullWidth    
                                id="idZona"
                                componentname="idZona"
                                freeSolo={true}
                                getOptionKey={(o) => o.id}
                                onChange={(e, v) => {
                                    if(v === null)
                                        {
                                            formik.setFieldValue("idZona", 0)                                                     
                                        }
                                        else
                                        {
                                            formik.setFieldValue("idZona", v.id)     
    
                                        }
                                }}
                                isOptionEqualToValue={(option, value) => option.tipo === value}
                                getOptionLabel={(option) => option.tipo ?? option}
                                inputValue={formik.values.nombreZona}
                                value={formik.values.nombreZona}
                                onInputChange={(e,v) => formik.setFieldValue("nombreZona", v)}   
                                renderInput={(params) => 
                                <TextField
                                InputLabelProps={{shrink: true}}
                                {...params}
                                label="Zonas disponibles" />
                                }
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box
                                        key={key}
                                        component="li"
                                        {...optionProps}>
                                        {option.tipo}
                                        </Box>
                                    );
                                  }}
                                />
                                <FormHelperText>
                                    {
                                        formik.errors.idZona
                                    }
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Box id="BoxEstadoZonaSeleccionadaAgregarAccesoGU">
                            {/* <FormControl fullWidth>
                                <InputLabel id="LblEstadoZona">
                                    {
                                        "Estado Zona"
                                    }
                                    </InputLabel>
                                <TextField variant="outlined" fullWidth>

                                </TextField>
                            </FormControl> */}
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesAgregarAccesoGU">
                    <Box id="BoxBtnCancelarAgregarAccesoGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAgregarAgregarAccesoGU">
                        <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCuatro
                        id={"LoadingMasRespuestaCuatroNuevoAcceso"}
                        open={openLoading}
                        setOpen={setOpenLoading}
                        msj={"Se está agregando un nuevo acceso al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        tituloLoading={"Agregando Acceso..."}
                        loading={loading}
                        respuesta={response}
                        titulo={response === 201 ? "Acceso agregado":"Acceso no agregado"}
                        mensajeRespuesta={response === 201 ? 
                            "El nuevo Acceso ha sido agregado exitosamente. Ahora puedes gestionar los accesos o agregar otro método."
                            :
                            "No se ha podido agregar el Acceso debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
                        txtBotonUno={"Agregar otro Acceso"}
                        txtBotonDos={null}
                        eventBotonUno={handleOtro}
                        eventoBotonDos={null} 
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}