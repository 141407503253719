import { useEffect, useState } from 'react'
import { EliminarRegistroHistorial, Historial } from '../../Service/API/Api';
import axios from 'axios';

function useNotificaciones () {
    const [loading, setLoading] = useState(true);
    const [notificaciones, setNotificaciones] = useState([]);
    const [error, setError] = useState('false');
    const [response, setResponse] = useState();

    const saveData = (data) =>{
        setNotificaciones(data)
        setLoading(false)
    }

    useEffect(() => {
        if (loading){
          async function getData () {
              await axios.get(Historial)
              .then((response)=>{
                saveData(response.data)})
              .catch( (response)=>(setError(response.response)) )
              } 
          getData();
        }
    }, [loading])

    const EliminarRegistro = (id) => {
      	return new Promise((resolve, reject) => {
			axios.delete(EliminarRegistroHistorial + id)
			.then(
				(response) => {
					resolve(true);
					setResponse(response.status)
					setLoading(false);
				}

			)
			.catch(
				(response) => {
					reject(false);
					setResponse(response.response.status)
					setLoading(false);
				}
			)
      	})
    }

    return {loading, 
        setLoading, 
        notificaciones, 
        error, 
        response,
		    EliminarRegistro
      };
}

export default useNotificaciones;
