import React from 'react';
import Dashboard from '../../Components/Dashboard/Dashboard';
import ReportesComponent from '../../Components/Reportes/ReportesComponent';

const Reportes = () => {
    return (
        <>
            <Dashboard>
                <ReportesComponent />
            </Dashboard>
        </>
    );
}

export default Reportes;
