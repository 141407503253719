import React from 'react';
import GenerarQREvento from '../../Components/GenerarQR/GenerarQREvento';

const GenerarQRPage = () => {
    return (
        <GenerarQREvento />
    );
}

export default GenerarQRPage;
