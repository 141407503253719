import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputsBarraNavegacion from '../BarraNavegacion/inputsBarraNavegacion.js';
import { CandadoGestionZonas } from './IconsGestionAccesos.js';
import './EstilosGestionAccesos.css'
import useAcceso from '../../Hooks/Acceso/useAcceso.js'
import ModalNuevoAcceso from './ModalNuevoAcceso.js';
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion.js';
import { IconoAgregarNuevaRow } from '../CabeceraTablasGestion/IconosCabecera.js';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { SwitchTablas } from '../Switch/switch.js';
import useConfirm from '../../Hooks/ConfirmDialog/useConfirmDialog.js';
import { IconoAlerta } from '../Usuarios/UsuariosIcon.js';
import TooltipTipoUno from '../ToolTip/Tooltip.js';
import { PapeleroIconA } from '../GestionUbicaciones/IconosGestionUbicaciones.js';
import { SnackBarTipoUno } from '../snackBar/SnackBar.js';

const opciones =  [{'nombre':'Estado', 'valor' : 0}, {'nombre':'Habilitados', 'valor' : 1}, {'nombre':'Desactivados', 'valor' : 2}]    

const GestionAccesos = () => {
    const [filtro, setFiltro] = useState(0);


    const {accesos, response, DeshabilitarActivarAcceso, loading, setLoading, EliminarAccesoEspecifico, CrearNuevoAcceso, AgregarNuevoAcceso, setAccesos} = useAcceso();
    const [rows, setRows] = useState([]);
    const [nuevoAcceso, setNuevoAcceso] = useState(Boolean(false));
    const [searchText, setSearchText] = useState('');
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [titulo, setTitulo] = useState('');
    const [mensaje, setMensaje] = useState('');

    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    const handlerInputBarraNavegacion = (seleccion) => {

        let estadoFiltro = seleccion;
        if(seleccion === 0){
            setRows(accesos);
        }

        else {

            if(seleccion === 1){
                estadoFiltro = true;
            }
        

            else if(seleccion === 2){
                estadoFiltro = false;
            }

            const accesosFiltro = accesos.filter(acceso => acceso.estado === estadoFiltro);
            setRows(accesosFiltro);
        }
    }

    const handleOpenNT = () => {
        setNuevoAcceso(true); 
    }

    useEffect(() => {
        setRows(accesos);
    }, [accesos])

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);

        if(searchValue === ""){
            setRows(accesos);
        }

        else{
            const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            const filteredRows = rows.filter((row) => {
                return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
                });
            });
            setRows(filteredRows);
        }
                
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value);

    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const changeEstado = async  (row) =>{
        if (row.estado){
            setTitulo("¿Desactivar?")
            setMensaje("Estás a punto de desactivar a esta persona de la zona. ¿Deseas continuar?")            
        }else{
            setTitulo("¿Activar?")
            setMensaje("Estás a punto de activar a esta persona de la zona. ¿Deseas continuar?")
        }
        
        let respuesta = await confirm();

        if(respuesta){
            DeshabilitarActivarAcceso(row.idAcceso, row.estado)
            .then((res) => {

                const rowActualizado = {
                    ...row,
                    estado: !row.estado
                }
                const rowsActualizados = accesos.map((row) => (row.idAcceso === rowActualizado.idAcceso ? rowActualizado : row));
                setAccesos(rowsActualizados);
            })
            .catch((error) => (console.log("Error al habilitar o deshabilitar acceso: ",error)));
        }
    }

    const columns = [
        { field: 'rut', headerName: 'Rut',   type: 'string', width: 190, headerAlign: 'center', align:'center', sortable: false, editable: false, valueFormatter: params => params?.value ?? "-"},
        { field: 'pasaporte', headerName: 'Pasaporte',   type: 'string', width: 190, headerAlign: 'center', align:'center', sortable: false, editable: false, valueFormatter: params => params?.value ?? "-"},
        { field: 'nombrePersona', headerName: 'Nombres',   type: 'string', minwidth: 320, flex: 1, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'nombreZona', headerName: 'Nombre de la zona',   type: 'string', minwidth: 500, flex: 1, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field:'Estado2', headerName:'Estado', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false,
        renderCell: (row) => (
            <SwitchTablas
                id={row.idAcceso} 
                value={row.row.estado}
                handler={()=>changeEstado(row.row)}
                disable={false}
                />
            )
        },
        {
            field: 'eliminar', headerName: 'Eliminar', type: 'actions', width: 150, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id}) => {
                return [
                    <TooltipTipoUno titulo={"Eliminar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        label="Eliminar"
                        onClick={handleEliminar(id)}
                        icon={<Box
                            id="iconoEditarBox"
                            sx={{
                              display: 'flex',
                              padding: '6px',
                              alignItems: 'flex-start',
                              gap: '10px',
                              borderRadius: '25px',
                              background: '#FFF',
                              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                              '& .icono-papelero .fondo-icono-papelero':{
                                fill: 'white',
                              },
                              '& .icono-papelero .trazo-icono-papelero':{
                                stroke: '#626262',
                                strokeWidth: 1.5,
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                              },
                              '&:hover':  {
                                backgroundColor: '#FAE9E8',
                                '.icono-papelero .fondo-icono-papelero': {
                                  fill: '#FAE9E8',
                                },
                                '.icono-papelero .trazo-icono-papelero': {
                                  stroke: 'red',
                                },
                              },
                              '&:active':  {
                                backgroundColor: 'red',
                                '.icono-papelero .fondo-icono-papelero': {
                                  fill: 'red',
                                },
                                '.icono-papelero .trazo-icono-papelero': {
                                  stroke: 'white',
                                },
                              }
                            }}
                          >
                            <PapeleroIconA id='iconoEditar'/> 
                        </Box>}
                        />
                    </TooltipTipoUno>
                ];
            }
        },
    ];
    
    const handleEliminar = (id) => async () => {
        let rowEdit = rows.find(r => r.idAcceso === id);

        if(rowEdit.idAcceso === null){
            setSnackbarSeverity("error")
            setSnackMsg("Ha ocurrido un error al eliminar el acceso");
            setSnackbar(true);
        }
        else{
            //Está en veremos si se utilizará esto o el titulo y mensaje por separado
            //setConfirmMessage({ titulo: "¿Eliminar?", mensaje: "Estás a punto de eliminar la asignacion del estacionamiento ¿Deseas continuar?"});
            setTitulo("¿Eliminar?");
            setMensaje("Estás a punto de eliminar este acceso ¿Deseas continuar?");
            let res = await confirm();
            if(res)
            {
                await EliminarAccesoEspecifico(rowEdit.idAcceso)
                    .then(
                        (resolve) => {
                            setAccesos(accesos.filter((row) => row.idAcceso !== id));
                            setSnackbarSeverity("success")
                            setSnackMsg("Asignacion eliminada correctamente")
                            setSnackbar(true); 
                        }
                        ,
                        (reject) => {
                            setSnackbarSeverity("error")
                            setSnackMsg("Ocurrio un error al eliminar el acceso, reintente nuevamente")
                            setSnackbar(true); 
                        }
                    )
            }
            else
            {
                console.log("cancelo la eliminacion de la asociacion");
            }
        }
    }//fin handleEliminar



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////7

    return (
        <Box id="BoxContenedorTablaAccesos">
            <CabeceraTablasGestion
                icono1={<CandadoGestionZonas/>}
                titulo={"Gestión de accesos"}
                data={rows}
                agregarNuevoIcon={<IconoAgregarNuevaRow />}

                //Acá debe ir una función que cambia el estado del ModalNuevoAcceso (variable nuevoAcceso) a true, osea hace uso de la misma función que se pone en el setOpen 
                // que es parte de las props del componente modal
                handlerAN={handleOpenNT}

                cargaMasivaIcon={null}
                descargarIcon={false}
                handlerCM={null}
                handlerDownload={null}
                value={searchText}
                onChange={handleSearch}
                clearSearch={clearSearch}
            >
                <Box id='BoxGestionAccesosNavA'>
                    <InputsBarraNavegacion opciones={opciones} filtro={filtro} setFiltro={setFiltro} handlerInputBarraNavegacion={handlerInputBarraNavegacion}/>
                </Box>
            </CabeceraTablasGestion>

            <DataGrid id='TablaGestionTarjetas'
                style={{ width:'100%', height:"100%"}}
                sx={{ 
                    '& .MuiDataGrid-columnHeaderTitle': {
                    color:'#175676',
                    },
                        '& .MuiDataGrid-columnHeader': {
                        borderBottom: '2px solid #94B1C0',
                    },
                    '& .MuiDataGrid-row':{
                        borderBottom:'1px solid #94B1C0'
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                        width: "7.024px",
                        background: "transparent"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                        background: "transparent"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                        background: "#C9C9C9",
                        borderRadius: "100px"
                    }
                }}
                getRowId={(row) => row.idAcceso}
                columns={columns} 
                rows={rows}
                initialState={{
                        pagination: {
                            paginationModel: {
                            page: 0,
                            pageSize: 10,
                            },
                        },
                    }}
                checkboxSelection={false}
                disableColumnFilter={true}
                disableColumnMenu={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                disableVirtualization={true}
                showCellVerticalBorder={false}
                showColumnVerticalBorder={false}
                hideFooterSelectedRowCount={true}
                rowSelection
                loading={loading}
                disableRowSelectionOnClick 
                pageSizeOptions={[10, 20]}
            />

            <ModalNuevoAcceso 
                open={nuevoAcceso} //Acá debe ir la variable que indica el estado booleano para abrir o no el modal
                setOpen={setNuevoAcceso} //Acá debe ir la función que cambia el estado de la variable añadida dentro de "open"
                setRows={setRows}
                loading={loading}
                setLoading={setLoading}
                response={response}
                crearNuevoAcceso={CrearNuevoAcceso}
                agregarNuevoAcceso={AgregarNuevoAcceso}

            />

            <SnackBarTipoUno 
                open={snackbar}
                handler={handleCloseSnackbar}
                duration={6000}
                severity={snackbarSeverity}
                msg={snackMsg}
            />

            <ConfirmationDialog
                icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
                titulo={titulo}
                mensaje={mensaje}
            />

        </Box>
    );
}

export default GestionAccesos;
