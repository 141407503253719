import { useNavigate, useSearchParams } from "react-router-dom";
import usePerfil from "../../Hooks/OpcionesPerfil/usePerfil";
import { useEffect, useRef, useState } from "react";
import { Box, Button, Card, CardMedia, TextField, Typography } from "@mui/material";
import {LoadingMasRespuesta} from "../../Components/Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import CP2 from "../../assets/imagenes/CP2.png";
import "./ValidacionCambioCorreo.css";
import { IconoExito, IconoFallo } from "../../Components/Modal/RespuestaServidor/RespuestaServidorIcon";
import { SnackBarTipoUno } from "../../Components/snackBar/SnackBar";


const ValidacionCambioCorreo = () =>{
    const {respuestaCambioCorreo, CambiarCorreo, loading, setLoading} = usePerfil();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [correoActual, setCorreoActual] = useState(null);
    const [correoNuevo, setCorreoNuevo] = useState(null);
    const [token, setToken] = useState(null);
    const [idEmpresa, setIdEmpresa] = useState(null);
    const buttonRef = useRef();
    const [snackMsg, setSnackMsg] = useState({severity: "", message: ""});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);



    const handleConfirmarCambio = () =>{        
        if(correoActual !== null && correoNuevo !== null && token !== null && idEmpresa !== null)
        {
            setLoading(true);
            setOpenLoading(true);
            CambiarCorreo(correoActual, correoNuevo, idEmpresa, token);
        }
        else{
            setSnackMsg({
                severity: "error",
                message: "Error de validacion, confirma tus datos"
            })
            setSnackbar(true);
        }
    }

    useEffect(() => {
        if(searchParams.size > 0){
            setCorreoActual(searchParams.get('correoActual'));
            setCorreoNuevo(searchParams.get('correoNuevo'));
            setIdEmpresa(searchParams.get('empresa'));
            setToken(searchParams.get('token'));

            searchParams.delete('token');
            searchParams.delete('empresa');
            searchParams.delete('correoActual');
            searchParams.delete('correoNuevo');

            setSearchParams(searchParams);
            setLoading(true);
            setOpenLoading(true);
            setTimeout(() => {
                ActivarBoton();
            }, 3000);
            console.log(respuestaCambioCorreo)
        }

    },[correoActual, correoNuevo, idEmpresa, token, searchParams, setSearchParams, setLoading, respuestaCambioCorreo]);

    if(respuestaCambioCorreo === 200){
        setTimeout(() => {
            navigate("/login", { replace: true });
        }, 5000);
    }
    const ActivarBoton = () => {
       buttonRef.current.click()
    }

    const IrALogin = () => {
        navigate("/login", { replace: true });
    }

    

    return(
    <>
        <Box component="div" id="boxCambioCorreoValidacion">
            <Card id="CardCambioCorreoValidacion">
                <Box id="CardContenidoCambioCorreoValidacion">
                        <Box id="BoxImagenCambioCorreoValidacion">
                            <CardMedia id="imagenCambioCorreoValidacion" image={CP2}></CardMedia>
                        </Box>
                        <Box id="BoxTituloCambioCorreoValidacion">
                            <Typography id="TextoTituloCambioCorreoValidacion">
                                {
                                    "Cambio de correo electronico"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxCorreoInformacionCambio">
                            <TextField 
                            value={correoActual !== undefined ? correoActual:""}
                            id={"txtCorreoActualValidacionCC"}
                            variant="filled"
                            label="Correo actual"
                            disabled={true}
                            fullWidth={true}
                            />
                            <TextField 
                            value={correoNuevo !== undefined ? correoNuevo:""}
                            id={"txtCorreoNuevoValidacionCC"}
                            variant="filled"
                            label="Correo nuevo"
                            disabled={true}
                            fullWidth={true}
                            />

                            <Box id="BoxBotonConfirmarCambioCorreo">
                                <Button id="BotonEnCambioDeCorreo"  onClick={IrALogin}>Iniciar Sesion</Button>
                                <Button id="BotonEnCambioDeCorreo" ref={buttonRef} onClick={handleConfirmarCambio}> Reintentar </Button>
                            </Box>
                        </Box>
                </Box>
            </Card>    
        </Box>
        <LoadingMasRespuesta
            open={openLoading}
            setOpen={setOpenLoading} 
            msj={"Realizando modificacion, un momento mas en linea"}
            id={"ModalRespuestaValidacionCambioCorreo"}
            loading={loading}
            icono={
                respuestaCambioCorreo === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                respuestaCambioCorreo === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            respuesta={respuestaCambioCorreo}
            titulo={respuestaCambioCorreo === 200 ? "Cambio con exito":"Error al cambiar"}
            mensajeRespuesta={respuestaCambioCorreo === 200 ? "Su correo electrónico ha sido cambiado exitosamente! redirigiendo...":"El token enviado para el cambio de correo electrónico ha caducado. Vuelve a solicitarlo."}
            />
            <SnackBarTipoUno
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackMsg.severity}
            msg={snackMsg.message}
            />
    </>
    );
}


export default ValidacionCambioCorreo;