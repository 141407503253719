import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './Actuadores.css'
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion';
import { IconoGestionActuadores, IconoVerMas } from './IconosGestionActuadores';
import { FiltroSelect } from '../CabeceraTablasGestion/FiltrosCabecera/FiltrosCabecera';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { SwitchTablas } from '../Switch/switch';
import useGetActuadores from '../../Hooks/Actuadores/useGetActuadores';
import { useSelector } from 'react-redux';
import useGestZonasEmpresa from '../../Hooks/Zona/useGetZonasEmpresa';
import dayjs from 'dayjs';
import useTipoActuador from '../../Hooks/TipoActuador/useTipoActuador';
import { IconoAlerta, IconoEditarUsuario } from '../Usuarios/UsuariosIcon';
import TooltipTipoUno from '../ToolTip/Tooltip';
import { ModalGestionActuador } from './GestionOpcionesActuadores/ModalGestionActuador';
import { SnackBarTipoUno } from '../snackBar/SnackBar';
import useConfirm from '../../Hooks/ConfirmDialog/useConfirmDialog';
import useActuadores from '../../Hooks/Actuadores/useActuador';


const Actuadores = () => {
    const data = useSelector(action => action.user.authenticated);
    const { ModificarEstadoActuador, ModificarRemotoActuador} = useActuadores();
    const {zonasFormadas} = useGestZonasEmpresa();
    const { loading, actuadores} = useGetActuadores(data.idEmpresa);
    const { tipoActuadores } = useTipoActuador();
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [opcionZona, setOpcionZona] = useState(0);
    const [tipoActuador, setTipoActuador] = useState(0);
    const [openModalGestionActuador, setOpenModalGestionActuador] = useState(Boolean(false));
    const [opcionSeleccionada, setOpcionSeleccionada] = useState();
    const [verMasData, setVerMasData] = useState({});
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [dialogText, setDialogText] = useState({titulo: '', mensaje: ''});

    

    const handleZona = (e) => {
        setOpcionZona(e.target.value);
        if(e.target.value === 0) {
            setRows(actuadores);
        }
        else
        {
            const listaFiltrada = actuadores.filter((row) => row.idZona === e.target.value);
            setRows(listaFiltrada);
        }
        
    }


    const handleEstado = (id) => async () => {
        const findrow = rows.find((row) => row.idActor === id);
        if(findrow.estado === true){
            setDialogText(
                {
                    titulo: "¿Desactivar Actuador?",
                    mensaje: "Estás a punto de desactivar este actuador ¿Deseas continuar?"
                }
            );
        }
        else
        {
            setDialogText(
                {
                    titulo: "¿Activar Actuador?",
                    mensaje: "Estás a punto de activar este actuador ¿Deseas continuar?"
                }
            );
        }

        const responseConfirm = await confirm(); 
        if(responseConfirm === true) {
            ModificarEstadoActuador(id, findrow.estado)
            .then(
                (result) => {
                    findrow.estado = !findrow.estado
                    setRows(rows.map((row) => (row.idActor === findrow.idActor ? findrow : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(findrow.estado === true ? 
                        "El actuador ha sido activado exitosamente!"
                        :
                        "El actuador ha sido desactivado exitosamente!"
                    )
                    setSnackbar(true);
                }
            )
            .catch(
                (error) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true);   
                }
            )
        }        
        else
        {
            console.log("Rechaza la modificacion")
        }
        
    }

    const handleEstadoRemoto = (id) => async () => {
        const findrow = rows.find((row) => row.idActor === id);
        
        if(findrow.remoto === true){
            setDialogText(
                {
                    titulo: "¿Desactivar Remoto?",
                    mensaje: "Estás a punto de desabilitar el acceso remoto de este actuador ¿Deseas continuar?"
                }
            );
        }
        else
        {
            setDialogText(
                {
                    titulo: "¿Activar Remoto?",
                    mensaje: "Estás a punto de activar el acceso remoto de este actuador ¿Deseas continuar?"
                }
            );
        }

        const responseConfirm = await confirm(); 
        if(responseConfirm === true) {
            ModificarRemotoActuador(id, findrow.remoto)
            .then(
                (result) => {
                    findrow.remoto = !findrow.remoto
                    setRows(rows.map((row) => (row.idActor === findrow.idActor ? findrow : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(findrow.remoto === true ? 
                        "Acceso remoto Activado exitosamente!"
                        :
                        "Acceso remoto desactivado exitosamente!"
                    )
                    setSnackbar(true);
                }
            )
            .catch(
                (error) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true);   
                }
            )
        }        
        else
        {
            console.log("Rechaza la modificacion")
        }
        
    }

    const handleNuevoActuador = () => {
        setOpenModalGestionActuador(true);
        setOpcionSeleccionada(1);
    }


    const handleTipoActuadores = (e) => {
        setTipoActuador(e.target.value);
        if(e.target.value === 0) {
            setRows(actuadores);
        }
        else
        {
            const listaFiltrada = actuadores.filter((row) => row.idTipoActor === e.target.value);
            setRows(listaFiltrada);
        }
    }

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = actuadores.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const openVerMas = (row) => () => {
        setOpenModalGestionActuador(true);
        setVerMasData(row)
        
        setOpcionSeleccionada(3);
    }

    const openEditar = (row) => () => {
        setOpenModalGestionActuador(true);
        setVerMasData(row)
        
        setOpcionSeleccionada(2);
    }


    useEffect(() => {
        setRows(actuadores);
    }, [actuadores])


    const columns = [
        { field: 'idActor', headerName: 'ID', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'nombre', headerName: 'Nombre', flex: 0.5, minWidth: 150, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'tipoActor', headerName: 'Tipo', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'sentido', headerName: 'Sentido', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'nombreZona', headerName: 'Ubicacion', type: 'string', flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'fechaActualizacion', headerName: 'Ultima Conexion', type: 'string', flex: 0.5, minWidth: 150, headerAlign: 'center', valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY HH:mm:ss"), align:'center', sortable: false, editable: false},
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <TooltipTipoUno titulo={"Editar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<IconoEditarUsuario  id='iconoEnTablaUsuarios'/>}
                        label="Editar"
                        onClick={openEditar(row)}
                        />
                    </TooltipTipoUno>
                ];
            }
        },
        { field: 'remoto', headerName: 'Remoto', type:'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions', 
            getActions:({id, row})=>{
                return [
                    <SwitchTablas
                    id={"SwitchRemotoActuadorTabla"}
                    value={row.remoto}
                    handler={handleEstadoRemoto(id)}
                    disabled={false} />
                    ]
            }
        },
        { field: 'estado', headerName: 'Estado', type:'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions', 
            getActions:({id, row})=>{
                return [
                    <SwitchTablas
                    id={"SwitchEstadoActuadorTabla"}
                    value={row.estado}
                    handler={handleEstado(id)}
                    disabled={false} />
                    ]
            }
        },
        {
            field: 'verMas', headerName: 'Ver más', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({row}) => {
                return [
                    <TooltipTipoUno titulo={"Ver más"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<IconoVerMas  id='iconoEnTablaUsuarios'/>}
                        label="Ver más"
                        onClick={openVerMas(row)}
                        />
                    </TooltipTipoUno>
                ];
            }
        },
    ];

    return (
    <Box id="BoxContenedorTablaActuadores">
        <CabeceraTablasGestion 
            icono1={<IconoGestionActuadores />}
            titulo={"Actuadores"}
            rows={rows}
            setRows={setRows}
            agregarNuevoIcon={true}
            handlerAN={handleNuevoActuador}
            cargaMasivaIcon={null}
            handlerCM={null}
            descargarIcon={null}
            handlerDownload={null}
            onChange={handleSearch}
            value={searchText}
            clearSearch={clearSearch}
            >
                <Box id="BoxSelectContainerCabeceras">
                    <FiltroSelect id="FiltroSelectTiposActores" 
                    nombre={"Tipos"} 
                    listaOpciones={tipoActuadores}
                    value={tipoActuador}
                    handler={handleTipoActuadores}
                    />
                    <FiltroSelect id="FiltroSelectZonasEmpresa" 
                    nombre={"Zonas"} 
                    listaOpciones={zonasFormadas}
                    value={opcionZona}
                    handler={handleZona}
                    />
                </Box>
            </CabeceraTablasGestion>
            <DataGrid id='TablaActuadores'
            style={{ width:'100%', height:"100%"}}
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                color:'#175676',
            },
                '& .MuiDataGrid-columnHeader': {
                borderBottom: '2px solid #94B1C0',
            },
            '& .MuiDataGrid-row':{
                borderBottom:'1px solid #94B1C0'
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                width: "7.024px",
                background: "transparent"
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                background: "transparent"
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                background: "#C9C9C9",
                borderRadius: "100px"
            }
            }}
            getRowId={(row) => row.idActor}
            columns={columns} 
            rows={rows}
            initialState={{
                    pagination: {
                        paginationModel: {
                        page: 0,
                        pageSize: 10,
                        },
                    },
                }}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection
            loading={loading}
            disableRowSelectionOnClick 
            pageSizeOptions={[10, 20]}
            />
            
            <ModalGestionActuador
            open={openModalGestionActuador}
            handlerOpen={setOpenModalGestionActuador}
            setRows={setRows}
            opcion={opcionSeleccionada}
            data={verMasData}
            rows={rows}
            />

            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />

            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={dialogText.titulo}
            mensaje={dialogText.mensaje}
            />
    </Box>
  );
}


export default Actuadores;