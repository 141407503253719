import { Box, IconButton, Modal } from "@mui/material";
import { CerrarModalNuevoUsuario } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import { NuevaAsignacion } from "./NuevaAsignacion";
import { EditarAsignacion } from "./EditarAsignacion";
import "./OpcionesAsignacion.css";

const style = {
    width: "1024px",
    height: "680px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};


export const OpcionesAsignacion = ({open, setOpen, rowTarjeta, setRows, opcion, rows}) => {

    const handlerClose = () => {
        setOpen(false);
    }
     
    return (
        <Modal id="modalNuevaAsignacionPase" open={open} onClose={handlerClose}>
            <Box id="BoxNuevoPase" sx={{ ...style }}>
                <Box id="CabeceraNuevaAsignacionPase">
                    <IconButton onClick={handlerClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>
                <Box id="ContenidoModalNuevaAsignacionPase">
                    {
                        opcion === 1 ?
                        <NuevaAsignacion setOpen={setOpen} setRows={setRows} rows={rows} dataEditar={rowTarjeta}/>
                        :
                        <EditarAsignacion setOpen={setOpen} setRows={setRows} rows={rows} dataEditar={rowTarjeta} />
                    }
                </Box>
            </Box>
        </Modal>
    );
}