import {useState } from 'react';
import axios from 'axios';
import { LOGINPATH } from '../../Service/API/Api'
import { useDispatch } from 'react-redux';
import { disconnect, setUser } from '../../store/misSlice';
import { Navigate } from 'react-router-dom';


function useAuth() {
    const [response, setResponse] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const GuardarInfo = (response, values) =>{

        dispatch(setUser({
            authenticated: true,
            token: response.token,
            expiracion: response.expiracion,
            idEmpresa: response.idEmpresa,
            usuario: response.usuario,
            idPersona: response.idUsuario,
            rol: response.rol,
            correo: values.correo,
            rememberMe: values.recordarMe,
        }
        ));
        setLoading(false);
        return <Navigate to={"/Inicio"} replace/>  
    }

    const Iniciar = async (values) => {
        const login = {
            email: values.correo,
            contrasena: values.contrasena,
        };
        
        await axios.post(LOGINPATH, login)
        .then(
            (response) => {
                setResponse(response.status)
                GuardarInfo(response.data, values)
            }
        )
        .catch((error) => {
                setResponse(error.response.status)
                setLoading(false);
            }
        )
    }

 

    const Salir = () => {
        dispatch(disconnect(
            {
                authenticated: false,
                token: null,
                expiracion: null,
                idEmpresa: null,
                usuario: null,
                idPersona: null,
                rol: null,
                correo: null,
                rememberMe: null
            }
        ))
        return <Navigate to={"/login"} replace />;     
    }


    return {
        loading,
        setLoading,
        response,
        Iniciar,
        Salir
    }
};

export default useAuth;
