import { Box, IconButton, Modal } from "@mui/material";
import "./MenuGestionUsuario.css";
import { useState } from "react";
import { IconoCerrarGU } from "./MenuGestionUsuarioIcon";
import { SeleccionadorOpcionesMenu } from "./OpcionesVisibles/ManejadorOpciones";
import { GestionRapidaUsuarioMenu } from "./MenuOpciones/OpcionesMenuGestionUsuario";


const style = {
    display: "flex",
    width: "1264px",
    height: "640px",
    position: 'absolute',
    flexDirection: "column",
    justifyContent: "center",
    aligItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
};

export const MenuGestionUsuario =  ({id, open, setOpen, dataUsuario, rows, setRows}) =>{
    const [opcionMenu, setOpcionMenu] = useState(0);

    const handleClose = () =>{
        setOpcionMenu(0)
        setOpen(false);
    }


    return (
        <>
            <Modal id={id} open={open} onClose={handleClose}>
                <Box sx={{...style}}>
                    <Box id="BoxMenuGestionUsuarios">
                        <Box id="BoxLayoutMenuGestionUsurio">
                            <GestionRapidaUsuarioMenu dataUsuario={dataUsuario} opcion={opcionMenu} setOpcionMenu={setOpcionMenu} />
                        </Box>
                        <Box id="BoxLayoutOpcionVisibleMenu">
                            <Box id="BoxCerrarModal">
                                <IconButton onClick={handleClose}>
                                    <IconoCerrarGU />
                                </IconButton>
                            </Box>
                            <Box>
                                <SeleccionadorOpcionesMenu
                                opcion={opcionMenu}
                                userData={dataUsuario} 
                                rows={rows}
                                setRows={setRows}
                                handleOpen={setOpen}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}