import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import "./ModalGestionActuador.css"
import { useFormik } from "formik";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import useTipoActuador from "../../../Hooks/TipoActuador/useTipoActuador";
import useGestZonasEmpresa from "../../../Hooks/Zona/useGetZonasEmpresa";
import { TextFieldCuatroMask } from "../../TextField/TextFieldCuatro";
import { EsquemaNuevoActuador } from "./schemasGestionOpcionesActuadores";
import { TextMaskCustomMAC } from "../../../Helper/Mask";
import useActuadores from "../../../Hooks/Actuadores/useActuador";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useState } from "react";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";

const sentidos = [
    { id: 0 , tipo: "Entrada"},
    { id: 1 , tipo: "Salida"},
    //{ id: 0 , tipo: "Encendido"},
    //{ id: 0 , tipo: "Apagado"}
]


export const EditarExistente = ({ setRows, handler, rows, actorData }) => {
    const { tipoActuadores } = useTipoActuador ();
    const {zonasFormadas} = useGestZonasEmpresa();
    const [openLoading, setOpenLoading] =  useState(Boolean(false));
    const { EditarExistente, loading, setLoading, response } = useActuadores();
    const [ConfirmationDialog, confirm ]= useConfirm();
    

    const formik = useFormik({
        initialValues: { 
            nombreActuador: actorData.nombre, 
            idTipoActuador: tipoActuadores.length > 0 ? actorData.idTipoActor : 0,
            idSentido: actorData.sentido === "Entrada" ? 0 : 1, 
            mac: actorData.mac, 
            serial: actorData.serial, 
            clave:  actorData.clave ?? "-", 
            ip:actorData.ip, 
            puerto: actorData.puerto, 
            idZona: zonasFormadas.length > 0 ? actorData.idZona : 0,
        },
        validationSchema: EsquemaNuevoActuador,
        enableReinitialize: true,
        onSubmit: async () => {
            var response = await confirm();
            if(response === true) {
                setLoading(true);
                setOpenLoading(true);
                await EditarExistente(actorData.idActor,formik.values)
                .then(
                    (resolve) => {
                        EditarRow(resolve);
                    }
                    ,
                    (reject) => {
                        console.log(reject)
                    }
                )
            }
            else{
                console.log("rechaza la modificacion")
            }
        }
    })

    const handlerClose = () => {
        handler(false);
        formik.resetForm();
    }

    const EditarRow = () => {

        const tipoActorFiltrado = tipoActuadores.find(t => t.id === formik.values.idTipoActuador);
        const sentidoFiltrado = sentidos.find(s => s.id === formik.values.idSentido);
        const zonafiltrada = zonasFormadas.find(z => z.id === formik.values.idZona);

        var actorDataUP = {
            idActor: actorData.idActor,
            nombre: formik.values.nombreActuador,
            idTipoActor : formik.values.idTipoActuador,
            tipoActor : tipoActorFiltrado.tipo,
            idSentido : formik.values.idSentido,
            sentido : sentidoFiltrado.tipo,
            fechaActualizacion : actorData.fechaActualizacion,
            mac : formik.values.mac.toUpperCase(),
            serial : formik.values.serial,
            clave : formik.values.clave === "" ? "-" : formik.values.clave,
            ip : formik.values.ip,
            puerto : formik.values.puerto,
            idZona : formik.values.idZona,
            nombreZona : zonafiltrada.tipo,
            remoto : actorData.remoto,
            estado :  actorData.estado,
        }
        setRows(rows.map((row) => (row.idActor === actorData.idActor ? actorDataUP : row)))
    }

    return(
        <Box id="BoxAgregarNuevoActuador">
            <Box id="BoxTituloSubtituloOpcionGestionActuador">
                <Box id="BoxTituloOpcionGestionActuador">
                    <Typography id="TxtTituloOpcionGestionActuador">
                        {
                            "Editar actuador"
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloOpcionGestion">
                    <Typography id="TxtSubTituloOpcionGestionActuador">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxFormularioOpcionGestionActuador" >
                <Box id="BoxContenidoFormularioOpcionGestionActuador" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxInformacionOpcionGestionActuador">
                        <Box id="BoxTituloInformacionOpcionGestionActuador">
                            <Typography id="TxtTitutlosFormulariosOpcionGestionActuador">
                                {
                                    "Información de actuador"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxOpcionesFormularioGestionActuador">
                            <Box id="BoxTextFieldOpcionesFormularioGestionActuador">
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                <TextFieldTres
                                    id={"nombreActuador"}
                                    label={"Nombre"}
                                    placeholder={"Porton entrada A"}
                                    icon={null                                    }
                                    helper={formik.errors.nombreActuador}
                                    value={formik.values.nombreActuador}
                                    name={"nombreActuador"}
                                    handlerChange={formik.handleChange}
                                    handlerClick={null} 
                                    disabled={false}
                                />
                                </Box>
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                    <FormControl fullWidth sx={{borderBottom:"2px solid #8A8A8A"}}>
                                        <Select 
                                        id={"idTipoActuador"}
                                        disableUnderline 
                                        variant="filled" 
                                        fullWidth
                                        name={"idTipoActuador"}
                                        value={formik.values.idTipoActuador}
                                        onChange={formik.handleChange}
                                        >
                                            {
                                                tipoActuadores.length > 0 ?
                                                tipoActuadores.map((item, index) => {
                                                    return(
                                                        <MenuItem
                                                        disableRipple
                                                        key={index}
                                                        value={item.id}
                                                        id=""
                                                        >
                                                        <Box id="">
                                                            <Typography>{item.tipo}</Typography>
                                                        </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem
                                                disableRipple
                                                key={0}
                                                value={0}
                                                >
                                                    <Typography>{"obteniendo..."}</Typography>
                                                </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box id="BoxTextFieldOpcionesFormularioGestionActuador">
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                    <FormControl fullWidth sx={{borderBottom:"2px solid #8A8A8A"}}>
                                        <Select 
                                        disableUnderline 
                                        sx={{height:"56px"}} 
                                        variant="filled" 
                                        fullWidth
                                        id={"idSentido"}
                                        name={"idSentido"}
                                        value={formik.values.idSentido}
                                        onChange={formik.handleChange}
                                        >
                                            {
                                                sentidos.length > 0 ?
                                                sentidos.map((item, index) => {
                                                    return(
                                                        <MenuItem
                                                        disableRipple
                                                        key={index}
                                                        value={item.id}
                                                        id=""
                                                        >
                                                        <Box id="">
                                                            <Typography>{item.tipo}</Typography>
                                                        </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem
                                                disableRipple
                                                key={0}
                                                value={0}
                                                id=""
                                                >
                                                    <Box id="">
                                                        <Typography>{"obteniendo..."}</Typography>
                                                    </Box>
                                                </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                    <TextFieldCuatroMask
                                    id={"macActuador"}
                                    mask={TextMaskCustomMAC}
                                    label={"MAC"}
                                    placeholder={"A0-A1-A2-B0-B1-B2"}
                                    icon={null}
                                    helper={formik.errors.mac}
                                    value={formik.values.mac.toUpperCase()}
                                    name={"mac"}
                                    handlerChange={formik.handleChange}
                                    handlerClick={null}
                                    disabled={false}
                                    />
                                </Box>
                            </Box>
                            <Box id="BoxTextFieldOpcionesFormularioGestionActuador">
                                <Box id="BoxOpcionTipoDosTextFielFormularioGestionActuador">
                                    <Box id="BoxOpcionTipoDos">
                                        <TextFieldTres
                                        id={"serial"}
                                        label={"Serial"}
                                        placeholder={"1234656789A"}
                                        icon={null}
                                        helper={formik.errors.serial}
                                        value={formik.values.serial}
                                        name={"serial"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                        />
                                    </Box>
                                    <Box id="BoxOpcionTipoDos">
                                        <TextFieldTres
                                        id={"clave"}
                                        label={"Clave"}
                                        placeholder={"ABCDEF45@"}
                                        icon={null}
                                        helper={formik.errors.clave}
                                        value={formik.values.clave}
                                        name={"clave"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                        />
                                    </Box>
                                </Box>
                                <Box id="BoxOpcionTipoDosTextFielFormularioGestionActuador">
                                    <Box id="BoxOpcionTipoDos">
                                    <TextFieldTres
                                        id={"ip"}
                                        label={"IP"}
                                        placeholder={"255.255.255.255"}
                                        icon={null}
                                        helper={formik.errors.ip}
                                        value={formik.values.ip}
                                        name={"ip"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                        />
                                    </Box>
                                    <Box id="BoxOpcionTipoTres">
                                        <TextFieldTres
                                        id={"puerto"}
                                        label={"Puerto"}
                                        placeholder={"4370"}
                                        icon={null}
                                        helper={formik.errors.puerto}
                                        value={formik.values.puerto}
                                        name={"puerto"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={false}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxSegundaInformacionOpcionGestionActuador">
                        <Box id="BoxTituloSegundaInformacionOpcionGestionActuador">
                            <Typography id="TxtTitutlosFormulariosOpcionGestionActuador">
                                {
                                    "Información de zona"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxSegundasOpcionesFormularioGestionActuador">
                            <FormControl fullWidth sx={{borderBottom:"2px solid #8A8A8A"}}>
                                <Select 
                                id={"idZona"}
                                name={"idZona"}                                
                                disableUnderline 
                                sx={{height:"56px"}} 
                                variant="filled" 
                                fullWidth
                                value={formik.values.idZona}
                                onChange={formik.handleChange}
                                >
                                {
                                    zonasFormadas.length > 0 ?
                                    zonasFormadas.map((item, index) => {
                                            return(
                                                        <MenuItem
                                                        disableRipple
                                                        key={index}
                                                        value={item.id}
                                                        id=""
                                                        >
                                                        <Box id="">
                                                            <Typography>{item.tipo}</Typography>
                                                        </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                        :
                                        <MenuItem
                                            disableRipple
                                            key={0}
                                            value={0}
                                            id=""
                                            >
                                            <Box id="">
                                                <Typography>{"obteniendo..."}</Typography>
                                            </Box>
                                        </MenuItem>
                                }
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxHandlerFormularioOpcionGestionActuador">
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                    </Box>
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Actualizar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCinco
                        open={openLoading} 
                        setOpen={setOpenLoading} 
                        msj={"La información del usuario está siendo actualizada. Este proceso puede tardara solo un momento. Agradecemos tu paciencia."} 
                        id={"RespuestaGuardarEditarUsuario"} 
                        tituloLoading={"Actualizando datos..."}
                        loading={loading} 
                        icono={
                            response === 200 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={
                            response === 200 ? 
                            "Aceptar"
                            :
                            "Reintentar"
                        }
                        titulo={response === 200 ? "Actualización exitosa":"Error al actualizar"}
                        mensajeRespuesta={response === 200 ? 
                            "La información del usuario se ha actualizado correctamente. Ahora puedes gestionar sus permisos o añadir un nuevo usuario"
                            :
                            "Ha ocurrido un error y no se ha podido actualizar la información del usuario. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
                        />
                        <ConfirmationDialog
                        icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                        titulo={"¿Actualizar datos?"}
                        mensaje={"Estás a punto de actualizar la información del usuario. ¿Deseas continuar?"}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}