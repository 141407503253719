import { LoginForm } from "./LoginForm";
import { RecuperarForm } from "./RecuperarForm";

export const HandlerVentanaUno = ({opcion, handleOpcion}) => {

    return(
        opcion === 1 ?
        <LoginForm handleOpcion={handleOpcion} />
        :
        <RecuperarForm handleOpcion={handleOpcion} />
    );
}