import { Box, Typography } from "@mui/material";
import "./OpcionesZona.css"
import { TextFieldUno } from "../../TextField/TextFieldUno";
import { useFormik } from "formik";
import { SwitchTablas } from "../../Switch/switch";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { useState } from "react";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import useZonas from "../../../Hooks/Zona/useZonas";
import { useSelector } from "react-redux";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";
import { EsquemaZona } from "./Validation";



export const NuevaZona = ({setOpen, setRows}) => {
    const [openLoading, setOpenLoading] = useState(false);
    const data = useSelector(action => action.user.authenticated)
    const [snackMsg, setSnackMsg] = useState({ severity: '', mensaje: ''});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const { loading, response, setLoading, HandleAgregarZona} = useZonas();

    const handleClose = () => {
        setOpen(false);
    }

    const AgregarRow = (nuevaId) => {
        var nuevaRow = {
            idZona: nuevaId,
            nombre: formik.values.nombreZona,
            antiPassBack: formik.values.apb,
            actuadoresEnZona: [],
            estado: true,
            idEmpresa: data.idEmpresa,
            isNew: true,
        }
        setRows((oldRows) => [...oldRows, nuevaRow]);
    }

    const formik = useFormik({
        initialValues: {nombreZona:'', apb: false},
        enableReinitialize: true,
        validationSchema: EsquemaZona,
        onSubmit: async () => {
            setLoading(true)      
            setOpenLoading(true)
            await HandleAgregarZona(formik.values, data.idEmpresa)
            .then(
                (resolve) => {
                    AgregarRow(resolve);
                }
                ,
                (reject) => {
                    setSnackMsg({ severity: 'error', mensaje: 'Ocurrio un error en la creacion, intente nuevamente'})
                    setSnackbar(true)
                }
            )
        }
    })

    const handleAgregarOtro = () => {
        formik.resetForm()
    }

    return(
        <Box id="BoxNuevaZona">
            <Box id="BoxBodyContenidoNuevaZona">
                <Box id="BoxContenidoFormularioNuevaZona">
                    <Box id="BoxTituloSubTituloNuevaZona">
                        <Box id="BoxTituloNuevaZona">
                            <Typography id="TxtTituloNuevaZona">
                                {"Crear zona"}
                            </Typography>
                        </Box>
                        <Box id="BoxSubTituloNuevaZona">
                            <Typography id="TxtSubtituloNuevaZona">
                                {
                                    "Todos los campos son obligatorios para completar el registro."
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box id="BoxFormularioNuevaZona">
                        <Box id="BoxFormularioOpcionNombreNuevaZona">
                            <Box id="BoxTituloOpcionNombreFormularioNuevaZona">
                                <Typography id="TxtTituloOpcionFormularioNuevo">
                                    {
                                        "Información de la zona"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTxtOpcionNombreFormularioNuevaZona">
                                <TextFieldUno
                                id="nombreZona"
                                label={"Nombre de la zona"}
                                placeholder={"Z-tres"}
                                name={"nombreZona"}
                                icon={<IconoBorrarCampoGU id="IconoEnTFUnoZona" />}
                                disabled={false}
                                formHandle={() => formik.setFieldValue("nombreZona", "")}
                                value={formik.values.nombreZona}
                                handlerChange={formik.handleChange}
                                focus={false}
                                helper={formik.errors.nombreZona}
                                />
                            </Box>
                        </Box>
                        <Box id="BoxFormularioOpcionAPBNuevaZona">
                            <Box id="BoxTituloOpcionAPBFormularioNuevaZona">
                                <Typography id="TxtTituloOpcionFormularioNuevo">
                                    {
                                        "Anti-passback en la zona"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTxtOpcionNombreFormularioNuevaZona">
                                <SwitchTablas 
                                value={formik.values.apb}
                                disabled={false}
                                handler={() => formik.setFieldValue("apb", !formik.values.apb)} 
                                />
                                
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesFormularioNuevaZona">
                    <Box id="BoxBtnAccionNuevaZona">
                        <BotonTipo2 
                        textoBoton={"Cancelar"}
                        event={handleClose}
                        disabled={false}
                        />
                    </Box>
                    <Box id="BoxBtnAccionNuevaZona">
                        <BotonTipo2 
                        textoBoton={"Crear"}
                        event={formik.submitForm}
                        disabled={false}
                        />
                    </Box>
                </Box>
            </Box>
            <LoadingMasRespuestaCuatro
            id={"LoadingMasRespuestaCuatroNuevaZona"}
            open={openLoading}
            setOpen={setOpenLoading}
            msj={
                "Se está agregando una nueva zona al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
            }
            tituloLoading={"Agregando actuador..."}
            loading={loading}
            respuesta={response}
            titulo={
                response === 201 ? 
                "Zona agregada"
                : 
                "Zona no agregado"
            }
            mensajeRespuesta={
                response === 201 ? 
                "La zona ha sido agregada exitosamente al sistema. Ahora puedes gestionar sus acceos o agregar una nueva zona."
                : response === 403 ?
                    "No se ha podido generar la solicitud de creacion, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                    :
                    "No se ha podido agregar la zona debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
            }
            txtBotonUno={"Agregar nueva zona"}
            eventBotonUno={handleAgregarOtro}
            />
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackMsg.severity}
            msg={snackMsg.mensaje}
            />

        </Box>
    );
}