import { Box } from "@mui/material";
import { Campana } from "../Icons/Iconos";
import CircleIcon from '@mui/icons-material/Circle';
import "./Bag.css"

export const PersonalizedBag = ({totalMensaje}) =>{
    return (
        <Box id="BoxPersonalizado" >
            <Campana id="CampanaNotificacion" />
            {
                totalMensaje > 0 ?
                <CircleIcon  id="CirculoDeDatos"/>
                :
                null
            }
        </Box>
    );
}