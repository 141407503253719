import * as Yup from 'yup';

export const EsquemaNuevo = Yup.object({
    idPersonaMiembro: Yup.number()
    .required('* Campo requerido')
    .test("min", "Debe seleccionar un usuario", function(value){
        if(value === -1){
            return false;
        }
        else
        {
            return true;
        }
    })
})