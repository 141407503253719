import { Box, CardMedia, IconButton, TextField, Typography } from "@mui/material";
import "./GenerarQREvento.css";
import { FechaIconVerificarEvento, HoraIconVerificarEvento, UbicacionIconVerificarEvento } from "../ValidacionEvento/IconosVerificarEvento";
import { ModalLoadingSimple } from "../Modal/ModalLoding/ModalLoading";
import { BotonConLoading } from "../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useVisitas from "../../Hooks/Eventos/useVisitas";
import dayjs from "dayjs";
import lcp2 from "../../assets/imagenes/CP2.png"
import { cleanRut, validateRut } from "../../Helper/ValidarRut";
import * as Yup from 'yup';
import TooltipTipoUno from "../ToolTip/Tooltip";
import { SnackBarTipoUno } from "../snackBar/SnackBar";


const ObtenerMesActualTexto = (id) =>{
    const date = new Date();
    date.setMonth(id);

    var mes = date.toLocaleString('es-ES', {month: 'long'})
    return mes;
}


export const ValidarEventoExistente = ({setOpcion, setInfoEvento}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [tipoIdentificador, setTipoIdentificador] = useState(false);
    const [evento, setEvento] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [dataEvento, setDataEvento] = useState({});
    const {BuscarEvento, loading, ValidarInvitado   } = useVisitas();
    const buscar = useRef();
    const [snackMsg, setSnackMsg] = useState({severity: '', mensaje:''});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);


    let nombre = dataEvento.nombreUbicacion === null ? "sin informacion": dataEvento.nombreUbicacion
    let numeracion = dataEvento.numeracion === null ? "sin informacion": dataEvento.numeracion
    let piso = dataEvento.pisoUbicacion === null ? "sin informacion": dataEvento.pisoUbicacion

    let destinoData = nombre + ", N°" + numeracion + ", Piso: " + piso + "."

    useEffect(() => {
        if(searchParams.size > 0){
            setEvento(atob(searchParams.get('idVisita')))
            searchParams.delete('idVisita');
            setSearchParams(searchParams);
            setTimeout(() => {
                ActivarBoton();
            }, 1000);
        }
    },[searchParams, setSearchParams]);

    const ValidationSchemaIdentificador = Yup.object({
        identificador: Yup.string()
        .test("min", tipoIdentificador ? "Rut invalido" : "Información no valida", function(value){
            var reg = /^\d+$/;
            if(tipoIdentificador){
                return (validateRut(cleanRut(value)));
            }
            else{
                if(value.match(reg)){
                    return true;
                }
                else{
                    return false;
                }
            }
        })
        .required("Este campo es obligatorio")        
    })

    
    const formik = useFormik({
        initialValues: {identificador:""},
        validationSchema: ValidationSchemaIdentificador,
        onSubmit: async () => {
            //derivar a la opcion 2 con los datos de la opcion
            await ValidarInvitado(formik.values.identificador, evento, tipoIdentificador)
            .then(
                (resolve) => {
                    setInfoEvento({idVisitaVisitante:resolve})
                    setOpcion(2)
                }
                ,
                (reject) => {
                    setSnackMsg({severity: "error", mensaje: "El usuario no pudo ser validado, reingresa los datos"})
                    setSnackbar(true)
                }
            )
            
        }, 
    });

    
    const handleIdentificador = () => {
        setTipoIdentificador(!tipoIdentificador)
    } 

    const ActivarBoton = () => {
        buscar.current.click()
    }

    const handleClickSearchEvento = async () => {
        setOpenModal(true);
        await BuscarEvento(evento)
        .then(
            
            (resolve) => {
                setDataEvento(resolve);
                setIsValid(true);
                setOpenModal(false);
            }
            ,
            (reject) => { 
                setDataEvento({});
                setIsValid(false);
                setOpenModal(false);
            }
        )
       
    } 

    

    return (
        <Box id="BoxContenedorQRVisita">
            <input ref={buscar} onClick={handleClickSearchEvento} style={{display:"none"}} />
            <Box id="BoxImagenMasContenidoQRVisita">
                <Box id="BoxContenedorImagenCP2QRVisita">
                    <CardMedia id="ImagenLogoCP2QRVisita" component="img" src={lcp2} />
                </Box>
                <Box id="BoxInformacionEventoQRVisita">
                    <Box id="BoxTituloAyudaInputQRVisita">
                        <Box id="BoxTituloEInformacionQRVisita">
                            <Box id="BoxTituloNombreEventoQRVisita">
                                <Typography id="TextoTituloNombreEventoQRVisita">
                                    {
                                        "Obtén tu código QR para el evento"                                            
                                    }
                                </Typography>
                                <Typography id="TextoTituloNombreEventoQRVisita">
                                    {
                                        dataEvento.nombreEvento                                       
                                    }
                                </Typography>
                            </Box>
                        <Box id="BoxDataDelEvento">
                            <Box id="TablaDataEventoQRVisita">
                                <Box id="BoxInformacionOrdenandaMasIconoQRVisita">
                                    <FechaIconVerificarEvento id="iconoQrEvento" /> 
                                    <Typography id="TextoInformacionEventoQrVisita">
                                        {
                                            "Fecha: " + dayjs(dataEvento.fechaVisita).format("DD") + " de " + ObtenerMesActualTexto(dayjs(dataEvento.fechaVisita).month()) + " del " + dayjs(dataEvento.fechaVisita).format("YYYY")
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInformacionOrdenandaMasIconoQRVisita">
                                    <HoraIconVerificarEvento id="iconoQrEvento" /> 
                                    <Typography id="TextoInformacionEventoQrVisita">
                                        {
                                            "Hora: De " + dayjs(dataEvento.fechaVisita).format("HH:mm") + " a " + dayjs(dataEvento.fechaTermino).format("HH:mm") + " horas."
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInformacionOrdenandaMasIconoQRVisita">
                                    <UbicacionIconVerificarEvento id="iconoQrEvento" /> 
                                    <TooltipTipoUno titulo={destinoData} ubicacion={"bottom"}>
                                        <Typography id="TextoInformacionEventoQrVisita">
                                            {
                                                "Destino: " + destinoData
                                            }
                                        </Typography>
                                    </TooltipTipoUno>
                                </Box>
                                <Box id="BoxInformacionOrdenandaMasIconoQRVisita">
                                    <Box id="iconoQrEvento">
                                    </Box>
                                    <TooltipTipoUno titulo={dataEvento.direccionEmpresa} ubicacion={"bottom"}>
                                        <Typography id="TextoInformacionEventoQrVisita">
                                            {
                                                "Direccion: " + dataEvento.direccionEmpresa + "."
                                            }
                                        </Typography>
                                    </TooltipTipoUno>                                    
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxAyudaMasInputVerifcadorQRVisita">
                        <Box id="BoxInformacionAyudaQRVisita">
                            <Typography id="TextoAyudaQRVisita">
                                {
                                    "Por favor, ingresa tu identificador para generar el código QR de acceso al evento."
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxInputIdentificadorQRVisita" component={"form"} onSubmit={formik.handleSubmit}>
                            <TextField fullWidth
                                sx={{
                                    borderRadius: "8px"
                                }}
                                id="identificador"
                                name={"identificador"}
                                hiddenLabel={true}
                                variant='filled'                                    
                                placeholder={"Información de identificacion"}
                                inputProps={
                                    {
                                        style: {
                                            paddingLeft: "15px",
                                        }
                                    }
                                }
                                value={formik.values.identificador}
                                onChange={formik.handleChange}
                                InputProps={
                                    {
                                        style: {
                                            paddingLeft: "0px",
                                        }
                                        ,
                                        disableUnderline: true
                                        ,
                                        startAdornment: tipoIdentificador ? 
                                        <IconButton id="IconButtonIdentificador" onClick={handleIdentificador}>
                                            <Typography id="TextoIdentificadorQRVisita">
                                                {
                                                    "Rut"
                                                }
                                            </Typography>
                                        </IconButton> 
                                        : 
                                        <IconButton id="IconButtonIdentificador" onClick={handleIdentificador}>
                                            <Typography id="TextoIdentificadorQRVisita">
                                                {
                                                    "Pasaporte"
                                                }
                                            </Typography>
                                        </IconButton>
                                    }
                                }
                                helperText={formik.errors.identificador}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionQrVisita">
                    {
                        isValid === true  && dataEvento.estado === true ? 
                            <BotonConLoading textoBoton={"Generar Codigo QR"} event={dataEvento.estado ? formik.submitForm:null} loadingStatus={loading} />
                            :
                            <Typography id="TextoVentanaObteniendoDataQRVisitaRespuestaServidor" >
                                {
                                    dataEvento.estado === false ?
                                    "Este evento se encuentra finalizado, confirme su invitacion"
                                    :
                                    "Alerta! No ha proporcionado informacion del evento"
                                }
                            </Typography>
                    }
                </Box>
            </Box>
            </Box>
            <Box id="BoxFooterQRVisita">
                {
                    loading === true ?
                    <Typography id="TextoVentanaObteniendoDataQRVisita" >
                    {
                        "Se esta verificando tu informacion en el sistema. Por favor, espera un momento..."
                    }
                    </Typography>
                    :
                    null
                        
                }
                <ModalLoadingSimple open={openModal} setOpen={setOpenModal} msj={"Validando evento..."} />
            </Box>
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackMsg.severity}
            msg={snackMsg.mensaje}
            />
        </Box>
    );
}