import { Box, IconButton, Typography } from "@mui/material";
import "./EventoEnAgenda.css";
import dayjs from "dayjs";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { PopOverTipoEventosDetalleAgenda } from "../../Popover/PopoverTipoUno";
import { DetalleEventoAgendado } from "../CuerpoAgenda/OpcionesEvento/ConEvento/ConEvento";
import { useState } from "react";

const ObtenerMesActualTexto = (id) =>{
    const date = new Date();
    date.setMonth(id);

    var mes = date.toLocaleString('es-ES', {month: 'short'})
    return mes;
}



export const EventoCorto =  ({eventoData}) => {
    var mes = ObtenerMesActualTexto(dayjs(eventoData.fechaVisita).month())

    return(
        <Box id="BoxContenidoDetalleEventoFechaDuracion">
            <Box id="BoxContenedorFechaFormatoCalendario">
                <Box id="BoxCabeceraCalendarioDetalleEventoView" />
                <Box id="BoxCuerpoCalendarioDetalleEventoView">
                    <Typography id="TxtCalendarioFechaInicioEventoView">
                        {
                            dayjs(eventoData.fechaVisita).format("DD")
                        }
                    </Typography>
                </Box>
                <Box id="BoxFooterCalendarioDetalleEventoView">
                    <Typography id="TxtMesCortoCalendarioFechaInicioEventoView">
                        {
                            mes.toUpperCase()
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxContenedorInformacionEvento">
                <Box id="BoxNombreEventoAgendadoView">
                    <Typography id="TxtNombreEventoAgendadoView">
                        {
                            eventoData.nombreEvento
                        }
                    </Typography>
                </Box>
                <Box id="BoxContenedoHorasInicioTerminoEventoView" height={eventoData.idTipoVisita === 1 ? "40px":"20px"}>
                    <Box id="BoxContenedorTxtHoraInicioFinEventoView">
                        <Typography id="TxtHoraInicioFinEventoView">
                            {
                                "De " + dayjs(eventoData.fechaVisita).format("HH:mm") + " a " + dayjs(eventoData.fechaTermino).format("HH:mm") + " Horas"
                            }
                        </Typography>
                    </Box>
                    {
                        eventoData.idTipoVisita === 1 ? 
                        <Box id="BoxContenedorFechaTerminoTipoUnoEventoView">
                            <Typography id="TxtFechaTerminoFinEventoView">
                            {
                                "Finaliza el " + dayjs(eventoData.fechaTermino).format("DD/MM/YYYY") 
                            }
                            </Typography>
                        </Box>
                        :
                        null
                    }
                </Box>
                <Box id="BoxContenedorUbicacionEventoView">
                    <Typography id="TxtNombreUbicacionEventoAgendadoView">
                        {
                            eventoData.nombreUbicacion + " Nº"+ eventoData.numeracion + "|Piso " + eventoData.pisoUbicacion
                        }
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}


export const EventoCortoTipoDos =  ({eventoData}) => {
    const [anchorElEC, setAnchorElEC] = useState(false);

    const openCreado = Boolean(anchorElEC)
    
    const HandleOpenDetalleEvento = (e) => {
        setAnchorElEC(e.currentTarget)
    }

    return(
        <Box id="BoxContenidoDetalleEventoFechaDuracionTipo2">            
            <Box id="BoxContenedorInformacionEventoTipo2">
                <Box id="BoxNombreEventoAgendadoViewTipo2">
                    <Typography id="TxtNombreEventoAgendadoViewTipo2">
                        {
                            eventoData.nombreEvento
                        }
                    </Typography>
                </Box>
                <Box id="BoxContenedoHorasInicioTerminoEventoViewTipo2" height={eventoData.idTipoVisita === 1 ? "40px":"20px"}>
                    <Box id="BoxContenedorTxtHoraInicioFinEventoViewTipo2">
                        <Typography id="TxtHoraInicioFinEventoViewTipo2">
                            {
                                "De " + dayjs(eventoData.fechaVisita).format("HH:mm") + " a " + dayjs(eventoData.fechaTermino).format("HH:mm") + " Horas"
                            }
                        </Typography>
                    </Box>
                    {
                        eventoData.idTipoVisita === 1 ? 
                        <Box id="BoxContenedorFechaTerminoTipoUnoEventoViewTipo2">
                            <Typography id="TxtFechaTerminoFinEventoViewTipo2">
                            {
                                "Finaliza el " + dayjs(eventoData.fechaTermino).format("DD/MM/YYYY") 
                            }
                            </Typography>
                        </Box>
                        :
                        null
                    }
                </Box>
            </Box>            
            <Box>
                <IconButton onClick={(e) => HandleOpenDetalleEvento(e)}>
                    {
                        <KeyboardArrowRightIcon  />
                    }
                </IconButton>
            </Box>
            <PopOverTipoEventosDetalleAgenda open={openCreado} anchorEl={anchorElEC} setOpen={setAnchorElEC} children={<DetalleEventoAgendado eventoData={eventoData} setOpen={setAnchorElEC} />}/>
        </Box>
    );
}