import axios from "axios";
import { useState } from "react";
import { ConfirmacionCorreo } from "../../Service/API/Api";

export function useConfirmacion(){
    const [loading, setLoading] = useState();
    const [respuesta, setRespuesta] = useState();

    const ConfirmarCorreo = (token, correo) => {
        const nuevoCorreo = {
            Token: token,
            Correo: correo
        }

        axios.post(ConfirmacionCorreo, nuevoCorreo)
        .then((response) => {
            setLoading(false);
            setRespuesta(response.status);
        })
        .catch(
            (response)=>{
                setLoading(false);
                console.error(response.response)
            }
        );
        
    }

    return {
        ConfirmarCorreo,
        loading,
        setLoading,
        respuesta
    };
}