import { SvgIcon } from "@mui/material";
import "./GestionUbicaciones.css"

export function IconoGestionUbicaciones (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="28" viewBox="0 0 22 28" fill="none">
                <path d="M11 15.3333C12.864 15.3333 14.375 13.7053 14.375 11.697C14.375 9.68866 12.864 8.06061 11 8.06061C9.13604 8.06061 7.625 9.68866 7.625 11.697C7.625 13.7053 9.13604 15.3333 11 15.3333Z" stroke="#333333" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11 26C15.9706 23.5758 20 17.0525 20 11.697C20 6.34148 15.9706 2 11 2C6.02943 2 2 6.34148 2 11.697C2.50625 18.9091 7.625 24.3636 11 26Z" stroke="#333333" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>

    )
}

export function PlusIcon (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 18 18" fill="none">
                <path d="M1 9H17M9 1V17" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function UsuariosIconA (props){
    return(
        <SvgIcon {...props} className="usuarios-icon-a">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect x="0" y="0" width="24" height="24" rx="0" className="fondo-usuarios-icon-a" fill="none" />
                <path className="trazo-usuarios-icon-a" d="M19 15C21.2091 15 23 16.7909 23 19V21H21M16 10.874C17.7252 10.4299 19 8.86383 19 6.99999C19 5.13615 17.7252 3.57005 16 3.12601M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM5 15H13C15.2091 15 17 16.7909 17 19V21H1V19C1 16.7909 2.79086 15 5 15Z"/>
            </svg>
        </SvgIcon>
    )
}

export function PapeleroIconA (props){
    return(
        <SvgIcon {...props} className="icono-papelero">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24<" fill="none">
                <rect x="0" y="0" width="24" height="24" rx="0" className="fondo-icono-papelero" fill="none" />
                <path className="trazo-icono-papelero" d="M10 10V16M14 10V16M18 6V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V6M4 6H20M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6"/>
            </svg>
        </SvgIcon>
    )
}

export function XCloseGestionUbicaciones (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M17 1L1 17M1.00002 1L17 17" stroke="#626262" strokeidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}