import { Box } from "@mui/material";
import CabeceraTablasGestion from "../CabeceraTablasGestion/CabecerasTablasGestion";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useEffect, useState } from "react";
import useConfirm from "../../Hooks/ConfirmDialog/useConfirmDialog";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { IconoAlerta, IconoEditarUsuario } from "../Usuarios/UsuariosIcon";
import { SnackBarTipoUno } from "../snackBar/SnackBar";
import TooltipTipoUno from "../ToolTip/Tooltip";
import { SwitchTablas } from "../Switch/switch";
import "./GestionPatentes.css";
import usePatentes from "../../Hooks/Patentes/usePatentes";
import { OpcionesPatente } from "./OpcionesPatente/OpcionesPatente";

export const Patentes = () => {
    const {loading, patentes, ActivarDesactivarPatente} = usePatentes();
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [confirmMessage, setConfirmMessage] = useState({titulo: '', mensaje: ''});
    const [openGestionPatente, setOpenGestionPatente] = useState(Boolean(false));
    const [opcionSeleccionada, setOpcionSeleccionada] = useState();
    const [dataEdit, setDataEdit] = useState({});

    useEffect(() => {
        setRows(patentes)
    }, [patentes])
    

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            const filteredRows = patentes.filter((row) => { 
                return Object.keys(row).some((field) => {
                  return searchRegex.test(row[field]);
                });
            });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleEstadoPatente = (id) => async() => {
        let rowFinded = rows.find(d => d.idPatente === id);
        if(rowFinded.estadoPatente){
            setConfirmMessage({ titulo: "¿Desactivar?", mensaje: "Estás a punto de desactivar la patente ¿Deseas continuar?"}) 
        }
        else{
            setConfirmMessage({ titulo: "¿Activar?", mensaje: "Estás a punto de activar la patente ¿Deseas continuar?"}) 
        }
        let response = await confirm();
        if(response)
        {
            await ActivarDesactivarPatente(rowFinded.idPatente, rowFinded.estadoPatente)
            .then(
                (resolve) => {
                    rowFinded.estadoPatente = !rowFinded.estadoPatente
                    setRows(rows.map((row) => (row.idPatente === rowFinded.idPatente ? rowFinded : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(rowFinded.estadoPatente === true ? 
                        "Patente Activada exitosamente!"
                        :
                        "Patente desactivada exitosamente!"
                    )
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Fallo la modificacion, reintente")
                    setSnackbar(true);
                }
            )
        }
        else
        {
            console.log("Rechaza modificacion");
        }
    }

    const handleNuevaPatente = () => {
        setOpenGestionPatente(true);
        setOpcionSeleccionada(1);
    }


    const handleEditarPatente = (id) => () =>{
        var dataEditar = rows.find(p => p.idPatente === id);
        setOpenGestionPatente(true);
        setDataEdit(dataEditar);
        setOpcionSeleccionada(2);
    }

    const columns = [
        { field: 'patenteDigitos', headerName: 'P. Patente', flex: 0.5, minWidth: 150, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'nombreCompleto', headerName: 'Pertenece a', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'rut', headerName: 'Rut', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'pasaporte', headerName: 'Pasaporte', type: 'string', flex: 0.5, minWidth: 200, valueFormatter: (params) => params?.value === null ? "-":params?.value, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'codigo', headerName: 'TAG', type: 'string', flex: 0.5, minWidth: 200, headerAlign:  'center',valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'estadoPatente', headerName: 'Estado', type:'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions', 
            getActions:({id, row})=>{
                return [
                    <SwitchTablas
                    id={"SwitchEstadoActuadorTabla"}
                    value={row.estadoPatente}
                    handler={handleEstadoPatente(id)}
                    disabled={false} />
                    ]
            }
        }
        ,
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <TooltipTipoUno titulo={"Editar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<IconoEditarUsuario  id='iconoEnTablaUsuarios'/>}
                        label="Editar"
                        onClick={handleEditarPatente(id)}
                        />
                    </TooltipTipoUno>
                ];
            }
        },
    ];

    return(
        <Box id="BoxContenedorTablaPatentesEmpresa">
            <CabeceraTablasGestion 
            icono1={<DirectionsCarIcon />}
            titulo={"Patentes"}
            rows={rows}
            setRows={setRows}
            agregarNuevoIcon={true}
            handlerAN={handleNuevaPatente}
            cargaMasivaIcon={null}
            handlerCM={null}
            descargarIcon={null}
            handlerDownload={null}
            onChange={handleSearch}
            value={searchText}
            clearSearch={clearSearch}
            >
                <Box id="BoxSelectContainerCabeceras">
                    {/* <FiltroSelect id="FiltroSelectTiposActores" 
                    nombre={"Tipos"} 
                    listaOpciones={tipoActuadores}
                    value={tipoActuador}
                    handler={handleTipoActuadores}
                    />
                    <FiltroSelect id="FiltroSelectZonasEmpresa" 
                    nombre={"Zonas"} 
                    listaOpciones={zonasFormadas}
                    value={opcionZona}
                    handler={handleZona}
                    /> */}
                </Box>
            </CabeceraTablasGestion>
            <DataGrid id='TablaPatentesEmpresa'
            style={{ width:'100%', height:"100%"}}
            sx={{ 
                '& .MuiDataGrid-columnHeaderTitle': {
                color:'#175676',
                },
                    '& .MuiDataGrid-columnHeader': {
                    borderBottom: '2px solid #94B1C0',
                },
                '& .MuiDataGrid-row':{
                    borderBottom:'1px solid #94B1C0'
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                    width: "7.024px",
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                    background: "#C9C9C9",
                    borderRadius: "100px"
                }
            }}
            getRowId={(row) => row.idPatente}
            columns={columns} 
            rows={rows}
            initialState={{
                pagination: {
                    paginationModel: {
                        page: 0,
                        pageSize: 10,
                        },
                    },
                }}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection
            loading={loading}
            disableRowSelectionOnClick 
            pageSizeOptions={[10, 20]}
            />
            
            <OpcionesPatente
            open={openGestionPatente}
            setOpen={setOpenGestionPatente}
            setRows={setRows}
            opcion={opcionSeleccionada}
            dataEditar={dataEdit}
            rows={rows}
            />

            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />

            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={confirmMessage.titulo}
            mensaje={confirmMessage.mensaje}
            />
        </Box>
    );
}


