import { useEffect, useState } from "react";
import axios from "axios";
import { CrearZona, DesactivarAPBZona, DesactivarZona, EditarZona, ReactivarAPBZona, ReActivarZona, ZonasEmpresa } from "../../Service/API/Api";

export default function useZonas(){
    const [zonas, setZonas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState();
    const [reload, setReload] = useState(true);

    useEffect(() => {
        axios.get(ZonasEmpresa)
        .then(
            (response) => {
                setZonas(response.data)
                setLoading(false)
            }
        )
        .catch(
            (error) => {
                console.log(error.response);      
                setLoading(false)          
            }
        )

        return () => setReload(false);

    }, [reload])
    
    const HandleEstadoZona = (id, estadoActual) => {

        return new Promise(async (resolve, reject) => {
            axios.patch(estadoActual ? DesactivarZona + id : ReActivarZona + id)
            .then(
                (response) => {
                    resolve(true)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setLoading(false)
                }
            )
        });
    }

    const HandleAPBZona = (id, estadoActual) => {
        return new Promise(async (resolve, reject) => {
            axios.patch(estadoActual ? DesactivarAPBZona + id : ReactivarAPBZona + id)
            .then(
                (response) => {
                    resolve(true)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setLoading(false)
                }
            )
        });
    }


    const HandleAgregarZona = (values, idEmpresa) => {
        return new Promise(async (resolve, reject) => {
            var nueva = {
                "nombre": values.nombreZona,
                "antiPassBack": values.apb,
                "idEmpresa": idEmpresa
              }

            axios.post(CrearZona, nueva)
            .then(
                (response) => {
                    resolve(response.data)
                    setResponse(response.status)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setResponse(error.response.status)
                    setLoading(false)
                }
            )
        });
    }

    const HandleEditarZona = (values, idEmpresa, idZona) => {
        return new Promise(async (resolve, reject) => {
            var toEdit = {
                "idZona": idZona,
                "nombre": values.nombreZona,
                "antiPassBack": values.antiPassBack,
                "idEmpresa": idEmpresa
            }

            axios.put(EditarZona, toEdit)
            .then(
                (response) => {
                    resolve(true)
                    setResponse(response.status)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setResponse(error.response.status)
                    setLoading(false)
                }
            )
        });
    }
    
    
    return {
        zonas,
        loading,
        setLoading,
        response,
        setReload,
        HandleEstadoZona,
        HandleAPBZona,
        HandleAgregarZona,
        HandleEditarZona
    }
}