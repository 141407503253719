import { Box, TextField, Typography } from "@mui/material";
import "./ListaNegra.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { NoData } from "../../../../Box/NoDataMenuGestionUsuario/NoData";
import dayjs from "dayjs";
import useListaNegra from "../../../../../Hooks/ListaNegra/useListaNegra";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";


export const ExisteEnListaNegra = ({informacion, setOpenAgregar, setInfo}) =>{
    const {EliminarEnLN, setLoading, loading, response} = useListaNegra();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const HandleDelete = async () => {
        const response = await confirm();

        if(response)
        {
            setLoading(true);
            setOpenLoading(true);
            await EliminarEnLN(informacion.idListaNegra)
            .then(
                (resolve) => {
                    setTimeout(() => {
                        setInfo({})
                    }, 2000);
                }
                ,
                (reject) => {
                    console.log("error:", reject);
                }
            );
        }
        else
        {
            console.log("rechaza modificacion")
        }
        
    }

    return (
        informacion.idListaNegra === null || informacion.idListaNegra === undefined ?
        <NoData
        textoBtn={"Añadir a Lista Negra"} 
        mensaje={"Este usuario actualmente no está en la lista negra. Agregarlo a la lista negra restringirá su acceso según las políticas de seguridad establecidas.Utiliza el botón “Agregar a lista negra” para proceder."}
        event={setOpenAgregar}
        />
        :
        <Box id="BoxLayoutExisteEnListaNegraGU">
            <Box id="BoxLayouContenidoExisteEnListaNegraGU">
                <Box id="BoxContenidoExisteEnListaNegraGU">
                    <Box id="BoxTituloContenidoExisteEnListaNegraGU">
                         <Typography id="TxtTituloExisteEnListaNegraGU">
                            {
                                "Información de ingreso"
                            }
                         </Typography>
                    </Box>
                    <Box id="BoxDataExisteEnListaNegraGU">
                        <Box id="BoxDatosFechaCreacionCreadorExisteEnListaNegraGU">
                            <Box id="BoxFechaCreacionExisteEnListaNegraGU">
                                <TextField label="Fecha de ingreso" readOnly fullWidth value={dayjs(informacion.fechaIngreso).format("DD/MM/YYYY HH:mm:ss")} />
                            </Box>
                            <Box id="BoxCreadorExisteEnListaNegraGU">
                                <TextField label="Ingresado por" readOnly fullWidth value={informacion.nombreCreador}/>
                            </Box>
                        </Box>
                        <Box id="BoxMotivoIngresoExisteEnListaNegraGU">
                            <TextField label="Motivo" readOnly fullWidth multiline rows={3} value={informacion.motivoIngreso}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box id="BoxAccionesExisteEnListaNegraGU">
                <Box id="BoxBtnRemoverExisteEnListaNegraGU">
                    <BotonTipo2 textoBoton={"Remover"} event={HandleDelete} />
                    <ConfirmationDialog
                    icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
                    titulo={"¿Remover usuario?"}
                    mensaje={"Estás a punto de remover a este usuario de la lista negra. Esta acción restaurará su acceso configurado previamente. ¿Deseas continuar?"}
                    />
                    <LoadingMasRespuestaCinco
                    open={openLoading}
                    setOpen={setOpenLoading}
                    msj={"Se esta procesando la remocion del usuario de la lista negra. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                    id={"LoadingAgregaListaNegraGU"}
                    tituloLoading={"Removiendo usuario..."}
                    respuesta={response}
                    loading={loading}
                    icono={
                        response === 200 ?
                        <IconoExito id="IconoRespuestaServidor" />
                        :
                        <IconoFallo id="IconoRespuestaServidor" />
                    }
                    textoBoton={response === 200 ? 
                        "Aceptar"
                        :
                        "Reintentar"
                    }
                    titulo={response === 200 ? 
                        "Usuario removido"
                        :
                        "Error al remover usuario"
                    }
                    mensajeRespuesta={response === 200 ? 
                        "El usuario ha sido removido exitosamente de la lista negra y su acceso ha sido restaurado. Ahora puedes gestionar sus permisos ."
                        :
                        "Ha ocurrido un error y no se pudo eliminar al usuario de la lista negra. Por favor, intenta de nuevo más tarde y si el problema persiste, contáctanos para asistencia."
                    }
                    />
                </Box>
            </Box>
        </Box>
    );
}