import { Box, IconButton, Typography } from "@mui/material";
import "./DetalleEventos.css";
import { CerrarDetalleIcon } from "./DetalleEventosIconos";
import useVisitantesEnVisita from "../../../Hooks/VisitantesEnVisita/useVisitantesEnVisita";
import dayjs from "dayjs";
import TooltipTipoUno from "../../ToolTip/Tooltip";

const ObtenerMesActualTexto = (id) =>{
    const date = new Date();
    date.setMonth(id-1);

    var mes = date.toLocaleString('es-ES', {month: 'long'})
    return mes.toUpperCase();
}

const DetalleEventos = ({ setOpen, item}) => {
    const {visitantes} = useVisitantesEnVisita(item.idVisita);

    if(item !== null){
        var mes = ObtenerMesActualTexto(dayjs(item.fechaVisita).format("MM"))
    }
    
    const handleClose = () =>{
        setOpen(null);
    }

    return (
            <Box id="BoxDetalleEventos">
                <Box id="BoxIconBtnCerrarDE">
                    <IconButton onClick={handleClose}>
                        <CerrarDetalleIcon id="iconoCierreDetalleEventos" />
                    </IconButton>
                    {/* <IconoEspecialConexion id="IconoEspecialConexionConTabla" /> */}
                </Box>
                <Box id="BoxDetalleEventoCreado">
                    <Box id="BoxFechaNombreHoraEventoCreado">
                        <Box id="BoxFechaEventoCreado">
                            <Typography id="FechaEventoCreadoHE">
                                {
                                    dayjs(item.fechaVisita).format("DD") + " DE " + mes
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxNombreEventoCreado">
                            <Typography id="NombreEventoCreadoHE">
                                {
                                    item.nombreEvento
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxHoraInicioTerminoEventoCreado">
                            <Typography id="HoraInicioTerminoEventoHE">
                                {
                                    "De " + dayjs(item.fechaVisita).format("HH:mm") + " a " + dayjs(item.fechaTermino).format("HH:mm") + " Horas"
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box id="BoxCreadorDetalleEventoCreado">
                        <Box id="BoxAgendoDetalleEventoCreado">
                            <Box id="BoxAgendoDetalleEventoCreadoHE">
                                <Typography id="AgendoDetalleEventoCreadorHE">
                                    {
                                        "Agendó"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxQuienAgendoDetalleEventoCreadoHE">
                                <TooltipTipoUno titulo={item.nombres} ubicacion={"top-start"}>
                                    <Typography id="NombreCreadorDetalleEventoCreadoHE">
                                        {
                                            item.nombres
                                        }
                                    </Typography>
                                </TooltipTipoUno>
                            </Box>
                        </Box>
                        <Box id="BoxRutDetalleEventoCreado">
                            <Box id="BoxAgendoDetalleEventoCreadoHE">
                                <Typography id="AgendoDetalleEventoCreadorHE">
                                    {
                                        "Rut"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxQuienAgendoDetalleEventoCreadoHE">
                                <Typography id="NombreCreadorDetalleEventoCreadoHE">
                                    {
                                        item.rut
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Box id="BoxTelefonoDetalleEventoCreado">
                            <Box id="BoxAgendoDetalleEventoCreadoHE">
                                <Typography id="AgendoDetalleEventoCreadorHE">
                                    {
                                        "Teléfono"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxQuienAgendoDetalleEventoCreadoHE">
                                <Typography id="NombreCreadorDetalleEventoCreadoHE">
                                    {
                                        item.telefono
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Box id="BoxDestinoDetalleEventoCreado">
                            <Box id="BoxAgendoDetalleEventoCreadoHE">
                                <Typography id="AgendoDetalleEventoCreadorHE">
                                    {
                                        "Destino"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxQuienAgendoDetalleEventoCreadoHE">
                                <Typography id="NombreCreadorDetalleEventoCreadoHE">
                                    {
                                        item.nombreUbicacion
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Box id="BoxPisoDetalleEventoCreado">
                            <Box id="BoxAgendoDetalleEventoCreadoHE">
                                <Typography id="AgendoDetalleEventoCreadorHE">
                                    {
                                        "Piso | N°"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxQuienAgendoDetalleEventoCreadoHE">
                                <Typography id="NombreCreadorDetalleEventoCreadoHE">
                                    {
                                        "Piso " + item.pisoUbicacion + " | " + item.numeracion 
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Box id="BoxInvitadosDetalleEventoCreado">
                            <Box id="BoxAgendoDetalleEventoCreadoHE">
                                <Typography id="AgendoDetalleEventoCreadorHE">
                                    {
                                        "Invitados"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxQuienAgendoDetalleEventoCreadoHE">
                                <Typography id="NombreCreadorDetalleEventoCreadoHE">
                                    {
                                        visitantes.length + " Personas"
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
    );
}


export default DetalleEventos;