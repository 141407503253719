import { Configuracion } from "./IconosMenu";
import { v4 as uuidv4 } from 'uuid';

export const ReporteSubMenu = [
    {
        id: uuidv4(),
        title: "Reportes",
        path: "/Reportes",
        icon: <Configuracion />,
    },
    {
        id: uuidv4(),
        title: "Graficos",
        path: "/Graficos",
        icon: <Configuracion />,
    }
]

export const ConfiguracionSM = [
    {
        id: uuidv4(),
        title: "Usuarios",
        path: "/Configuracion/Gestion/Usuarios",
        icon: <Configuracion />,
    }
    ,
    {
        id: uuidv4(),
        title: "Zonas",
        path: "/Configuracion/Gestion/Zonas",
        icon: <Configuracion />,
    }
    ,
    {
        id: uuidv4(),
        title: "Accesos",
        path: "/Configuracion/Gestion/Accesos",
        icon: <Configuracion />,
    }
    ,
    {
        id: uuidv4(),
        title: "Ubicaciones",
        path: "/Configuracion/Gestion/Ubicaciones",
        icon: <Configuracion />,
    }
    ,
    {
        id: uuidv4(),
        title: "Estacionamientos",
        path: "/Configuracion/Gestion/Estacionamientos",
        icon: <Configuracion />,
    }
    ,
    {
        id: uuidv4(),
        title: "Horarios",
        path: "/Configuracion/Gestion/Horarios",
        icon: <Configuracion />,
    }
    // ,
    // {
    //     id: uuidv4(),
    //     title: "Huellas",
    //     path: "/Configuracion/Gestion/Huellas",
    //     icon: <Configuracion />,
    // }
    // ,
    // {
    //     id: uuidv4(),
    //     title: "Facial",
    //     path: "/Configuracion/Gestion/Facial",
    //     icon: <Configuracion />,
    // }
    ,
    {
        id: uuidv4(),
        title: "Tarjetas",
        path: "/Configuracion/Gestion/Tarjetas",
        icon: <Configuracion />,
    }
    ,
    {
        id: uuidv4(),
        title: "Patentes",
        path: "/Configuracion/Gestion/Patentes",
        icon: <Configuracion />,
    }
]

