import { Box, Typography } from "@mui/material";
import "./DetalleRegistro.css";


export const DetalleRegistro = ({registroData}) => {
    return(
        <Box id="BoxDetalleRegistro">
            <Box id="ContenidoBoxDetalleRegistro">
                <Box id="CabeceraBoxDetalleRegistro">
                    <Typography id="TxtTituloNombreDestinoDetalleRegistro">
                        {
                            "Detalle del registro"
                        }
                    </Typography>
                </Box>
                <Box id="BoxNombreDestinoDetalleRegistro">
                    <Box id="TituloNombreDestinoDetalleRegistro">
                        <Typography id="TxtTituloNombreDestinoDetalleRegistro">
                            {
                                "Evento"
                            }
                        </Typography>
                    </Box>
                    <Box id="NombreDestinoDetalleRegistro">
                        <Typography id="TxtNombreDestinoDetalleRegistro">
                            {
                                registroData.nombreEvento
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box id="BoxNombreDestinoDetalleRegistro">
                    <Box id="TituloNombreDestinoDetalleRegistro">
                        <Typography id="TxtTituloNombreDestinoDetalleRegistro">
                            {
                                "Destino"
                            }
                        </Typography>
                    </Box>
                    <Box id="NombreDestinoDetalleRegistro">
                        <Typography id="TxtNombreDestinoDetalleRegistro">
                            {
                                registroData.nombreDestino
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box id="BoxUbicacionDetalleRegistro">
                    <Box id="TituloUbicacionDetalleRegistro">
                        <Typography id="TxtTituloNombreDestinoDetalleRegistro">
                            {
                                "Ubicación"
                            }
                        </Typography>
                    </Box>
                    <Box id="UbicacionDetalleRegistro">
                        <Typography id="TxtNombreDestinoDetalleRegistro">
                            {
                                registroData.destino
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box id="BoxComentarioDetalleRegistro">
                    <Box id="BoxTituloComentarioDetalleRegistro">
                        <Typography id="TxtTituloNombreDestinoDetalleRegistro">
                            {
                                "Comentario"
                            }
                        </Typography>
                    </Box>
                    <Box id="ComentarioDetalleRegistro">
                        <Typography id="TxtNombreDestinoDetalleRegistro">
                            {
                                registroData.comentario
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}