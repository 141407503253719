import dayjs from "dayjs";


export const initialValuesEvento = {
    idTipoEvento: -1,
    nombreTipoEvento: "Seleccione",
    nombreEvento: '',
    idDestino: -1,
    nombreDestino: 'Destinos',
    fechaUno: dayjs(Date.now()),
    fechaDos: dayjs(Date.now()),
    desde: dayjs(Date.now()),
    hasta: dayjs(Date.now()),
    lunes: Boolean(false),
    martes: Boolean(false),
    miercoles: Boolean(false),
    jueves: Boolean(false),
    viernes: Boolean(false),
    sabado: Boolean(false),
    domingo: Boolean(false),
}