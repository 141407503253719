import dayjs from 'dayjs';
import * as Yup from 'yup';

export const EsquemaNuevoHorario = Yup.object({
    nombre: Yup.string()
    .required("Debe indicar un nombre"),
    lunesInicio: Yup.date(),
    lunesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.lunesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.lunesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            console.log(inicioHora)
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    martesInicio: Yup.date(),
    martesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.martesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.martesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    miercolesInicio: Yup.date(),
    miercolesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.miercolesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.miercolesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    juevesInicio: Yup.date(),
    juevesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.juevesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.juevesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    viernesInicio: Yup.date(),
    viernesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.viernesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.viernesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    sabadoInicio: Yup.date(),
    sabadoTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.sabadoInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.sabadoInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }), 
    domingoInicio: Yup.date(),
    domingoTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.domingoInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.domingoInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }), 
})


export const EditarHorario = Yup.object({
    nombre: Yup.string()
    .required("Debe indicar un nombre"),
    lunesInicio: Yup.date(),
    lunesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.lunesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.lunesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            console.log(inicioHora)
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    martesInicio: Yup.date(),
    martesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.martesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.martesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    miercolesInicio: Yup.date(),
    miercolesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.miercolesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.miercolesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    juevesInicio: Yup.date(),
    juevesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.juevesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.juevesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    viernesInicio: Yup.date(),
    viernesTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.viernesInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.viernesInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }),
    sabadoInicio: Yup.date(),
    sabadoTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.sabadoInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.sabadoInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }), 
    domingoInicio: Yup.date(),
    domingoTermino: Yup.date()
    .test("min", "verifique fecha de termino", function(value) {
        var inicioHora = dayjs(this.parent.sabadoInicio).format("HH:mm")
        var inicioFecha = dayjs(this.parent.sabadoInicio)
        var valueHora = dayjs(value).format("HH:mm")
        var valueFecha = dayjs(value)

        if(inicioHora === "00:00" && valueHora === "00:00"){
            return true
        }
        else{
            if(valueFecha.isAfter(inicioFecha)){
                return true
            }
            else{
                return false
            }
        }
        
    }), 
})