import { Box, Button, Grid, Typography } from "@mui/material";
import "./CuerpoMes.css";
import dayjs from "dayjs";
import useEventosData from "../../../../Hooks/Agenda/useEventosData";
import { useState, useEffect } from "react";
import { HandlerOpcionesDetalleEventos } from "../OpcionesEvento/OpcionesEvento";

const semana = [
    {id: 0, nombre:"Domingo"},
    {id: 1, nombre:"Lunes"},
    {id: 2, nombre:"Martes"},
    {id: 3, nombre:"Miercoles"},
    {id: 4, nombre:"Jueves"},
    {id: 5, nombre:"Viernes"},
    {id: 6, nombre:"Sabado"}
]

export const CuerpoMes = ({fechaActual, setOpcion, setNewDate}) => {
    const { listaEventos } = useEventosData();
    const [vistaEvento, setVistaEvento] = useState(false);
    const [dataEvento, setDataEvento] = useState({});
    const [fechaNoEvento, setFechaNoEvento] = useState();
    const [opcionVista, setOpcionVista] = useState();
    const [dataMes, setDataMes] = useState([]);
    

    useEffect(() => {
        var fechaInicio = dayjs(fechaActual).set('D', 1).set('hour', 0).set('m',0).set('second',0)
        var ultimoDiaDelMes = dayjs(fechaActual).daysInMonth()
        var fechaTermino = dayjs(fechaActual).set('D', ultimoDiaDelMes).set('hour', 23).set('m', 59).set('second',59)

        const listaPeridosSemanas = ObtenerDiasOrdenados(fechaActual);
        
        listaPeridosSemanas.forEach(semanas => {
            var fechaInicioSemana = dayjs(semanas.fechaInicio).set("h", 0).set("m", 0).set("s", 0);
            var fechaTerminoSemana = dayjs(semanas.fechaFin).set("h", 23).set("m", 59).set("s", 59)
        
            var listaDias = [{ id:0, fecha: fechaInicioSemana, eventos: []}, 
                            { id:1, fecha: fechaInicioSemana.add(1, 'days'), eventos: []},
                            { id:2, fecha: fechaInicioSemana.add(2, 'days'), eventos: []},
                            { id:3, fecha: fechaInicioSemana.add(3, 'days'), eventos: []},
                            { id:4, fecha: fechaInicioSemana.add(4, 'days'), eventos: []},
                            { id:5, fecha: fechaInicioSemana.add(5, 'days'), eventos: []},
                            { id:6, fecha: fechaTerminoSemana, eventos: []}]
            semanas.diasEnSemana = listaDias
        });
        
        var listaEventosMes = []
        
        listaEventos.forEach((evento) => {
        
            if((dayjs(evento.fechaVisita).isAfter(fechaInicio) || dayjs(evento.fechaVisita).isSame(fechaInicio))
                && 
                (dayjs(evento.fechaTermino).isBefore(fechaTermino) || dayjs(evento.fechaTermino).isSame(fechaTermino)))
                {
                    listaEventosMes.push(evento)
                }
        })

        listaEventosMes.forEach(evento => {
            
            var inicioEvento = dayjs(evento.fechaVisita).format("DD/MM/YYYY");
            var finEvento = dayjs(evento.fechaTermino).format("DD/MM/YYYY");

            var semana0 = listaPeridosSemanas.find(l => l.id === 0).diasEnSemana
            var semana1 = listaPeridosSemanas.find(l => l.id === 1).diasEnSemana
            var semana2 = listaPeridosSemanas.find(l => l.id === 2).diasEnSemana
            var semana3 = listaPeridosSemanas.find(l => l.id === 3).diasEnSemana
            var semana4 = listaPeridosSemanas.find(l => l.id === 4).diasEnSemana
            var semana5 = listaPeridosSemanas.find(l => l.id === 5).diasEnSemana
            
            var fechaSemana0 = semana0.find(s0 => dayjs(s0.fecha).format("DD/MM/YYYY") === inicioEvento && dayjs(s0.fecha).format("DD/MM/YYYY") === finEvento)
            if(fechaSemana0 !== undefined){
                fechaSemana0.eventos.push(evento)
            }
            var fechaSemana1 = semana1.find(s1 => dayjs(s1.fecha).format("DD/MM/YYYY") === inicioEvento && dayjs(s1.fecha).format("DD/MM/YYYY") === finEvento)
            if(fechaSemana1 !== undefined){
                fechaSemana1.eventos.push(evento)
            }
            var fechaSemana2 = semana2.find(s2 => dayjs(s2.fecha).format("DD/MM/YYYY") === inicioEvento && dayjs(s2.fecha).format("DD/MM/YYYY") === finEvento)
            if(fechaSemana2 !== undefined){
                fechaSemana2.eventos.push(evento)
            }
            var fechaSemana3 = semana3.find(s3 => dayjs(s3.fecha).format("DD/MM/YYYY") === inicioEvento && dayjs(s3.fecha).format("DD/MM/YYYY") === finEvento)
            if(fechaSemana3 !== undefined){
                fechaSemana3.eventos.push(evento)
            }
            var fechaSemana4 = semana4.find(s4 => dayjs(s4.fecha).format("DD/MM/YYYY") === inicioEvento && dayjs(s4.fecha).format("DD/MM/YYYY") === finEvento)
            if(fechaSemana4 !== undefined){
                fechaSemana4.eventos.push(evento)
            }
            var fechaSemana5 = semana5.find(s5 => dayjs(s5.fecha).format("DD/MM/YYYY") === inicioEvento && dayjs(s5.fecha).format("DD/MM/YYYY") === finEvento)
            if(fechaSemana5 !== undefined){
                fechaSemana5.eventos.push(evento)
            }
            
        });

        setDataMes(listaPeridosSemanas)
    }, [listaEventos, fechaActual])
    
    const HandlerDayClick = (fecha) => () => {
        setNewDate(fecha) 
        setOpcion(2)       
    }

    const HandlerNoEventsView = (fecha) => () =>  {
        setFechaNoEvento(fecha)
        setOpcionVista(2)
        setVistaEvento(true);     
    }

    const HandlerEventView = (evento) => () =>  {
        setDataEvento(evento)
        setOpcionVista(1)
        setVistaEvento(true);       
    }

    return(
    <Box id="BoxLayoutVistaMes">
        <Box id="BoxVistaCalendarioMes">
            <Grid id="BoxContenedorDiasDeSemanaRow" container spacing={0}>
                {
                    semana.map((item, index) => {
                        return(
                            <Grid id="BoxNombreDiaSemanaVistaMes" item key={index}>
                                <Typography id="TxtNombreDiaSemanaVistaMes">
                                    {
                                        item.nombre
                                    }
                                </Typography>
                            </Grid>
                        );
                    })
                }
            </Grid>
            <Grid id="ContenedorMes" container spacing={0}>
                {
                    dataMes.map((item, index) => {
                        return(
                            <Grid container key={index} id="BoxSemanasRowDataEventos">
                                {
                                    item.diasEnSemana.map((item, index) => {
                                        var diaIgual = dayjs(item.fecha).format("DD/MM/YYYY") === dayjs(new Date()).format("DD/MM/YYYY")
                                        var mesIgual = dayjs(item.fecha).month() === dayjs(fechaActual).month();

                                        return(
                                            <Grid id="BoxNombreDiaSemanaVistaMes" sx={{border: diaIgual === true ? "1.5px solid #175676": null }} item key={index}>                                                
                                                <Box id="BoxContedorDiaEventos">
                                                    <Box id="BoxContenedorDia">
                                                        <Button id="BotonNumeroDiaMesVistaMes" onClick={item.eventos.length === 0 ? HandlerNoEventsView(item.fecha):null} disabled={mesIgual ? false:true}>
                                                            <Typography sx={{ color: mesIgual ? "#175676":"#94B1C0"}} id="txtDiaNombreEnSemanaVistaMes">
                                                                {
                                                                    dayjs(item.fecha).format("DD")
                                                                }
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                    <Box id="BoxContendorDiaEventos">
                                                       {
                                                        item.eventos.length === 0 ?
                                                        null
                                                        :
                                                        item.eventos.length > 2 ?
                                                        <Box key={index} id="BoxMultipleEventosDia" >
                                                            <Button id="BtnBoxMultipleEventos" fullWidth sx={{padding: "0px", textTransform:"none"}} onClick={HandlerDayClick(item.fecha)}>
                                                                <Box id="BoxTituloMultiple">
                                                                    <Typography id="txtInfoEventoTitulo">
                                                                        {
                                                                            "Multiples Eventos" 
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                                <Box id="BoxTituloMultiple">
                                                                    <Typography id="txtInfoEvento">
                                                                        {
                                                                            "+3 Eventos" 
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </Button>
                                                        </Box>
                                                        :
                                                        item.eventos.map((item, index) => {
                                                            return(
                                                                <Box key={index} id="BoxDataEventoDiario" sx={{ background: item.estado === false ? "#94B1C0": item.idTipoVisita === 0 ? "#175676" : item.idTipoVisita === 1 ? "#1F7098": "#699FBA"}} >
                                                                   <Button fullWidth  sx={{padding: "0px", textTransform:"none"}} onClick={HandlerEventView(item)}>
                                                                        <Typography id="txtNombreEvento">
                                                                            {
                                                                                item.nombreEvento //.split(" ")[0] + " " +item.nombreEvento.split(" ")[1].substring(0,3) + "..."
                                                                            }
                                                                        </Typography>
                                                                   </Button>
                                                                </Box>
                                                            )
                                                        })                                                        
                                                       }
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        );
                    })
                }
            </Grid>
        </Box>
        <HandlerOpcionesDetalleEventos 
        open={vistaEvento}
        setOpen={setVistaEvento} 
        eventoData={dataEvento}
        opcion={opcionVista}
        fechaSeleccionada={fechaNoEvento}
        />
    </Box>
    );
}



const ObtenerDiasOrdenados = (fechaActual) => {
    let ListaSemana = [
        {id: 0, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 1, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 2, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 3, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 4, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 5, fechaInicio: '', fechaFin: '', diasEnSemana: []},
    ]

    var diaInicioEnSemana = dayjs(fechaActual).set('D', 1).day();
    var fechaInicio = dayjs(fechaActual).set('D', 1);

    if(diaInicioEnSemana === 0)
        {
            var primero0 = ListaSemana.find(d => d.id === 0)
            primero0.fechaInicio = dayjs(fechaInicio);
            primero0.fechaFin = dayjs(fechaInicio).add(6, 'day');
    
            var segunda0 = ListaSemana.find(d => d.id === 1)
            segunda0.fechaInicio = dayjs(fechaInicio).add(7, 'day');
            segunda0.fechaFin = dayjs(fechaInicio).add(13, 'day');
    
            var tercera0 = ListaSemana.find(d => d.id === 2)
            tercera0.fechaInicio = dayjs(fechaInicio).add(14, 'day');
            tercera0.fechaFin = dayjs(fechaInicio).add(20, 'day');
            
            var cuarta0 = ListaSemana.find(d => d.id === 3)
            cuarta0.fechaInicio = dayjs(fechaInicio).add(21, 'day');
            cuarta0.fechaFin = dayjs(fechaInicio).add(27, 'day');
    
            var quinta0 = ListaSemana.find(d => d.id === 4)
            quinta0.fechaInicio = dayjs(fechaInicio).add(28, 'day');
            quinta0.fechaFin = dayjs(fechaInicio).add(34, 'day');
    
            var sexta0 = ListaSemana.find(d => d.id === 5)
            sexta0.fechaInicio = dayjs(fechaInicio).add(35, 'day');
            sexta0.fechaFin = dayjs(fechaInicio).add(41, 'day');
        }
    
        if(diaInicioEnSemana === 1)
        {
            var primero1 = ListaSemana.find(d => d.id === 0)
            primero1.fechaInicio = dayjs(fechaInicio).add(-1, 'day');;
            primero1.fechaFin = dayjs(fechaInicio).add(5, 'day');
        
            var segunda1 = ListaSemana.find(d => d.id === 1)
            segunda1.fechaInicio = dayjs(fechaInicio).add(6, 'day');
            segunda1.fechaFin = dayjs(fechaInicio).add(12, 'day');
    
            var tercera1 = ListaSemana.find(d => d.id === 2)
            tercera1.fechaInicio = dayjs(fechaInicio).add(13, 'day');
            tercera1.fechaFin = dayjs(fechaInicio).add(19, 'day');
    
            var cuarta1 = ListaSemana.find(d => d.id === 3)
            cuarta1.fechaInicio = dayjs(fechaInicio).add(20, 'day');
            cuarta1.fechaFin = dayjs(fechaInicio).add(26, 'day');
            
            var quinta1 = ListaSemana.find(d => d.id === 4)
            quinta1.fechaInicio = dayjs(fechaInicio).add(27, 'day');
            quinta1.fechaFin = dayjs(fechaInicio).add(33, 'day');
            
            var sexta1 = ListaSemana.find(d => d.id === 5)
            sexta1.fechaInicio = dayjs(fechaInicio).add(34, 'day');
            sexta1.fechaFin = dayjs(fechaInicio).add(40, 'day');
        }
    
        if(diaInicioEnSemana === 2)
        {
            var tercero2 = ListaSemana.find(d => d.id === 0)
            tercero2.fechaInicio = dayjs(fechaInicio).add(-2, 'day');;
            tercero2.fechaFin = dayjs(fechaInicio).add(4, 'day');
            
            var segunda2 = ListaSemana.find(d => d.id === 1)
            segunda2.fechaInicio = dayjs(fechaInicio).add(5, 'day');
            segunda2.fechaFin = dayjs(fechaInicio).add(11, 'day');
            
            var tercera2 = ListaSemana.find(d => d.id === 2)
            tercera2.fechaInicio = dayjs(fechaInicio).add(12, 'day');
            tercera2.fechaFin = dayjs(fechaInicio).add(18, 'day');
                    
            var cuarta2 = ListaSemana.find(d => d.id === 3)
            cuarta2.fechaInicio = dayjs(fechaInicio).add(19, 'day');
            cuarta2.fechaFin = dayjs(fechaInicio).add(25, 'day');
            
            var quinta2 = ListaSemana.find(d => d.id === 4)
            quinta2.fechaInicio = dayjs(fechaInicio).add(26, 'day');
            quinta2.fechaFin = dayjs(fechaInicio).add(32, 'day');
            
            var sexta2 = ListaSemana.find(d => d.id === 5)
            sexta2.fechaInicio = dayjs(fechaInicio).add(33, 'day');
            sexta2.fechaFin = dayjs(fechaInicio).add(39, 'day');
        }
    
        if(diaInicioEnSemana === 3)
        {
            var primera3 = ListaSemana.find(d => d.id === 0)
            primera3.fechaInicio = dayjs(fechaInicio).add(-3, 'day');;
            primera3.fechaFin = dayjs(fechaInicio).add(3, 'day');
                
            var segunda3 = ListaSemana.find(d => d.id === 1)
            segunda3.fechaInicio = dayjs(fechaInicio).add(4, 'day');
            segunda3.fechaFin = dayjs(fechaInicio).add(10, 'day');
                
            var tercera3 = ListaSemana.find(d => d.id === 2)
            tercera3.fechaInicio = dayjs(fechaInicio).add(11, 'day');
            tercera3.fechaFin = dayjs(fechaInicio).add(17, 'day');
                        
            var cuarta3 = ListaSemana.find(d => d.id === 3)
            cuarta3.fechaInicio = dayjs(fechaInicio).add(18, 'day');
            cuarta3.fechaFin = dayjs(fechaInicio).add(24, 'day');
                
            var quinta3 = ListaSemana.find(d => d.id === 4)
            quinta3.fechaInicio = dayjs(fechaInicio).add(25, 'day');
            quinta3.fechaFin = dayjs(fechaInicio).add(31, 'day');
                
            var sexta3 = ListaSemana.find(d => d.id === 5)
            sexta3.fechaInicio = dayjs(fechaInicio).add(32, 'day');
            sexta3.fechaFin = dayjs(fechaInicio).add(38, 'day');
        }
    
        if(diaInicioEnSemana === 4)
        {
            var primero4 = ListaSemana.find(d => d.id === 0)
            primero4.fechaInicio = dayjs(fechaInicio).add(-4, 'day');;
            primero4.fechaFin = dayjs(fechaInicio).add(2, 'day');
                    
            var segunda4 = ListaSemana.find(d => d.id === 1)
            segunda4.fechaInicio = dayjs(fechaInicio).add(3, 'day');
            segunda4.fechaFin = dayjs(fechaInicio).add(9, 'day');
                    
            var tercera4 = ListaSemana.find(d => d.id === 2)
            tercera4.fechaInicio = dayjs(fechaInicio).add(10, 'day');
            tercera4.fechaFin = dayjs(fechaInicio).add(16, 'day');
                            
            var cuarta4 = ListaSemana.find(d => d.id === 3)
            cuarta4.fechaInicio = dayjs(fechaInicio).add(17, 'day');
            cuarta4.fechaFin = dayjs(fechaInicio).add(23, 'day');
                    
            var quinta4 = ListaSemana.find(d => d.id === 4)
            quinta4.fechaInicio = dayjs(fechaInicio).add(24, 'day');
            quinta4.fechaFin = dayjs(fechaInicio).add(30, 'day');
                    
            var sexta4 = ListaSemana.find(d => d.id === 5)
            sexta4.fechaInicio = dayjs(fechaInicio).add(31, 'day');
            sexta4.fechaFin = dayjs(fechaInicio).add(37, 'day');
        }
    
        if(diaInicioEnSemana === 5)
        {
            var primero5 = ListaSemana.find(d => d.id === 0)
            primero5.fechaInicio = dayjs(fechaInicio).add(-5, 'day');
            primero5.fechaFin = dayjs(fechaInicio).add(1, 'day');;
    
            var segunda5 = ListaSemana.find(d => d.id === 1)
            segunda5.fechaInicio = dayjs(fechaInicio).add(2, 'day');
            segunda5.fechaFin = dayjs(fechaInicio).add(8, 'day');
    
            var tercera5 = ListaSemana.find(d => d.id === 2)
            tercera5.fechaInicio = dayjs(fechaInicio).add(9, 'day');
            tercera5.fechaFin = dayjs(fechaInicio).add(15, 'day');
            
            var cuarta5 = ListaSemana.find(d => d.id === 3)
            cuarta5.fechaInicio = dayjs(fechaInicio).add(16, 'day');
            cuarta5.fechaFin = dayjs(fechaInicio).add(22, 'day');
    
            var quinta5 = ListaSemana.find(d => d.id === 4)
            quinta5.fechaInicio = dayjs(fechaInicio).add(23, 'day');
            quinta5.fechaFin = dayjs(fechaInicio).add(29, 'day');
    
            var sexta5 = ListaSemana.find(d => d.id === 5)
            sexta5.fechaInicio = dayjs(fechaInicio).add(30, 'day');
            sexta5.fechaFin = dayjs(fechaInicio).add(36, 'day');
        }
    
        if(diaInicioEnSemana === 6)
        {
            var primero6 = ListaSemana.find(d => d.id === 0)
            primero6.fechaInicio = dayjs(fechaInicio).add(-6, 'day');
            primero6.fechaFin = dayjs(fechaInicio);
        
            var segunda6 = ListaSemana.find(d => d.id === 1)
            segunda6.fechaInicio = dayjs(fechaInicio).add(1, 'day');
            segunda6.fechaFin = dayjs(fechaInicio).add(7, 'day');
    
            var tercera6 = ListaSemana.find(d => d.id === 2)
            tercera6.fechaInicio = dayjs(fechaInicio).add(8, 'day');
            tercera6.fechaFin = dayjs(fechaInicio).add(14, 'day');
            
            var cuarta6 = ListaSemana.find(d => d.id === 3)
            cuarta6.fechaInicio = dayjs(fechaInicio).add(15, 'day');
            cuarta6.fechaFin = dayjs(fechaInicio).add(21, 'day');
    
            var quinta6 = ListaSemana.find(d => d.id === 4)
            quinta6.fechaInicio = dayjs(fechaInicio).add(22, 'day');
            quinta6.fechaFin = dayjs(fechaInicio).add(28, 'day');
    
            var sexta6 = ListaSemana.find(d => d.id === 5)
            sexta6.fechaInicio = dayjs(fechaInicio).add(29, 'day');
            sexta6.fechaFin = dayjs(fechaInicio).add(35, 'day');
        }

    return ListaSemana
}

