import { Box, Typography } from "@mui/material";
import "./TarjetaDataVisita.css";
import dayjs from "dayjs";
import TooltipTipoUno from "../../ToolTip/Tooltip";


export const TarjetaDataVisita = ({nombreEvento, creador,  numero, piso, fechaInicio, fechaTermino, tipoEvento, nombreDestino }) => {

    var horaInicio = dayjs(fechaInicio).format("HH:mm");
    var horaTermino = dayjs(fechaTermino).format("HH:mm");
    var agendo = "Agendó: " + creador;
    var destino = nombreDestino + " N°: " + numero + " | Piso" + piso;
    var duracion = "De: " + horaInicio + " a " + horaTermino + " Horas";
    var visitaTermino = "Finaliza el " + dayjs(fechaTermino).format("DD/MM/YYYY");

    return (
        <TooltipTipoUno titulo={agendo} ubicacion={"bottom"}>
            <Box id="BoxDataEvento">
                <Typography id="NombreEvento">
                    {
                        nombreEvento
                    }
                </Typography>
                {
                    tipoEvento === 1 ?
                    <>
                        <Typography id="DuracionEvento">
                            {
                                duracion
                            }
                        </Typography>
                        <Typography id="TerminoEvento">
                            {
                                visitaTermino
                            }
                        </Typography>
                    </>
                    :
                        <Typography id="DuracionEvento">
                            {
                                duracion
                            }
                        </Typography>
                }
                <Box id="BoxDestinoEvento">
                   <Typography id="DestinoEvento">
                    {
                        destino
                    }
                    </Typography> 
                </Box>
            </Box>
        </TooltipTipoUno>
    );
};




