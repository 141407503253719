import { Box } from "@mui/material";
import { useState } from "react"
import { ValidaryConfirmar } from "./ValidaryConfirmar";
import { ConfirmarAsistencia } from "./ConfirmarAsistencia";
import "./ConfirmarAsistencia.css";


export const ConfirmarAsistenciaEvento = () => {
    const [opcion, setOpcion] = useState(1);
    const [inforEventoUsuario, setInfoEventoUsuario] = useState({});
    const [infoEvento, setInfoEvento] = useState();
    
    
    return (
        <Box id="BoxContenedorValidacionesEvento" >
            {
                opcion === 1 ?
                <ValidaryConfirmar setOpcion={setOpcion} setInfoEventoUsuario={setInfoEventoUsuario} setInfoEvento={setInfoEvento} />
                :
                <ConfirmarAsistencia inforEventoUsuario={inforEventoUsuario} infoEvento={infoEvento}  />
            }
        </Box>
    );
}

