import { useEffect, useState } from "react";
import { ZonasEmpresaParaAsignar } from "../../Service/API/Api";
import axios from "axios";

export default function useGestZonasEmpresa(){
    const [zonas, setZonas] = useState([]);
    const [zonasFormadas, setZonasFormadas] = useState([]);
    const [zonasFormadasReporte, setZonasFormadasReporte] = useState([]);

    useEffect(() => {
        axios.get(ZonasEmpresaParaAsignar)
        .then(
            (response) => {
                var lista = []
                var opcion0 = { id: 0, tipo: "Zonas"}
                lista.push(opcion0)
                response.data.forEach(element => {
                    var opcion = { id: element.idZona, tipo: element.nombreZona}
                    lista.push(opcion)
                });

                var lista2 = []
                var opcionTodos = { id: -1, tipo: "Todas"}
                lista2.push(opcionTodos)
                response.data.forEach(element => {
                    var opcion = { id: element.idZona, tipo: element.nombreZona}
                    lista2.push(opcion)
                });
                setZonasFormadas(lista)
                setZonasFormadasReporte(lista2)
                setZonas(response.data);
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
            }
        )
    }, [])
    
    return {
        zonas,
        zonasFormadas,
        zonasFormadasReporte
    }
}