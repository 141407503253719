import { Box, TextField, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import "./RegistroPatentes.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { ValidationNuevaPatente } from "./Validation";
import { useState } from "react";
import usePatentes from "../../../../../Hooks/Patentes/usePatentes";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";


export const EditarPatente = ({setOpen, rows, user, setRows, toEdit, digitos}) => {
    const {loading, setLoading, EditarPatente, response} = usePatentes();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [ConfirmationDialog, confirm ]= useConfirm();

    const handleClose = () =>{
        setOpen(false);
        setOpenLoading(false);
    }


    const EditarRow = async (patente) => {
        let rowToEdit = rows.find((p) => p.idPatente === toEdit);
        rowToEdit.patente = patente.toUpperCase();
        setRows(rows.map((row) => (row.idPatente === rowToEdit.idPatente ? rowToEdit : row)))
        setTimeout(() => {
            handleClose()
        }, 3000);
    }
   
    const formik = useFormik({
        initialValues:{ patente: digitos},
        enableReinitialize: true,
        validationSchema:ValidationNuevaPatente,
        onSubmit:
        async () => {
            let response = await confirm();
            if(response === true){
                setOpenLoading(true);
                setLoading(true);
                await EditarPatente(formik.values.patente, user, toEdit)
                .then(
                    (result) => {
                        EditarRow(formik.values.patente.toUpperCase());
                    }
                    ,
                    (error) => {
                        console.log(error);
                    }
                )
            }
            else{
                console.log("sin cambios no se acepta realizar modificacion")
            }
        },
    });
    
    return (
        <LayoutCrearNuevo titulo={"Editar Patente"}>
            <Box id="BoxLayoutAgregarPatenteGU">
                <Box id="BoxTituloTxtAgregarPatenteGU">
                    <Box id="BoxTituloAgregarPatenteGU">
                        <Typography id="TxtTituloAgregarPatenteGU">
                            {
                                "Información de Patente"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxTxtAgregarPatenteGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <TextField 
                        variant="filled" 
                        label="Numero de Patente" 
                        fullWidth
                        name="patente"
                        value={formik.values.patente}
                        onChange={formik.handleChange}
                        helperText={formik.errors.patente}
                        />
                    </Box>
                </Box>

                <Box id="BoxAccionesAgregarPatenteGU">
                    <Box id="BoxBtnCancelarAgregarPatenteGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAgregarAgregarPatenteGU">
                        <BotonTipo2 textoBoton={"Actualizar"} event={formik.submitForm} />
                        <ConfirmationDialog
                        icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
                        titulo={"¿Actualizar Patente?"}
                        mensaje={"Estás a punto de actualizar la información de la patente. ¿Deseas continuar?"}
                        />
                        <LoadingMasRespuestaCinco
                        open={openLoading}
                        icono={response === 200 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={response === 200 ?
                            "Aceptar" 
                            :
                            "Reintentar"
                        }
                        setOpen={setOpenLoading}
                        msj={"La información de la patente está siendo actualizada. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        id={"LoadingAgregaListaNegraGU"}
                        tituloLoading={"Actualizando patente..."}
                        loading={loading}
                        titulo={response === 200 ? 
                            "Actualización exitosa"
                            :
                            "Error al actualizar"
                        }
                        mensajeRespuesta={response === 200 ? 
                            "La información del usuario se ha actualizado correctamente. Ahora puedes gestionar sus permisos o añadir un nuevo usuario."
                            :
                            "Ha ocurrido un error y no se ha podido actualizar la información de la patente. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                        }
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}


