import * as Yup from 'yup';

export const ValidationSchema = Yup.object({
    password: Yup.string()
    .matches("^(?=.*[a-z])", "Debe incluir minimo una letra Minuscula")
    .matches("^(?=.*[A-Z])", "Debe incluir minimo una letra Mayuscula")
    .matches("^(?=.*[0-9])", "Debe incluir minimo un Numero")
    .min(8, '* La contraseña es de un minimo de 8 Caracteres')
    .max(13, '* La contraseña es de un maximo de 13 Caracteres')
    .required('* Campo requerido'),
    confirmacion: Yup.string()
    .oneOf([Yup.ref("password"), null], "*Las contraseñas no coinciden. Por favor inténtelo de nuevo.")
    .required('* Campo requerido'),
  })