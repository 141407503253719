import { Switch } from "@mui/material";
import TooltipTipoUno from "../ToolTip/Tooltip";

export const SwitchTablas = ({id, value, handler, disabled}) =>{
    return(
        <TooltipTipoUno titulo={ value === true ? "Habilitado":"Desactivado"} ubicacion={"right-start"}>
            <Switch
            id={id}
            sx={{
                width: "55px",
                padding: "6px 5px",
                "& .MuiSwitch-thumb":{
                    backgroundColor: "white",
                    width: "var(--6, 19px)",
                    height: "var(--6, 19px)",
                },
                "& .MuiSwitch-track":{
                    background: "#CC261A",
                    width: "100%",
                    height: "25px !important",
                    opacity: "unset",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    padding: "0px"      
                },
                "& .MuiSwitch-switchBase":{
                    "&.Mui-checked + .MuiSwitch-track" :{
                        backgroundColor: "#175676",
                        opacity: "unset",
                    },
                }
            }}
            disabled={disabled}
            checked={value}
            onClick={handler}
            />
        </TooltipTipoUno>
    );
}