import axios from "axios";
import { useEffect, useState } from "react";
import { CrearPersonaUbicacion, EliminarPersonaUbicacion, ListaUsuariosSinUbicacion } from "../../Service/API/Api";

export default function usePersonaEnUbicacion(){
    const [loadingReq, setLoadingReq] = useState();
    const [response, setResponse] = useState();
    const [usuariosSinUbicacion, setUsuariosSinUbicacion] = useState([]);

    const AgregarUsuarioAUbicacion = (values) => {
        return new Promise(async (resolve, reject) => {
            const nuevoPersonaUbicacion = {
                "idUbicacion": values.idUbicacion,
                "idPersona": values.idPersonaMiembro
            }
            await axios.post(CrearPersonaUbicacion, nuevoPersonaUbicacion)    
            .then(
                (response) =>{
                    console.log(response);
                    resolve(response.data);
                    setResponse(response.status)
                    setLoadingReq(false)
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(error.response.status)
                    setLoadingReq(false)
                }
            ); 
        });
    }

    const EliminarUsuarioDeUbicacion = (idPersonaUbicacion) => {
        return new Promise(async (resolve, reject) => {
            await axios.delete(EliminarPersonaUbicacion + idPersonaUbicacion)    
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status)
                    setLoadingReq(false)
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(response.status)
                    setLoadingReq(false)
                }
            ); 
        });
    }

    useEffect(() => {
        axios.get(ListaUsuariosSinUbicacion)    
            .then(
                (response) =>{
                    var lista = [];
                    var usuarioCero = {
                        "idUsuario": -1,
                        "nombre": "Usuarios",
                        "estado": null,
                        "tipoUsuario": null
                    }
                    lista.push(usuarioCero)
                    response.data.forEach(element => {
                        var usuario = {
                            "idUsuario": element.idUsuario,
                            "nombre": element.nombre,
                            "estado": element.estado,
                            "tipoUsuario": element.tipoUsuario,
                            "idPersonaUbicacion": element.idPersonaUbicacion
                        }
                        lista.push(usuario)
                    });

                    setUsuariosSinUbicacion(lista);
                }
            )
            .catch (
                (error) =>{
                    console.log(error.response);
                }
            ); 
    }, [])
    

    return {
        loadingReq,
        setLoadingReq,
        response,
        EliminarUsuarioDeUbicacion,
        AgregarUsuarioAUbicacion,
        usuariosSinUbicacion
    };
}