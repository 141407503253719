import { SvgIcon } from "@mui/material";

export function Menorque (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                <path d="M9 1L1 9L9 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function Mayorque (props){
    return(
        <SvgIcon {...props}> 
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                <path d="M1 17L9 9L1 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function PlusSVG (props){
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8 1V15M1 8H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function TresPuntosIcos (props){
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14Z" fill="#626262"/>
            </svg>
        </SvgIcon>
    )
}

export function EventoAgendadoIcon (props){
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                <path d="M19 9V17C19 18.1046 17.9926 19 16.75 19H3.25C2.00736 19 1 18.1046 1 17V5C1 3.89543 2.00736 3 3.25 3H16.75C17.9926 3 19 3.89543 19 5V9ZM19 9H10H1M13.375 1V5M6.625 1V5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function EditarEventoIcon (props){
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M10.8824 5.94118L13 3.82353L10.1765 1L8.05882 3.11765M10.8824 5.94118L3.82353 13H1V10.1765L8.05882 3.11765M10.8824 5.94118L8.05882 3.11765" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function CampanaIcon (props){
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                <path d="M4.64131 2.80544C5.81271 1.55397 7.37505 0.750021 8.99998 0.750014H9C9.00001 0.750014 9.00002 0.750014 9.00003 0.750014C10.625 0.750024 12.1873 1.55397 13.3587 2.80544C14.532 4.05895 15.25 5.69577 15.25 7.2544V13.6272V13.9247L15.4539 14.1413L17.25 16.049V16.0636H0.75V16.049L2.54606 14.1413L2.75 13.9247V13.6272V7.2544C2.75 5.69577 3.46801 4.05895 4.64131 2.80544ZM9 1.36811e-05V0.380008V1.36811e-05ZM9 19.25C8.58588 19.25 8.19148 19.0157 7.96276 18.6364H10.0372C9.80852 19.0157 9.41412 19.25 9 19.25Z" stroke="#626262" strokeWidth="1.5"/>
            </svg>
        </SvgIcon>
    )
}





