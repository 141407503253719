import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { authReducer, filtroTiempoRealReducer, TiposVisitaReducer } from "./misSlice";
const { configureStore, combineReducers } = require("@reduxjs/toolkit");

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    user: authReducer,
    tiposVisita: TiposVisitaReducer,
    filtroTiempoReal: filtroTiempoRealReducer,
  });

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})

export const persistor = persistStore(store)