import { Box, Divider, IconButton, Typography } from "@mui/material";
import "./../OpcionesEventos.css";
import { EventoAgendadoIcon, PlusSVG } from "../../../../Calendario/CalendarioIcons";
import { CerrarModalNuevoUsuario } from "../../../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import dayjs from "dayjs";
import { PopOverNuevoEvento } from "../../../../Popover/PopoverTipoUno";
import { useState } from "react";
import NuevoEvento from "../../../../Eventos/NuevoEvento/NuevoEvento";
import { EventoCortoTipoDos } from "../../../EventoAgenda/EventoEnAgenda";

const Semana = [
    {id: 0, nombre:"Domingo"},
    {id: 1, nombre:"Lunes"},
    {id: 2, nombre:"Martes"},
    {id: 3, nombre:"Miercoles"},
    {id: 4, nombre:"Jueves"},
    {id: 5, nombre:"Viernes"},
    {id: 6, nombre:"Sabado"}
]

const Meses = [
    {id:0, nombre:"Enero"},
    {id:1, nombre:"Febrero"},
    {id:2, nombre:"Marzo"},
    {id:3, nombre:"Abril"},
    {id:4, nombre:"Mayo"},
    {id:5, nombre:"Junio"},
    {id:6, nombre:"Julio"},
    {id:7, nombre:"Agosto"},
    {id:8, nombre:"Septiembre"},
    {id:9, nombre:"Octubre"},
    {id:10, nombre:"Noviembre"},
    {id:11, nombre:"Diciembre"},
]



export const MultipleEventos = ({fechaSeleccionada, setOpen, listaEventos}) => {
    const diaSemanaFecha = Semana.find(s => s.id === dayjs(fechaSeleccionada).day()).nombre;
    const mesFecha = Meses.find(s => s.id === dayjs(fechaSeleccionada).month()).nombre;
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl)

    const HandlerClose = () => {
        setOpen(false);
    }

    const HandleOpenNuevo= (e) => {
        setAnchorEl(e.currentTarget)
    }

    return (
        <Box id="BoxLayoutSinEventosView">
            <Box id="BoxCabeceraTituloSinEventosView">
                <Box id="BoxContedorIconosFechaSinEventosView">
                    <Box id="BoxIconoCalendario">
                        <EventoAgendadoIcon />
                    </Box>
                    <Box id="BoxIconoFechaActualSinEventosView">
                        <Typography id="TxtFechaFormateadaSinEventosView">
                            {
                                diaSemanaFecha + ", " + dayjs(fechaSeleccionada).format("DD") + " " + mesFecha +  " del " + dayjs(fechaSeleccionada).format("YYYY") 
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxIconosAccionSinEventosView">
                        <Box>
                            <IconButton onClick={(e) => HandleOpenNuevo(e)}>
                                <PlusSVG id="IconButtonNoEventosView" />
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton onClick={HandlerClose}>
                                <CerrarModalNuevoUsuario id="IconButtonNoEventosView" />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Divider orientation="horizontal" flexItem />
            <Box id="BoxCuerpoSinEventosView">
                <Box id="BoxCuerpoSinEventosViewTipo2">
                    {
                        listaEventos.map((item, index) => {
                            return(
                                <EventoCortoTipoDos key={index}
                                eventoData={item}
                                />
                            );
                        })
                    } 
                </Box>
                <Box id="BoxFinalVistaSinEventosViewTipo2">
                    <Typography id="TxtFechaFormateadaSinEventosViewTipo2">
                        {
                            "Multiples eventos en el destino"
                        }
                    </Typography>
                </Box>
            </Box>
            <PopOverNuevoEvento open={open} anchorEl={anchorEl} setOpen={setAnchorEl} children={<NuevoEvento setOpen={setAnchorEl} />}/>
        </Box>
    );
}