import * as Yup from 'yup';

export const ValidationNuevoPase = Yup.object({  
    idMetodoAcceso: Yup.number()
    ,
    idPatente: Yup.number()
    .test("min", "seleccione una patente", function(value) {
        if(value === -1){
            return false;
        }
        else {
            return true;
        }
    })
    ,
    idTarjeta: Yup.number()
    .test("min", "seleccione una tarjeta", function(value) {
        if(value === -1){
            return false;
        }
        else {
            return true;
        }
    })
})