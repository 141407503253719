import { Box, Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import "./DetalleTiempoReal.css";
import { CerrarDetalleIcon, DetalleAccesoExitosoIcon, DetalleAccesoFallidoIcon } from "./DetalleTiempoRealIconos";
import dayjs from "dayjs";
import TooltipTipoUno from "../../ToolTip/Tooltip";

const MostrarDetalleTR = ({ setOpen, item }) => {
  
    let valor = item.rut === null ? item.pasaporte === null ? "No encontrado": item.pasaporte : item.rut 

    const handlerClose = () => {
        setOpen(null);
    }
    

    return (
            <Box id="BoxDetalleRegistroTR">
                <Card id="CardDetalleRegistroTR">
                    <Box id="Cabecera">
                        <IconButton onClick={handlerClose} id="BotonCierreDetalleTR">
                            <CerrarDetalleIcon id="iconoCierreDetalleTR" />
                        </IconButton>
                        {/* <IconoEspecialConexion id="IconoEspecialConexionConTabla" /> */}
                    </Box>
                    <CardContent id="ContenidoTarjetaDetalleTR">
                        {
                            item === null ?
                                <Box>
                                    <Typography>
                                        {
                                            "No han encontrado registros"
                                        }
                                    </Typography>
                                </Box>
                                :
                                <Box id="BoxDetalleTiempoReal" sx={{ flexGrow: 1 }}>
                                    <Box id="DetalleUsuarioRegistroTR">
                                        <Box id="NombreRutPatente" >
                                            <Typography id="NombreUsuarioRegistro">
                                                {
                                                    item.nombrePersona === null || item.nombrePersona === "-"  ? "No encontrado" : item.nombrePersona

                                                }
                                            </Typography>
                                            <Typography id="RutPatente">
                                                {
                                                    item.patente === null ||  item.patente === undefined ?
                                                        "Sin patente - "  + valor
                                                        :
                                                        item.patente + " - " + valor
                                                }
                                            </Typography>
                                        </Box>
                                        <Box id="BoxDetalleRegistroTiempoReal">
                                            <Grid id="GridGeneralDetalleTR" container spacing={'auto'}>
                                                <Grid id="GTipoDetalleTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleNombres" >
                                                        {
                                                            "Fecha"
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid id="GDetalleIngresoTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleRespuestas" >
                                                        {
                                                            dayjs(item.fecha).format("DD/MM/YYYY")
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid id="GTipoDetalleTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleNombres" >
                                                        {
                                                            "Hora"
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid id="GDetalleIngresoTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleRespuestas" >
                                                        {
                                                            dayjs(item.fecha).format("HH:mm:ss")
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid id="GTipoDetalleTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleNombres" >
                                                        {
                                                            "Zona"
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid id="GDetalleIngresoTR" item md={6} textAlign={"left"}>
                                                    <TooltipTipoUno titulo={item.nombreZona} ubicacion={"bottom"}>
                                                        <Typography id="detalleRespuestas">
                                                            {
                                                                item.nombreZona
                                                            }
                                                        </Typography>
                                                    </TooltipTipoUno>                                                    
                                                </Grid>
                                                <Grid id="GTipoDetalleTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleNombres" >
                                                        {
                                                            "Acceso"
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid id="GDetalleIngresoTR" item md={6} textAlign={"left"}>
                                                    <TooltipTipoUno titulo={item.nombreActor} ubicacion={"top"}>
                                                        <Typography id="detalleRespuestas">
                                                            {
                                                                item.metodo
                                                            }
                                                        </Typography>
                                                    </TooltipTipoUno>
                                                </Grid>
                                                <Grid id="GTipoDetalleTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleNombres" >
                                                        {
                                                            "Destino"
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid id="GDetalleIngresoTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleRespuestas">
                                                        {
                                                            "Piso " + item.destino.split(",")[0].split(":")[1] + " | N°" + item.destino.split(",")[1].split(":")[1]
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid id="GTipoDetalleTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleNombres" >
                                                        {
                                                            "Nombre"
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid id="GDetalleIngresoTR" item md={6} textAlign={"left"}>
                                                    <Typography id="detalleRespuestas">
                                                        {
                                                            item.destinoNombre
                                                        }
                                                    </Typography>
                                                </Grid>
                                                
                                            </Grid>
                                        </Box>
                                        <Box id="BoxRespuestaEnDetalleTR" >
                                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                <Typography id="sentidoEnRegistro" sx={{ color: item.acceso ? "#338333" : "#CC261A" }}>
                                                    {
                                                        item.sentido
                                                    }
                                                </Typography>
                                                {
                                                    item.acceso ?
                                                        <DetalleAccesoExitosoIcon className="IconRespustaEnDetallePorAcceso" />
                                                        :
                                                        <DetalleAccesoFallidoIcon className="IconRespustaEnDetallePorAcceso" />
                                                }
                                            </Box>
                                            <Box id="BoxTextoRespuestaAcceso">
                                                <Typography id="RespuestaASolicitudAcceso">
                                                    {
                                                        item.comentario
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                        }
                    </CardContent>
                </Card>
        </Box>
    );
}


export default MostrarDetalleTR;