import "./Pases.css";
import { PasesTabla } from "./TablaPases";
import { LayoutMenuOpcion } from "../../../../Box/OpcionMenuGestionUsuario/LayoutMenuGU";
import { useEffect, useState } from "react";
import { NuevoPase } from "./NuevoPase";
import { NoData } from "../../../../Box/NoDataMenuGestionUsuario/NoData";
import { useTarjetas } from "../../../../../Hooks/Tarjetas/useTarjetas";
import useGetPase from "../../../../../Hooks/Pase/useGetPase";
import usePatentesUsuarioAsignaciones from "../../../../../Hooks/Patentes/usePatentesUsuarioAsignacion";


export const GestionUsuariosPases = ({userData}) => {
    const {pases} = useGetPase(userData.idPersona);
    const [rows, setRows] = useState([])
    const [nuevo, setNuevo] = useState(Boolean(false));
    const {patentesActivas}  = usePatentesUsuarioAsignaciones(userData.idPersona);
    const {tarjetas}  = useTarjetas();

    useEffect(() => {
        setRows(pases)
    }, [pases])


    return (
        nuevo ? 
        <NuevoPase 
        handleOpen={setNuevo}
        patentes={patentesActivas}
        setRows={setRows}
        tarjetas={tarjetas}
        user={userData.idPersona}
        />
        :
        <LayoutMenuOpcion titulo={"TAG/RFID"} showIcon={true} handleIcon={setNuevo}>
            {
                rows.length === 0 ?
                <NoData
                mensaje={"Este usuario actualmente no posee un TAG o RIFD de acceso asociado. Añade uno al sistema para que el usuario pueda ingresar utilizando este metodo.Utiliza el botón “Agregar” para proceder."}
                textoBtn={"Agregar"}
                event={setNuevo}
                />
                :
                <PasesTabla rows={rows} setRows={setRows} />
            }
        </LayoutMenuOpcion>
    );
}