import { IconButton, TextField } from "@mui/material";



//textfield con el diseno de perfil, se cambia de estilo standard a fill segun si la edicion esta activa o no
export const TextFieldUno = ({id, label, placeholder, name, helper, icon, disabled, formHandle, value, handlerChange,focus}) => {
    return(
        <TextField id={id}
        label={label}
        focused={focus}
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        value={value}
        fullWidth
        disabled={disabled}
        onChange={handlerChange}
        helperText={helper}
        sx={{
            "borderRadius": "8px",
            "& .Mui-disabled":{":before":{borderBottomStyle:"none"}, height: '100%'}, 
            "& .MuiInputBase-root":{":before":{borderBottomStyle:"none"}, height: '100%', paddingRight: disabled ? "0px":"16px"},
            "& .MuiFilledInput-root": {backgroundColor: "#F8F8F8"}, height: '100%' }}
        inputProps={{
            style:{
                color: "#626262",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                paddingLeft: "16px" ,
                WebkitTextFillColor: "#333",               
                paddingTop: disabled === true ? "0px" : "20px",
            },
        }}
        FormHelperTextProps={{
            style:{
                width:"100%",
                height:"10px",
                margin: "0px"
            }
        }}
        InputProps={{
            disableUnderline: true,
            sx:{display:"flex", 
                justifyContent:"center", 
                alignItems:"center", 
                //backgroundColor:"#f8f8f8f8", 
                borderBottom: disabled === true ? "2px solid #8A8A8A":null},
            endAdornment: disabled === true ? null : 
            <IconButton sx={{ padding: "0px"}} edge={"end"}  onClick={formHandle} >
                {icon}
            </IconButton> 
         }}
        InputLabelProps={{
            shrink:true,
            style:{
                width: "100%",
                //backgroundColor:"#f8f8f8f8",
                paddingLeft: disabled ? "20px": "0px",
                color: "#626262",
                fontFamily: "inherit",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                
            },
        }}
        variant= {disabled === true ? "standard" : "filled"} />
    );
}



export const TextFieldUnoPuntoDos = ({id, label, placeholder, name, helper, icon, disabled, formHandle, value, handlerChange,focus}) => {
    return(
        <TextField id={id}
        label={label}
        focused={focus}
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        value={value}
        fullWidth
        disabled={disabled}
        onChange={handlerChange}
        helperText={helper}
        sx={{
            "backgroundColor": "#F8F8F8",
            "borderRadius": "8px",
            "& .Mui-disabled":{":before":{borderBottomStyle:"none"}}, 
            "& .MuiInputBase-root":{":before":{borderBottomStyle:"none"}, height: '100%', paddingRight: "16px"},
            "& .MuiFilledInput-root": {backgroundColor: "#F8F8F8"}, height: '100%' }}
        inputProps={{
            style:{
                color:"#333",
                paddingLeft: "16px" ,
                WebkitTextFillColor: "#333",               
                paddingTop: "20px",
            },
        }}
        FormHelperTextProps={{
            style:{
                width:"100%",
                height:"10px",
                margin: "0px"
            }
        }}
        InputProps={{
            disableUnderline: true,
            sx:{ display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#f8f8f8f8"},
            endAdornment:
            <IconButton sx={{ padding: "0px"}} edge={"end"}  onClick={formHandle} >
                {icon}
            </IconButton> 
         }}
        InputLabelProps={{
            style:{
                shrink:true,
                width: "auto",
                paddingLeft: "0px",
                color: "#626262",
                fontFamily: "inherit", 
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                
            },
        }}
        variant= {"filled"} />
    );
}


export const TextFieldUnoEditarCorreo = ({id, label, placeholder, name, helper, icon, disabled, formHandle, value, handlerChange,focus}) => {
    return(
        <TextField id={id}
        label={label}
        focused={focus}
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        value={value}
        fullWidth
        disabled={disabled}
        onChange={handlerChange}
        helperText={helper}
        sx={{
            "borderRadius": "8px",
            "& .Mui-disabled":{":before":{borderBottomStyle:"none"}, height: '100%'}, 
            "& .MuiInputBase-root":{":before":{borderBottomStyle:"none"}, height: '100%', paddingRight: disabled ? "0px":"16px"},
            "& .MuiFilledInput-root": {backgroundColor: "#F8F8F8"}, height: '100%' }}
        inputProps={{
            style:{
                color: "#626262",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                paddingLeft: "16px" ,
                WebkitTextFillColor: "#333",               
                paddingTop: disabled === true ? "9px" : "20px",
            },
        }}
        FormHelperTextProps={{
            style:{
                width:"100%",
                height:"10px",
                margin: "0px"
            }
        }}
        InputProps={{
            disableUnderline: true,
            sx:{display:"flex", 
                justifyContent:"center", 
                alignItems:"center", 
                //backgroundColor:"#f8f8f8f8", 
                borderBottom: disabled === true ? "2px solid #8A8A8A":null},
            endAdornment: disabled === true ? null : 
            <IconButton sx={{ padding: "0px"}} edge={"end"}  onClick={formHandle} >
                {icon}
            </IconButton> 
         }}
        InputLabelProps={{
            style:{
                shrink:true,
                width: "100%",
                //backgroundColor:"#f8f8f8f8",
                paddingLeft: disabled ? "20px": "0px",
                color: "#626262",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                
            },
        }}
        variant= {disabled === true ? "standard" : "filled"} />
    );
}



export const TextFieldUnoEventos = ({id, label, placeholder, name, helper, icon, disabled, formHandle, value, handlerChange,focus}) => {
    return(
        <TextField id={id}
        label={label}
        focused={focus}
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        value={value}
        fullWidth
        disabled={disabled}
        onChange={handlerChange}
        helperText={helper}
        sx={{
            borderBottom: "1px solid black",
            "& .Mui-disabled":{":before":{borderBottomStyle:"none"}, height: '100%'}, 
            "& .MuiInputBase-root":{":before":{borderBottomStyle:"none"}, height: '100%', paddingRight: disabled ? "0px":"16px"},
            "& .MuiFilledInput-root": {backgroundColor: "#F8F8F8"}, height: '100%' }}
        inputProps={{
            style: {
                color: "#626262",
                fontFamily: "inherit",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "450",
                lineHeight: "24px",
                letterSpacing: "0.5px",
                background: '#FFF'
            }
        }}
        FormHelperTextProps={{
            style:{
                width:"100%",
                height:"10px",
                margin: "0px"
            }
        }}
        InputProps={{
            disableUnderline: true,
            sx:{display:"flex", 
                justifyContent:"center", 
                alignItems:"center", 
                borderBottom: disabled === true ? "1px solid #8A8A8A":null},
            endAdornment: disabled === true ? null : 
            <IconButton sx={{ padding: "0px"}} edge={"end"}  onClick={formHandle} >
                {icon}
            </IconButton> 
         }}
        InputLabelProps={{
            shrink: true,
            style: {
                color: "#626262",
                fontFamily: "inherit",
                fontSize: "16px",
                fontWeight: "400",
                fontStyle: "normal",
                lineHeight: "24px",
                letterSpacing: "0.5px",
            }
        }}
        variant= {"standard"} />
    );
}