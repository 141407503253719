import { SvgIcon } from "@mui/material";

export function IconoGestionActuadores (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path d="M2 2V10M2 10H10M2 10C5.10326 7.21521 7.97717 4.06235 12.3271 3.45038C14.9036 3.08791 17.5281 3.57254 19.8052 4.83125C22.0823 6.08995 23.8886 8.05455 24.9519 10.429M26 26V18M26 18H18M26 18C22.8967 20.7848 20.0228 23.9377 15.6729 24.5496C13.0964 24.9121 10.4719 24.4275 8.1948 23.1688C5.91772 21.9101 4.11142 19.9455 3.04806 17.571" stroke="#333333" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function IconoBuscarLupa (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20 20L14.9497 14.9498M14.9497 14.9498C16.2165 13.683 17 11.933 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C11.933 17 13.683 16.2165 14.9497 14.9498Z" stroke="#626262" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function IconoVerMas (props){
    return(
        <SvgIcon {...props}>
            <svg className="icono-ver-mas" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_6556_74557)">
                <rect className="fondo-icono-ver-mas" x="4" y="3" width="40" height="40" rx="20"/>
                <path className="trazo-icono-ver-mas" d="M32 31L26.9497 25.9498M26.9497 25.9498C28.2165 24.683 29 22.933 29 21C29 17.134 25.866 14 22 14C18.134 14 15 17.134 15 21C15 24.866 18.134 28 22 28C23.933 28 25.683 27.2165 26.9497 25.9498Z"/>
                </g>
                <defs>
                <filter id="filter0_dd_6556_74557" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_6556_74557"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6556_74557"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_6556_74557" result="effect2_dropShadow_6556_74557"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6556_74557" result="shape"/>
                </filter>
                </defs>
            </svg>
        </SvgIcon>
    )
}