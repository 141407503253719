import "./RegistroPatentes.css";
import { PatentesTablaGU } from "./TablaPatentes";
import { LayoutMenuOpcion } from "../../../../Box/OpcionMenuGestionUsuario/LayoutMenuGU";
import { useEffect, useState } from "react";
import { NuevaPatente } from "./NuevaPatente";
import { NoData } from "../../../../Box/NoDataMenuGestionUsuario/NoData";
import usePatentesUsuario from "../../../../../Hooks/Patentes/usePatentesUsuario";
import { EditarPatente } from "./EditarPatente";

export const GestionUsuariosPatentes = ({userData}) =>{
    const [nuevo, setNuevo] = useState(Boolean(false));
    const [editar, setEditar] = useState(Boolean(false));
    const [idToEdit, setIdToEdit] = useState(Boolean(false));
    const [digitos, setDigitos] = useState(Boolean(false));
    const {patentes} = usePatentesUsuario(userData.idPersona);
    const [rows, setRows] = useState([]);
    
    useEffect(() => {
        setRows(patentes)
    }, [patentes])

    return (
        nuevo ?
        <NuevaPatente setOpen={setNuevo} userData={userData} setRows={setRows}/>
        :
        editar === true ?
        <EditarPatente setOpen={setEditar} rows={rows} user={userData.idPersona} setRows={setRows} toEdit={idToEdit} digitos={digitos}/>
        :
        <LayoutMenuOpcion titulo={"Patentes"} showIcon={true} handleIcon={setNuevo}>
            {
                rows.length === 0 ?
                <NoData 
                mensaje={"Actualmente, no hay patentes asociadas a este usuario.Agregar una patente facilitará la gestión y el control de accesos vehiculares.Utiliza el botón “Agregar patente” para comenzar."}
                textoBtn={"Agregar patente"}
                event={setNuevo}
                /> 
                :
                <PatentesTablaGU dataPatente={rows} setRows={setRows} openEdit={setEditar} setEdit={setIdToEdit} setDigitos={setDigitos} />
            }
        </LayoutMenuOpcion>
    );
}