import { Box, Divider, MenuItem } from "@mui/material";
import useInvitados from "../../../Hooks/Eventos/useInvitados";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";
import { useState } from "react";

export const MenuCorreoInvitado = ({infoEnviarCorreo}) => {
    const [ConfirmationDialog, confirm ]= useConfirm();
    const {EnviarInvitacionUnica, EnviarAccesoIndividual} = useInvitados()
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    const handleEnvioInvitacion = async () => {
        let response = await confirm();
        if(response === true) {
            EnviarInvitacionUnica(infoEnviarCorreo.idVisita, infoEnviarCorreo.idVisitante)
            .then(
                (resolve) => {
                    setSnackbarSeverity("success");
                    setSnackMsg("Correo de invitacion enviado exitosamente!")
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("fallo el envio, reintente nuevamente!")
                    setSnackbar(true);
                }
            )
        }
        else{
            console.log("Rechaza envio");
        }
    };

    const handleEnvioAcceso = async() => {
        let response = await confirm();
        if(response === true) {
            EnviarAccesoIndividual(infoEnviarCorreo.idVisita, infoEnviarCorreo.idVisitante)
            .then(
                (resolve) => {
                    setSnackbarSeverity("success");
                    setSnackMsg("Correo de acceso enviado exitosamente!")
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("fallo el envio, reintente nuevamente!")
                    setSnackbar(true);
                }
            )
        }
        else{
            console.log("Rechaza envio");
        }
    };

    return(
        <Box>
            <MenuItem onClick={handleEnvioInvitacion}>Enviar Invitacion</MenuItem>
            <Divider orientation="horizontal"/>
            <MenuItem onClick={handleEnvioAcceso}>Enviar Acceso</MenuItem> 
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={"¿Enviar correo?"}
            mensaje={"Se enviara un nuevo correo al invitado ¿Continuar?"}
            />
            <SnackBarTipoUno
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />
        </Box>
        
    );
}