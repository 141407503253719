import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import "./ModalLoading.css";

const style = {
    display: "flex",
    width: "404px",
    height: "368px",
    position: 'absolute',
    flexDirection: "column",
    justifyContent: "center",
    aligItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    border: "none",
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
};


export const ModalLoading = ({msj}) => {
    return(
        <Box sx={{...style}}>
            <Box id="BoxContenidoLoading">
                <Box id="BoxLoadingCircular" >
                    <CircularProgress size={96} style={{color:"#175676"}} />
                </Box>
                <Box id="BoxTextoLoading">
                    <Box id="BoxLoadingTitulo">
                        <Typography id="TituloLoading">
                            {
                                "Loading..."
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxLoadingTexto">
                        <Typography id="TextoLoading">
                            {
                                msj
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export const ModalLoadingDos = ({tituloLoading, msj}) => {
    return(
        <Box sx={{...style}}>
            <Box id="BoxContenidoLoading">
                <Box id="BoxLoadingCircular" >
                    <CircularProgress size={96} style={{color:"#175676"}} />
                </Box>
                <Box id="BoxTextoLoading">
                    <Box id="BoxLoadingTitulo">
                        <Typography id="TituloLoading">
                            {
                                tituloLoading
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxLoadingTexto">
                        <Typography id="TextoLoading">
                            {
                                msj
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export const ModalLoadingSimple = ({open, setOpen, msj}) => {
    const handleClose = () => {
        setOpen(false);
    }

    return(
        <Modal open={open} onClose={handleClose}>
            <Box sx={{...style}}>
                <Box id="BoxContenidoLoading">
                    <Box id="BoxLoadingCircular" >
                        <CircularProgress size={96} style={{color:"#175676"}} />
                    </Box>
                    <Box id="BoxTextoLoading">
                        <Box id="BoxLoadingTitulo">
                            <Typography id="TituloLoading">
                                {
                                    "Loading..."
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxLoadingTexto">
                            <Typography id="TextoLoading">
                                {
                                    msj
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}