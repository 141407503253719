import { SvgIcon } from "@mui/material";

export function GestionZonasIcon (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" width="28" height="30" viewBox="0 0 28 30" fill="none">
                <path d="M3.60539 20C2.58437 20.7845 2 21.6952 2 22.6667C2 25.6122 7.37258 28 14 28C20.6274 28 26 25.6122 26 22.6667C26 21.6952 25.4156 20.7845 24.3946 20M16.625 9.27273C16.625 10.779 15.4497 12 14 12C12.5503 12 11.375 10.779 11.375 9.27273C11.375 7.7665 12.5503 6.54545 14 6.54545C15.4497 6.54545 16.625 7.7665 16.625 9.27273ZM21 9.27273C21 13.2893 17.866 18.1818 14 20C11.375 18.7727 7.39375 14.6818 7 9.27273C7 5.25611 10.134 2 14 2C17.866 2 21 5.25611 21 9.27273Z" stroke="#333333" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function BulkLoadIcon (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function PlusIcon (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 18 18" fill="none">
            <path d="M1 9H17M9 1V17" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function EditarIcon (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 10L21 7L17 3L14 6M18 10L8 20H4V16L14 6M18 10L14 6" stroke="#626262" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function EliminarIcon (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10 10V16M14 10V16M18 6V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V6M4 6H20M15 6V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V6" stroke="#626262" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function InactivoIcon (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M14 6L6 14M6.00001 6L14 14M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#CC261A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function ActivoIcon (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
                <path d="M76.4444 36.3333L42.2221 70.5557L27.5553 55.8889M96 51C96 75.3005 76.3005 95 52 95C27.6995 95 8 75.3005 8 51C8 26.6995 27.6995 7 52 7C76.3005 7 96 26.6995 96 51Z" stroke="#338333" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}