import { Box, CardMedia, IconButton } from "@mui/material";
import "./LoginVentanaDos.css"
import torniquete from "../../../assets/imagenes/LoginImagenDerecha.png";
import { ContactoIcon } from "../LoginIcons";
import TooltipTipoUno from "../../ToolTip/Tooltip";

export const ImagenLAU = ({handleOpcion}) => {

    const handlerToContact = () => {
        handleOpcion(2);
    }



    return(
        <Box id="BoxImagenContactoVentanaDos">
            <Box id="BoxImagenVentanaDosLogin">
                <CardMedia id="ImagenLoginTorLau" component={"img"} src={torniquete} alt="CPLau" />
            </Box>
            <Box id="BoxIconoToContacto">
                <TooltipTipoUno titulo={"Contactanos"} ubicacion={"top"}>
                    <IconButton onClick={handlerToContact}>
                        <ContactoIcon />
                    </IconButton>
                </TooltipTipoUno>                
            </Box>
        </Box>
    );
}