import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import usePatentes from "../../../Hooks/Patentes/usePatentes";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import "./OpcionesPatente.css";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import { TextFieldTres, TextFieldTresPuntoDos } from "../../TextField/TextFieldTres";
import useGetAll from "../../../Hooks/Usuarios/useGetAll";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useState } from "react";
import { EsquemaNuevaPatente } from "./ValidationScheme";

export const NuevaPatenteGestion = ({setOpen, setRows}) => {
    const {loading, response, CrearNuevo, setLoading} = usePatentes();
    const [openLoading, setOpenLoading] = useState(false);
    const {listaUsuarios} = useGetAll(); 

    const handlerClose = () => {
        setOpen(false);
    }

    const nuevaRow = (nuevaId) => {
        var userFinded = listaUsuarios.find(u => u.idPersona === formik.values.idPersona);
        
        setRows((oldRows) => [
            
            ...oldRows,
            {
              codigo: null,
              estadoPatente: true,
              estadoTarjeta: null,
              idPase: null,
              idPatente: nuevaId,
              idPersona: formik.values.idPersona,
              idTarjeta: null,
              idTipoTarjeta: null,
              idVisitante: null,
              nombreCompleto: formik.values.nombreCompleto.split("/")[0],
              pasaporte: userFinded.pasaporte,
              patenteDigitos: formik.values.patente,
              rut: userFinded.rut,
              tipo: null,
              isNew: true,
            },
        ]);
    }

    const formik = useFormik({
        initialValues: { patente: "", idPersona: -1, nombreCompleto: "Usuarios"},
        enableReinitialize: true,
        validationSchema: EsquemaNuevaPatente,
        onSubmit: async () => {
            setLoading(true)
            setOpenLoading(true);
            await CrearNuevo(formik.values.patente, formik.values.idPersona)
            .then(
                (resolve) => {
                    nuevaRow(resolve);
                },
                (reject) => {
                    console.log("fallo la creacion");
                }
            )
        }
    })

    const handleAgregarOtro = () => {
        formik.resetForm();
        handlerClose();
    }

    return(
        <Box id="BoxAgregarNuevaPatente">
            <Box id="BoxTituloSubtituloOpcionNuevaPatente">
                <Box id="BoxTituloOpcionNuevaPatente">
                    <Typography id="TxtTituloOpcionNuevaPatente">
                        {
                            "Agregar patente"
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloOpcionNuevaPatente">
                    <Typography id="TxtSubTituloOpcionNuevaPatente">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxFormularioOpcionNuevaPatente" >
                <Box id="BoxContenidoFormularioNuevaPatente" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxInformacionPatenteNuevaPatente">
                        <Box id="BoxTituloInformacionPatenteNuevaPatente">
                            <Typography id="TxtTitulosFormulariosNuevaPatente">
                                {
                                    "Inf. de la Patente"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxTextFieldFormularioNuevaPatente">
                            <TextFieldTresPuntoDos
                            id="patente"
                            label={"Patente"}
                            placeholder={"Patente"}
                            icon={<IconoBorrarCampoGU />}
                            helper={formik.errors.patente}
                            value={formik.values.patente.toLocaleUpperCase()}
                            name={"patente"}
                            handlerChange={formik.handleChange}
                            handlerClick={() => formik.setFieldValue("patente", "")}
                            disabled={false}
                            />
                        </Box>
                    </Box>
                    <Box id="BoxSegundaInformacionPatenteNuevaPatente">
                        <Box id="BoxTituloSegundaInformacionNuevaPatente">
                            <Typography id="TxtTitulosFormulariosNuevaPatente">
                                {
                                    "Inf. del Usuario"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxTextFieldUsuarioFormularioNuevaPatente">
                            <FormControl fullWidth>
                                <Autocomplete                                   
                                disablePortal
                                ListboxProps={{ sx: { maxHeight: "150px"}}}
                                options={listaUsuarios}
                                fullWidth    
                                id="idPersona"
                                componentname="idPersona"
                                freeSolo={true}
                                getOptionKey={(o) => o.idPersona}
                                isOptionEqualToValue={(o, v) => o.nombreRut === v}
                                onChange={(e, v) => {
                                    if(v === null)
                                    {
                                        formik.setFieldValue("idPersona", -1)                                           
                                    }
                                    else{
                                        formik.setFieldValue("idPersona", v.idPersona)
                                    }
                                }}
                                getOptionLabel={(option) =>  option.nombreRut ?? option}
                                inputValue={formik.values.nombreCompleto}
                                value={formik.values.nombreCompleto}
                                onInputChange={(e,v) => formik.setFieldValue("nombreCompleto", v)}   
                                renderInput={(params) => 
                                    <TextField
                                    InputLabelProps={{shrink: true}}
                                    {...params}
                                    label="Usuarios" />
                                }
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                            <Box
                                            key={key}
                                            component="li"
                                            {...optionProps}>
                                            {option.nombreRut}
                                            </Box>
                                        );
                                    }}
                                />
                                <FormHelperText>
                                    {
                                        formik.errors.idPersona
                                    }
                                </FormHelperText>                         
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxHandlerFormularioNuevaPatente">
                    <Box id="BoxAccionNuevaPatente">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                    </Box>
                    <Box id="BoxAccionNuevaPatente">
                        <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCuatro
                            id={"LoadingMasRespuestaCuatroCrearPatente"}
                            open={openLoading}
                            setOpen={setOpenLoading}
                            msj={
                                "Se está agregando un nueva patente al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                            }
                            tituloLoading={"Agregando patente..."}
                            loading={loading}
                            respuesta={response}
                            titulo={
                                response === 201 ? 
                                "Patente agregada"
                                : 
                                "Patente no agregada"
                            }
                            mensajeRespuesta={
                                response === 201
                                ? "La patente ha sido agregado exitosamente al sistema"
                                : response === 403 ?
                                    "No se ha podido generar la solicitud de creacion, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                                    :
                                    "No se ha podido agregar la patente debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
                            }
                            txtBotonUno={"Agregar otra patente"}
                            eventBotonUno={handleAgregarOtro}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}