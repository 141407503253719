import axios from "axios";
import { useEffect, useState } from "react";
import { BuscarActuadoresEmpresa } from "../../Service/API/Api";

export default function useGetActuadores(idEmpresa) {
    const [actuadores, setActuadores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get(`${BuscarActuadoresEmpresa}${idEmpresa}`)
            .then((response) => {
                setActuadores(response.data);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    // Si la respuesta del servidor está disponible pero hay un error (404, 500, etc.)
                    console.error("Error en la respuesta del servidor:", error.response.data);
                    setError("Error en la respuesta del servidor.");
                } else if (error.request) {
                    // Si no se recibió ninguna respuesta (fallo de red, servidor no disponible)
                    console.error("No se pudo conectar con el servidor.");
                    setError("No se pudo conectar con el servidor.");
                } else {
                    // Si ocurre un error durante la configuración de la solicitud
                    console.error("Error desconocido:", error.message);
                    setError("Error desconocido.");
                }
                setLoading(false);
            });
    }, [idEmpresa]);

    return {
        actuadores,
        loading,
        error,
        setLoading
    };
}
