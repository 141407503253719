import { SvgIcon } from "@mui/material";



export function ReportesIcon (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28" fill="none">
                <path d="M5.75 8H18.25M5.75 14H18.25M5.75 20H13.25M2 2H22V26H2V2Z" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function DownloadIcon (props){
    return(
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M20 15V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 15M8 11L12 15M12 15L16 11M12 15V3" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </SvgIcon>
    )
}

export function LupaBuscarReportes (props){
    return(
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M19 19L12.2468 12.2468M12.2468 12.2468C13.4391 11.0546 14.1765 9.40752 14.1765 7.58823C14.1765 3.94965 11.2268 1 7.58824 1C3.94965 1 1 3.94965 1 7.58823C1 11.2268 3.94965 14.1765 7.58824 14.1765C9.40753 14.1765 11.0546 13.4391 12.2468 12.2468Z" stroke="#8A8A8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </SvgIcon>
    )
}

export function ComentarioWhiteIcon (props){
    return(
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8 8H16M8 12H13M7 16V21L12 16H20V4H4V16H7Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </SvgIcon>
    )
}

export function ComentarioBlackIcon (props){
    return(
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8 8H16M8 12H13M7 16V21L12 16H20V4H4V16H7Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </SvgIcon>
    )
}

export function TrianguloIcon(props){
    return(
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" viewBox="0 0 18 25" fill="white">
            <path d="M18 12.5L9.27582e-07 24.1913L1.837e-06 0.808657L18 12.5Z"  stroke="white"/>
        </svg>
    </SvgIcon>
    )
}

export function SvgModalIcon(props){
    return(
    <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
            <path d="M48 72.3956V72.4444M48 23.5556V57.7778M92 48C92 72.3005 72.3005 92 48 92C23.6995 92 4 72.3005 4 48C4 23.6995 23.6995 4 48 4C72.3005 4 92 23.6995 92 48Z" stroke="#CC261A" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </SvgIcon>
    )
}


export function ComentarioIcono(props){
    return(
    <SvgIcon {...props}>
        <svg className="icono-comentario" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dd_6111_296583)">
            <rect className="fondo-icono-comentario" x="4" y="3" width="40" height="40" rx="20"/>
            <path className="trazo-icono-comentario" d="M20 19H28M20 23H25M19 27V32L24 27H32V15H16V27H19Z"/>
            </g>
            <defs>
            <filter id="filter0_dd_6111_296583" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_6111_296583"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="1.5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6111_296583"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_6111_296583" result="effect2_dropShadow_6111_296583"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6111_296583" result="shape"/>
            </filter>
            </defs>
        </svg>
    </SvgIcon>
    )
}