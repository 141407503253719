import * as Yup from 'yup';
import dayjs from 'dayjs';


export const validationSchema = Yup.object({
    idTipoEvento: Yup.number()
    .test("min", "Debe seleccionar un tipo", function(value){
        if(value === -1)
        {
            return false;
        }
        else 
        {
            return true;
        }
    })
    ,  
    nombreEvento: Yup.string()
        .max(20, "* Permitido Maximo de 20 caracteres")
        .min(5,"* Requerido Minimo de 5 caracteres")
        .required('* Debe indicar un nombre de evento')
    ,
    fechaUno: Yup.date()
    .test("min", "fecha no Valida", function(value){
        const dn = Date.now()
        const fu = dayjs(value)
        const fv = dayjs(dn)

        if(fu.isSame(fv, 'day')){

            return true
        }

        if(fu.isAfter(fv, 'day')){

            return true
        }

        if(fu.isBefore(fv, 'day')){

            return false
        }

    })
    ,
    fechaDos: Yup.date()
    .test("min", "fecha no Valida", function(value){
        const { fechaUno, idTipoEvento } = this.parent
        if(idTipoEvento === 0){
            return true
        }
        else{
            const fd = dayjs(value)
            const fu = dayjs(fechaUno)
            
            if(fd.isBefore(fu, 'day')){
                return false
            }
            if(fd.isSame(fu, 'day') || fd.isAfter(fu, 'day'))
            {
                return true
            }
        }

    })
    ,
    desde: Yup.date()
    .test("min", "Hora no valida", function(value){
        const { fechaUno } = this.parent
        const hora = dayjs(value).hour();
        const minuto = dayjs(value).minute();
        const segundos = dayjs(value).second();
        
        const diaSeleccionado = dayjs(fechaUno).hour(hora).minute(minuto).second(segundos);
        const dn = Date.now()
        const fv = dayjs(dn)

        if(diaSeleccionado.isSame(fv, 'minutes')){
            return true
        }

        if(diaSeleccionado.isBefore(fv, 'minutes')){
            return false
        }

        if(diaSeleccionado.isAfter(fv, 'minutes')){
            return true
        }
        
    })
    ,
    hasta: Yup.date()
    .test("min", "Hora no valida", function(value){
        const { fechaUno, desde, fechaDos, idTipoEvento } = this.parent        
        
        const hora = dayjs(value).hour();
        const minuto = dayjs(value).minute();
        const segundos = dayjs(value).second();

        const fuHora = dayjs(desde).hour();
        const fuMminuto = dayjs(desde).minute();
        const fuSegundos = dayjs(desde).second();


        const fechaUnoSeleccionada = dayjs(fechaUno).hour(fuHora).minute(fuMminuto).second(fuSegundos);
        const fechaDosSeleccionada = dayjs(fechaDos).hour(hora).minute(minuto).second(segundos);
        var fud = dayjs(fechaUnoSeleccionada);
        
       if(idTipoEvento === 0)
       {
            const fechaDosSeleccionadaTE = dayjs(fechaUno).hour(hora).minute(minuto).second(segundos);

            
            if(fechaDosSeleccionadaTE.isAfter(fud, 'minutes'))
            {
                return true;
            }
            
            if(fechaDosSeleccionadaTE.isSame(fud, 'minutes')){
                return false;
            }
            
            if(fechaDosSeleccionadaTE.isBefore(fud, 'minutes')){
                return false;
            }
       }
       else
       {
            if(fechaDosSeleccionada.isAfter(fud, 'minutes'))
            {
                return true;
            }
            
            if(fechaDosSeleccionada.isSame(fud, 'minutes')){
                return false;
            }
            
            if(fechaDosSeleccionada.isBefore(fud, 'minutes')){
                return false;
            }
       }
    })
    ,
    idDestino: Yup.number()
    .test("min", "debe seleccionar un destino", function(value){
        if(value === -1)
        {
            return false;
        }
        else
        {
            return true;
        }
    })
    ,
    lunes: Yup.boolean(),
    martes: Yup.boolean(),
    miercoles: Yup.boolean(),
    jueves: Yup.boolean(),
    viernes: Yup.boolean(),
    sabado: Yup.boolean(),
    domingo: Yup.boolean(),
})