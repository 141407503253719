import axios from "axios";
import { useEffect, useState } from "react";
import { BuscarUbicacionPersona } from "../../Service/API/Api";

export default function useUbicacionUsuario(idPersona)
{
    const [ubicacion, setUbicacion] = useState({
        idPersonaUbicacion: null,
        idUbicacion: null,
        nombreUbicacion: null,
        piso: null,
        numeracion: null,
        tipoUbicacion: null
    });

    useEffect(() => {
        axios.get(BuscarUbicacionPersona + idPersona)
        .then(
            (response) => {
                setUbicacion({
                    idPersonaUbicacion: response.data.idPersonaUbicacion,
                    idUbicacion: response.data.idUbicacion,
                    nombreUbicacion: response.data.nombreUbicacion,
                    piso: response.data.piso,
                    numeracion: response.data.numeracion,
                    tipoUbicacion: response.data.tipoUbicacion
                });
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
            }
        )
    }, [idPersona])

    return {
        ubicacion
    }
    
}