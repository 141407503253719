import { Box, Dialog, DialogActions, Typography } from '@mui/material';
import { useState } from 'react';
import { BotonTipo2 } from '../../Components/Botones/BotonesTabla/Botones';
import "./useConfirmDialog.css";

const useConfirm = () => {
    const [promise, setPromise] = useState(null);
  
    const confirm = () => new Promise((resolve, reject) => {
      setPromise({ resolve });
    });
  
    const handleClose = () => {
      setPromise(null);
    };
  
    const handleConfirm = () => {
      promise?.resolve(true);
      handleClose();
    };
  
    const handleCancel = () => {
      promise?.resolve(false);
      handleClose();
    };

    const ConfirmationDialog = ({icono, titulo, mensaje}) => (
        <Dialog
        open={promise !== null}
        fullWidth
        PaperProps={{
            sx:{
                width: '404px',
                height: '436px',
                margin: "0px",
                overflowY: 'unset',
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
                borderRadius:"16px"
            }
        }}
            >
            <Box id="BoxDialogConfirmacion">
                <Box id="BoxDataConfirmacion">
                    <Box id="BoxIconoDialogConfirmacion" >
                        {
                            icono
                        }
                    </Box>
                    <Box id="BoxTituloMensajeConfirmacion" >
                        <Box id="BoxTituloDialogConfirmacion">
                            <Typography id="txtTituloDialogConfirmacion">
                                {
                                    titulo
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxMensajeDialogConfirmacion">
                            <Typography id="txtMensajeDialogConfirmacion">
                                {
                                    mensaje
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesDialogConfirmacion" >
                    <DialogActions  sx={{ width:"100%",height:"100%", padding:"0px"}}>
                        <Box id="BoxOpcionesDialogConfirmacion">
                            <Box id="BoxBotonDialogConfirmacion">
                                <BotonTipo2 textoBoton={"Cancelar"} event={handleCancel} />
                            </Box>
                            <Box id="BoxBotonDialogConfirmacion">
                                <BotonTipo2 textoBoton={"Aceptar"} event={handleConfirm} />
                            </Box>
                        </Box>
                    </DialogActions>
                </Box>
            </Box>
      </Dialog>
    );
    return [ConfirmationDialog, confirm];
  };
  
  export default useConfirm;