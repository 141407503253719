import { Box, Divider, Typography } from "@mui/material"
import "./ManejadorOpciones.css";
import { useFormik } from "formik";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useState } from "react";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import SeleccionTiempoHorario from "../../TimePicker/TimePickerDos";
import { EsquemaNuevoHorario } from "./validationSchema";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import useGestionHorarios from "../../../Hooks/Horarios/useGestionHorarios";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";

export const EditarHorarioGestionHorario = ({setRows, setOpen, dataEditar, rows}) => {
    const data = useSelector(action => action.user.authenticated)
    const {loading, response, EditHorario, setLoading} = useGestionHorarios();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [ConfirmationDialog, confirm ]= useConfirm();


    //lunes
    const lunesTextInicio = dataEditar.lunes === null ? "00:00" : dataEditar.lunes.split("a")[0] 
    const lunesTextTermino = dataEditar.lunes === null ? "00:00" : dataEditar.lunes.split("a")[1].split("hrs")[0]
    const lunesInicio = new Date(2024, 1, 1, lunesTextInicio.split(":")[0], lunesTextInicio.split(":")[1])
    const lunesTermino = new Date(2024, 1, 1, lunesTextTermino.split(":")[0], lunesTextTermino.split(":")[1])
    //martes
    const martesTextInicio = dataEditar.martes === null ? "00:00" : dataEditar.martes.split("a")[0] 
    const martesTextTermino = dataEditar.martes === null ? "00:00" : dataEditar.martes.split("a")[1].split("hrs")[0]
    const martesInicio = new Date(2024, 1, 1, martesTextInicio.split(":")[0], martesTextInicio.split(":")[1])
    const martesTermino = new Date(2024, 1, 1, martesTextTermino.split(":")[0], martesTextTermino.split(":")[1])
    //miercoles
    const miercolesTextInicio = dataEditar.miercoles === null ? "00:00" : dataEditar.miercoles.split("a")[0] 
    const miercolesTextTermino = dataEditar.miercoles === null ? "00:00" : dataEditar.miercoles.split("a")[1].split("hrs")[0]
    const miercolesInicio = new Date(2024, 1, 1, miercolesTextInicio.split(":")[0], miercolesTextInicio.split(":")[1])
    const miercolesTermino = new Date(2024, 1, 1, miercolesTextTermino.split(":")[0], miercolesTextTermino.split(":")[1])
    //jueves
    const juevesTextInicio = dataEditar.jueves === null ? "00:00" : dataEditar.jueves.split("a")[0] 
    const juevesTextTermino = dataEditar.jueves === null ? "00:00" : dataEditar.jueves.split("a")[1].split("hrs")[0]
    const juevesInicio = new Date(2024, 1, 1, juevesTextInicio.split(":")[0], juevesTextInicio.split(":")[1])
    const juevesTermino = new Date(2024, 1, 1, juevesTextTermino.split(":")[0], juevesTextTermino.split(":")[1])
    //viernes
    const viernesTextInicio = dataEditar.viernes === null ? "00:00" : dataEditar.viernes.split("a")[0] 
    const viernesTextTermino = dataEditar.viernes === null ? "00:00" : dataEditar.viernes.split("a")[1].split("hrs")[0]
    const viernesInicio = new Date(2024, 1, 1, viernesTextInicio.split(":")[0], viernesTextInicio.split(":")[1])
    const viernesTermino = new Date(2024, 1, 1, viernesTextTermino.split(":")[0], viernesTextTermino.split(":")[1])
    //sabado
    const sabadoTextInicio = dataEditar.sabado === null ? "00:00" : dataEditar.sabado.split("a")[0] 
    const sabadoTextTermino = dataEditar.sabado === null ? "00:00" : dataEditar.sabado.split("a")[1].split("hrs")[0]
    const sabadoInicio = new Date(2024, 1, 1, sabadoTextInicio.split(":")[0], sabadoTextInicio.split(":")[1])
    const sabadoTermino = new Date(2024, 1, 1, sabadoTextTermino.split(":")[0], sabadoTextTermino.split(":")[1])
    //domingo
    const domingoTextInicio = dataEditar.domingo === null ? "00:00" : dataEditar.domingo.split("a")[0] 
    const domingoTextTermino = dataEditar.domingo === null ? "00:00" : dataEditar.domingo.split("a")[1].split("hrs")[0]
    const domingoInicio = new Date(2024, 1, 1, domingoTextInicio.split(":")[0], domingoTextInicio.split(":")[1])
    const domingoTermino = new Date(2024, 1, 1, domingoTextTermino.split(":")[0], domingoTextTermino.split(":")[1])


    const formik = useFormik({
        initialValues:
        {
            nombre: dataEditar.nombre,
            lunesInicio: dayjs(lunesInicio),
            lunesTermino: dayjs(lunesTermino),
            martesInicio: dayjs(martesInicio),
            martesTermino: dayjs(martesTermino),
            miercolesInicio: dayjs(miercolesInicio),
            miercolesTermino: dayjs(miercolesTermino),
            juevesInicio: dayjs(juevesInicio),
            juevesTermino: dayjs(juevesTermino),
            viernesInicio: dayjs(viernesInicio),
            viernesTermino: dayjs(viernesTermino),
            sabadoInicio: dayjs(sabadoInicio),
            sabadoTermino: dayjs(sabadoTermino),
            domingoInicio: dayjs(domingoInicio),
            domingoTermino: dayjs(domingoTermino),
        },
        enableReinitialize: true,
        validationSchema: EsquemaNuevoHorario,
        onSubmit: async () => {
            var response = await confirm();
            if(response){
                setLoading(true)
                setOpenLoading(true)
                await EditHorario(formik.values, data.idEmpresa, dataEditar.idHorario)
                .then(
                    (resolve) => {

                        var horarioUp = {
                            idHorario: dataEditar.idHorario,
                            nombre: formik.values.nombre,
                            lunes: dayjs(formik.values.lunesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.lunesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.lunesInicio).format("HH:mm") + " a " + dayjs(formik.values.lunesTermino).format("HH:mm"),
                            martes: dayjs(formik.values.martesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.martesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.martesInicio).format("HH:mm") + " a " + dayjs(formik.values.martesTermino).format("HH:mm"),
                            miercoles: dayjs(formik.values.miercolesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.miercolesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.miercolesInicio).format("HH:mm") + " a " + dayjs(formik.values.miercolesTermino).format("HH:mm"),
                            jueves: dayjs(formik.values.juevesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.juevesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.juevesInicio).format("HH:mm") + " a " + dayjs(formik.values.juevesTermino).format("HH:mm"),
                            viernes: dayjs(formik.values.viernesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.viernesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.viernesInicio).format("HH:mm") + " a " + dayjs(formik.values.viernesTermino).format("HH:mm"),
                            sabado: dayjs(formik.values.sabadoInicio).format("HH:mm") === "00:00" && dayjs(formik.values.sabadoTermino).format("HH:mm") === "00:00" ?  "-":  dayjs(formik.values.sabadoInicio).format("HH:mm") + " a " + dayjs(formik.values.sabadoTermino).format("HH:mm"),
                            domingo: dayjs(formik.values.domingoInicio).format("HH:mm") === "00:00" && dayjs(formik.values.domingoTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.domingoInicio).format("HH:mm") + " a " + dayjs(formik.values.domingoTermino).format("HH:mm"),
                        }
                        setRows(rows.map((row) => (row.idHorario === horarioUp.idHorario ? horarioUp : row)))
                    },
                    (reject) => {
                        console.log("fallo la actualizacion")
                    }
                )
            }
            else{
                console.log("Rechaza la actualizacion")
            }
        }
    })

    const handleClose = () =>{
        setOpen(false);
        formik.resetForm();
    }
    

    return (
        <Box id="BoxAgregarNuevoHorario">
            <Box id="BoxTituloSubTituloAgregarHorario">
                <Box>
                    <Box id="BoxTituloOpcionGestionHorario">
                        <Typography id="TxtTituloOpcionGestionHorario">
                            {
                                "Editar horario"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxSubTituloOpcionGestionHorario">
                        <Typography id="TxtSubTituloOpcionGestionHorario">
                            {
                                "Todos los campos son obligatorios para completar el registro."
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box id="BoxContenidoFormularoCreacionHorarioGestionHorario" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxLayoutContenidoNuevoHorario">
                        <Box id="BoxInformacionNombreHorario">
                            <Box id="BoxTextoInformacionNuevoHorario">
                                <Typography id="TxtSubTituloOpcionGestionHorarioNuevo">
                                    {
                                        "Información de horario"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTexfieldNombreHorarioNuevo">
                                <TextFieldTres
                                    label={"Nombre Horario"}
                                    id="NombresUsuarioGUEditar"
                                    placeholder={"Nombre del Horario"}
                                    name={"nombre"}                                
                                    helper={formik.errors.nombre}
                                    icon={<IconoBorrarCampoGU id="IconoEnHorarios" />}
                                    disabled={ false}
                                    handlerClick={() => formik.setFieldValue("nombre", "")}
                                    value={formik.values.nombre}
                                    handlerChange={formik.handleChange}
                                    focus={false}
                                />                                    
                            </Box>
                        </Box>
                        <Box id="BoxContenidoOpcionNuevoHorario">
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Lunes"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.lunesInicio}
                                        handleTime={(value)=> formik.setFieldValue("lunesInicio", value)}
                                        name={"lunesInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.lunesTermino}
                                        handleTime={(value)=> formik.setFieldValue("lunesTermino", value)}
                                        name={"lunesTermino"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Martes"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.martesInicio}
                                        handleTime={(value)=> formik.setFieldValue("martesInicio", value)}
                                        name={"martesInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.martesTermino}
                                        handleTime={(value)=> formik.setFieldValue("martesTermino", value)}
                                        name={"martesTermino"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Miercoles"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.miercolesInicio}
                                        handleTime={(value)=> formik.setFieldValue("miercolesInicio", value)}
                                        name={"miercolesInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.miercolesTermino}
                                        handleTime={(value)=> formik.setFieldValue("miercolesTermino", value)}
                                        name={"miercolesTermino"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Jueves"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.juevesInicio}
                                        handleTime={(value)=> formik.setFieldValue("juevesInicio", value)}
                                        name={"juevesInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.juevesTermino}
                                        handleTime={(value)=> formik.setFieldValue("juevesTermino", value)}
                                        name={"juevesTermino"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Viernes"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.viernesInicio}
                                        handleTime={(value)=> formik.setFieldValue("viernesInicio", value)}
                                        name={"viernesInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.viernesTermino}
                                        handleTime={(value)=> formik.setFieldValue("viernesTermino", value)}
                                        name={"viernesTermino"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Sabado"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.sabadoInicio}
                                        handleTime={(value)=> formik.setFieldValue("sabadoInicio", value)}
                                        name={"sabadoInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.sabadoTermino}
                                        handleTime={(value)=> formik.setFieldValue("sabadoTermino", value)}
                                        name={"sabadoTermino"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Domingo"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.domingoInicio}
                                        handleTime={(value)=> formik.setFieldValue("domingoInicio", value)}
                                        name={"domingoInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.domingoTermino}
                                        handleTime={(value)=> formik.setFieldValue("domingoTermino", value)}
                                        name={"domingoTermino"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxAccionesFormiularioAgregarHorario">
                        <Box id="BoxTamanoBotonHandlerAgregarHorario">
                            <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                        </Box>
                        <Box id="BoxTamanoBotonHandlerAgregarHorario">
                            <BotonTipo2 textoBoton={"Actualizar"} disabled={loading}/>
                            <LoadingMasRespuestaCinco
                                id={"LoadingMasRespuestaDosEditarHorario"}
                                open={openLoading}
                                setOpen={setOpenLoading}
                                msj={
                                "Se está actualizando el horario.Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                                }
                                tituloLoading={"Actualizando..."}
                                loading={loading}
                                titulo={
                                    response === 200 ? 
                                    "Horario actualizado"
                                    : 
                                    "Horario no actualizado"
                                }
                                icono={
                                    response === 200 ?
                                    <IconoExito id="IconoRespuestaServidor" />
                                    :
                                    <IconoFallo id="IconoRespuestaServidor" />
                                }
                                mensajeRespuesta={
                                    response === 200
                                    ? "El Horario ha sido actualizado exitosamente al sistema. Ahora puedes asignar este horario a los usuarios"
                                    : response === 403 ?
                                        "No se ha podido generar la solicitud de actualizacion, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                                        :
                                        "No se ha podido actualizar el Horario debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
                                }
                                textoBoton={response === 200 ?
                                    "Aceptar"
                                    :
                                    "Reintentar"
                                }
                            />

                        <ConfirmationDialog
                        icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                        titulo={"¿Actualizar datos?"}
                        mensaje={"Estás a punto de actualizar la información del horario. ¿Deseas continuar?"}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}