
import { Box, IconButton, Modal } from "@mui/material";
import { CerrarModalNuevoUsuario } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import { AgregarNuevo } from "./OpcionNuevo";
import { VerInformacion } from "./VerInformacion";
import { EditarExistente } from "./EditarExistente";

const style = {
    width: "1024px",
    height: "680px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};



export const ModalGestionActuador = ({ open, handlerOpen, setRows, opcion, data, rows }) => {
    const handlerClose = () => {
        handlerOpen(false);
    };
    
    return (
        <Modal id="modalNuevoActuador" open={open} onClose={handlerClose} sx={{border:"unset"}}>
            <Box sx={{...style}}>
                <Box id="BoxCabeceraNuevoActuador">
                    <IconButton onClick={handlerClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>
                <Box id="BoxContenidoOpcionGestionActuador">
                    {
                        opcion === 1 ?
                        <AgregarNuevo setRows={setRows} handler={handlerOpen} />
                        :
                        opcion === 2 ?
                            <EditarExistente handler={handlerOpen} setRows={setRows} rows={rows} actorData={data}/>
                            :
                            <VerInformacion handler={handlerOpen} row={data} />
                    }
                </Box>
            </Box>
        </Modal>
    );
}