import { Button, CircularProgress, Typography } from "@mui/material";
import "./Botones.css";
import { LoadingButton } from "@mui/lab";

export const BotonTipo1 = ({textoBoton, event}) =>
{
    return(
        <Button id="BotonTipo1"
        fullWidth
        type="submit"
        onClick={event}> 
            {
                textoBoton
            }
        </Button>
    );
};


export const BotonTipo1Ref = ({textoBoton, referencia, event}) =>
{
    return(
        <Button id="BotonTipo1"
        fullWidth
        ref={referencia}
        type="submit"
        onClick={event}> 
            {
                textoBoton
            }
        </Button>
    );
};

export const BotonTipo2 = ({textoBoton, event, disabled}) =>
{   
    if(disabled === undefined || disabled === null || disabled === false){

        return(
            <Button id="BotonTipo2"
            fullWidth
            type="submit"
            onClick={event}> 
                {
                    textoBoton
                }
            </Button>
        );

    }else if(disabled){
        return(
            <Button id="BotonTipo2"
            disabled
            fullWidth
            type="submit"
            onClick={event}> 
                {
                    textoBoton
                }
            </Button>
        );
    }
};

export const BotonTipo3Ref = ({referencia, textoBoton, event}) =>
{
    return(
        <Button id="BotonTipo2"
        fullWidth
        ref={referencia}
        type="submit"
        onClick={event}> 
            {
                textoBoton
            }
        </Button>
    );
};


export const BotonTipo4Menu = ({ open, textoBoton, event, startIcon}) =>
{
    return(
        <Button id="BotonTipo4Menu"
        fullWidth
        type="submit"
        startIcon={startIcon}
        sx={{ 
            height: "100%",
            display: "flex",
            borderRadius: "0 0 24px 24px",
            color: "#626262",
            justifyContent: "center",
            backgroundColor: "#fff",
            fontFamily: "inherit",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
            letterSpacing: "0.5px",
            textTransform:"none",
            "& .MuiButton-startIcon":{ width:"35px", marginRight: "0px", marginLeft:"0px", color: "#626262", justifyContent: "center"},
            ":hover":{
                borderRadius: "0 0 24px 24px",
                color: "#FFF",
                backgroundColor: "#1F7098",
                boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",        
                "& .MuiButton-startIcon":{ marginRight: "0px", marginLeft:"0px", color: "#B7CBD5"},
            }
        }}
        onClick={event}> 
            {
                open ?
                <Typography sx={{width:"125px", display: "flex", justifyContent:"left"}}>
                {
                    textoBoton
                }
                </Typography>
                :
                null
            }
        </Button>
    );
};


export const BotonConLoading = ({textoBoton, loadingStatus, event}) =>
{
    return(
        <LoadingButton id="BotonLoading"
        type="submit" 
        loading={loadingStatus}
        variant="outlined"
        fullWidth 
        onClick={event}
        loadingIndicator={<CircularProgress style={{ color: "white" }} size={25} />}
        > 
            {
                loadingStatus === true ?
                null
                :
                textoBoton
            }
        </LoadingButton>
    );
};

export const BotonConLoadingEvent = ({textoBoton, loadingStatus, event, disabled}) =>
    {
        return(
            <LoadingButton id="BotonLoading"
            disabled = {disabled}
            type="submit" 
            loading={loadingStatus}
            variant="outlined"
            onClick={event}
            fullWidth 
            loadingIndicator={<CircularProgress style={{ color: "white" }} size={25} />}
            > 
                {
                    loadingStatus === true ?
                    null
                    :
                    textoBoton
                }
            </LoadingButton>
        );
    };



export const BotonConLoadingRef = ({textoBoton, loadingStatus, referencia, event}) =>
{
    return(
        <LoadingButton id="BotonLoading"
        type="submit" 
        loading={loadingStatus}
        variant="outlined"
        ref={referencia}
        fullWidth 
        onClick={event}
        loadingIndicator={<CircularProgress style={{ color: "white" }} size={25} />}
        > 
            {
                loadingStatus === true ?
                null
                :
                textoBoton
            }
        </LoadingButton>
    );
};



export const BotonTipoContactanos = ({textoBoton, event}) =>
    {
            return(
                <Button id="BotonTipoContactanos"
                fullWidth
                type="submit"
                onClick={event}> 
                    {
                        textoBoton
                    }
                </Button>
            );
    };
    