import { useEffect, useState } from "react";
import { ObtenerBotonesEmpresa } from "../../Service/API/Api";
import axios from "axios";

function useMapButtons() {
    const [isLoading, setIsLoading] = useState(Boolean(false));
    const [botones, setBotones] = useState({});

    useEffect(() => {
        axios.get(ObtenerBotonesEmpresa)
            .then(
                (response) => 
                    {
                        setBotones(response.data.botones)
                    }
                )
            .catch(
                (response) => 
                    {
                        console.log(response.response)
                    }
                )
    }, [])

    
    return {
        isLoading,
        setIsLoading,
        botones
    }
}

export default useMapButtons;