export const Dedos = [
    {
        id: 0,
        dedo: "dedo 0 - Meñique izquierdo"
    }
    ,
    {
        id: 1,
        dedo: "dedo 1 - Anular izquierdo"
    }
    ,
    {
        id: 2,
        dedo: "dedo 2 - Medio izquierdo"
    }
    ,
    {
        id: 3,
        dedo: "dedo 3 - Indice izquierdo"
    }
    ,
    {
        id: 4,
        dedo: "dedo 4 - Pulgar izquierdo"
    }
    ,
    {
        id: 5,
        dedo: "dedo 5 - Pulgar derecho"
    }
    ,
    {
        id: 6,
        dedo: "dedo 6 - Indice derecho"
    }
    ,
    {
        id: 7,
        dedo: "dedo 7 - Medio derecho"
    }
    ,
    {
        id: 8,
        dedo: "dedo 8 - Anular derecho"
    }
    ,
    {
        id: 9,
        dedo: "dedo 9 - Meñique derecho"
    }
]