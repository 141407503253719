import { useState } from 'react';
import axios from 'axios';
import { RecuperarContrasena } from '../../Service/API/Api'


function useRecuperarContrasena() {
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();

    const Recuperar = async (token, correo, pass) => {
        return new Promise(async (resolve, reject) => {
            const recuperar = {
                correo: correo,
                nuevaContrasena: pass,
                token: token
            };
            await axios.post(RecuperarContrasena, recuperar)
            .then(
                (response) => {
                    resolve(true)
                    setResponse(response.status)
                    setLoading(false)
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setResponse(response.status)
                    setLoading(false)
                }
            )
            
        });
        
        
    }

    return {
        loading,
        setLoading,
        Recuperar,
        response
    }
};

export default useRecuperarContrasena;