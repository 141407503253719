import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import "./Graficos.css";
import GraficoLineas from './GraficoLineas';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import LeyendaGraficoCircular from './LeyendaGraficoCircular';
import BoxTituloGraficoLinea from './BoxTituloGraficoLinea';
import useGetDatosGraficos from './useLogicaGraficos';
import GraficoCircular from './GraficoCircular';
import { IconoGraficos } from './Iconos';
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion';

const Graficos = () => {
    const [valorGrafico, setGrafico] = useState(0);
    const [EjeX, setEjeX] = useState([])
    const { datosCircular, datosLineas } = useGetDatosGraficos(valorGrafico, setEjeX)

    return (
        <Box id='ComponenteGraficos'>
            <CabeceraTablasGestion
                icono1={<IconoGraficos />}
                showSB={false}
                titulo={"Graficos"}
                data={null}
                agregarNuevoIcon={false}
                handlerAN={null}
                cargaMasivaIcon={false}
                descargarIcon={false}
                handlerCM={null}
                handlerDownload={null}
                rows={false}
                setRows={false}
                children={null}
            />
            <Box id='BoxInternoDosGraficos'>
                <Box id='BoxIzquierdaGraficos'>

                    <BoxTituloGraficoLinea setGrafico={setGrafico} valorGrafico={valorGrafico}/>
                    <GraficoLineas datos={datosLineas} EjeX={EjeX}></GraficoLineas>
                    
                    <Box id='idholdingLeyenda'>
                       {datosLineas.map((dato)=>
                            <Box  id='BoxLeyenda' key={dato.tipo + 'lineas'}>
                                <Typography> {dato.tipo} </Typography> <HorizontalRuleIcon sx={{color:dato.color, fontSize:'48px'}}/>
                            </Box>
                        )}
                    </Box> 
                </Box>

                <Box id='BoxDerechaGraficos'>
                    <Box id='BoxTituloGraficoDerecha'>
                        <Typography id='TituloGraficoIzquierda'>Accesos - Métodos de identificación</Typography>
                    </Box>
                    
                    <GraficoCircular datos={datosCircular} valorGrafico={valorGrafico}/>
                    
                    <Box id='boxLeyendaDerecha'>
                        {datosCircular.data.map((dato)=>
                            <LeyendaGraficoCircular dato={dato} key={dato.nombre} totales={datosCircular.totales}></LeyendaGraficoCircular>
                            )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Graficos;
