import { Box, IconButton, TextField } from "@mui/material";
import { useFormik } from "formik";
import { IconoAgregarNuevo, IconoCargaMasiva, IconoLimpiarNombreEvento, IconoUsuario } from "./CabeceraIcons";
import "./CabeceraInvitados.css";
import {InitialValues} from "./InitialValues";
import {ValidationSchema} from "./ValidationSchema";
import IMGConfirmacionIndividual from "../../../../assets/imagenes/ImgMCESingle.png";
import { useState } from "react";
import useInvitados from "../../../../Hooks/Eventos/useInvitados";
import { SnackBarTipoUno } from "../../../snackBar/SnackBar";
import TooltipTipoUno from "../../../ToolTip/Tooltip";
import useConfirmDos from "../../../../Hooks/ConfirmDialog/useConfirmDialogDos";
import { useSelector } from "react-redux";
import SyncIcon from '@mui/icons-material/Sync';

function CabeceraInvitados({setRows, selectedRows, idVisita, actualizar})
{
    
    const {GuardarInvitado, EliminarTodosInvitados, EliminarInvitadosSeleccionados} = useInvitados();
    const data = useSelector(action => action.user.authenticated)
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [ConfirmationDialogDos, confirmDos]= useConfirmDos();

    const formik = useFormik(
        {
            initialValues: InitialValues,
            validationSchema: ValidationSchema,
            onSubmit: async () => {
                await GuardarInvitado(formik.values, idVisita)
                .then(
                    (resolve) => {
                        setRows((oldRows) => [...oldRows, 
                            {
                                idVisitante: resolve.idVisitante,
                                idVisitaVisitante:resolve.idVisitaVisitante,
                                nombres:formik.values.nombres,
                                apellidos:formik.values.apellidos,
                                rut:formik.values.rut,
                                pasaporte:formik.values.pasaporte,
                                correo:formik.values.correo,
                                patente: formik.values.patente === "" ? "-" : formik.values.patente,
                                estadoAceptacion: "PENDIENTE",
                                estadoEnvioCorreo: false,
                                isNew: false 
                            }
                        ]);
                        setSnackbarSeverity("success")
                        setSnackMsg("Invitado agregado exitosamente")
                        setSnackbar(true);
                        handleOtro();
                        
                    }
                    ,
                    (reject) => {
                        setSnackbarSeverity("error")
                        setSnackMsg("No pudo ser agregado, reintente!")
                        setSnackbar(true);
                    }
                );
            }
    });

    const handleSelectedDelete = async () =>{
        let response = await confirmDos();
        if(response === true)
        {
            EliminarInvitadosSeleccionados(idVisita, selectedRows)
            .then
            (
                (resolve) => {
                    
                    const selectedIDs = new Set(selectedRows);
                    console.log(selectedIDs);
                    setRows((r) => r.filter((x) => !selectedIDs.has(x.idVisitaVisitante)));
                    setSnackbarSeverity("success")
                    setSnackMsg("Invitados eliminados exitosamente")
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al eliminar a los invitados, reintente")
                    setSnackbar(true);
                }
            )
        }
        else
        {
            console.log("usuario rechaza la eliminacion");
        }
    }

    const handleDeleteAll = async () => 
    {
        let response = await confirmDos();
        if(response === true)
        {
            EliminarTodosInvitados(idVisita)
            .then
            (
                (resolve) => {
                    setRows([]);
                    setSnackbarSeverity("success")
                    setSnackMsg("Invitados eliminados exitosamente")
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al eliminar a los invitados, reintente")
                    setSnackbar(true);
                }
            )
        }
        else
        {
            console.log("usuario rechaza la eliminacion");
        }
         
    };

    const handleOtro = () => {
        formik.resetForm();

    }

    return(
        <Box id="BoxNuevoInvitadoManual" component={'form'} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Box id="BoxIconoUsuario">
                <IconoUsuario id="IconoUsuarioInvitado" />
            </Box>
            <Box id="BoxTextFieldNuevoUsuarioNombre">
                <TextField variant="filled" 
                label="Nombres"
                value={formik.values.nombres}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
                id="Nombres" name="nombres" 
                placeholder="Nombres"fullWidth
                helperText=
                {
                    formik.errors.nombres ?
                    <span id="ErrorSpan">{formik.errors.nombres}</span>
                    :
                    null
                } 
                />
            </Box>
            <Box id="BoxTextFieldNuevoUsuarioApellido">
                <TextField variant="filled" 
                label={"Apellidos"} 
                id="Apellidos" name="apellidos" 
                placeholder="Apellidos" 
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
                value={formik.values.apellidos}
                helperText=
                {
                    formik.errors.apellidos ?
                    <span id="ErrorSpan">{formik.errors.apellidos}</span>
                    :
                    null
                }
                />
            </Box>
            <Box id="BoxTextFieldNuevoUsuarioRut">
                <TextField variant="filled" 
                label={"Rut"} 
                id="Rut" name="rut" 
                placeholder="11111111-1" 
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
                value={formik.values.rut}
                helperText=
                {
                    formik.errors.rut ?
                    <span id="ErrorSpan">{formik.errors.rut}</span>
                    :
                    null
                }
                />
            </Box>
            <Box id="BoxTextFieldNuevoUsuarioRut">
                <TextField variant="filled" 
                label={"Pasaporte"} 
                id="Pasaporte" name="pasaporte" 
                placeholder="000000000000" 
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
                value={formik.values.pasaporte}
                helperText=
                {
                    formik.errors.pasaporte ?
                    <span id="ErrorSpan">{formik.errors.pasaporte}</span>
                    :
                    null
                }
                />
            </Box>
            <Box id="BoxTextFieldNuevoUsuarioCorreo">
                <TextField variant="filled" 
                label={"Correo"} 
                id="Correo" name="correo" 
                placeholder="dominio@dominio.cl" 
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
                value={formik.values.correo}
                helperText=
                {
                    formik.errors.correo ?
                    <span id="ErrorSpan">{formik.errors.correo}</span>
                    :
                    null
                }
                />
            </Box>
            <Box id="BoxTextFieldNuevoUsuarioPatente">
                <TextField variant="filled" 
                label={"Patente"} 
                id="Patente" name="patente" 
                placeholder="DLAU00" 
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
                value={formik.values.patente}
                helperText=
                {
                    formik.errors.patente ?
                    <span id="ErrorSpan">{formik.errors.patente}</span>
                    :
                    null
                }
                />
            </Box>
            <Box id="BoxTextFieldNuevoUsuarioOpciones">
                <TooltipTipoUno titulo={"Actualizar"} ubicacion={"bottom"}>
                    <IconButton onClick={actualizar}>
                        <SyncIcon fontSize="large" sx={{color:"#175676"}}/>
                    </IconButton>
                </TooltipTipoUno>
                <TooltipTipoUno titulo={"Agregar Invitado"} ubicacion={"bottom"}>
                    <IconButton onClick={formik.submitForm} id="btnIconoAccionInvitado">
                        <IconoAgregarNuevo id="IconoMenuOpciones" />
                    </IconButton>
                </TooltipTipoUno>
                <TooltipTipoUno titulo={"Cargar masiva"} ubicacion={"bottom"}>
                    <IconButton >
                        <IconoCargaMasiva id="IconoMenuOpciones" />
                    </IconButton>
                </TooltipTipoUno>
                {
                    selectedRows.length > 0?
                    <TooltipTipoUno titulo={"Borrar Seleccionados"} ubicacion={"bottom"}>
                        <IconButton onClick={handleSelectedDelete}>
                            <IconoLimpiarNombreEvento id="IconoMenuOpciones" />                    
                        </IconButton>
                    </TooltipTipoUno>
                    :
                    <TooltipTipoUno titulo={"Borrar todos"} ubicacion={"bottom"}>
                        <IconButton onClick={handleDeleteAll}>
                            <IconoLimpiarNombreEvento id="IconoMenuOpciones" />
                        </IconButton>
                    </TooltipTipoUno>
                }
            </Box>
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />
            <ConfirmationDialogDos
            img={IMGConfirmacionIndividual}
            titulo={"Eliminar Invitados"}
            mensaje={selectedRows.length > 0? 
                "estas seguro(a) de eliminar a los invitados seleccionados" 
                : 
                "estas seguro(a) de eliminar a todos los invitados de la lista"}
            usuario={data.usuario.split(' ')[0]}
            />
        </Box>   
    );
}

export default CabeceraInvitados;