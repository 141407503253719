
import Dashboard from "../../Components/Dashboard/Dashboard";
import { MapaEmpresa } from "../../Components/Map/Map";

const Inicio = () => {

    return (
        <Dashboard>
            <MapaEmpresa />
        </Dashboard>
    );
}

export default Inicio;