import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

    export const AutocompleteForm = ({idName, labelName, placeholder, options, setIdSelectedOption}) => {
    
    //Manejador de cambios en el autocomplete
    const handleOnChange = (event, value) => {
        
        // Si no hay nada escrito en el autocomplete
        if (value === null){
            setIdSelectedOption(-1);
        }
        else{
            //Si el valor escrito en el autocomplete no coincide con ninguna opción disponible
            if (value[labelName] === null || value[labelName] === undefined){
                setIdSelectedOption(-1);
            }
            //Sino, quiere decir que ya se seleccionó una opción, por lo que se cambia el estado por la opción seleccionada
            else{
                setIdSelectedOption(value[idName]);
            }
        }
    }

  return (
    <Autocomplete
      disablePortal
      ListboxProps={{ sx: { maxHeight: "150px"}}}
      freeSolo={true}
      getOptionKey={(option) => option[idName]}
      id="combo-box-demo"
      isOptionEqualToValue={(option, value) => option[labelName] === value[labelName]}
      onChange={(e, v) => handleOnChange(e, v)}
      options={options}
      getOptionLabel={(option) => option[labelName] ?? option}
      renderInput={(params) => <TextField {...params} label={placeholder} />}
      sx={{ width: "100%" }}
    />
  );
}