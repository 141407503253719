import { SvgIcon } from "@mui/material";

export function Campana(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none">
                <path d="M5.10487 3.63992C6.42181 2.18372 8.17638 1.25002 9.99998 1.25002H10C10 1.25002 10 1.25002 10 1.25002C11.8236 1.25003 13.5782 2.18372 14.8951 3.63992C16.2128 5.09696 17.0278 7.00877 17.0278 8.84256V16.1713V16.4614L17.223 16.676L19.25 18.9043V19.0856H0.75V18.9043L2.77702 16.676L2.97222 16.4614V16.1713V8.84256C2.97222 7.00877 3.78718 5.09696 5.10487 3.63992ZM10 0.500016V0.810924V0.500016ZM10 22.75C9.46334 22.75 8.93943 22.3935 8.68999 21.8193H11.31C11.0606 22.3935 10.5367 22.75 10 22.75Z" stroke="#626262" strokeWidth="1.5"/>
            </svg>
        </SvgIcon>
        
    );
}


export function DownRow(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
            <path d="M1 1L9 9L17 1" stroke="#626262" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}