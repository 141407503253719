import { Button, FormControl, MenuItem, Select } from "@mui/material";
import "./BotonesCalendario.css"
import { PlusSVG } from "../../Calendario/CalendarioIcons";


export const BotonTipoCalendarioUno = ({textoBoton, event, showStartIcon}) =>{

        return(
            <Button id="BotonTipoCalendarioUno"
            fullWidth
            type="submit"
            startIcon={showStartIcon ? <PlusSVG id="IconoInicio" /> : null}
            
            onClick={event}> 
                {
                    textoBoton
                }
            </Button>
        );
};


export const BotonTipoCalendarioUnoSelect = ({listaOpciones, setOpcion, opcion}) =>{

    const handler = (val) => {
        setOpcion(val);
    }

    return(
        <FormControl fullWidth>
            <Select
            id="SelectOpcionesTipoVistaCalendario"
            value={opcion}
            label={null}
            sx={{
                height:"42px",
                paddingLeft: "10px",
                borderRadius: "25px",
                border: "1.5px solid #333",
                backgroundColor: "#FFF",
                color: "#1976d2",
                opacity:0.8,
                ":hover":{
                    border: "1.5px solid #1F7098",
                    opacity:0.6,
                    backgroundColor: "rgba(23, 86, 118, 0.08)"
                },
                ":active": {
                    border: "1.5px solid #103D548",
                    color: "#103D54",
                    background: "rgba(23, 86, 118, 0.16)",
                    opacity:0.8,

                }
            }}
            slotProps={{ input: {sx: { 
                fontFamily: "inherit", 
                fontSize: "15px", 
                fontStyle:"normal", 
                fontWeight: 500, 
                lineHeight:"24px", 
                letterSpacing: "0.15px", 
                textTransform: "none", },
                
            }}}
            onChange={(e) => handler(e.target.value)}
            >
                {
                listaOpciones.map((item) => {                    
                    return(
                        <MenuItem key={item.id} value={item.id} >
                            {item.tipo}
                        </MenuItem>
                        );
                    })
                }
            </Select>   
        </FormControl>
        
    );
};


export const BotonTipoCalendarioDos = ({textoBoton, event}) =>{

    return(
        <Button id="BotonTipoCalendarioDos"
        fullWidth
        type="submit"
        onClick={event}> 
            {
                textoBoton
            }
        </Button>
    );
};