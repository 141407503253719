import { Menu } from "@mui/material";

export const MenuTipoUno = ({open, anchorEl, setOpen, children}) =>{

    const handleClose = () => {
        setOpen(null);
    } 

    return(
        <Menu
        id={'basic-menu'}
        slotProps={{
            paper:{
                sx:{
                    display: "flex",
                    padding: "16px 0px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                    borderRadius: "8px",
                    background:"#fff",
                    boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)"
                }
            }
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: "top",
        horizontal: "right",
        }}
        >
            {
                children
            }
        </Menu>
    );
}


export const MenuTipoDos = ({open, anchorEl, setOpen, children}) =>{

    const handleClose = () => {
        setOpen(null);
    } 

    return(
        <Menu
        id={'basic-menu'}
        slotProps={{
            paper:{
                sx:{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "4px",
                    borderRadius: "8px",
                    background:"#fff",
                    boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)"
                }
            }
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
        }}
        >
            {
                children
            }
        </Menu>
    );
}