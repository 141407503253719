import axios from "axios";
import { BuscarPasePersona } from "../../Service/API/Api";
import { useEffect, useState } from "react";

export default function useGetPase(user){
    const [pases, setPases] = useState([]);

    useEffect(() => {
        async function ObtenerPases(){
            await axios.get(BuscarPasePersona + user)
            .then(
                (response) =>
                {
                    setPases(response.data)
                }
            )
            .catch(
                (response) => {
                    console.log(response.response.data)
                }
            );
        }
        ObtenerPases();
    }, [user])
    
    return {
        pases
    }
}