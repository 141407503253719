import { Box, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import "./Ubicacion.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { ValidationAsignarUbicacion } from "./Validation";
import useUbicacionesEvento from "../../../../../Hooks/Ubicaciones/useUbicaciones";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";

export const EditarUbicacion = ({setEditar, ubicacionesEvento, setInfo, user, editarInfo}) =>{
    const {loading, setLoading, response, EditarAsignacionUbicacion, BuscarUbicacionInfo} = useUbicacionesEvento();
    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const handleClose = () => {
        setEditar(false);
    }


    const EditarInfo = async (idSeleccionada) => {
        BuscarUbicacionInfo(formik.values.idUbicacion)
        .then(
            (resolve) => {
                var info = {
                    idPersonaUbicacion: editarInfo.idPersonaUbicacion,
                    idUbicacion: idSeleccionada,
                    nombreUbicacion: resolve.nombre,
                    piso: resolve.piso,
                    numeracion: resolve.numeracion,
                    tipoUbicacion: resolve.tipoUbicacion
                }
                setTimeout(() => {
                    setInfo(info);
                    setOpenLoading(false);
                    setEditar(false);
                }, 3000);
            }
            ,
            (reject) => {
                console.log(reject);
            }

        );
        
        
    }

    const formik = useFormik({
        initialValues:{idUbicacion:editarInfo.idUbicacion},
        enableReinitialize: true,
        validationSchema:ValidationAsignarUbicacion,
        onSubmit:
        async () => {
            setLoading(true);
            setOpenLoading(true);
            await EditarAsignacionUbicacion(editarInfo.idPersonaUbicacion, user, formik.values.idUbicacion)
            .then(
                (resolve) => {
                   
                    EditarInfo(formik.values.idUbicacion);
                }
                ,
                (reject) => {
                    console.log(reject);
                }
            )
        }
    })

    return(
        <LayoutCrearNuevo titulo={"Editar Ubicación"}>
            <Box id="BoxLayoutCambiarUbicacionGU">
                <Box id="BoxTituloContenidoCambiarUbicacionGU">
                    <Box id="BoxTituloCambiarUbicacionGU">
                        <Typography id="TxtTituloCambiarUbicacionGU">
                            {
                                "Listado de Ubicaciones"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxContenidoCambiarUbicacionGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <Box id="BoxSelectCambiarUbicacionGU">
                            <FormControl fullWidth>
                                <InputLabel id="LblCambiarUbicacionGU">
                                    {
                                        "Seleccione una Ubicación"
                                    }
                                </InputLabel>
                                <Select
                                    fullWidth 
                                    variant="outlined"
                                    name="idUbicacion"
                                    labelId="LblCambiarUbicacionGU"
                                    label="Seleccione una Ubicación"
                                    value={formik.values.idUbicacion}
                                    onChange={formik.handleChange}
                                    >
                                    {
                                        ubicacionesEvento.map((item, index) => {
                                            return (
                                                <MenuItem disableRipple key={index} value={item.idUbicacion} id="ItemMenuUbi" >
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "180px" }}>
                                                        <ListItemText id="TextoItem">
                                                            {
                                                                item.nombreUbicacion
                                                            }
                                                        </ListItemText>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                                <FormHelperText>
                                    {
                                        formik.errors.idUbicacion
                                    }
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesCambiarUbicacionGU">
                    <Box id="BoxBtnCancelarCambiarUbicacionGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAgregarCambiarUbicacionGU">
                        <BotonTipo2 textoBoton={"Cambiar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCinco
                        open={openLoading}
                        setOpen={setOpenLoading}
                        msj={"Se está asignado a la ubicacion. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        id={"LoadingAgregaListaNegraGU"}
                        tituloLoading={"Asignando..."}
                        loading={loading}
                        titulo={response === 200  ? 
                            "Usuario agregado"
                            :
                            "Usuario no agregado"
                        }
                        icono={response === 200 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={response === 200 ?
                            "Aceptar" 
                            :
                            "Reintentar"
                        }
                        mensajeRespuesta={response === 200 ? 
                            "El usuario ha sido agregado exitosamente a la ubicacion, restringiendo su acceso según las políticas de seguridad."
                            :
                            "No se ha podido agregar el usuario a la ubicacion debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                        }
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}