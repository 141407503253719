import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import "./VerMiembros.css"
import usePersonaEnUbicacion from "../../../Hooks/PersonaUbicacion/usePersonaEnUbicacion";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { useState } from "react";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { EsquemaNuevo } from "./Validation";


export const NuevoMiembro = ({setRows, ubicacion}) => {
    const {usuariosSinUbicacion, AgregarUsuarioAUbicacion, loadingReq, setLoadingReq, response} = usePersonaEnUbicacion();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [confirmMessage, setConfirmMessage] = useState({titulo: "", mensaje: ""});
    const [ConfirmationDialog, confirm ]= useConfirm();

    const AgregarRow = (nuevaId) => {
        var userFinded = usuariosSinUbicacion.find(u => u.idUsuario === formik.values.idPersonaMiembro)

        setRows((oldRows) => [
            
            ...oldRows,
            {
                idUsuario: formik.values.idPersonaMiembro,
                nombre: formik.values.nombrePersona,
                idPersonaUbicacion: nuevaId,
                tipoUsuario: userFinded.tipoUsuario,
                estado: userFinded.estado,
                isNew: true,
            },
        ]);
    }

    const handleAgregarOtro = () => {
        formik.resetForm();
    }

    const formik = useFormik(
        {
            initialValues: {
                idUbicacion: ubicacion,
                idPersonaMiembro: -1,
                nombrePersona : "Usuarios"
            },
            validationSchema: EsquemaNuevo,
            enableReinitialize: true,
            onSubmit: async() => {
                setConfirmMessage({titulo: "¿Agregar?", mensaje: "Estas a punto de agregar un nuevo usuario a la ubicacion ¿Continuar?"})
                var response = await confirm();
                if(response)
                {
                    setLoadingReq(true);
                    setOpenLoading(true);
                    await AgregarUsuarioAUbicacion(formik.values)
                    .then(
                        (resolve) => {
                            console.log("nueva id", resolve);

                            AgregarRow(resolve);
                        },
                        (reject) => {
                            console.log("Error en nueva asociacion");
                        }
                    )
                }
                else{
                    console.log("Rechaza la nueva asociacion");
                }
            }
        }
    )

    return(
        <Box id="BoxFormNuevoMiembro">
            <Box id="BoxCabeceraNuevoMiembro">
                <Typography id="TxtCabeceraNuevoMiembro">
                    {
                        "Agregar usuario"
                    }
                </Typography>
            </Box>
            <Box id="BoxSeleccionadorNuevoUsuarioUbicacion">
                <Box id="BoxAutocompleteUsuarios">
                    <FormControl fullWidth>
                        <Autocomplete
                        disablePortal
                        
                        id="idPersonaMiembro"                                    
                        options={usuariosSinUbicacion}
                        fullWidth
                        ListboxProps={{ sx: { maxHeight: "140px"}}}
                        getOptionKey={(o) => o.idUsuario}
                        isOptionEqualToValue={(o, v) => o.nombre === v}
                        freeSolo={true}
                        onChange={(e, v) => {
                            if(v === null)
                            {
                                formik.setFieldValue("idPersonaMiembro", -1)                                           
                            }
                            else{
                                formik.setFieldValue("idPersonaMiembro", v.idUsuario)
                            }
                        }}
                        
                        renderInput={(params) => <TextField 
                            InputLabelProps={{shrink: true}}
                            sx={{"& .MuiOutlinedInput-root": {padding: "6px"}}}
                            {...params}   
                            label="Usuarios"                          
                            />
                        }
                        value={formik.values.nombrePersona}
                        inputValue={formik.values.nombrePersona}
                        onInputChange={(e, v) => formik.setFieldValue("nombrePersona", v)}
                        componentname="idPersonaMiembro"
                        getOptionLabel={(option) => option.nombre ?? option}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box
                                key={key}
                                component="li"
                                {...optionProps}>
                                {option.nombre}
                                </Box>
                            );
                          }}
                        />
                        <FormHelperText>
                            {
                                formik.errors.idPersonaMiembro
                            }
                        </FormHelperText>                                   
                    </FormControl>
                </Box>
                <Box id="BoxBotonAgregar">
                    <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                    
                </Box>
            </Box>
            <LoadingMasRespuestaCuatro
            id={"LoadingMasRespuestaDosNuevoActuador"}
            open={openLoading}
            setOpen={setOpenLoading}
            msj={
                "Se está agregando un usuario a la ubicacion. Este proceso puede tomar un momento. Agradecemos tu paciencia."
            }
            tituloLoading={"Agregando usuario..."}
            loading={loadingReq}
            respuesta={response}
            titulo={
                response === 201 ? 
                "Usuario agregado"
                : 
                "Usuario no agregado"
            }
            mensajeRespuesta={
                response === 201
                ? "El Usuario ha sido agregado exitosamente a la ubicacion. Ahora puedes agregar otros miembros a este destino"
                : response === 403 ?
                    "No se ha podido generar la solicitud de creacion, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                    :
                    "No se ha podido agregar el usuario debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
            }
            txtBotonUno={"Agregar otro"}
            eventBotonUno={handleAgregarOtro}
            />
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
            titulo={confirmMessage.titulo}
            mensaje={confirmMessage.mensaje}
            />
        </Box> 
    );
}