import { Box, Divider, Typography } from "@mui/material"
import "./ManejadorOpciones.css";
import { useFormik } from "formik";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useState } from "react";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import SeleccionTiempoHorario from "../../TimePicker/TimePickerDos";
import { EsquemaNuevoHorario } from "./validationSchema";
import { InitialNuevoHorario } from "./initialValues";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import useGestionHorarios from "../../../Hooks/Horarios/useGestionHorarios";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export const CrearNuevoHorarioGestionHorario = ({setRows, setOpen}) => {
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const data = useSelector(action => action.user.authenticated)
    const {loading, response, setLoading, CreateHorario} = useGestionHorarios();
    
   
    

    const formik = useFormik({
        initialValues: InitialNuevoHorario,
        validationSchema: EsquemaNuevoHorario,
        onSubmit: () => {
            setLoading(true)
            setOpenLoading(true)
            CreateHorario(formik.values, data.idEmpresa)
            .then(
                (resolve) => {
                    setRows((oldRows) => [
                        ...oldRows, {
                            idHorario: resolve,
                            nombre: formik.values.nombre,
                            lunes: dayjs(formik.values.lunesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.lunesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.lunesInicio).format("HH:mm") + " a " + dayjs(formik.values.lunesTermino).format("HH:mm"),
                            martes: dayjs(formik.values.martesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.martesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.martesInicio).format("HH:mm") + " a " + dayjs(formik.values.martesTermino).format("HH:mm"),
                            miercoles: dayjs(formik.values.miercolesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.miercolesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.miercolesInicio).format("HH:mm") + " a " + dayjs(formik.values.miercolesTermino).format("HH:mm"),
                            jueves: dayjs(formik.values.juevesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.juevesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.juevesInicio).format("HH:mm") + " a " + dayjs(formik.values.juevesTermino).format("HH:mm"),
                            viernes: dayjs(formik.values.viernesInicio).format("HH:mm") === "00:00" && dayjs(formik.values.viernesTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.viernesInicio).format("HH:mm") + " a " + dayjs(formik.values.viernesTermino).format("HH:mm"),
                            sabado: dayjs(formik.values.sabadoInicio).format("HH:mm") === "00:00" && dayjs(formik.values.sabadoTermino).format("HH:mm") === "00:00" ?  "-":  dayjs(formik.values.sabadoInicio).format("HH:mm") + " a " + dayjs(formik.values.sabadoTermino).format("HH:mm"),
                            domingo: dayjs(formik.values.domingoInicio).format("HH:mm") === "00:00" && dayjs(formik.values.domingoTermino).format("HH:mm") === "00:00" ?  "-": dayjs(formik.values.domingoInicio).format("HH:mm") + " a " + dayjs(formik.values.domingoTermino).format("HH:mm"),
                            isNew: true,
                        },
                    ]);
                }
                ,
                (reject) => {
                    console.log(reject);
                }
            )
        }
    })

    const handleClose = () =>{
        setOpen(false);
        formik.resetForm();
    }
    
    const handleOthers = () =>{
        formik.resetForm();
    }

    return (
        <Box id="BoxAgregarNuevoHorario">
            <Box id="BoxTituloSubTituloAgregarHorario">
                <Box>
                    <Box id="BoxTituloOpcionGestionHorario">
                        <Typography id="TxtTituloOpcionGestionHorario">
                            {
                                "Agregar horario"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxSubTituloOpcionGestionHorario">
                        <Typography id="TxtSubTituloOpcionGestionHorario">
                            {
                                "Todos los campos son obligatorios para completar el registro."
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box id="BoxContenidoFormularoCreacionHorarioGestionHorario"  component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxLayoutContenidoNuevoHorario">
                        <Box id="BoxInformacionNombreHorario">
                            <Box id="BoxTextoInformacionNuevoHorario">
                                <Typography id="TxtSubTituloOpcionGestionHorarioNuevo">
                                    {
                                        "Información de horario"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTexfieldNombreHorarioNuevo">
                                <TextFieldTres
                                    label={"Nombre Horario"}
                                    id="NombresUsuarioGUEditar"
                                    placeholder={"Nombre del nuevo Horario"}
                                    name={"nombre"}                                
                                    helper={formik.errors.nombre}
                                    icon={<IconoBorrarCampoGU id="IconoEnHorarios" />}
                                    disabled={ false}
                                    handlerClick={() => formik.setFieldValue("nombre", "")}
                                    value={formik.values.nombre}
                                    handlerChange={formik.handleChange}
                                    focus={false}
                                />                                    
                            </Box>
                        </Box>
                        <Box id="BoxContenidoOpcionNuevoHorario">
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Lunes"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.lunesInicio}
                                        handleTime={(value)=> formik.setFieldValue("lunesInicio", value)}
                                        name={"lunesInicio"}
                                        helper={null}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.lunesTermino}
                                        handleTime={(value)=> formik.setFieldValue("lunesTermino", value)}
                                        name={"lunesTermino"}
                                        helper={formik.errors.lunesTermino}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Martes"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.martesInicio}
                                        handleTime={(value)=> formik.setFieldValue("martesInicio", value)}
                                        name={"martesInicio"}
                                        helper={null}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.martesTermino}
                                        handleTime={(value)=> formik.setFieldValue("martesTermino", value)}
                                        name={"martesTermino"}
                                        helper={formik.errors.martesTermino}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Miercoles"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.miercolesInicio}
                                        handleTime={(value)=> formik.setFieldValue("miercolesInicio", value)}
                                        helper={null}
                                        name={"miercolesInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.miercolesTermino}
                                        handleTime={(value)=> formik.setFieldValue("miercolesTermino", value)}
                                        name={"miercolesTermino"}
                                        helper={formik.errors.miercolesTermino}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Jueves"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}                                          
                                        timeSet={formik.values.juevesInicio}
                                        helper={null}
                                        handleTime={(value)=> formik.setFieldValue("juevesInicio", value)}
                                        name={"juevesInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.juevesTermino}
                                        handleTime={(value)=> formik.setFieldValue("juevesTermino", value)}
                                        name={"juevesTermino"}
                                        helper={formik.errors.juevesTermino}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Viernes"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}          
                                        helper={null}                                
                                        timeSet={formik.values.viernesInicio}
                                        handleTime={(value)=> formik.setFieldValue("viernesInicio", value)}
                                        name={"viernesInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        timeSet={formik.values.viernesTermino}
                                        handleTime={(value)=> formik.setFieldValue("viernesTermino", value)}
                                        name={"viernesTermino"}
                                        helper={formik.errors.viernesTermino}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Sabado"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}       
                                        helper={null}                                        
                                        timeSet={formik.values.sabadoInicio}
                                        handleTime={(value)=> formik.setFieldValue("sabadoInicio", value)}
                                        name={"sabadoInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        helper={formik.errors.sabadoTermino}
                                        timeSet={formik.values.sabadoTermino}
                                        handleTime={(value)=> formik.setFieldValue("sabadoTermino", value)}
                                        name={"sabadoTermino"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" />
                            <Box id="BoxDiaInicioFinNuevoHorario">
                                <Box id="BoxTextoDiaHorarioNuevo">
                                    <Typography id="TextoDiaHorarioNuevo">
                                        {
                                            "Domingo"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxInicioFinNuevoHorarioPicker">
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario  
                                        label={"Inicio"}       
                                        helper={null}                                     
                                        timeSet={formik.values.domingoInicio}
                                        handleTime={(value)=> formik.setFieldValue("domingoInicio", value)}
                                        name={"domingoInicio"}
                                        />
                                    </Box>
                                    <Box id="BoxHoraNuevoHorarioPicker">
                                        <SeleccionTiempoHorario
                                        label={"Fin"}
                                        helper={formik.errors.domingoTermino}
                                        timeSet={formik.values.domingoTermino}
                                        handleTime={(value)=> formik.setFieldValue("domingoTermino", value)}
                                        name={"domingoTermino"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxAccionesFormiularioAgregarHorario">
                        <Box id="BoxTamanoBotonHandlerAgregarHorario">
                            <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                        </Box>
                        <Box id="BoxTamanoBotonHandlerAgregarHorario">
                            <BotonTipo2 textoBoton={"Agregar"} disabled={loading} />
                            <LoadingMasRespuestaCuatro
                                id={"LoadingMasRespuestaDosNuevoHorario"}
                                open={openLoading}
                                setOpen={setOpenLoading}
                                msj={
                                "Se está agregando un nuevo horario al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                                }
                                tituloLoading={"Agregando horario..."}
                                loading={loading}
                                respuesta={response}
                                titulo={
                                    response === 201 ? 
                                    "Horario agregado"
                                    : 
                                    "Horario no agregado"
                                }
                                mensajeRespuesta={
                                    response === 201
                                    ? "El Horario ha sido agregado exitosamente al sistema. Ahora puedes asignar este horario a los usuarios"
                                    : response === 403 ?
                                        "No se ha podido generar la solicitud de creacion, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                                        :
                                        "No se ha podido agregar el Horario debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
                                }
                                txtBotonUno={"Agregar horario"}
                                eventBotonUno={handleOthers}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}