import { Box } from "@mui/material";
import CabeceraTablasGestion from "../CabeceraTablasGestion/CabecerasTablasGestion";
import {GestionZonasIcon } from "./Iconos";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { IconoAlerta, IconoEditarUsuario } from "../Usuarios/UsuariosIcon";
import useConfirm from "../../Hooks/ConfirmDialog/useConfirmDialog";
import useZonas from "../../Hooks/Zona/useZonas";
import { useEffect, useState } from "react";
import { SwitchTablas } from "../Switch/switch";
import "./Zonas.css"
import TooltipTipoUno from "../ToolTip/Tooltip";
import { SnackBarTipoUno } from "../snackBar/SnackBar";
import { HandleOpcionesZona } from "./OpcionesZona/HandlerOpcionesZona";
import { BotonTipoCalendarioUnoSelect } from "../Botones/BotonesCalendario/BotonesCalendario";

const OpcionesZonas = [
    {
        id: 0, tipo: "Habilitadas/Deshabilitadas"
    }
    ,
    {
        id: 1, tipo: "Solo habilitadas"
    }
    ,
    {
        id: 2, tipo: "Solo deshabilitadas"
    }
]

const Zonas = () => {
    const [ConfirmationDialog, confirm ]= useConfirm();
    const {zonas, loading, HandleEstadoZona, HandleAPBZona} = useZonas();
    const [rows, setRows] = useState([])
    const [openOpciones, setOpenOpciones] = useState(false)
    const [opcionZona, setOpcionZona] = useState(0);
    const [opcion, setOpcion] = useState(null)
    const [dataEdit, setDataEdit] = useState({})
    const [dialogText, setDialogText] = useState({titulo: '', mensaje: ''});
    const [snackMsg, setSnackMsg] = useState({ severity: '', mensaje: ''});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        setRows(zonas)
    }, [zonas])

    const handleEstado = (id) => async () => {
        const findrow = rows.find((row) => row.idZona === id);
        if(findrow.estado === true){
            setDialogText(
                {
                    titulo: "¿Desactivar zona?",
                    mensaje: "Estás a punto de desactivar esta zona, por lo cual no podra ser utilizado para un acceso ¿Deseas continuar?"
                }
            );
        }
        else
        {
            setDialogText(
                {
                    titulo: "¿Activar zona?",
                    mensaje: "Estás a punto de activar esta zona ¿Continuar?"
                }
            );
        }

        const res = await confirm(); 
        if(res === true) {
            HandleEstadoZona(id, findrow.estado)
            .then(
                (result) => {
                    findrow.estado = !findrow.estado
                    setRows(rows.map((row) => (row.idZona === findrow.idZona ? findrow : row)));
                  
                    setSnackMsg(
                        {
                            severity: "success",
                            mensaje: findrow.estado === true ? 
                            "La zona ha sido activada exitosamente!"
                            :
                            "La zona ha sido desactivada exitosamente!"
                        }
                    )
                    setSnackbar(true);

                }
            )
            .catch(
                (error) => {
                    setSnackMsg(
                        {
                            severity: "error",
                            mensaje: "Ocurrio un error al actualizar, reintente nuevamente"
                        }
                    )
                    setSnackbar(true);
                }
            )
        }        
        else
        {
            console.log("Rechaza la modificacion")
        }
        
    }

    const HandleNuevaZona = () => {
        setOpcion(1)
        setOpenOpciones(true);
    }
    
    const HandleEditZona = (row) => () => {
        setOpcion(2)
        setDataEdit(row)
        setOpenOpciones(true);
    }

    const handleAPB = (id) => async () => {
        const findrow = rows.find((row) => row.idZona === id);
        if(findrow.antiPassBack === true){
            setDialogText(
                {
                    titulo: "¿Desactivar APB?",
                    mensaje: "Estás a punto de desactivar el Anti-passback en la zona ¿Continuar?"
                }
            );
        }
        else
        {
            setDialogText(
                {
                    titulo: "¿Activar APB?",
                    mensaje: "Estás a punto de activar el Anti-passback en esta zona ¿Continuar?"
                }
            );
        }

        const res = await confirm(); 
        if(res === true) {
            HandleAPBZona(id, findrow.antiPassBack)
            .then(
                (result) => {
                    findrow.antiPassBack = !findrow.antiPassBack
                    setRows(rows.map((row) => (row.idZona === findrow.idZona ? findrow : row)));
                  
                    setSnackMsg(
                        {
                            severity: "success",
                            mensaje: findrow.antiPassBack === true ? 
                            "APB activado en la zona exitosamente!"
                            :
                            "APB desactivado exitosamente!"
                        }
                    )
                    setSnackbar(true);
                }
            )
            .catch(
                (error) => {
                    setSnackMsg(
                        {
                            severity: "error",
                            mensaje: "Ocurrio un error al actualizar, reintente nuevamente"
                        }
                    )
                    setSnackbar(true);
                }
            )
        }        
        else
        {
            console.log("Rechaza la modificacion")
        }
        
    }
    
    const handleEstadoZonaSearch = (e) => {
        
        setOpcionZona(e);
        if(e === 0) {
            setRows(zonas);
        }
        else
        {
            let opcion = e === 1 ? true : false
            const listaFiltrada = zonas.filter((row) => row.estado === opcion);
            setRows(listaFiltrada);
        }
        
    }
    
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = zonas.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const colName = [
        {
            field: 'idZona', headerName: 'ID', type: 'string', width: 110, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'nombre', headerName: 'Nombre Zona', type: 'string', flex: 1, minWidth: 500, headerAlign: 'left', align:'left', sortable: false, editable: false
        }
        ,
        {
            field: 'antiPassBack', headerName: 'APB', type: 'string', width: 200, headerAlign: 'center', align:'center', sortable: false, editable: false,
            renderCell: ({id, row}) => 
            (
                <SwitchTablas 
                value={row.antiPassBack}
                disabled={false}
                handler={handleAPB(id)} 
                />
            )
        }
        ,
        {
            field: 'estado', headerName: 'Estado', type: 'bool', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false,
            renderCell: ({id, row}) => 
            (
                <SwitchTablas 
                value={row.estado}
                disabled={false}
                handler={handleEstado(id)} 
                />
            )
        },
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 95, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({row}) => {        
                return [
                    <TooltipTipoUno titulo={"Editar"} ubicacion={"right-start"}>
                        <GridActionsCellItem
                        icon={<IconoEditarUsuario  id='iconoEnTablaZonas'/>}
                        label="Editar"
                        onClick={HandleEditZona(row)}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
    ];

   

    return (
        <Box id='BoxZonas'>
            <CabeceraTablasGestion
                icono1={<GestionZonasIcon id ="GestionZonasIcon" />}
                titulo={"Gestión de zonas"}
                agregarNuevoIcon={true}
                handlerAN={HandleNuevaZona} //agregar row
                cargaMasivaIcon={false}
                handlerCM={null}//manejador de boton carga masiva
                descargarIcon={false}
                handlerDownload={null}
                value={searchText}
                onChange={handleSearch}
                clearSearch={clearSearch}
            >
                <Box id='BoxSelectContainerCabeceras'>
                    <Box id="BoxFiltroEtadoSelectCabeceraZona">
                        <BotonTipoCalendarioUnoSelect id="FiltroSelectOpcionZona"
                        listaOpciones={OpcionesZonas}
                        opcion={opcionZona}
                        setOpcion={handleEstadoZonaSearch}
                        />
                    </Box>
                </Box>

            </CabeceraTablasGestion>

            <DataGrid id='TablaGestionTarjetas'
                style={{ width:'100%', height:"100%"}}
                sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                    color:'#175676',
                },
                    '& .MuiDataGrid-columnHeader': {
                    borderBottom: '2px solid #94B1C0',
                },
                '& .MuiDataGrid-row':{
                    borderBottom:'1px solid #94B1C0'
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                    width: "7.024px",
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                    background: "#C9C9C9",
                    borderRadius: "100px"
                }
                }}
                getRowId={(row) => row.idZona}
                columns={colName} 
                rows={rows}
                initialState={{
                        pagination: {
                            paginationModel: {
                            page: 0,
                            pageSize: 10,
                            },
                        },
                    }}
                checkboxSelection={false}
                disableColumnFilter={true}
                disableColumnMenu={true}
                disableColumnSelector={true}
                disableDensitySelector={true}
                disableVirtualization={true}
                showCellVerticalBorder={false}
                showColumnVerticalBorder={false}
                hideFooterSelectedRowCount={true}
                rowSelection
                loading={loading}
                disableRowSelectionOnClick 
                pageSizeOptions={[10, 20]}
            />

           

            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={dialogText.titulo}
            mensaje={dialogText.mensaje}
            />

            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackMsg.severity}
            msg={snackMsg.mensaje}
            />

            <HandleOpcionesZona
            open={openOpciones}
            setOpen={setOpenOpciones}
            rows={rows}
            setRows={setRows}
            opcion={opcion}
            dataEdit={dataEdit}
            />
        </Box>
    );
}


export default Zonas;