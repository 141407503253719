import axios from "axios";
import { useEffect, useState } from "react";
import { BuscarPersonasUbicacion } from "../../Service/API/Api";

export default function usePersonaUbicacion(idUbicacion){
    const [loading, setLoading] = useState();
    const [personasUbicacion, setPersonasUbicacion] = useState([]);

    useEffect(() => {
        setLoading(true)
        axios.get(BuscarPersonasUbicacion + idUbicacion)    
        .then(
            (response) =>{
                setPersonasUbicacion(response.data);
                setLoading(false);
            }
        )
        .catch (
            (error) =>{
                console.log("fallo la busqueda");
                setLoading(false);
            }
        );
    }, [idUbicacion])
    

    return {
        loading,
        setLoading,
        personasUbicacion
    };
}