
import { IconoGestionEstacionamientos } from './IconosGestionEstacionamientos'
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import TooltipTipoUno from '../ToolTip/Tooltip';
import { IconoAlerta, IconoEditarUsuario } from '../Usuarios/UsuariosIcon';
import { SwitchTablas } from '../Switch/switch'
import useGetEstacionamientos from '../../Hooks/Estacionamientos/useGetEstacionamientos'
import { HandleOpcionesEstacionamiento } from './OpcionesEstacionamientos/ManejadorOpciones';
import { PapeleroIconA } from '../GestionUbicaciones/IconosGestionUbicaciones';
import useConfirm from '../../Hooks/ConfirmDialog/useConfirmDialog';
import { SnackBarTipoUno } from '../snackBar/SnackBar';

export default function GestionEstacionamientos() {
    const {data, loading, HandleEstadoEstacionamiento, EliminarAsociacion} = useGetEstacionamientos();
    const [rows, setRows] = useState([]);
    const [openModal, setOpenModal] = useState(Boolean(false));
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);
    const [dataEditar, setDataEditar] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [confirmMessage, setConfirmMessage] = useState({titulo: "", mensaje: ""});
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    
    useEffect(() => {
        setRows(data);
    }, [data])
    
    const handleOpenNuevoEstacionamiento = () => {
        setOpenModal(true)
        setOpcionSeleccionada(1)
    }

    const OpenEditarEstacionamiento = (value) => () => {
        setOpenModal(true)
        setOpcionSeleccionada(2)
        setDataEditar(value)
    }

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = data.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const handleEstado = (id) => async() => {
        var rowEdit = rows.find(e => e.idEstacionamiento === id);
        if(rowEdit.estado === true){
            setConfirmMessage({ titulo: "¿Desactivar?", mensaje: "Estás a punto de desactivar el estacionamiento ¿Deseas continuar?"}) 
        }
        else{
            setConfirmMessage({ titulo: "¿Activar?", mensaje: "Estás a punto de activar el estacionamiento ¿Deseas continuar?"}) 
        }

        let res = await confirm();
        if(res){
            await HandleEstadoEstacionamiento(rowEdit.idEstacionamiento, rowEdit.estado)
            .then(
                (resolve) => {
                    rowEdit.estado = !rowEdit.estado
                    setRows(rows.map((row) => (row.idEstacionamiento === rowEdit.idEstacionamiento ? rowEdit : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(rowEdit.estado === true ? 
                        "Estacionamiento Activado exitosamente!"
                        :
                        "Estacionamiento desactivado exitosamente!"
                    )
                    setSnackbar(true);
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true); 
                }
            )
        }
        else{
            console.log("cancela la modificacion de estado");
        }
    }

    const handleEliminar = (id) => async () => {
        let rowEdit = rows.find(r => r.idEstacionamiento === id);

        if(rowEdit.idPersonaEstacionamiento === null){
            setSnackbarSeverity("error")
            setSnackMsg("Estacionamiento no se encuentra asignado a un usuario")
            setSnackbar(true);
        }
        else{
            setConfirmMessage({ titulo: "¿Eliminar?", mensaje: "Estás a punto de eliminar la asignacion del estacionamiento ¿Deseas continuar?"}) 
            let res = await confirm();
            if(res)
            {
                
                await EliminarAsociacion(rowEdit.idPersonaEstacionamiento)
                    .then(
                        (resolve) => {
                            rowEdit.idPersonaEstacionamiento = null;
                            rowEdit.idPersona = null;
                            rowEdit.idVisita = null;
                            rowEdit.nombreCompleto = null;
                            rowEdit.rut = null;
                            rowEdit.pasaporte = null;
                            setRows(rows.map((row) => (row.idEstacionamiento === rowEdit.idEstacionamiento ? rowEdit : row)))
                            setSnackbarSeverity("success")
                            setSnackMsg("Asignacion eliminada correctamente")
                            setSnackbar(true); 
                        }
                        ,
                        (reject) => {
                            setSnackbarSeverity("error")
                            setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                            setSnackbar(true); 
                        }
                    )
            }
            else
            {
                console.log("cancelo la eliminacion de la asociacion");
            }
        }
        
        
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const columns = [
        { field: 'nombreZona', headerName: 'Ubicacion', width: 150, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'numero', headerName: 'Nombre', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'nombreCompleto', headerName: 'Asignado A', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'rut', headerName: 'RUT', type: 'string',  flex: 0.5, minWidth: 150, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'pasaporte', headerName: 'Pasaporte', type: 'string',  flex: 0.5, minWidth: 150, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'estado', headerName: 'Estado', type:'actions', width: 90, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions', 
            getActions:({id, row})=>{
                return [
                    <SwitchTablas
                    id={"SwitchEstadoEstacionamientoTabla"}
                    value={row.estado}
                    handler={handleEstado(id)}
                    disabled={false} />
                    ]
            }
        }        
        ,
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 80, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <TooltipTipoUno titulo={"Editar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<IconoEditarUsuario  id='iconoEnTablaUsuarios'/>}
                        label="Editar"
                        onClick={OpenEditarEstacionamiento(row)}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
        ,
        {
            field: 'eliminar', headerName: 'Eliminar', type: 'actions', width: 80, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id}) => {
                return [
                    <TooltipTipoUno titulo={"Eliminar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        label="Eliminar"
                        onClick={handleEliminar(id)}
                        icon={<Box
                            id="iconoEditarBox"
                            sx={{
                              display: 'flex',
                              padding: '6px',
                              alignItems: 'flex-start',
                              gap: '10px',
                              borderRadius: '25px',
                              background: '#FFF',
                              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                              '& .icono-papelero .fondo-icono-papelero':{
                                fill: 'white',
                              },
                              '& .icono-papelero .trazo-icono-papelero':{
                                stroke: '#626262',
                                strokeWidth: 1.5,
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                              },
                              '&:hover':  {
                                backgroundColor: '#FAE9E8',
                                '.icono-papelero .fondo-icono-papelero': {
                                  fill: '#FAE9E8',
                                },
                                '.icono-papelero .trazo-icono-papelero': {
                                  stroke: 'red',
                                },
                              },
                              '&:active':  {
                                backgroundColor: 'red',
                                '.icono-papelero .fondo-icono-papelero': {
                                  fill: 'red',
                                },
                                '.icono-papelero .trazo-icono-papelero': {
                                  stroke: 'white',
                                },
                              }
                            }}
                          >
                            <PapeleroIconA id='iconoEditar'/> 
                        </Box>}
                        />
                    </TooltipTipoUno>
                ];
            }
        },
    ];
    return (
    <Box id="BoxContenedorTablaActuadores">
        <CabeceraTablasGestion 
            icono1={<IconoGestionEstacionamientos />}
            titulo={"Gestion Estacionamiento"}
            rows={rows}
            setRows={setRows}
            agregarNuevoIcon={true}
            handlerAN={handleOpenNuevoEstacionamiento}
            cargaMasivaIcon={null}
            handlerCM={null}
            descargarIcon={null}
            handlerDownload={null}
            onChange={handleSearch}
            value={searchText}
            clearSearch={clearSearch}
            >
        </CabeceraTablasGestion>
        <DataGrid id="TablaHorarios"
            style={{ width:'100%', height:"100%"}}
            sx={{ 
                '& .MuiDataGrid-columnHeaderTitle': {
                color:'#175676',
                },
                    '& .MuiDataGrid-columnHeader': {
                    borderBottom: '2px solid #94B1C0',
                },
                '& .MuiDataGrid-row':{
                    borderBottom:'1px solid #94B1C0'
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                    width: "7.024px",
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                    background: "#C9C9C9",
                    borderRadius: "100px"
                }
            }}
            getRowId={(row) => row.idEstacionamiento}
            columns={columns} 
            rows={rows}
            initialState={{
                pagination: {
                    paginationModel: {
                        page: 0,
                        pageSize: 10,
                        },
                    },
                }}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection
            loading={loading}
            disableRowSelectionOnClick 
            pageSizeOptions={[10, 20]}
            />

            <HandleOpcionesEstacionamiento
            open={openModal}
            setOpen={setOpenModal}
            setRows={setRows}
            rows={rows}
            opcion={opcionSeleccionada}
            dataEdit={dataEditar}
            />
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />

            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={confirmMessage.titulo}
            mensaje={confirmMessage.mensaje}
            />
    </Box>
  )
}
