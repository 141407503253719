import { Box, Card, CardMedia, TextField, Typography } from "@mui/material";
import "./ConfirmarUsuario.css";
import CP2 from "../../assets/imagenes/CP2.png"
import { BotonConLoadingRef} from "../../Components/Botones/BotonesTabla/Botones";
import { useConfirmacion } from "../../Hooks/Usuarios/useConfirmacion";
import { useNavigate, useSearchParams } from "react-router-dom";
import {LoadingMasRespuesta} from "../../Components/Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useEffect, useRef, useState } from "react";
import { IconoExito, IconoFallo } from "../../Components/Modal/RespuestaServidor/RespuestaServidorIcon";
import { SnackBarTipoUno } from "../../Components/snackBar/SnackBar";


export const ConfirmarRegistro = () => {
    const { setLoading, loading, respuesta, ConfirmarCorreo} = useConfirmacion();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [correo, setCorreo] = useState(null);
    const [token, setToken] = useState(null);
    const buttonRef = useRef();
    const [snackMsg, setSnackMsg] = useState({severity: "", message: ""});
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    const handleConfirmar = () =>{
        if(correo !== null && token !== null){
            setLoading(true);
            setOpenLoading(true);
            ConfirmarCorreo(token, correo);
        }
        else{
            setSnackMsg({
                severity: "error",
                message: "Error de validacion, confirma tus datos"
            })
            setSnackbar(true);
        }
    }

    useEffect(() => {
        if(searchParams.size > 0){
            setCorreo(searchParams.get('correo'));
            setToken(searchParams.get('token'));

            searchParams.delete('token');
            searchParams.delete('correo');
            
            setSearchParams(searchParams);
            // setLoading(true);
            // setOpenLoading(true);
            setTimeout(() => {
                ActivarBoton();
            }, 1500);
        }

    },[correo, token, searchParams, setSearchParams, setLoading, respuesta]);

    if(respuesta === 200){
        setTimeout(() => {
            navigate("/login", { replace: true });
        }, 3000);
    }
    const ActivarBoton = () => {
       buttonRef.current.click()
    }

    return (
        <Box>
            <Box id="BoxConfirmarNuevoUsuario">
                <Card id="CardCambioConfirmarNuevoUsuario">
                    <Box id="CardContenidoConfirmarNuevoUsuario">
                            <Box id="BoxImagenConfirmarNuevoUsuario">
                                <CardMedia id="CP2Confirmar" image={CP2}></CardMedia>
                            </Box>
                            <Box id="BoxTituloConfirmarNuevoUsuario">
                                <Typography id="TextoTituloConfirmarNuevoUsuario">
                                    {
                                        "Confirmacion de registro"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxCorreoNuevoUsuario">
                                <TextField 
                                value={correo ? correo : ""}
                                id={"txtCorreoConfirmarNuevoUsuario"}
                                variant="filled"
                                label="Correo"
                                disabled={true}
                                fullWidth={true}
                                />
                                <Box id="BoxBotonConfirmarNuevoUsuario">
                                    <BotonConLoadingRef referencia={buttonRef} loadingStatus={loading} textoBoton={"Confirmar"} event={handleConfirmar} />
                                </Box>
                            </Box>
                    </Box>
                </Card>  
            </Box>
            <LoadingMasRespuesta
            open={openLoading}
            setOpen={setOpenLoading} 
            msj={"Validando al nuevo usuario..."}
            id={"ModalRespuestaConfirmacionNuevoEvento"}
            icono={
                respuesta === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                respuesta === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            loading={loading}
            respuesta={respuesta}
            titulo={respuesta === 200 ? "Validado exitosamente":"Error al validar"}
            mensajeRespuesta={respuesta === 200 ? "Su correo electrónico ha sido validado exitosamente! redirigiendo...":"Ocurrio un inconveniente al validar. Vuelve a intentarlo o contacta al administrador del sistema"}
            />
            <SnackBarTipoUno
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackMsg.severity}
            msg={snackMsg.message}
            />
        </Box>
    );
}