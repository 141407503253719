import { Autocomplete, Box, FormControl, FormHelperText, IconButton, Modal, TextField, Typography } from "@mui/material";
import "./AgregarAListaNegra.css";
import { useFormik } from "formik";
import { CerrarModalNuevoUsuario } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import useTodosVisitantes from "../../../Hooks/Visitante/useTodosVisitantes";
import useListaNegra from "../../../Hooks/ListaNegra/useListaNegra";
import { useState } from "react";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useSelector } from "react-redux";
import useNoEnListaNegra from "../../../Hooks/ListaNegra/useNoEnListaNegra";
import { ValidationIngresoListaNegra } from "../ValidationListaNegra";

const style = {
    width: "1024px",
    height: "680px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};

export const AgregarAListaNegra = ({open, setOpen, setRows}) => {
    const data = useSelector((action) => action.user.authenticated)
    const {listaUsuarios} = useNoEnListaNegra();
    const {listaVisitantes} = useTodosVisitantes();
    const {AgregarLN, loading, setLoading, response, listaNegra} = useListaNegra(); 
    const [openLoading, setOpenLoading] = useState(false);

    const handlerClose = () => {
        setOpen(false);
        formik.resetForm();
    }

    const AgregarRow = (nuevaId) => {
        var userFinded = listaUsuarios.find(u => u.idPersona === formik.values.idPersona)
        var invitadoFinded = listaVisitantes.find(u => u.idVisitante === formik.values.idVisitante);

        var nuevaRow = {
            idListaNegra: nuevaId,
            fechaIngreso: new Date(Date.now()),
            idPersona: data.idPersona,
            nombreCreador: data.usuario,
            rutCreador: null,
            pasaporteCreador: null,
            nombre: formik.values.idPersona === -1 ? invitadoFinded.nombreRut.split("/")[0] : userFinded.nombreRut.split("/")[0],
            rutPersona: formik.values.idPersona === -1 ? invitadoFinded.rut : userFinded.rut,
            pasaporte: formik.values.idPersona === -1 ? invitadoFinded.pasaporte : userFinded.pasaporte,
            motivoIngreso: formik.values.motivo
        }

        setRows((oldRows) => [            
            ...oldRows,
            nuevaRow,
        ]);

        listaNegra.push(nuevaRow);
        
        //eliminar de las lista al usuario o invitado.
    }


    const formik = useFormik({
        initialValues: {
            idPersona: -1,
            nombrePersona: "Usuarios",
            idVisitante: -1,
            nombreVisitante: "Visitantes",
            motivo: ""
        },
        enableReinitialize: true,
        validationSchema:ValidationIngresoListaNegra,
        onSubmit: async () => {
            var dataToLN = {
                rut: '',
                pasaporte: ''
            }
            setLoading(true)
            setOpenLoading(true)
            if(formik.values.idPersona !== -1) {
                var userFinded = listaUsuarios.find(u => u.idPersona === formik.values.idPersona)
                dataToLN.rut = userFinded.rut;
                dataToLN.pasaporte = userFinded.pasaporte;
            }
            else{
                var invitadoFinded = listaVisitantes.find(u => u.idVisitante === formik.values.idVisitante);
                dataToLN.rut = invitadoFinded.rut;
                dataToLN.pasaporte = invitadoFinded.pasaporte;
            }

            await AgregarLN(dataToLN.rut, dataToLN.pasaporte, data.idPersona, formik.values.motivo, data.idEmpresa)
                .then(
                    (resolve) => {
                        AgregarRow(resolve);
                    }
                    ,
                    (reject) => {
                        console.log("Fallo al agregar a lista negra");
                    }
                )
        }
    })

    const handlerOtro = () => {
        formik.resetForm();
        setOpenLoading(false);
    }

    return (
        <Modal id="modalAgregarAListaNegra" open={open} onClose={handlerClose} sx={{border:"unset"}}>
            <Box sx={{...style}}>
                <Box id="BoxCabeceraAgregarAListaNegra">
                    <IconButton onClick={handlerClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>
                <Box id="BoxContenidoOpcionAgregarAListaNegra">
                    <Box id="BoxAgregarAListaNegra">
                        <Box id="BoxTituloSubtituloAgregarAListaNegra">
                            <Box id="BoxTituloAgregarAListaNegra">
                                <Typography id="TxtTituloAgregarAListaNegra">
                                    {
                                        "Agregar a Lista Negra"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxSubTituloAgregarAListaNegra">
                                <Typography id="TxtSubTituloAgregarAListaNegra">
                                    {
                                        "Todos los campos son obligatorios para completar el registro."
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Box id="BoxFormularioAgregarAListaNegra" >
                            <Box id="BoxContenidoFormularioAgregarAListaNegra">
                                <Box id="BoxContenidoFormularioUsuariosAListaNegra">
                                    <Box id="BoxTituloUsuariosIngresoListaNegra">
                                        <Typography id="TxtOpcionTituloFormulario">
                                            {
                                                "Información del Usuario"
                                            }
                                        </Typography>
                                    </Box>
                                    <Box id="BoxUsuariosIngresoAListaNegra">
                                        <FormControl fullWidth>
                                            <Autocomplete                                   
                                            disablePortal
                                            options={listaUsuarios}
                                            fullWidth
                                            ListboxProps={{ sx: { maxHeight:"150px"}}} 
                                            id="idPersona"
                                            componentname="idPersona"
                                            freeSolo={true}
                                            getOptionKey={(o) => o.idPersona}
                                            isOptionEqualToValue={(o, v) => o.nombreRut === v}
                                            onChange={(e, v) => {
                                                if(v === null)
                                                {
                                                    formik.setFieldValue("idPersona", -1)                                  
                                                }
                                                else{
                                                    formik.setFieldValue("idPersona", v.idPersona)
                                                    formik.setFieldValue("idVisitante", -1)
                                                    formik.setFieldValue("nombreVisitante", "Visitantes")    
                                                }
                                            }}
                                            getOptionLabel={(option) =>  option.nombreRut ?? option}
                                            inputValue={formik.values.nombrePersona}
                                            value={formik.values.nombrePersona}
                                            onInputChange={(e,v) => formik.setFieldValue("nombrePersona", v)}   
                                            renderInput={(params) => 
                                            <TextField
                                            InputLabelProps={{shrink: true}}
                                            {...params}
                                            label="Usuarios" />
                                            }
                                            renderOption={(props, option) => {
                                                const { key, ...optionProps } = props;
                                                return (
                                                    <Box
                                                    key={key}
                                                    component="li"
                                                    {...optionProps}>
                                                    {option.nombreRut}
                                                    </Box>
                                                );
                                            }}
                                            />
                                            <FormHelperText>
                                                {
                                                    formik.errors.idPersona
                                                }
                                            </FormHelperText>                         
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box id="BoxContenidoFormularioInvitadosAListaNegra">
                                    <Box id="BoxTituloInvitadosIngresoListaNegra">
                                        <Typography id="TxtOpcionTituloFormulario">
                                            {
                                                "Información del Invitado"
                                            }
                                        </Typography>
                                    </Box>
                                    <Box id="BoxInvitadosIngresoAListaNegra">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                            disablePortal
                                            options={listaVisitantes}
                                            ListboxProps={{ sx: { maxHeight:"150px"}}} 
                                            fullWidth
                                            id="idVisitante"
                                            componentname="idVisitante"
                                            freeSolo={true}
                                            getOptionKey={(o) => o.idVisitante}
                                            getOptionLabel={(option) =>  option.nombreRut ?? option}
                                            isOptionEqualToValue={(o, v) => o.nombreRut === v}
                                            onChange={(e, v) => {
                                                if(v === null)
                                                {
                                                    formik.setFieldValue("idVisitante", -1)                                           
                                                }
                                                else{
                                                    formik.setFieldValue("idVisitante", v.idVisitante)
                                                    formik.setFieldValue("idPersona", -1)
                                                    formik.setFieldValue("nombrePersona", "Usuarios")  
                                                }
                                            }}
                                            value={formik.values.nombreVisitante}
                                            inputValue={formik.values.nombreVisitante}
                                            onInputChange={(e,v) => formik.setFieldValue("nombreVisitante", v)}   
                                            renderInput={(params) => 
                                                <TextField  {...params} 
                                                label="Visitantes" 
                                                />
                                            }
                                            />
                                            <FormHelperText>
                                                {
                                                    formik.errors.idVisitante
                                                }
                                            </FormHelperText>                                       
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box id="BoxContenidoFormularioMotivoIngreso">
                                    <Box id="BoxTituloMotivoIngresoListaNegra">
                                        <Typography id="TxtOpcionTituloFormulario">
                                            {
                                                "Motivo de Ingreso"
                                            }
                                        </Typography>
                                    </Box>
                                    <Box id="BoxMotivoIngresoAListaNegra">
                                        <TextField 
                                        variant="filled" 
                                        fullWidth 
                                        id={"motivo"}
                                        placeholder="Motivo..."
                                        label="Motivo del Ingreso" 
                                        name={"motivo"}
                                        multiline 
                                        rows={3}
                                        value={formik.values.motivo}
                                        onChange={formik.handleChange}
                                        helperText={formik.errors.motivo}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box id="BoxHandlerFormularioAgregarAListaNegra">
                                <Box id="BoxBotonesAccionAgregarAListaNegra">
                                    <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                                </Box>
                                <Box id="BoxBotonesAccionAgregarAListaNegra">
                                    <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                                    <LoadingMasRespuestaCuatro
                                        id={"LoadingMasRespuestaDosNuevoActuador"}
                                        open={openLoading}
                                        setOpen={setOpenLoading}
                                        msj={
                                            "Se está agregando a la lista negra del sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                                        }
                                        tituloLoading={"Agregando..."}
                                        loading={loading}
                                        respuesta={response}
                                        titulo={
                                            response === 201 ? 
                                            "Agregado exitosamente"
                                            : 
                                            "No agregado"
                                        }
                                        mensajeRespuesta={
                                            response === 201
                                            ? "Ha sido agregado exitosamente a la Lista Negra del sistema. Esto bloqueara sus accesos y no podra ingresar utilizando los metodos habilitados por la empresa"
                                            : response === 403 ?
                                                "No se ha podido generar la solicitud, ya que no cuenta con el perfil requerido, contacte al administrador del sistema"
                                                :
                                                "No se ha podido agregar al sistema, debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"
                                        }
                                        txtBotonUno={"Agregar otro"}
                                        eventBotonUno={handlerOtro}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}