import { Box, Button, CardMedia, IconButton, Typography } from "@mui/material";
import useEventos from "../../Hooks/Eventos/useEventos";
import "./HistorialEventos.css";
import { AgregarEventoIcon, CalendarioIconHistorialEventos, CerrarHistorialEventos } from "./HistorialEventosIcons";
import NoEventos from "../../assets/imagenes/NoEventos.png";
import UnEvento from "../../assets/imagenes/UnEvento.png";
import { ListaEventos } from "./EventosMap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PopOverNuevoEvento } from "../Popover/PopoverTipoUno";
import NuevoEvento from "./NuevoEvento/NuevoEvento";


export const Historial = ({setOpen}) => {
    const {dataEventos, ObtenerHE} = useEventos();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            ObtenerHE();
        }, 5000);

        return () => clearTimeout(timer);
    }, [ObtenerHE, dataEventos]);

    //corregir aqui
    const handleOpenNE = (e) => {
        setAnchorEl(e.currentTarget);
    };


    const verTodos = () =>{
        navigate('/Agenda', { replace: true });
    }

    return(
        <Box>
            <Box id="CabeceraHE">
                        <Button onClick={handleClose} >
                            <Box id="BoxIconoCalendarioTexto">
                                <CerrarHistorialEventos id="IconoCierreHE" />
                                <Box id="BoxCalendarioTextoHE">
                                    <CalendarioIconHistorialEventos id="IconoCalendarioHE" />
                                    <Typography id="TextoBotonHEAbierto">
                                        {
                                        "Eventos agendados"
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Button>
                        <IconButton id="IconoBotonNuevoEvento" onClick={(e) => {handleOpenNE(e)}}>
                            <AgregarEventoIcon id="AgregarNuevoEvento" />
                        </IconButton>
                    </Box>
                    {
                        dataEventos.length === 0 ?
                        <Box id="BoxDataHistorialEventos">
                            <Box id="BoxImagenSinEventos">
                                <Box id="BoxImagenNoEventos">
                                    <CardMedia component={"img"} id="imageNoEventos" src={NoEventos} alt="sinEventos"/>
                                </Box>
                                <Box id="BoxTextoNoEventos">
                                    <Typography id="TextoNoEventosRegistrados">
                                    {
                                        "No se han encontrado eventos cercanos..."
                                    }
                                    </Typography> 
                                </Box>
                            </Box>
                        </Box>
                        :
                        dataEventos.length === 1 ?
                            <Box id="BoxHitorialUnEvento"  >
                                <Box id="BoxImagenUnEvento">
                                    <CardMedia component={"img"} id="ImagenUnEvento" src={UnEvento} alt="sinEventos"/>
                                </Box>
                                <ListaEventos dataEventos={dataEventos}  /> 
                            </Box>
                            :
                            <Box id="BoxEventos">
                                <ListaEventos dataEventos={dataEventos}  />
                                <Box id="BoxButtonVerTodos">
                                    <Button id="BtnVerTodos" onClick={verTodos}>
                                        <Typography id="txtBtnVerTodos">
                                            Ver todos
                                        </Typography>
                                    </Button>
                                </Box>                                 
                            </Box>
                    }
            <PopOverNuevoEvento open={open} anchorEl={anchorEl} setOpen={setAnchorEl} children={<NuevoEvento setOpen={setAnchorEl} />}/>

        </Box>
    );
}