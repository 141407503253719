import { useState } from "react";
import useGetEstacionamientos from "../../../Hooks/Estacionamientos/useGetEstacionamientos";
import useGestZonasEmpresa from "../../../Hooks/Zona/useGetZonasEmpresa";
import { useFormik } from "formik";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import useGetAll from "../../../Hooks/Usuarios/useGetAll";
import useTodosVisitantes from "../../../Hooks/Visitante/useTodosVisitantes";
import { EsquemaNuevaPatente } from "./ValidationSchema";
import useConfirm from "../../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { IconoAlerta } from "../../Usuarios/UsuariosIcon";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";



export const EditarEstacionamiento = ({setOpen, setRows, rows, dataEditar}) => {
    const {zonasFormadas} = useGestZonasEmpresa();
    const {setLoading, loading, response, EditEstacionamiento, EliminarAsociacion, NuevaAsociacion, EditarAsociacion } = useGetEstacionamientos();
    const {listaUsuarios} = useGetAll();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [confirmMessage, setConfirmMessage] = useState({titulo: "", mensaje: ""});
    const {listaVisitantes} = useTodosVisitantes();
    
    
    const handlerClose = () => {
        formik.resetForm();
        setOpen(false);
    }

    const handleEditar = async (rowId) => {
        await EditEstacionamiento(formik.values, dataEditar.idEstacionamiento)
        .then(
            (resolve) => {
                let rowEdit = rows.find(r => r.idEstacionamiento === rowId);
                rowEdit.idZona = formik.values.idZonaEstacionamiento;
                rowEdit.nombreZona = formik.values.nombreZona;
                rowEdit.numero = formik.values.nombreEstacionamiento;
        
                setRows(rows.map((row) => (row.idEstacionamiento === rowEdit.idEstacionamiento ? rowEdit : row)))
                handleTimeOut();
            }
            ,
            (reject) => {
                console.log("fallo actualizacion...");
            }
        )

       
    }

    const handleTimeOut = () => {
        setTimeout(() => {
            setOpen(false);            
        }, 5000);
    }
    const handleEliminarAsociacion = () => {
        let rowEdit = rows.find(r => r.idEstacionamiento === dataEditar.idEstacionamiento);
        rowEdit.idPersonaEstacionamiento = null;
        rowEdit.idPersona = null;
        rowEdit.idVisita = null;
        rowEdit.nombreCompleto = null;
        rowEdit.rut = null;
        rowEdit.pasaporte = null;
        setRows(rows.map((row) => (row.idEstacionamiento === rowEdit.idEstacionamiento ? rowEdit : row)))
        handleTimeOut();

    }
    
    const handleAsignacion = (nuevaIdAsignacion) => {
        let rowEdit = rows.find(r => r.idEstacionamiento === dataEditar.idEstacionamiento);
        var persona = formik.values.idPersona === -1 ? null:listaUsuarios.find(u => u.idPersona === formik.values.idPersona)
        var invitado = formik.values.idVisitante === -1 ? null:listaVisitantes.find(u => u.idVisitante === formik.values.idVisitante)
        rowEdit.idPersonaEstacionamiento = nuevaIdAsignacion;
        rowEdit.idPersona = formik.values.idPersona === -1 ? null : formik.values.idPersona;
        rowEdit.idVisita = formik.values.idVisitante === -1 ? null : formik.values.idPersona;
        rowEdit.nombreCompleto = formik.values.idPersona === -1 ? formik.values.idVisitante === -1 ? null: formik.values.visitanteNombre.split("/")[0] : formik.values.personaNombre.split("/")[0]
        rowEdit.rut = persona === null ? invitado === null ? null : invitado.rut : persona.rut;
        rowEdit.pasaporte = persona === null ? invitado === null ? null : invitado.pasaporte : persona.pasaporte;

        setRows(rows.map((row) => (row.idEstacionamiento === rowEdit.idEstacionamiento ? rowEdit : row)))
        handleTimeOut();


    };

    const handleEditarAsignacion = () => {
        let rowEdit = rows.find(r => r.idEstacionamiento === dataEditar.idEstacionamiento);
        var persona = formik.values.idPersona === -1 ? null:listaUsuarios.find(u => u.idPersona === formik.values.idPersona)
        var invitado = formik.values.idVisitante === -1 ? null:listaVisitantes.find(u => u.idVisitante === formik.values.idVisitante)
        rowEdit.idPersonaEstacionamiento = dataEditar.idPersonaEstacionamiento;
        rowEdit.idPersona = formik.values.idPersona === -1 ? null : formik.values.idPersona;
        rowEdit.idVisita = formik.values.idVisitante === -1 ? null : formik.values.idPersona;
        rowEdit.nombreCompleto = formik.values.idPersona === -1 ? formik.values.idVisitante === -1 ? null: formik.values.visitanteNombre.split("/")[0] : formik.values.personaNombre.split("/")[0]
        rowEdit.rut = persona === null ? invitado === null ? null : invitado.rut : persona.rut;
        rowEdit.pasaporte = persona === null ? invitado === null ? null : invitado.pasaporte : persona.pasaporte;

        setRows(rows.map((row) => (row.idEstacionamiento === rowEdit.idEstacionamiento ? rowEdit : row)))
        handleTimeOut();

    }
    
    const formik = useFormik({
        initialValues: {
            nombreEstacionamiento: dataEditar.numero,
            idZonaEstacionamiento:  dataEditar.idZona,
            nombreZona: dataEditar.nombreZona,
            idPersona: dataEditar.idPersona ?? -1,
            personaNombre: dataEditar.idPersona === null ? "Usuarios" : dataEditar.nombreCompleto + "/" + dataEditar.rut ?? dataEditar.pasaporte,
            idVisitante: dataEditar.idVisita ?? -1,
            visitanteNombre: dataEditar.idVisita === null ? "Visitantes" : dataEditar.nombreCompleto + "/" + dataEditar.rut ?? dataEditar.pasaporte,
        },
        enableReinitialize: true,
        validationSchema: EsquemaNuevaPatente,
        onSubmit: async ()=> {
            if(formik.values.idPersona === -1 && formik.values.idVisitante === -1)
            {
                if(dataEditar.idPersonaEstacionamiento === null)
                {
                    setConfirmMessage({ titulo: "¿Actualizar datos?", mensaje: "Actualizar la informacion del estacionamiento ¿Continuar?"})
                    let responseEdit = await confirm();
                    if(responseEdit){
                        setOpenLoading(true);
                        setLoading(true);
                        handleEditar(dataEditar.idEstacionamiento);
                    }
                    else
                    {
                        console.log("cancelo la eliminacion de la asociacion");
                    }
                }
                else
                {
                    //crear funcion con los dos pasoss
                    setConfirmMessage({ titulo: "¿Actualizar datos?", mensaje: "Estás a punto de eliminar la asignacion del estacionamiento ¿Deseas continuar?"}) 
                    let resEliminacion = await confirm();
                    if(resEliminacion)
                    {
                        setOpenLoading(true);
                        setLoading(true);
                        await EliminarAsociacion(dataEditar.idPersonaEstacionamiento)
                        .then(
                            (resolve) => {
                                handleEliminarAsociacion();
                                handleEditar(dataEditar.idEstacionamiento);
                            }
                            ,
                            (reject) => {
                                console.log("fallo eliminacion de asociacion");
                            }
                        )
                    }
                    else
                    {
                        console.log("cancelo la eliminacion de la asociacion");
                    }
                               
                }
            }
            else
            {
                if(dataEditar.idPersonaEstacionamiento === null)
                {               
                    setConfirmMessage({ titulo: "¿Actualizar datos?", mensaje: "Esta apunto de asociar un usuario al estacionamiento ¿Continuar?"})
                    var resNuevo = await confirm();

                    if(resNuevo)
                    {
                        setOpenLoading(true);
                        setLoading(true);
                        await NuevaAsociacion(formik.values, dataEditar.idEstacionamiento)
                        .then(
                            (resolve) => {
                                handleAsignacion(resolve);
                                handleEditar(dataEditar.idEstacionamiento);
                            }
                            ,
                            (reject) => {
                                console.log("fallo la asociacion");
                            }
                        )
                    }
                    else
                    {
                        console.log("rechaza asociacion");
                    }
                }
                else
                {           
                    setConfirmMessage({ titulo: "¿Actualizar datos?", mensaje: "Esta apunto de editar la asociacion del estacionamiento ¿Continuar?"})
                    var res = await confirm();      
                    if(res){
                        setOpenLoading(true);
                        setLoading(true);
                        await EditarAsociacion(dataEditar.idPersonaEstacionamiento, dataEditar.idEstacionamiento, formik.values)
                        .then(
                            (resolve) => {
                                handleEditarAsignacion()
                                handleEditar(dataEditar.idEstacionamiento);
                            }
                            ,
                            (reject) => {
                                console.log("fallo la edicion de la asociacion");
                            }
                        )
                    }  
                    else
                    {
                        console.log("rechaza edicion de asociacion");
                    } 
                   
                }
            }
            
        }
    })



    return (
        <Box id="BoxContenedorNuevoEstacionamiento">
            <Box id="BoxTituloSubTituloNuevoEstacionamiento">
                <Box id="BoxTituloOpcionGestionEstacionamientos">
                    <Typography id="TxtTituloGestionEstacionamiento">
                        {
                            "Editar estacionamiento"
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloOpcionGestionEstacionamientos">
                    <Typography id="TxtSubTituloGestionEstacionamiento">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxInformacionNuevoEstacionamiento">
                <Box id="BoxContenidoInformacionEstacionamiento" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxContenidoZonaNombreEstacionamiento">
                        <Box id="BoxTituloNuevoEstacionamientoFormato">
                            <Typography id="TxtOpcionTituloFormulario">
                                {
                                    "Información del estacionamiento"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxFormularioNuevoEstacionamiento">
                            <Box id="BoxContenedorOpcionesFormularioNuevoEstacionamiento">
                                <TextFieldTres
                                id="TFNuevoEstacionamientoNombre"
                                label={"Nombre Estacionamiento"}
                                placeholder={"Nombre del estacionamiento"}
                                icon={<IconoBorrarCampoGU id="IconoEnEstacionamiento" />}
                                helper={formik.errors.nombreEstacionamiento}
                                value={formik.values.nombreEstacionamiento}
                                name={"nombreEstacionamiento"}
                                handlerChange={formik.handleChange}
                                handlerClick={() => formik.setFieldValue("nombreEstacionamiento", "")}
                                disabled={false}
                                />
                            </Box>
                            <Box id="BoxContenedorOpcionesFormularioNuevoEstacionamiento">
                                <FormControl fullWidth>
                                    <Autocomplete
                                    disablePortal
                                    id="idZonaEstacionamiento"                                    
                                    options={zonasFormadas}
                                    fullWidth
                                    freeSolo={true}
                                    getOptionKey={(o) => o.id}
                                    isOptionEqualToValue={(o, v) => o.tipo === v}
                                    onChange={(e, v) => {
                                        if(v === null)
                                        {
                                            formik.setFieldValue("idZonaEstacionamiento", 0)                                           
                                        }
                                        else{
                                            formik.setFieldValue("idZonaEstacionamiento", v.id)
                                        }
                                    }}
                                    
                                    renderInput={(params) => <TextField 
                                        InputLabelProps={{shrink: true}}
                                        {...params}                                        
                                        label="Zonas"                          
                                        />
                                    }
                                    value={formik.values.nombreZona}
                                    inputValue={formik.values.nombreZona}
                                    onInputChange={(e, v) => formik.setFieldValue("nombreZona", v)}
                                    componentname="idZonaEstacionamiento"
                                    getOptionLabel={(option) => option.tipo ?? option}
                                    renderOption={(props, option) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <Box
                                            key={key}
                                            component="li"
                                            {...optionProps}>
                                            {option.tipo}
                                            </Box>
                                        );
                                      }}
                                    />
                                    <FormHelperText>
                                        {
                                            formik.errors.idZonaEstacionamiento
                                        }
                                    </FormHelperText>                                   
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxContenidoAsignacionEstacionamiento">
                        <Box id="BoxTituloNuevoEstacionamientoFormato">
                            <Typography id="TxtOpcionTituloFormulario">
                                {
                                    "Información del usuario"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxFormularioNuevoEstacionamiento">
                            <Box id="BoxContenedorOpcionesFormularioNuevoEstacionamiento">
                                <FormControl fullWidth>
                                    <Autocomplete                                   
                                    disablePortal
                                    options={listaUsuarios}
                                    fullWidth    
                                    id="idPersona"
                                    componentname="idPersona"
                                    freeSolo={true}
                                    getOptionKey={(o) => o.idPersona}
                                    isOptionEqualToValue={(o, v) => o.nombreRut === v}
                                    onChange={(e, v) => {
                                        if(v === null)
                                        {
                                            formik.setFieldValue("idPersona", -1)                                           
                                        }
                                        else{
                                            formik.setFieldValue("idPersona", v.idPersona)
                                        }
                                    }}
                                    getOptionLabel={(option) =>  option.nombreRut ?? option}
                                    inputValue={formik.values.personaNombre}
                                    value={formik.values.personaNombre}
                                    onInputChange={(e,v) => formik.setFieldValue("personaNombre", v)}   
                                    renderInput={(params) => 
                                    <TextField
                                    InputLabelProps={{shrink: true}}
                                    {...params}
                                    label="Usuarios" />
                                    }
                                    renderOption={(props, option) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <Box
                                            key={key}
                                            component="li"
                                            {...optionProps}>
                                            {option.nombreRut}
                                            </Box>
                                        );
                                      }}
                                    />
                                    <FormHelperText>
                                        {
                                            formik.errors.idPersona
                                        }
                                    </FormHelperText>                         
                                </FormControl>
                            </Box>
                            <Box id="BoxContenedorOpcionesFormularioNuevoEstacionamiento">
                                <FormControl fullWidth>
                                    <Autocomplete
                                    disablePortal
                                    options={listaVisitantes}
                                    fullWidth
                                    id="idVisitante"
                                    componentname="idVisitante"
                                    freeSolo={true}
                                    getOptionKey={(o) => o.idVisitante}
                                    getOptionLabel={(option) =>  option.nombreRut ?? option}
                                    isOptionEqualToValue={(o, v) => o.nombreRut === v}
                                    onChange={(e, v) => {
                                        if(v === null)
                                        {
                                            formik.setFieldValue("idVisitante", -1)                                           
                                        }
                                        else{
                                            formik.setFieldValue("idVisitante", v.idVisitante)
                                        }
                                    }}
                                    value={formik.values.visitanteNombre}
                                    inputValue={formik.values.visitanteNombre}
                                    onInputChange={(e,v) => formik.setFieldValue("visitanteNombre", v)}   
                                    renderInput={(params) => 
                                        <TextField  {...params} 
                                        label="Visitantes" 
                                        />
                                    }
                                    />
                                    <FormHelperText>
                                        {
                                            formik.errors.idVisitante
                                        }
                                    </FormHelperText>                                       
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BotoncesAccionModalNuevoUsuario">
                    <Box id="BoxBotones">
                        <Box id="BoxBotonNuevoUsuario">
                            <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                        </Box>
                        <Box id="BoxBotonNuevoUsuario">
                        <BotonTipo2
                            textoBoton={"Actualizar"}
                            event={formik.submitForm}
                            disabled={loading}
                        />
                        </Box>
                    </Box>
                </Box>

                <LoadingMasRespuestaCinco
                open={openLoading} 
                setOpen={setOpenLoading} 
                msj={"La información del estacionamiento está siendo actualizada. Este proceso puede tardara solo un momento. Agradecemos tu paciencia."} 
                id={"RespuestaGuardarEditarEstacionamiento"} 
                tituloLoading={"Actualizando datos..."}
                loading={loading} 
                respuesta={response} 
                icono={
                    response === 200 ?
                    <IconoExito id="IconoRespuestaServidor" />
                    :
                    <IconoFallo id="IconoRespuestaServidor" />
                }
                textoBoton={
                    response === 200 ? 
                    "Aceptar"
                    :
                    "Reintentar"
                }
                titulo={response === 200 ? "Actualización exitosa":"Error al actualizar"}
                mensajeRespuesta={response === 200 ? 
                    "La información se ha actualizado correctamente"
                    :
                    "Ha ocurrido un error y no se ha podido actualizar la información"}
                />
                <ConfirmationDialog
                icono={<IconoAlerta id="iconoEnDialogConfirmacionEditarUsuario" />}
                titulo={confirmMessage.titulo}
                mensaje={confirmMessage.mensaje}
                />
            </Box>
        </Box>
    );
}