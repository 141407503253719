import { Box, TextField, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import "./RegistroPatentes.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { ValidationNuevaPatente } from "./Validation";
import { useState } from "react";
import usePatentes from "../../../../../Hooks/Patentes/usePatentes";
import { LoadingMasRespuestaCuatro } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";


export const NuevaPatente = ({setOpen, userData, setRows}) => {
    const {loading, setLoading, CrearNuevo, response} = usePatentes();
    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const handleClose = () =>{
        setOpen(false);
    }

    const handleOtro = () => {
        formik.setFieldValue("patente", "");
        handleCloseLoading();
    }

    const handleCloseLoading = () => {
        setOpenLoading(false);
    }

    const AgregarRow = async (patente, nuevaId) => {
        setRows((oldRows) => [...oldRows, 
            {
                idPatente: nuevaId, 
                patente: patente.toUpperCase(),
                estadoPatente: true,
                isNew: true 
            }
        ]);

        setTimeout(() => {
            setOpen(false);
            handleCloseLoading();
        }, 300);
    }
   
    const formik = useFormik({
        initialValues:{ patente: ""},
        validationSchema:ValidationNuevaPatente,
        onSubmit:
        async () => {
            setOpenLoading(true);
            setLoading(true);
            await CrearNuevo(formik.values.patente, userData.idPersona)
            .then(
                (result) => {
                    AgregarRow(formik.values.patente.toUpperCase(), result);
                }
                ,
                (error) => {
                    console.log(error);
                }
            )
        },
    });
    
    return (
        <LayoutCrearNuevo titulo={"Agregar Patente"}>
            <Box id="BoxLayoutAgregarPatenteGU">
                <Box id="BoxTituloTxtAgregarPatenteGU">
                    <Box id="BoxTituloAgregarPatenteGU">
                        <Typography id="TxtTituloAgregarPatenteGU">
                            {
                                "Información de Patente"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxTxtAgregarPatenteGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <TextField 
                        variant="filled" 
                        label="Numero de Patente" 
                        fullWidth
                        name="patente"
                        value={formik.values.patente}
                        onChange={formik.handleChange}
                        helperText={formik.errors.patente}
                        />
                    </Box>
                </Box>

                <Box id="BoxAccionesAgregarPatenteGU">
                    <Box id="BoxBtnCancelarAgregarPatenteGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAgregarAgregarPatenteGU">
                        <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCuatro
                        id={"LoadingMasRespuestaTresNuevaPatente"}
                        open={openLoading}
                        setOpen={setOpenLoading}
                        msj={"Se está agregando la nueva patente al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        tituloLoading={"Agregando patente..."}
                        loading={loading}
                        respuesta={response}
                        titulo={response === 201 ? "Patente agregada":"Patente no agregada"}
                        mensajeRespuesta={response === 201 ? 
                            "El nuevo método de identificación ha sido agregado exitosamente. Ahora puedes gestionar los accesos o agregar otro método."
                            :
                            "No se ha podido agregar la nueva patente debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
                        txtBotonUno={"Agregar nuevo método"}
                        eventBotonUno={handleOtro}
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}


