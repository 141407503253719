import dayjs from "dayjs";

const d = new Date(2024, 1, 1, 0, 0, 0)

export const InitialNuevoHorario = {
    nombre: '',
    lunesInicio: dayjs(d),
    lunesTermino: dayjs(d),
    martesInicio: dayjs(d),
    martesTermino: dayjs(d),
    miercolesInicio: dayjs(d),
    miercolesTermino: dayjs(d),
    juevesInicio: dayjs(d),
    juevesTermino: dayjs(d),
    viernesInicio: dayjs(d),
    viernesTermino: dayjs(d),
    sabadoInicio: dayjs(d),
    sabadoTermino: dayjs(d),
    domingoInicio: dayjs(d),
    domingoTermino: dayjs(d),
}