import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import "./CabecerasTablasGestion.css";
import { Search } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { IconoAgregarNuevaRow, IconoCargaMasiva, IconoDescargar } from "./IconosCabecera";
import TooltipTipoUno from "../ToolTip/Tooltip";



const CabeceraTablasGestion = ({children, 
    icono1, titulo, agregarNuevoIcon, handlerAN, cargaMasivaIcon, handlerCM, descargarIcon, handlerDownload, 
    showSB=true, clearSearch, value, onChange}) => {

    return (
        <Box id="BoxCabeceraTablasGestion">
            <Box id="BoxIconoTituloBuscarFiltros">
                <Box id="BoxIconoTitulo">
                    <Box id="BoxIconoCabecera">
                        {
                            icono1
                        }
                    </Box>
                    <Box id="BoxTituloCabecera">
                        <Typography id="txtTitulo">
                            {
                                titulo
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box id="BoxSearchBarCabeceraTablaGU">
                    {
                        showSB ? 
                        <Box id="BoxTextFieldSearchbar">
                            <TextField 
                            sx={{
                                height: "40px",
                                border: "1.5px solid #626262",
                                borderRadius: "28px",
                                background: "rgba(76, 76, 76, 0.08)",
                                display: "flex",
                                flexDirection: "row",
                            }}
                            variant="standard"
                            fullWidth
                            value={value}
                            onChange={onChange}
                            InputProps={{
                                sx: {height: "40px"},
                                disableUnderline:true,
                                startAdornment: 
                                <InputAdornment position="start" >
                                    <Search sx={{ marginLeft: "15px", marginRight:"2px"}} />
                                </InputAdornment>,
                                endAdornment: 
                                <InputAdornment position="end" >
                                    <IconButton onClick={clearSearch}>
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                            
                            id="TextFieldSearchbar"
                            placeholder="Buscar...."
                            />
                        </Box>
                        :
                        null
                    }
                    <Box>
                        {
                            children
                        }
                    </Box>
                </Box>
            </Box>
            <Box id="BoxBotonesGestiones">
                {
                    agregarNuevoIcon ?
                        <Box id="BoxContenedorIconoOpcion">
                            <TooltipTipoUno titulo={"Agregar nuevo"} >
                                <IconButton onClick={handlerAN} id="btnIconoAccionCabecera">
                                    <IconoAgregarNuevaRow id="iconoCabecera"  />
                                </IconButton>
                            </TooltipTipoUno>
                        </Box> 
                        :
                        null
                }
                {
                    cargaMasivaIcon ?
                        <Box id="BoxContenedorIconoOpcion">
                            <TooltipTipoUno titulo={"Importar"} >
                                <IconButton onClick={handlerCM} id="btnIconoAccionCabecera">
                                    <IconoCargaMasiva id="iconoCabecera" />
                                </IconButton>
                            </TooltipTipoUno>
                            <input type="file" hidden accept="xlsx"/>
                        </Box>
                        :
                        null
                }
                {
                    descargarIcon ?
                        <Box id="BoxContenedorIconoOpcion">
                            <TooltipTipoUno titulo={"Exportar"} >
                                <IconButton onClick={handlerDownload} id="btnIconoAccionCabecera">
                                    <IconoDescargar id="iconoCabecera"/>
                                </IconButton>
                            </TooltipTipoUno>
                        </Box>
                        :
                        null
                }
            </Box>
        </Box>
    );
}
export default CabeceraTablasGestion;