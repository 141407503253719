import { IconButton, TextField } from "@mui/material";



//textfield con el diseno de perfil, se cambia de estilo standard a fill segun si la edicion esta activa o no
export const TextFieldDos = ({id, blur, label, placeholder, icon,icon2, disabled, helper, value, name, handlerChange, handlerClick, type}) => {

    
    return(
        <TextField id={id}
        label={label}
        placeholder={placeholder}
        autoComplete="chrome-off"
        name={name}
        value={value}
        onBlur={blur}
        fullWidth
        autoFocus={false}
        disabled={disabled}
        onChange={handlerChange}
        helperText={helper}
        type= {type} 
        sx={{ 
        "& .Mui-disabled":{":before":{borderBottomStyle:"none"}}, 
        "& .MuiInputBase-root":{":before":{borderBottomStyle:"none"}} }}
        inputProps={{
            autoComplete: 'new-password',
            style:{
                color:"#333",
                paddingLeft: "16px" ,
                WebkitTextFillColor: "#333",
                backgroundColor: "#F8F8F8",
                paddingTop: "30px"
            },
        }}
        FormHelperTextProps={{
            style:{
                width:"100%",
                color:"#CC261A",
                fontFamily: "inherit",
                fontSize:"12px",
                fontStyle:"normal",
                fontWeight:"400",
                lineHeight:"16px",
                height:"10px",
                margin: "0px",
                background: "none",
            }
        }}
        InputProps={{
            disableUnderline: true,
            sx: { backgroundColor: "#F8F8F8",
                borderBottom: "1px solid #8A8A8A", ":hover": { backgroundColor: "#F8F8F8"}
            },
            endAdornment: disabled === true ? null: type === "text" ? <IconButton  edge={"end"} onClick={handlerClick}>{icon}</IconButton> : <IconButton  edge={"end"} onClick={handlerClick}>{icon2}</IconButton>
         }}
        InputLabelProps={{
            style:{
                paddingLeft: "5px",
                marginTop: "10px",
                color: "#626262",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                
            },
        }}
        variant= "filled" />
    );
}


export const TextFieldDosPuntoUno = ({id, label, placeholder, icon, disabled, helper, value, name, handlerChange, handlerClick, type}) => {

    return (
    <TextField id={id}
        label={label}
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        value={value}
        fullWidth
        disabled={disabled}
        onChange={handlerChange}
        helperText={helper}
        type={type}
        sx={{
            "borderRadius": "8px",
            "& .Mui-disabled":{":before":{borderBottomStyle:"none"}, height: '100%'}, 
            "& .MuiInputBase-root":{":before":{borderBottomStyle:"none"}, height: '100%', paddingRight: disabled ? "0px":"16px"},
            "& .MuiFilledInput-root": {backgroundColor: "#F8F8F8"}, height: '100%'
        }}
        inputProps={{
            style:{
                color: "#175676",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                paddingLeft: "16px" ,
                WebkitTextFillColor: "#175676",               
                paddingTop: "20px",
            },
        }}
        FormHelperTextProps={{
            style:{
                width:"100%",
                height:"10px",
                margin: "0px"
            }
        }}
        InputProps={{
            disableUnderline: true,
            sx:{display:"flex", 
                justifyContent:"center", 
                alignItems:"center", 
                //backgroundColor:"#f8f8f8f8", 
                borderBottom: "1px solid #333",
            },
            endAdornment:
            <IconButton sx={{ padding: "0px"}} edge={"end"}  onClick={handlerClick} >
                {icon}
            </IconButton> 
         }}
        InputLabelProps={{
            shrink:true,
            style:{
                width: "100%",
                color: "#175676",
                fontFamily: "inherit",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                
            },
        }}
        variant= {"filled"} />
    );
}

export const TextFieldDosPuntoDos = ({id, label, placeholder, icon, icon2, disabled, helper, value, name, handlerChange, handlerClick, type}) => {

    return (
    <TextField id={id}
        label={label}
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        value={value}
        fullWidth
        disabled={disabled}
        onChange={handlerChange}
        helperText={helper}
        type={type}
        sx={{
            "borderRadius": "8px",
            "& .Mui-disabled":{":before":{borderBottomStyle:"none"}, height: '100%'}, 
            "& .MuiInputBase-root":{":before":{borderBottomStyle:"none"}, height: '100%', paddingRight: disabled ? "0px":"16px"},
            "& .MuiFilledInput-root": {backgroundColor: "#F8F8F8"}, height: '100%' }}
        inputProps={{
            style:{
                color: "#175676",
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                paddingLeft: "16px" ,
                WebkitTextFillColor: "#175676",               
                paddingTop: disabled === true ? "0px" : "20px",
            },
        }}
        FormHelperTextProps={{
            style:{
                width:"100%",
                height:"10px",
                margin: "0px"
            }
        }}
        InputProps={{
            disableUnderline: true,
            sx:{display:"flex", 
                justifyContent:"center", 
                alignItems:"center", 
                //backgroundColor:"#f8f8f8f8", 
                borderBottom: "1px solid #333"},
            endAdornment: type === "text" ? 
            <IconButton  edge={"end"} onClick={handlerClick}>
                {icon}
            </IconButton> 
            : 
            <IconButton  edge={"end"} onClick={handlerClick}>
                {icon2}
            </IconButton>
         }}
        InputLabelProps={{
            shrink:true,
            style:{
                width: "100%",
                color: "#175676",
                fontFamily: "inherit",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
                
            },
        }}
        variant= {"filled"} />
    );
}