import { ListItem, ListItemButton, Typography } from "@mui/material";

export const ListItemMenuGU = ({optionSelected, itemId, titulo, setOptionMenu, toOption}) => {
    const handleOption = () =>{
        setOptionMenu(toOption)
    }

    return(
        <ListItem sx={{height:"54px", paddingBottom:"4px"}} disablePadding >
            <ListItemButton itemID={itemId}  
            onClick={handleOption} 
            selected={optionSelected === toOption}
            sx={{
                fontFamily: "inherit",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.1px",
                display:"flex",
                width: "248px",
                height: "48px",
                padding: "10px 16px 10px 64px",
                alignItems: "center",
                gap: "8px",
                borderRadius: "4px",
                background: "#fff",
                ":hover":{
                    borderRadius: "4px",
                    boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                    color: "#175676",
                    background: "rgba(23, 86, 118, 0.16)",
                    fontWeight: 500,
                    "& #TxtSubMenu":{
                        color: "#175676",
                    },
                },
                "&.MuiListItemButton-root.Mui-selected":{
                    borderRadius: "4px",
                    background: "rgba(23, 86, 118, 0.16)",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",                      
                    
                    "& #TxtSubMenu":{
                        color: "#175676",
                    }
                },
                
            }}
            >
                <Typography id="TxtSubMenu" sx={{
                    fontFamily: "inherit",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                }}>
                    {
                        titulo
                    }
                </Typography>
            </ListItemButton>
        </ListItem>
    );
}