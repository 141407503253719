import { Alert, Snackbar } from "@mui/material";

export const SnackBarTipoUno = ({open, handler, duration, severity, msg}) => {

    return(
        <Snackbar open={open} onClose={handler} autoHideDuration={duration}>
            <Alert  onClose={handler} severity={severity}>
                {
                    msg
                }
            </Alert>
        </Snackbar>
    );
}