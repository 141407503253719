import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion';
import { FiltroSelect, FiltroSelectTres } from '../CabeceraTablasGestion/FiltrosCabecera/FiltrosCabecera';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import TooltipTipoUno from '../ToolTip/Tooltip';
import { IconoGestionUbicaciones, UsuariosIconA } from './IconosGestionUbicaciones';
import useUbicacionesGestion from '../../Hooks/Ubicaciones/useUbicacionesGestion';
import { IconoEditarUsuario } from '../Usuarios/UsuariosIcon';
import useGestZonasEmpresa from '../../Hooks/Zona/useGetZonasEmpresa';
import useTiposUbicaciones from '../../Hooks/TiposUbicaciones/useTiposUbicaciones';
import { ModalOpcionesGestionUbicacion } from './OpcionesUbicaciones/ManejadorOpcionesUbicacion';
import "./GestionUbicaciones.css";

const GestionUbicaciones = () => {
    const { ubicaciones, loading} = useUbicacionesGestion();
    const {tiposUbicaciones} = useTiposUbicaciones();
    const {zonasFormadas} = useGestZonasEmpresa();
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openModalGestionUbicacionr, setOpenModalGestionUbicacion] = useState(Boolean(false));
    const [opcionSeleccionada, setOpcionSeleccionada] = useState();
    const [dataEditar, setDataEditar] = useState();
    const [tipoUbicacion, setTipoUbicacion] = useState(-1);
    const [opcionZona, setOpcionZona] = useState(0);
    const [idBusqueda, setIdBusqueda] = useState({});


    useEffect(() => {
        setRows(ubicaciones);
    }, [ubicaciones])

    const handleZona = (e) => {
        setOpcionZona(e.target.value);
        if(e.target.value === 0) {
            setRows(ubicaciones);
        }
        else
        {
            const listaFiltrada = ubicaciones.filter((row) => row.idZona === e.target.value);
            setRows(listaFiltrada);
        }
        
    }

    const handleNueva = ()=> {
        setOpenModalGestionUbicacion(true);
        setOpcionSeleccionada(1);
    }

    const openEditar = (row) => () => {
        setOpenModalGestionUbicacion(true);
        setDataEditar(row);
        setOpcionSeleccionada(2);
    }



    const handleTipoUbicacion = (e) => {
        setTipoUbicacion(e.target.value);
        if(e.target.value === -1) {
            setRows(ubicaciones);
        }
        else
        {
            const listaFiltrada = ubicaciones.filter((row) => row.idTipoUbicacion === e.target.value);
            setRows(listaFiltrada);
        }
    }

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = ubicaciones.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handlerVistaMiembros = (id) => () => {
        setOpenModalGestionUbicacion(true);
        setIdBusqueda(id);
        setOpcionSeleccionada(3);
    }

    const columns = [
        {   field: 'idUbicacion', headerName: 'ID', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'nombreZona', headerName: 'Ubicada en', flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'tipoUbicacion', headerName: 'T. Ubicacion', type: 'string',  width: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'nombre', headerName: 'N. Ubicacion', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'piso', headerName: 'Piso', type: 'string', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'numeracion', headerName: 'Numero', type: 'string',  flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        {   field: 'Usuarios', headerName: 'Miembros', type: 'actions',  width: 134, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions',
            getActions:({id, row})=>{
                return [<GridActionsCellItem
                    icon={<Box
                        id="iconoEditarBox"
                        sx={{
                          display: 'flex',
                          padding: '6px',
                          alignItems: 'flex-start',
                          gap: '10px',
                          borderRadius: '25px',
                          background: '#FFF',
                          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                          '& .usuarios-icon-a .fondo-usuarios-icon-a':{
                            fill: 'white',
                          },
                          '& .usuarios-icon-a .trazo-usuarios-icon-a':{
                            stroke: '#626262',
                            strokeWidth: 1.5,
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                          },
                          '&:hover':  {
                            backgroundColor: '#E8EEF1',
                            '.usuarios-icon-a .fondo-usuarios-icon-a': {
                              fill: '#E8EEF1',
                            },
                            '.usuarios-icon-a .trazo-usuarios-icon-a': {
                              stroke: '#175676',
                            },
                          },
                          '&:active':  {
                            backgroundColor: '#1F7098',
                            '.usuarios-icon-a .fondo-usuarios-icon-a': {
                              fill: '#1F7098',
                            },
                            '.usuarios-icon-a .trazo-usuarios-icon-a': {
                              stroke: 'white',
                            },
                          }
                        }}
                      >
                        <UsuariosIconA id='iconoEditar'/> 
                    </Box>}
                    label="Miembros"
                    onClick={handlerVistaMiembros(id)}
                />]
            }
        },
        {   field: 'editar', headerName: 'Editar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <TooltipTipoUno titulo={"Editar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<IconoEditarUsuario  id='iconoEnTablaUsuarios'/>}
                        label="Editar"
                        onClick={openEditar(row)}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
    ];

    return (
    <Box id="BoxContenedorTablaActuadores">
        <CabeceraTablasGestion 
            icono1={<IconoGestionUbicaciones />}
            titulo={"Ubicaciones"}
            rows={rows}
            setRows={setRows}
            agregarNuevoIcon={true}
            handlerAN={handleNueva} //agregar handle nuevo
            cargaMasivaIcon={null}
            handlerCM={null}
            descargarIcon={null}
            handlerDownload={null}
            onChange={handleSearch}
            value={searchText}
            clearSearch={clearSearch}
            >
                <Box id="BoxSelectContainerCabeceras">
                    <FiltroSelectTres id="FiltroSelectTiposActores" 
                    nombre={"Tipos"} 
                    listaOpciones={tiposUbicaciones}
                    value={tipoUbicacion}
                    handler={handleTipoUbicacion}
                    />
                    <FiltroSelect id="FiltroSelectZonasEmpresa" 
                    nombre={"Zonas"} 
                    listaOpciones={zonasFormadas}
                    value={opcionZona}
                    handler={handleZona}
                    />
                </Box>
            </CabeceraTablasGestion>
            <DataGrid id='TablaUbicaciones'
            style={{ width:'100%', height:"100%"}}
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                color:'#175676',
            },
                '& .MuiDataGrid-columnHeader': {
                borderBottom: '2px solid #94B1C0',
            },
            '& .MuiDataGrid-row':{
                borderBottom:'1px solid #94B1C0'
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                width: "7.024px",
                background: "transparent"
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                background: "transparent"
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                background: "#C9C9C9",
                borderRadius: "100px"
            }
               
            }}
            getRowId={(row) => row.idUbicacion}
            columns={columns} 
            rows={rows}
            initialState={{
                    pagination: {
                        paginationModel: {
                        page: 0,
                        pageSize: 10,
                        },
                    },
                }}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection
            loading={loading}
            disableRowSelectionOnClick 
            pageSizeOptions={[10, 20]}
            />            
            <ModalOpcionesGestionUbicacion
            open={openModalGestionUbicacionr}
            setOpen={setOpenModalGestionUbicacion}
            setRows={setRows}
            dataEditar={dataEditar}
            rows={rows}
            opcion={opcionSeleccionada}
            ubicacion={idBusqueda}
            />            
    </Box>
  );
}


export default GestionUbicaciones;