import { Box, Modal } from "@mui/material";
import { EditarUsuario } from "../EditarUsuario/EditarUsuario";
import { NuevoUsuario } from "../NuevoUsuario/Nuevo";


export const HandleOpcionesUsuario = ({ open, setOpen, setRows, rows, opcion, dataEdit}) => {
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <Modal open={open} onClose={handleClose} sx={{border:"unset"}}>
            <Box>
                {
                opcion === 1 ?
                    <NuevoUsuario  handlerOpen={setOpen} setRows={setRows} />
                    :
                    <EditarUsuario handlerOpen={setOpen}  userData={dataEdit} rows={rows} setRows={setRows}/>
                }
            </Box>
        </Modal>
    )
}