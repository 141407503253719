import * as Yup from 'yup';

export const EsquemaNuevaPatente = Yup.object({
    patente: Yup.string()
    .required("Debe indicar una patente")
    .max(6, "maximo de 6 caracteres")
    .min(6, "minimo de 6 caracteres")
    ,
    idPersona: Yup.number()
    .test("min", "Debe indicar un usuario", function(value){
        if(value === -1){
            return false;
        }
        else{
            return true;
        }
    })
})