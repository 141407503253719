import { Autocomplete, Box, Divider, FormControl, FormHelperText, IconButton, TextField, Typography } from "@mui/material";
import "./NuevoEvento.css";
import { IconoCalendarioNuevoEventos, IconoCerrarNuevoEventos } from "./NuevoEventoIcons";
import useTiposEventos from "../../../Hooks/TiposEventos/useTiposEventos";
import { useFormik } from "formik";
import { SeleccionFecha } from "../../DatePicker/DatePicker";
import SeleccionTiempo from "../../TimePicker/TimePicker";
import useUbicacionesEvento from "../../../Hooks/Ubicaciones/useUbicaciones";
import { RadioRecurrencia } from "./RadioRecurrencia";    
import { validationSchema } from "./ValidationScheme";
import useNuevoEvento from "../../../Hooks/Eventos/useNuevoEvento";
import { useSelector } from "react-redux";
import { useState } from "react";
import { BotonConLoading } from "../../Botones/BotonesTabla/Botones";
import dayjs from "dayjs";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import { TextFieldUnoEventos } from "../../TextField/TextFieldUno";

const EditarEvento = ({setOpen, dataEvento}) => {
    const { tiposVisitas } = useTiposEventos();
    const { ubicacionesEvento } = useUbicacionesEvento();
    const user = useSelector(action => action.user.authenticated);
    const {isLoading, setIsLoading, response, EditarEvento} = useNuevoEvento();
    const [openLoading, setOpenLoading] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    const formik = useFormik(
        {
            initialValues: {
                idTipoEvento: dataEvento.idTipoVisita,
                nombreTipoEvento: dataEvento.tipoVisita,
                nombreEvento: dataEvento.nombreEvento,
                idDestino: dataEvento.idUbicacion,
                nombreDestino: dataEvento.nombreUbicacion,
                fechaUno: dayjs(dataEvento.fechaVisita),
                fechaDos: dayjs(dataEvento.fechaTermino),
                desde: dayjs(dataEvento.fechaVisita),
                hasta: dayjs(dataEvento.fechaTermino),
                lunes: Boolean(false),
                martes: Boolean(false),
                miercoles: Boolean(false),
                jueves: Boolean(false),
                viernes: Boolean(false),
                sabado: Boolean(false),
                domingo: Boolean(false),
            },
            validationSchema: validationSchema,
            onSubmit: async () => {
                setOpenLoading(true);
                setIsLoading(true);
                await EditarEvento(formik.values, user.idPersona, dataEvento.idVisita)
                .then(
                    (resolve) => 
                    {
                        setSnackbarSeverity("success")
                        setSnackMsg("Evento actualizado exitosamente!")
                        setSnackbar(true);
                    }
                    ,
                    (reject) =>
                    {
                        setSnackbarSeverity("error")
                        setSnackMsg("fallo la actualizacion, reintente!")
                        setSnackbar(true);
                        
                    }
                )
            }
        }
    );

    const handleClose = () => {
        setOpen(null);
        formik.resetForm();
    }
    
    return (
        <Box id="AgendarEventoBox" sx={{height:"648px"}}>
        <Box id="CabeceraNuevoEvento">
            <Box id="BoxIconoTituloNuevoEvento">
                <Box id="BoxIconoNuevoEvento">
                    <IconoCalendarioNuevoEventos id="IconoCalendarioNE" />
                </Box>
                <Box id="BoxTituloAgendarNuevoEvento">
                    <Typography id="TextoAgregarNuevoEvento">
                    {
                        "Agendar evento"
                    }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxIconoCerrarEvento">
                <IconButton onClick={handleClose}>
                    <IconoCerrarNuevoEventos id="IconoCerrarNE" />
                </IconButton>
            </Box>
        </Box>
        <Divider orientation="horizontal" flexItem />
        <Box id="BoxDataToCreateNuevoEvento" sx={{height:"556px"}}>
            <Box id="BoxInternoBodyCrearNuevoEvento">
                <Box id="BoxContenedorFormularioNuevoEvento" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxContenedorSelectTipoEvento">
                        <FormControl id="TiposEventosSelectFormControl">
                            <Autocomplete                                                                   
                                disablePortal
                                options={tiposVisitas}
                                fullWidth    
                                disabled={true}
                                id="idTipoEvento"
                                componentname="idTipoEvento"
                                freeSolo={true}
                                getOptionKey={(o) => o.idTipoVisita}
                                isOptionEqualToValue={(o, v) => o.tipoVisita === v}
                                onChange={(e, v) => {
                                    if(v === null)
                                    {
                                        formik.setFieldValue("idTipoEvento", -1)                                           
                                    }
                                    else{
                                        formik.setFieldValue("idTipoEvento", v.idTipoVisita)
                                    }
                                }}
                                getOptionLabel={(option) =>  option.tipoVisita ?? option}
                                inputValue={formik.values.nombreTipoEvento}
                                value={formik.values.nombreTipoEvento}
                                onInputChange={(e,v) => formik.setFieldValue("nombreTipoEvento", v)}   
                                renderInput={(params) => 
                                <TextField
                                variant="standard"                                    
                                InputLabelProps={{shrink: true}}
                                {...params}
                                label="Tipo de Visita"
                                />
                                }
                                renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                <Box
                                    key={key}
                                    component="li"
                                    {...optionProps}>
                                    {option.tipoVisita}
                                    <Box sx={{padding: "4px", marginLeft:"15px"}}>
                                        {option.icono}
                                    </Box>
                                </Box>
                                );
                            }}
                            />
                            <FormHelperText>
                                {
                                    formik.errors.idTipoEvento
                                }
                            </FormHelperText>   
                        </FormControl>
                    </Box>
                    <Box id="BoxContenedorNombrEventoNuevo">
                        <TextFieldUnoEventos 
                        id={"nombreEvento"}
                        label={"Nombre del Evento"}
                        focus={false}
                        placeholder={"Fiesta"}
                        name={"nombreEvento"}
                        icon={<IconoBorrarCampoGU id="IconoEnNuevoEvento"/>}
                        disabled={false}
                        value={formik.values.nombreEvento}
                        handlerChange={formik.handleChange}
                        formHandle={() =>  formik.setFieldValue("nombreEvento", "")}
                        helper={formik.errors.nombreEvento}
                        />
                    </Box>
                    <Box id="BoxContenedorFechaInicioFinEvento">
                        {
                            formik.values.idTipoEvento === 0 ?
                            <Box id="BoxSeleccionadorDeFechaTipoDiario">
                                <SeleccionFecha 
                                handleDate={(value)=> formik.setFieldValue("fechaUno", value)}
                                valueSelected={formik.values.fechaUno} 
                                labelName={"Fecha de Visita"} 
                                name={"fechaUno"} 
                                helperText={formik.errors.fechaUno}
                                />
                            </Box>
                            :
                            <Box id="BoxSeleccionadorDeFechaTipoEventosYRecurrentes">
                                <Box id="BoxDesdeFecha">
                                    <SeleccionFecha 
                                    handleDate={(value)=> formik.setFieldValue("fechaUno", value)}
                                    valueSelected={formik.values.fechaUno} 
                                    labelName={"Inicia"} 
                                    name={"fechaUno"} 
                                    helperText={formik.errors.fechaUno}
                                    />
                                </Box>
                                <Box id="BoxHastaFecha">
                                    <SeleccionFecha 
                                    handleDate={(value)=> formik.setFieldValue("fechaDos", value)} 
                                    valueSelected={formik.values.fechaDos} 
                                    labelName={"Termina"} 
                                    name={"fechaDos"}
                                    helperText={formik.errors.fechaDos}
                                    />
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Box id="BoxContenedorSeleccionadorHoraInicioFinNuevoEvento">
                        <Box id="BoxDesde">
                            <SeleccionTiempo 
                            timeSet={formik.values.desde} 
                            handleTime={(value)=> formik.setFieldValue("desde", value)} 
                            namePicker={"Desde"} 
                            name={"desde"}
                            helper={formik.errors.desde} 
                            />

                        </Box>
                        <Box id="BoxHasta">
                            <SeleccionTiempo 
                            timeSet={formik.values.hasta} 
                            handleTime={(value)=> formik.setFieldValue("hasta", value)} 
                            namePicker={"Hasta"} 
                            name={"hasta"} 
                            helper={formik.errors.hasta}
                            />
                        </Box>
                    </Box>
                    <Box id="BoxContenedorSelectDestinoNuevoEvento">
                        <FormControl id="DestinosEmpresa">
                            <Autocomplete                                                                   
                                disablePortal
                                options={ubicacionesEvento}
                                fullWidth    
                                id="idDestino"
                                ListboxProps={{ sx: { maxHeight: "160px"}}}
                                componentname="idDestino"
                                freeSolo={true}
                                getOptionKey={(o) => o.idUbicacion}
                                isOptionEqualToValue={(o, v) => o.nombreUbicacion === v}
                                onChange={(e, v) => {
                                    if(v === null)
                                    {
                                        formik.setFieldValue("idDestino", -1)                                           
                                    }
                                    else{
                                        formik.setFieldValue("idDestino", v.idUbicacion)
                                    }
                                }}
                                getOptionLabel={(option) =>  option.nombreUbicacion ?? option}
                                inputValue={formik.values.nombreDestino}
                                value={formik.values.nombreDestino}
                                onInputChange={(e,v) => formik.setFieldValue("nombreDestino", v)}   
                                renderInput={(params) => 
                                <TextField
                                variant="standard"                                    
                                InputLabelProps={{shrink: true}}
                                {...params}
                                label="Ubicaciones para visitas"
                                />
                                }
                                renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                <Box
                                    key={key}
                                    component="li"
                                    {...optionProps}>
                                    {option.nombreUbicacion}
                                </Box>
                                );
                            }}
                            />
                            <FormHelperText>
                                {
                                    formik.errors.idDestino
                                }
                            </FormHelperText>  
                        </FormControl>
                    </Box>
                    <Box id="BoxContenedorSeleccionRecurrencia">
                        {
                            formik.values.idTipoEvento === 2 ?
                            <Box id="BoxContenidoRecurrencia">
                                <Box id="lblBoxTextRecurrencia">
                                    <Typography id="TextoRecurrencia">
                                        {
                                            "Recurrencia"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="IconosDias">
                                    <RadioRecurrencia dia={"Lu"} id={"lunes"} estado={formik.values.lunes} handle={() => formik.setFieldValue("lunes", Boolean(!formik.values.lunes))} />
                                    <RadioRecurrencia dia={"Ma"} id={"martes"} estado={formik.values.martes} handle={() => formik.setFieldValue("martes", Boolean(!formik.values.martes))} />
                                    <RadioRecurrencia dia={"Mi"} id={"miercoles"} estado={formik.values.miercoles} handle={() => formik.setFieldValue("miercoles", Boolean(!formik.values.miercoles))} />
                                    <RadioRecurrencia dia={"Ju"} id={"jueves"} estado={formik.values.jueves} handle={() => formik.setFieldValue("jueves", Boolean(!formik.values.jueves))} />
                                    <RadioRecurrencia dia={"Vi"} id={"viernes"} estado={formik.values.viernes} handle={() => formik.setFieldValue("viernes", Boolean(!formik.values.viernes))} />
                                    <RadioRecurrencia dia={"Sa"} id={"sabado"} estado={formik.values.sabado} handle={() => formik.setFieldValue("sabado", Boolean(!formik.values.sabado))} />
                                    <RadioRecurrencia dia={"Do"} id={"domingo"} estado={formik.values.domingo} handle={() => formik.setFieldValue("domingo", Boolean(!formik.values.domingo))} />
                                </Box>
                            </Box>
                            :
                            null
                        }
                    </Box>
                </Box>
                <Box id="BoxContenedorBtnAccionesNuevoEvento">
                    <BotonConLoading 
                    event={formik.submitForm}
                    loadingStatus={isLoading}
                    textoBoton={ isLoading ? null : "Agendar" }
                    />
                </Box>
            </Box>
        </Box>            

        <LoadingMasRespuestaCinco
        open={openLoading} 
        setOpen={setOpenLoading} 
        msj={"Se esta actualizando el evento. Este proceso puede tardara solo un momento. Agradecemos tu paciencia."} 
        id={"RespuestaDesactivarEvento"} 
        tituloLoading={"Actualizando"}
        loading={isLoading} 
                respuesta={response} 
        icono={
            response === 200 ?
            <IconoExito id="IconoRespuestaServidor" />
            :
            <IconoFallo id="IconoRespuestaServidor" />
        }
        textoBoton={
            response === 200 ? 
            "Aceptar"
            :
            "Reintentar"
        }
        titulo={response === 200 ? "Evento actualizado":"Error al actualizar"}
        mensajeRespuesta={response === 200 ? 
            "El evento se ha actualizado exitosamente"
            :
                "Ha ocurrido un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
        />

        <SnackBarTipoUno 
        open={snackbar}
        handler={handleCloseSnackbar}
        duration={6000}
        severity={snackbarSeverity}
        msg={snackMsg}
        />        
    </Box>    
    );
}

export default EditarEvento;