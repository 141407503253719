import { Box } from '@mui/material';
import { PieChart } from '@mui/x-charts';

const GraficoCircular = ({datos, valorGrafico}) => {

    return (
        <Box sx={{width:'416px', height:'320px'}}>
            <PieChart
                series={[
                    {
                    data: datos.data,
                    cx:208,
                    cy:155,
                    outerRadius:113,
                    },
                ]}
            
                width={320}
                height={320}
                {...{margin:{left:0}}}
            />
        </Box>
    );
}

export default GraficoCircular;
