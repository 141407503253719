import axios from 'axios';
import { useEffect, useState } from 'react';
import { VisitantesEnVisita } from '../../Service/API/Api';


function useInvitadosEvento(idVisita){
    const [invitados, setInvitados] = useState([]);
    const [reload, setReload] = useState(false);
    const [loadingRow, setLoadingRow] = useState(false);


   useEffect(() => {
        axios.get(VisitantesEnVisita + idVisita)
        .then(
            (response) => {
                
                setInvitados(response.data);  
                setLoadingRow(false)         
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
                setLoadingRow(false)         
            }
        )
        return () => setReload(false);
   }, [reload, idVisita])
   

    

    return {
        invitados,
        setReload,
        loadingRow,
        setLoadingRow
    };
}


export default useInvitadosEvento;