import axios from "axios";
import { useState } from "react";
import { CrearHorario, EditarHorario} from "../../Service/API/Api";
import dayjs from "dayjs";

export default function useGestionHorarios()
{
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();

    const CreateHorario = (values, empresa) =>{
        return new Promise((resolve, reject) => {
            var lista = []

            var diaLunes = {
                "idDia": 0,
                "horaEntrada": dayjs(values.lunesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.lunesTermino).format("HH:mm:ss")
            }
            
            lista.push(diaLunes)
            var diaMartes = {
                "idDia": 1,
                "horaEntrada": dayjs(values.martesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.martesTermino).format("HH:mm:ss")
            }
    
            lista.push(diaMartes)
        
            var diaMiercoles = {
                "idDia": 2,
                "horaEntrada": dayjs(values.miercolesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.miercolesTermino).format("HH:mm:ss")
            }
    
            lista.push(diaMiercoles)
        
            var diaJueves = {
                "idDia": 3,
                "horaEntrada": dayjs(values.juevesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.juevesTermino).format("HH:mm:ss")
            }
    
            lista.push(diaJueves)
        
            var diaViernes = {
                "idDia": 4,
                "horaEntrada": dayjs(values.viernesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.viernesTermino).format("HH:mm:ss")
            }
    
            lista.push(diaViernes)
            var diaSabado = {
                "idDia": 5,
                "horaEntrada": dayjs(values.sabadoInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.sabadoTermino).format("HH:mm:ss")
            }
    
            lista.push(diaSabado)

            var diaDomingo = {
                "idDia": 6,
                "horaEntrada": dayjs(values.domingoInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.domingoTermino).format("HH:mm:ss")
            }
    
            lista.push(diaDomingo)
        
            const nuevoHorarioObjeto = {
                "nombre": values.nombre,
                "idEmpresa": empresa,
                "lDias": lista
            }
            axios.post(CrearHorario, nuevoHorarioObjeto)
            .then(
                (response) => {
                    const createdID = response.data;
                    resolve(createdID);
                    setResponse(response.status);
                    setLoading(false);
                }
            ).catch(
                (error) => {
                    reject(false)
                    setResponse(error.response.status)
                    setLoading(false)
                }
            )
        
        })
    }

    const EditHorario = (values, empresa, idActual) =>{
        return new Promise((resolve, reject) => {
            var lista = []

            var diaLunes = {
                "idDia": 0,
                "horaEntrada": dayjs(values.lunesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.lunesTermino).format("HH:mm:ss")
            }
            
            lista.push(diaLunes)

            var diaMartes = {
                "idDia": 1,
                "horaEntrada": dayjs(values.martesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.martesTermino).format("HH:mm:ss")
            }
    
            lista.push(diaMartes)
        
            var diaMiercoles = {
                "idDia": 2,
                "horaEntrada": dayjs(values.miercolesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.miercolesTermino).format("HH:mm:ss")
            }
    
            lista.push(diaMiercoles)
        
            var diaJueves = {
                "idDia": 3,
                "horaEntrada": dayjs(values.juevesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.juevesTermino).format("HH:mm:ss")
            }
    
            lista.push(diaJueves)
        
            var diaViernes = {
                "idDia": 4,
                "horaEntrada": dayjs(values.viernesInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.viernesTermino).format("HH:mm:ss")
            }
    
            lista.push(diaViernes)
            var diaSabado = {
                "idDia": 5,
                "horaEntrada": dayjs(values.sabadoInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.sabadoTermino).format("HH:mm:ss")
            }
    
            lista.push(diaSabado)
            
            var diaDomingo = {
                "idDia": 6,
                "horaEntrada": dayjs(values.domingoInicio).format("HH:mm:ss"),
                "horaSalida": dayjs(values.domingoTermino).format("HH:mm:ss")
            }
    
            lista.push(diaDomingo)

            const editarExistenteObjeto = {
                "idHorario": idActual,
                "nombre": values.nombre,
                "idEmpresa": empresa,
                "lDias": lista
            }
            axios.put(EditarHorario, editarExistenteObjeto)
            .then(
                (response) => {
                    setResponse(response.status)
                    resolve(true);
                    setLoading(false);
                }
            ).catch(
                (error) => {
                    reject(false)
                    setResponse(error.response.status)
                    setLoading(false)
                }
            )
        
        })
    }


   
    return {
        loading,
        setLoading,
        response,
        CreateHorario,
        EditHorario
    }
    
}