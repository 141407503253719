import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import useEventosData from "../../../../Hooks/Agenda/useEventosData";
import useUbicacionesGestion from "../../../../Hooks/Ubicaciones/useUbicacionesGestion";
import "./CuerpoDia.css";
import { EventoUbicacionColumna } from "./EventosUbicacion/EventosUbicacion";
import { Mayorque, Menorque } from "../../../Calendario/CalendarioIcons";
import {v4 as uuidv4} from 'uuid';

export const CuerpoDia = ({fechaActual, addDay, restDay}) => {
    const {listaEventos} = useEventosData();
    const {ubicaciones} = useUbicacionesGestion();
    const [dataDia, setDataDia] = useState([]);

    useEffect(() => {        
        var listaDia = [];
        
        listaEventos.forEach(element => {
            
            let vistaUnoFormated = dayjs(fechaActual).format("DD/MM/YYYY")
            
            let fechaInicioEvento = dayjs(element.fechaVisita).format("DD/MM/YYYY");

            let fechaTerminoEvento = dayjs(element.fechaTermino).format("DD/MM/YYYY");            

            if(fechaInicioEvento === vistaUnoFormated && fechaTerminoEvento === vistaUnoFormated){
                listaDia.push(element) 
            }
        });

        var listaUbicacionesConEventos = [] 

        ubicaciones.forEach(ubicacion => {
            var listaEventoEnUbicacion = [];

            listaDia.forEach(eventoDia => {
                if(eventoDia.idUbicacion === ubicacion.idUbicacion){
                    listaEventoEnUbicacion.push(eventoDia)
                }
            });
            
            var eventosEnUbicacion = {
                idUbicacion: ubicacion.idUbicacion,
                nombreUbicacion: ubicacion.nombre,
                listaEventos: listaEventoEnUbicacion
            }

            listaUbicacionesConEventos.push(eventosEnUbicacion)
        });

        if(ubicaciones.length <= 8)
        {
            var largoActual = listaUbicacionesConEventos.length;
            for(var i = largoActual; i <= 7; i++){
                let sinUbicaciones = {
                    idUbicacion: uuidv4(),
                    nombreUbicacion: "",
                    listaEventos: []
                }
                listaUbicacionesConEventos.push(sinUbicaciones)

            }
            
            
        }
        
        setDataDia(listaUbicacionesConEventos)
    }, [listaEventos, fechaActual, ubicaciones])


    var horasDia = [
        {id:0, hora: "00:00"},
        {id:1, hora: "01:00"},
        {id:2, hora: "02:00"},
        {id:3, hora: "03:00"},
        {id:4, hora: "04:00"},
        {id:5, hora: "05:00"},
        {id:6, hora: "06:00"},
        {id:7, hora: "07:00"},
        {id:8, hora: "08:00"},
        {id:0, hora: "09:00"},
        {id:10, hora: "10:00"},
        {id:11, hora: "11:00"},
        {id:12, hora: "12:00"},
        {id:13, hora: "13:00"},
        {id:14, hora: "14:00"},
        {id:15, hora: "15:00"},
        {id:16, hora: "16:00"},
        {id:17, hora: "17:00"},
        {id:18, hora: "18:00"},
        {id:19, hora: "19:00"},
        {id:20, hora: "20:00"},
        {id:21, hora: "21:00"},
        {id:22, hora: "22:00"},
        {id:23, hora: "23:00"}
    ]
    
    return (
        <Box id="BoxLayoutVistaDia" >
            <Box id="BoxVistaCalendarioDia">
                <Grid id="ContenedorHoras" container spacing={0}>
                    <Grid item id="GridItemAccion">
                        <IconButton onClick={restDay}>
                            <Menorque id="iconoAccionCambioVistaDiaAgenda" />
                        </IconButton>
                    </Grid>
                    <Grid container spacing={0}>
                        {
                            horasDia.map((item, index) => {
                                return(
                                    <Grid id="HoraDia" item key={index}>
                                        <Typography id="TxtHoraDiaVistaDiaAgenda">
                                            {
                                                item.hora
                                            }
                                        </Typography>
                                    </Grid>
                                );                      
                            })
                        }
                    </Grid>
                </Grid>
                <Box  id="BoxContenedorUbicacionesColumna" height={26*48+"px"} sx={{overflowX: "scroll", overflowY:"hidden"}} >
                    {
                        dataDia.map((item, index) => {
                            return(
                               <Grid container key={index} id="GridContenedorUbicacion">
                                    <Grid item id="GridNombreUbicacion">
                                        <Typography id="TxtNombreUbicacionEmpresaVistaDiaAgenda">
                                            {
                                                item.nombreUbicacion
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid container spacing={0} id="GridListaDeHorasUbicacion">
                                        <EventoUbicacionColumna 
                                        eventosEnUbicacion={item.listaEventos}
                                        />
                                    </Grid>
                               </Grid>
                            );
                        })
                    }
                </Box>
                <Grid id="ContenedorHoras" container spacing={0}>
                    <Grid item id="GridItemAccion">
                        <IconButton onClick={addDay}>
                            <Mayorque id="iconoAccionCambioVistaDiaAgenda" />
                        </IconButton>
                    </Grid>
                    <Grid  container spacing={0}>
                        {
                            horasDia.map((item, index) => {
                                return(
                                    <Grid id="HoraDia" item key={index}>
                                        <Typography id="TxtHoraDiaVistaDiaAgenda">
                                            {
                                                item.hora
                                            }
                                        </Typography>
                                    </Grid>
                                );                      
                            })
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

