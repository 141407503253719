import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice
    (
        {
            name: "authentication",
            initialState:
            {
                authenticated: false,
                token: null,
                expiracion : null,
                idEmpresa: null,
                usuario: null,
                correo: null,
                idPersona: null,
                rol: null,
                rememberMe: false,
            },
            reducers:
            {
                setUser: (state, action) => {
                    state.authenticated = action.payload;
                    state.token = action.payload;
                    state.expiracion = action.payload;
                    state.idEmpresa = action.payload;
                    state.usuario = action.payload;
                    state.correo = action.payload;
                    state.idPersona = action.payload;
                    state.rol = action.payload;
                    state.rememberMe = action.payload;
                },
                disconnect: (state, action) => {
                    state.authenticated = action.payload;
                    state.token = action.payload;
                    state.expiracion = action.payload;
                    state.idEmpresa = action.payload;
                    state.usuario = action.payload;
                    state.correo = action.payload;
                    state.idPersona = action.payload;
                    state.rol = action.payload;
                    state.rememberMe = action.payload;
                }
            }
        }
    )


const tipoVisitaSlice = createSlice
    (
        {
            name: "tiposVisita",
            initialState:{
                tiposVisita:[]
            }
            ,
            reducers:
            {
                setTipoVisita: (state, action) => {
                    state.tiposVisita.push(action.payload)
                }
            }
        }
    )

    export const { setUser, disconnect } = authSlice.actions;
    export const authReducer = authSlice.reducer;
    
    export const { setTipoVisita } = tipoVisitaSlice.actions;
    export const TiposVisitaReducer = tipoVisitaSlice.reducer;
    


    //////////////////////// CODIGO GERARDO ///////////////////////////////////////////////////
    const filtroTiempoRealSlice = createSlice({
        name: 'filtroTiempoReal',
        initialState: {
          listadoFiltros: [
            {
                idActor: 12,
                idTipoActor: 3,
                filtroActivado: false,
            },
            {
                idActor: 18,
                idTipoActor: 3,
                filtroActivado: false,
            },
            {
                idActor: 13,
                idTipoActor: 6,
                filtroActivado: false,
            },
            {
                idActor: 19,
                idTipoActor: 6,
                filtroActivado: false,
            },
            {
                idActor: 21,
                idTipoActor: 7,
                filtroActivado: false,
            },
            {
                idActor: 16,
                idTipoActor: 7,
                filtroActivado: false,
            },
            {
                idActor: 14,
                idTipoActor: 8,
                filtroActivado: false,
            },
            {
                idActor: 20,
                idTipoActor: 8,
                filtroActivado: false,
            },
            {
                idActor: 5,
                idTipoActor: 11,
                filtroActivado: false,
            },
            {
                idActor: 22,
                idTipoActor: 12,
                filtroActivado: false,
            },
            {
                idActor: 3,
                idTipoActor: 12,
                filtroActivado: false,
            },
            {
                idActor: 2,
                idTipoActor: 13,
                filtroActivado: false,
            },
            {
                idActor: 8,
                idTipoActor: 13,
                filtroActivado: false,
            },
            {
                idActor: 10,
                idTipoActor: 15,
                filtroActivado: false,
            },  
        ]
        },
        reducers: {
          actualizarFiltroTiempoReal: (state, action) => {
            state.listadoFiltros = action.payload;
          }
        }
      });

      export const { actualizarFiltroTiempoReal } = filtroTiempoRealSlice.actions;
      // export default mensajeSlice.reducer;
      export const filtroTiempoRealReducer = filtroTiempoRealSlice.reducer;
    //////////////////////////////////////////////////////////////////////////////////////////
    

