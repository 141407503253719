import axios from "axios";
import { useEffect, useState } from "react"
import { BuscarTarjetasEmpresa } from "../../Service/API/Api";

export function useTarjetasGestion(){
    const [tarjetas, setTarjetas] = useState([]);
    const [loading, setLoading] = useState();


    useEffect(() => {
        setLoading(true);
        axios.get(BuscarTarjetasEmpresa)
        .then(
            (response) => {
                setTarjetas(response.data);
                setLoading(false);
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
                setLoading(false);
            }
        )
    }, [])
    

    return{
        tarjetas,
        loading
    }
}