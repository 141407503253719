import { Box, Typography } from "@mui/material";
import "./HandlerOpciones.css"
import dayjs from "dayjs";

const Semana = [
    {id:0, nombre:"Domingo"},
    {id:1, nombre:"Lunes"},
    {id:2, nombre:"Martes"},
    {id:3, nombre:"Miercoles"},
    {id:4, nombre:"Jueves"},
    {id:5, nombre:"Viernes"},
    {id:6, nombre:"Sabado"},
]

const Meses = [
    {id:0, nombre:"Enero"},
    {id:1, nombre:"Febrero"},
    {id:2, nombre:"Marzo"},
    {id:3, nombre:"Abril"},
    {id:4, nombre:"Mayo"},
    {id:5, nombre:"Junio"},
    {id:6, nombre:"Julio"},
    {id:7, nombre:"Agosto"},
    {id:8, nombre:"Septiembre"},
    {id:9, nombre:"Octubre"},
    {id:10, nombre:"Noviembre"},
    {id:11, nombre:"Diciembre"},
]


const OrganizarFecha = (date) => {
    const hoy = dayjs(date);
    const hoyNumeroDiaSemana = hoy.day();
    const diaDeMes = hoy.format("DD");
    const anioEnCurso = hoy.format("YYYY");
    const mesNumero = hoy.month();
    //console.log(dateMonthNumero);
    
    let hoyNombre = Semana.find(s => s.id === hoyNumeroDiaSemana).nombre;
    let mesNombre = Meses.find(m => m.id ===mesNumero).nombre;

    return  hoyNombre + ", "+ diaDeMes + " de " + mesNombre + " del " + anioEnCurso
}

export const CabeceraHoy = ({fechaActual}) => {
    let hoy = OrganizarFecha(fechaActual)
    
    return (
        <Box id="BoxCabeceraOpcionHoy">
            <Typography id="TextoHoyFormateado">
                {
                    hoy
                }
            </Typography>
        </Box>
    );
}