import { Box, Button } from "@mui/material";
import DetalleEventos from "../Eventos/DetalleEventos/DetalleEventos";
import "./Tarjeta.css";
import { useState } from "react";
import dayjs from "dayjs";
import { TarjetaDataVisita } from "./Eventos/TarjetaDataVisita";
import { PopOverTipoTarjetaDetalleEventos } from "../Popover/PopoverTipoUno";


const ObtenerMesActualTexto = (id) =>{
    const date = new Date();
    date.setMonth(id);

    var mes = date.toLocaleString('es-ES', {month: 'short'})
    return mes;
}

export const TarjetaDetalleEvento = ({item }) =>{
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handlerOpen = (e) => {
        setAnchorEl(e.currentTarget);
    }

    var mes = ObtenerMesActualTexto(dayjs(item.fechaVisita).month())
    var dia = dayjs(item.fechaVisita).format("DD");

    return(
        <Box>
            <Button id="BotonEventoUno" fullWidth onClick={(e) => handlerOpen(e)} >
                <Box id="Calendario">
                    <Box id="BoxCabecera">
                    </Box>
                    <Box id="BoxDiaNumero">
                    {
                        dia
                    }
                    </Box>
                    <Box id="BoxMesNumero">
                    {
                        mes.toUpperCase()
                    }
                    </Box>
                </Box>
                <Box id="DataEvento">
                    <TarjetaDataVisita 
                    nombreEvento={item.nombreEvento} 
                    creador={item.nombres}  
                    numero={item.numeracion} 
                    piso={item.pisoUbicacion} 
                    fechaInicio={item.fechaVisita}
                    fechaTermino={item.fechaTermino} 
                    tipoEvento ={item.idTipoVisita}
                    nombreDestino={item.nombreUbicacion} />
                </Box>
            </Button>
            <PopOverTipoTarjetaDetalleEventos
            open={open}
            setOpen={setAnchorEl}
            anchorEl={anchorEl}
            children={<DetalleEventos setOpen={setAnchorEl} item={item} />}
            />
        </Box>
    );
}
