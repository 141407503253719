import * as Yup from 'yup';

export const EsquemaNuevaUbicacion = Yup.object({
    idZona: Yup.number()
    .required('* Campo requerido')
    .test("min", "Debe seleccionar una zona", function(value){
        if(value === 0){
            return false;
        }
        else
        {
            return true;
        }
    })
    , 
    nombreUbicacion: Yup.string()
    .required("* Campo Requerido")
    .min(5, "minimo de caracteres 5")
    .max(30, "maximo de 30 caracteres")
    , 
    idTipoUbicacion: Yup.number()
    .required("* Campo Requerido")
    .test("min", "Debe seleccionar un tipo de ubicacion", function(value){
        if(value === -1){
            return false;
        }
        else
        {
            return true;
        }
    })
    , 
    piso: Yup.string()
    .required('* Campo requerido')
    , 
    numeracion: Yup.string()
    .required('* Campo requerido')
})