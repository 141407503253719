import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import "./LoginVentanaDos.css"
import { useFormik } from "formik";
import { BotonAControlPass, CerrarContactanosIcon, CerrarContacto, IconoFacebook, IconoInstagram, IconoWhatsApp, UbicacionIcon } from "../LoginIcons";
import {BotonTipoContactanos } from "../../Botones/BotonesTabla/Botones";
import { Esquema } from "./Esquema";

export const Contacto =  ({handlerOpcion}) => {

    const handleToIMG = () => {
        handlerOpcion(1);
    }

    const ActivarOL = () => {
        var to = "jsimanca@automatismoslau.cl";
        var subject = '';
        var name = '';
        var body = '';
        var formedBody = '';

        var motivo = formik.values.motivo;
        var nombre  = formik.values.nombreCompleto;
        var mensaje = formik.values.mensaje;

        if(motivo !== null || motivo !== ''){
            subject = encodeURI("?subject=" + motivo);
        }
        else{
            subject = '';
        }
        if(nombre !== null || nombre !== ''){
            name = encodeURI(nombre);
            formedBody = "&body=Hola%20soy%20"+ name + ",%0A"            
        }else{
            name = '';
            formedBody = '';
        }
        if(mensaje !== null || mensaje !== ''){
            body = encodeURI(mensaje);
            formedBody = formedBody + body
        }else{
            body = '';
            formedBody = formedBody + '';
        }
        var elementoMailto = document.createElement("a");
        elementoMailto.href = "mailto:"+ to + subject + formedBody;
        elementoMailto.click();
    }

    const formik = useFormik({
        initialValues: {
            nombreCompleto: '',
            motivo: '',
            mensaje: '',
        },
        enableReinitialize: true,
        validationSchema: Esquema,
        onSubmit: () => {
            ActivarOL();
        }
    })

    const HandleIconCP = () => {
        window.location = "https://www.controlpass.cl"
    }
    const HandleIconInsta = () => {
        window.location = "https://www.Instagram.com/ControlPass"
    }
    const HandleIconFB = () => {
        window.location = "https://www.Facebook.com/ControlPass"
    }
    const HandleIconWA = () => {
        window.location = "https://www.whatsapp.com"
    }

    return (
        <Box id="BoxContactanosLogin">
            <Box id="BoxCabeceraContactanosLogin">
                <IconButton onClick={handleToIMG} id="IconButtonCerrarContacto">
                    <CerrarContacto />
                </IconButton>
            </Box>
            <Box id="BoxContenidoContactanosLogin">
                <Box id="BoxContenidoFormularioLogin">
                    <Box id="BoxFormularioContactanosLogin">
                        <Box id="BoxTituloFormularioLogin">
                            <Typography id="TxtTituloFormularioLogin">
                                {
                                    "Contáctanos"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxFormularioLogin" component={"form"} onSubmit={formik.handleSubmit}>
                            <Box id="BoxTxtfieldNombreLogin">
                                <TextField  variant="filled" fullWidth
                                id='nombreCompleto' label='Nombre' name='nombreCompleto' 
                                inputProps={{
                                    style: {
                                    color: "#FFF",
                                    fontFamily: "inherit",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.5px",
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: "#FFF",
                                        fontFamily: "inherit",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        fontStyle: "normal",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px"
                                        }
                                }}
                                size="small"
                                autoFocus 
                                FormHelperTextProps={{
                                    style: {
                                        color: "#FFF",
                                        padding: "0px",
                                        fontFamily: "inherit",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "normal",
                                        letterSpacing: "0.5px",
                                    }
                                }}
                                helperText={formik.errors.nombreCompleto}
                                value={formik.values.nombreCompleto} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                InputProps={{
                                    style: {background: "transparent", borderBottom: "1px solid #FFF"},
                                    disableUnderline: true,
                                    endAdornment: 
                                        <InputAdornment position='end' >
                                            <IconButton
                                            aria-label="Nombre"
                                            edge="end"
                                            onClick={() => formik.setFieldValue("nombreCompleto", "")}>
                                            <CerrarContactanosIcon />
                                        </IconButton>
                                        </InputAdornment>
                                }}></TextField>
                            </Box>
                            <Box id="BoxTxtfieldMotivoLogin">
                                <TextField variant="filled" fullWidth
                                id='motivo' label='Motivo' name='motivo' 
                                inputProps={{
                                    style: {
                                    color: "#FFF",
                                    fontFamily: "inherit",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.5px",
                                    }
                                }}
                                helperText={formik.errors.motivo}
                                InputLabelProps={{
                                    style: {
                                        color: "#FFF",
                                        fontFamily: "inherit",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        fontStyle: "normal",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px"
                                        }
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        color: "#FFF",
                                        padding: "0px",
                                        fontFamily: "inherit",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "normal",
                                        letterSpacing: "0.5px",
                                    }
                                }}
                                size="small"
                                autoFocus value={formik.values.motivo} 
                                onChange={formik.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    borderbottom: "1px solid #FFF",
                                    style: {background: "transparent", borderBottom: "1px solid #FFF"},
                                    endAdornment: 
                                        <InputAdornment position='end' >
                                            <IconButton
                                            aria-label="Motivo"
                                            edge="end"
                                            onClick={() => formik.setFieldValue("motivo", "")}>
                                            <CerrarContactanosIcon />
                                        </IconButton>
                                        </InputAdornment>
                                }}
                                />
                            </Box>
                            <Box id="BoxTxtfieldMensajeLogin">
                                <TextField variant="filled" fullWidth
                                id='mensaje' label='Mensaje' name='mensaje' 
                                helperText={formik.errors.mensaje}
                                inputProps={{
                                    style: {
                                    color: "#FFF",
                                    fontFamily: "inherit",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.5px",
                                    }
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        color: "#FFF",
                                        padding: "0px",
                                        fontFamily: "inherit",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "normal",
                                        letterSpacing: "0.5px",
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: "#FFF",
                                        fontFamily: "inherit",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        fontStyle: "normal",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px"
                                        }
                                }}
                                multiline
                                autoFocus maxRows={2} value={formik.values.mensaje} 
                                onChange={formik.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {background: "transparent", height: "105px", borderBottom: "1px solid #FFF"},
                                    endAdornment: 
                                        <InputAdornment position="end"  sx={{ position:"absolute", top: 26, right:13}} >
                                            <IconButton
                                            aria-label="Mensaje"
                                            edge="end"
                                            onClick={() => formik.setFieldValue("mensaje", "")}>
                                                <CerrarContactanosIcon />
                                            </IconButton>
                                        </InputAdornment>
                                }} 
                                />
                            </Box>
                        </Box>
                        <Box id="BoxBtnAccionFormularioLogin">
                            <BotonTipoContactanos 
                            textoBoton={"Enviar"}
                            event={formik.submitForm}
                            />
                        </Box>
                    </Box>
                    <Box id="BoxContenidoDataEncuentranosLogin">
                        <Box id="BoxContenidoDataTituloEncuentranos">
                            <Typography id="TxtContenidoDataTituloEncuentranos">
                                {
                                    "Encuéntranos"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxContenidoDataUbicacionEncuentranos">
                            <Box id="BoxIconoUbicacionContactanos">
                                <IconButton onClick={null} id="IconBtnUbicacionContactanos">
                                    <UbicacionIcon id="IconoUbicacionContactanos" />
                                </IconButton>
                            </Box>
                            <Box id="BoxUbicacionEmpresaTexto">
                                <Typography id="TxtUbicacionEmpresa">
                                    {
                                        "Av. Francisco Bilbao 2942. Providencia. Santiago, Chile."
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Box id="BoxContenidoDataVentasSoporteEncuentranos">
                            <Box id="BoxContenidoDataSoporteEncuentranos">
                                <Box id="BoxContenidoDataSoporteTituloEncuentranos">
                                    <Typography id="TxtTituloSoporteEncuentranos">
                                        {
                                            "Área de soporte"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxContenidoDataSoporteCorreoEncuentranos">
                                    <Typography id="TxtCorreoSoporteEncuentranos">
                                        {
                                            "soporte@controlpass.cl"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxContenidoDataSoporteTelefonoEncuentranos">
                                    <Typography id="TxtTelefonoSoporteEncuentranos">
                                        {
                                            "+56 9 5378 5399"
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                            <Box id="BoxContenidoDataVentasEncuentranos">
                                <Box id="BoxContenidoDataVentasTituloEncuentranos">
                                    <Typography id="TxtTituloSoporteEncuentranos">
                                        {
                                            "Área de ventas"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxContenidoDataVentasCorreoEncuentranos">
                                    <Typography id="TxtCorreoSoporteEncuentranos">
                                        {
                                            "jsimanca@automatismoslau.cl"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxContenidoDataVentasTelefonoEncuentranos">
                                    <Typography id="TxtTelefonoSoporteEncuentranos">
                                        {
                                            "+56 9 6159 0010"
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box id="BoxContenidoDataRedesSocialesEncuentranos">
                            <Box id="BoxIconoPaginaControlpass">
                                <IconButton id="IconBtnRrssCp" onClick={HandleIconCP}>
                                    <BotonAControlPass />
                                </IconButton>
                            </Box>
                            <Box id="BoxIconoInstagramControlpass" >
                                <IconButton id="IconBtnRrssCp" onClick={HandleIconInsta}>
                                    <IconoInstagram />
                                </IconButton>
                            </Box>
                            <Box id="BoxIconoFacebookControlpass">
                                <IconButton id="IconBtnRrssCp" onClick={HandleIconFB}>
                                    <IconoFacebook />
                                </IconButton> 
                            </Box>
                            <Box id="BoxWhatsAppPaginaControlpass">
                                <IconButton id="IconBtnRrssCp" onClick={HandleIconWA}>
                                    <IconoWhatsApp />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}