import { Box, Button, Modal, Typography } from "@mui/material";
import "./HistorialEventos.css";
import { AbrirHistorialEventosIcon, RelogHistorialEventos } from "./HistorialEventosIcons";

import { Historial } from "./Historial";



const HistorialEventos = ({ heOpen, heWidth, heHeight, setHEOpen }) => {
    


    const style = {
        width: `${heWidth}px`,
        height: `${heHeight}px`,
        position: "absolute",
        right: "28px",
        top: "500px",
        left: "auto",
        background: "#fff",
        borderRadius: "16px",
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px"
    };

    const handleOpen = () => {
        setHEOpen(true);
    }
    const handleClose = () => {
        setHEOpen(false);
    }

    return (
        heOpen ?
            <Modal slotProps={{ backdrop:{sx:{background:"transparent"}} }} id="ModalHistorialEventos" onClose={handleClose} open={heOpen} >
                <Box id="BoxHistorialEventos" sx={{...style}}>
                    <Historial setOpen={setHEOpen} />
                </Box>
            </Modal>
            :
            <Button id="BotonMostrarHistorialEventos" onClick={handleOpen} sx={{ width: `${heWidth}px`, height: `${heHeight}px` }} startIcon={<RelogHistorialEventos id="RelojHistorialEventosCerrado" />} endIcon={<AbrirHistorialEventosIcon id="IconoAbrirHistorialEventos"/>} >
                <Typography id="TextoBotonHistorialEventos">
                    {
                        "Eventos agendados"
                    }
                </Typography>
            </Button>
    );
}

export default HistorialEventos;