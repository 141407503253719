import { SvgIcon } from "@mui/material";

export function IconoAgregarNuevaRow( props ){
    return(
        <SvgIcon {...props}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_4478_5666)">
                <rect x="4" y="3" width="40" height="40" rx="20" fill="#F8F8F8"/>
                <rect x="4.75" y="3.75" width="38.5" height="38.5" rx="19.25" stroke="currentColor" strokeWidth="1.5"/>
                <path d="M16 23H32M24 15V31" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <filter id="filter0_dd_4478_5666" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4478_5666"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4478_5666"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_4478_5666" result="effect2_dropShadow_4478_5666"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4478_5666" result="shape"/>
                </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
}


export function IconoCargaMasiva(props){
    return(
        <SvgIcon {...props}>
           <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_4478_128934)">
                <rect x="4" y="4" width="40" height="40" rx="20" fill="#F8F8F8"/>
                <rect x="4.75" y="4.75" width="38.5" height="38.5" rx="19.25" stroke="currentColor" strokeWidth="1.5"/>
                <path d="M32 25V30C32 31.1046 31.1046 32 30 32H18C16.8954 32 16 31.1046 16 30L16 25M28 20L24 16M24 16L20 20M24 16L24 28" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <filter id="filter0_dd_4478_128934" x="0" y="1" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4478_128934"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4478_128934"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_4478_128934" result="effect2_dropShadow_4478_128934"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4478_128934" result="shape"/>
                </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
}


export function IconoDescargar(props){
    return(
        <SvgIcon  {...props}>            
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_6242_10394)">
                <rect x="4" y="3" width="40" height="40" rx="20" fill="#F8F8F8"/>
                <rect x="4.75" y="3.75" width="38.5" height="38.5" rx="19.25" stroke="#175676" strokeWidth="1.5"/>
                <path d="M32 26V29C32 30.1046 31.1046 31 30 31H18C16.8954 31 16 30.1046 16 29L16 26M20 22L24 26M24 26L28 22M24 26V14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <filter id="filter0_dd_6242_10394" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_6242_10394"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6242_10394"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_6242_10394" result="effect2_dropShadow_6242_10394"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6242_10394" result="shape"/>
                </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
}