import { Box, Typography } from "@mui/material";

const UnderConstruction = () => {
    return(
        <Box border={1} sx={{display:"flex", 
            width:"1520px",
            height: "100%",
            flexDirection:"column",
            justifyItems:"center", 
            alignContent:"center", 
            textAlign:"center"}}>
            <Typography sx={{height: "100%"}}>
                {
                    "Pagina en Construccion! *-*"
                }
            </Typography>
        </Box>
    );
}


export default UnderConstruction;