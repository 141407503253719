import { SvgIcon } from "@mui/material";

export function IconoCerrar(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M17 1L1 17M1.00002 1L17 17" stroke="#626262" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}


export function IconoBorrarCampo(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M14 6L6 14M6.00001 6L14 14M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#8A8A8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}


export function IconoEditarCorreo(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M10.8824 5.94118L13 3.82353L10.1765 1L8.05882 3.11765M10.8824 5.94118L3.82353 13H1V10.1765L8.05882 3.11765M10.8824 5.94118L8.05882 3.11765" stroke="#626262" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}