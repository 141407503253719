import { Box, Divider, MenuItem } from "@mui/material";
import { useState } from "react";
import useNotificaciones from "../../../../../Hooks/Notificaciones/useNotificaciones";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import ModalNotificacion from "../ModalNotificacion";
import { LoadingMasRespuestaCinco } from "../../../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../../../Modal/RespuestaServidor/RespuestaServidorIcon";

export const Opciones = ({notificacion}) => {
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [openMensaje, setOpenMensaje] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const {EliminarRegistro, loading, setLoading, response} = useNotificaciones()

    const handleOpen = () => {
        setOpenMensaje(true);
    }

    const handleDelete = async() => {
        var response = await confirm();

        if(response)
        {
            setLoading(true);
            setOpenLoading(true);
            await EliminarRegistro(notificacion.idHistorial).then(
                (resolve) => {
                    setTimeout(() => {
                        setOpenLoading(false);
                    }, 5000);
                }
                ,
                (reject) => {
                    console.log("fallo la eliminacion");
                }
            )
        }
        else {
            console.log("cancela la eliminacion");
        }
    }
  
    return(
        <Box>
            <MenuItem onClick={handleOpen}>Ver </MenuItem>
            <Divider orientation="horizontal"/>
            <MenuItem onClick={handleDelete}>Eliminar </MenuItem> 
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={"¿Eliminar?"}
            mensaje={"Se eliminara este registro, esta accion no se puede deshacer y no podra recuperar este mensaje ¿Continuar?"}
            />
            <ModalNotificacion open={openMensaje} setOpen={setOpenMensaje} notificacion={notificacion} />
            <LoadingMasRespuestaCinco
            open={openLoading} 
            setOpen={setOpenLoading} 
            msj={"Eliminado el registro, esta accion puede tardar uno momentos..."} 
            id={"RespuestaGuardarEditarUsuario"} 
            tituloLoading={"Eliminando"}
            loading={loading} 
            respuesta={response} 
            icono={
                response === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                response === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            titulo={response === 200 ? "Eliminado":"Error al eliminar"}
            mensajeRespuesta={response === 200 ? 
                "El registro del mensaje se ha eliminado del sistema"
                :
                "Ha ocurrido un error y no se ha podido eliminar el registro. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."}
            />
        </Box>
        
    );
}