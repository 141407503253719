import { Box, TextField, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import "./ListaNegra.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { ValidationIngresoListaNegra } from "./Validation";
import useListaNegra from "../../../../../Hooks/ListaNegra/useListaNegra";
import { useSelector } from "react-redux";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";

export const AgregarListaNegra = ({setOpen, userData, setInfo}) => {
    const data = useSelector(action => action.user.authenticated);
    const {loading, setLoading, response, AgregarLN} = useListaNegra();
    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const handleClose = () => {
        setOpen(false);
    }

    const ActualizarInfo = (motivo, nuevaId) => {
        
        const aMostrar = {
            idListaNegra: nuevaId,
            fechaIngreso: new Date(Date.now()),
            nombreCreador: data.usuario,
            motivoIngreso: motivo
        }
        setTimeout(() => {
            setInfo(aMostrar);
            setOpen(false);
            setOpenLoading(false);
        }, 2000);
        
    }

    const formik = useFormik({
        initialValues:{motivo:""},
        validationSchema:ValidationIngresoListaNegra,
        onSubmit:
        async () => {
            setLoading(true);
            setOpenLoading(true);
            await AgregarLN(userData.rut, userData.pasaporte, data.idPersona, formik.values.motivo, data.idEmpresa)
            .then(
                (resolve) => {
                    ActualizarInfo(formik.values.motivo, resolve);
                }
                ,
                (reject) => {
                    console.log(reject);
                }
            );
        }
    });

    return (
        <LayoutCrearNuevo titulo={"Agregar a lista negra"}>
            <Box id="BoxLayoutAgregarListaNegraGU">
                <Box id="BoxDataCreacionAgregaListaNegraGU">
                    <Box id="BoxTituloDataAgregaListaNegraGU">
                        <Typography id="TxtTiuloAgregarListaNegraGU">
                            {
                              "Información de ingreso"  
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxMotivoAgregaListaNegraGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <TextField 
                        variant="filled" 
                        fullWidth 
                        placeholder="Motivo..."
                        label="Motivo del Ingreso" 
                        name={"motivo"}
                        multiline 
                        rows={3}
                        value={formik.values.motivo}
                        onChange={formik.handleChange}
                        helperText={formik.errors.motivo}
                        />
                    </Box>
                </Box>
                <Box id="BoxAccionesAgregarListaNegraGU">
                    <Box id="BoxBtnCancelarAgregarListaNegraGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAgregarAgregarListaNegraGU">
                        <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCinco
                        open={openLoading}
                        setOpen={setOpenLoading}
                        msj={"Se está agregando el usuario a la lista negra. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        id={"LoadingAgregaListaNegraGU"}
                        tituloLoading={"Agregando a lista negra..."}
                        respuesta={response}
                        loading={loading}
                        icono={
                            response === 201 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={response === 201 ? 
                            "Aceptar"
                            :
                            "Reintentar"
                        }
                        titulo={response === 201 ? 
                            "Usuario agregado"
                            :
                            "Usuario no agregado"
                        }
                        mensajeRespuesta={response === 201 ? 
                            "El usuario ha sido agregado exitosamente a la lista negra, restringiendo su acceso según las políticas de seguridad."
                            :
                            "No se ha podido agregar el usuario a la lista negra debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                        }
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}