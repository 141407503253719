import { Box, FormHelperText, IconButton, Modal, Typography } from '@mui/material';
import React, {useState } from 'react';
import { useFormik } from 'formik';
import { BotonTipo2 } from '../Botones/BotonesTabla/Botones';
import { IconCerrarAccesos } from './IconsGestionAccesos';
import { ValidationNuevoAcceso } from './NuevoActuadorValidation';
import { AutocompleteForm } from '../Autocomplete/AutocompleteForm';
import useUsuarios from '../../Hooks/Usuarios/useUsuarios';
import useGestZonasEmpresa from '../../Hooks/Zona/useGetZonasEmpresa';
import { LoadingMasRespuestaCuatro } from '../Modal/LoadingMasRespuesta/LoadingMasRespuesta';
import useGetAll from '../../Hooks/Usuarios/useGetAll';

    const ModalNuevoAcceso = ({ open, setOpen, accesos, loading, setLoading, crearNuevoAcceso, agregarNuevoAcceso, response }) => {

    const {usuarios} = useUsuarios();
    const {listaUsuarios: usuariosNombreRut} = useGetAll(); //usuarios obtenidos con el hook useGetAll, la idea es que este sea la fuente original

    const {zonas, zonasFormadas} = useGestZonasEmpresa(); //zonasFormadas no sirve para esto, usar solo zonas

    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const formik = useFormik({
        initialValues:{ 
            idPersona: -1, 
            idZona: -1
        },
        validationSchema: ValidationNuevoAcceso,
        onSubmit: async () => {
            setLoading(true);
            setOpenLoading(true);
            let datos = {
                "idPersona": formik.values.idPersona,
                "idZona": formik.values.idZona,
            }
            await crearNuevoAcceso(datos.idZona, datos.idPersona)
            .then(
                (result) => {
                    // Se obtienen los datos de la persona y la zona que fue agregada y se crea su objeto
                    const persona = usuarios.find(usuario => usuario.idPersona === datos.idPersona);
                    const zona = zonas.find(zona => zona.idZona === datos.idZona);
                    const nuevoAcceso = {
                                    idAcceso: result,
                                    estado: true,
                                    estadoPersona: true,
                                    estadoZona: true,
                                    idPersona: persona.idPersona,
                                    idZona: zona.idZona,
                                    nombrePersona: `${persona.nombres} ${persona.apellidos}`,
                                    nombreZona: zona.nombreZona,
                                    pasaporte: persona.pasaporte,
                                    rut: persona.rut,
                                    isNew: true
                                }

                    agregarNuevoAcceso(nuevoAcceso);
                },
                (error) => {
                    console.log("Error al agregar el nuevo acceso: ",error);
                }
            )   
        },
        onReset: () => {     
        },
    });
    
    // const [valorAutocompleteZonas, setValorAutocompleteZonas] = useState("");

    //NotaAlberto: revisar esta funcion
    // useEffect(() => {

    //     console.log("useEffect activado, formik.values.idPersona: ", formik.values.idPersona);

    //     // setValorAutocompleteZonas("Zonas");
    //     formik.setFieldValue("idZona", -1);
        
    //     // zonas.filter((zona)=>{
            
    //     // })
    // }, [formik.values.idPersona]);

    const handleChangePersona = (opcion) => {
        formik.setFieldValue('idPersona', opcion);
        formik.setFieldValue("idZona", -1);
    }

    const handleAgregarOtro = () => {
        formik.resetForm();
        setOpenLoading(false);
    }

    const handlerClose = () => {
        setOpen(false);
        formik.resetForm();
    }

    return (
        <Modal open={open} onClose={handlerClose}>
            <Box sx={{ ...style }}>
                <Box id="CabeceraGestionAccesos">
                    <IconButton onClick={handlerClose}>
                        <IconCerrarAccesos />
                    </IconButton>
                </Box>
                <Box id="CuerpoModalAgregarAcceso">
                    <Box id="BoxTituloSubtituloOpcionGestionAcceso">
                        <Box id="BoxTituloOpcionGestionAcceso">
                            <Typography id="TxtTituloOpcionGestionAcceso">
                                {
                                    "Agregar Acceso"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxSubTituloOpcionGestionAcceso">
                            <Typography id="TxtSubTituloOpcionGestionAcceso">
                                {
                                    "Todos los campos son obligatorios para completar el registro."
                                }
                            </Typography>
                        </Box>
                    </Box>

                    <Box id="BoxFormularioOpcionGestionAcceso">
                        <Box id="BoxContenidoFormularioOpcionGestionAcceso" component={"form"} onSubmit={formik.handleSubmit}>
                            <Box id="BoxInformacionOpcionGestionAcceso">
                                <Box id="BoxTituloInformacionOpcionGestionAcceso">
                                    <Typography id='TxtTitutlosFormulariosOpcionGestionAcceso'> 
                                        {
                                        "Información del usuario"
                                        } 
                                    </Typography>
                                </Box>
                                <Box id="BoxOpcionesFormularioGestionAcceso">
                                    <AutocompleteForm 
                                        options={usuariosNombreRut} 
                                        idName={"idPersona"} 
                                        labelName={"nombreRut"} 
                                        // setIdSelectedOption={(entrada) => formik.setFieldValue('idPersona', entrada)} 
                                        setIdSelectedOption={(opcion) => handleChangePersona(opcion)}  
                                        placeholder={"Seleccionar persona"}
                                    />
                                    <FormHelperText>{formik.errors.idPersona}</FormHelperText>
                                </Box>
                            </Box>
                            <Box id="BoxSegundaInformacionOpcionGestionAcceso">
                                <Box id="BoxTituloInformacionOpcionGestionAcceso">
                                    <Typography id='TxtTitutlosFormulariosOpcionGestionAcceso'> 
                                        {
                                        "Información zona"
                                        } 
                                    </Typography>

                                </Box>
                                <Box id="BoxOpcionesFormularioGestionAcceso">                                
                                    <AutocompleteForm 
                                        options={zonasFormadas} 
                                        idName={"id"} 
                                        labelName={"tipo"} 
                                        // setIdSelectedOption={(entrada) => formik.setFieldValue('idZona', entrada)} 
                                        setIdSelectedOption={(entrada) => formik.setFieldValue('idZona', entrada)} 
                                        placeholder={"Seleccionar zona"}
                                    />
                                    <FormHelperText>{formik.errors.idZona}</FormHelperText>
                                </Box>
                            </Box>
                        </Box>

                        <Box id="BoxHandlerFormularioOpcionGestionAcceso">
                            <Box id="BoxTamanoBotonHandler">
                                <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                            </Box>

                            <Box id="BoxTamanoBotonHandler">
                                <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} disabled={loading} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                    <LoadingMasRespuestaCuatro
                        id={"LoadingMasRespuestaCuatroNuevoAcceso"}
                        open={openLoading}
                        setOpen={setOpenLoading}
                        msj={
                        "Se está agregando una nuevo acceso al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                        }
                        tituloLoading={"Agregando acceso..."}
                        loading={loading}
                        respuesta={response}
                        titulo={
                            response === 201
                            ? "Acceso agregado"
                            : "Acceso no agregado"
                        }
                        mensajeRespuesta={
                            response === 201
                            ? "El acceso ha sido agregado exitosamente al sistema"
                            : "No se ha podido agregar el acceso debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                        }
                        txtBotonUno={"Agregar otro acceso"}
                        eventBotonUno={handleAgregarOtro}
                    />
            </Box>
        </Modal>
    );
}

export default ModalNuevoAcceso;

const style = {
    width: "1024px",
    height: "570px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
  };

