import { Popover } from "@mui/material";

export const PopOverTipoUno = ({open, anchorEl, setOpen, children}) =>{

    const handleClose = () => {
        setOpen(null);
    } 

    return(
        <Popover
        id={'simple-popover'}
        slotProps={{
            paper:{
                sx: {
                    backgroundColor:"#fff",
                    borderRadius:"16px"
                }
            }
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
        }}
        >
            {
                children
            }
        </Popover>
    );
}


export const PopOverTipoDos = ({open, anchorEl, setOpen, children}) =>{

    const handleClose = () => {
        setOpen(null);
    } 

    return(
        <Popover
        id={'simple-popover'}
        slotProps={{
            paper:{
                sx: {
                    backgroundColor:"#fff",
                    borderRadius:"16px"
                }
            }
        }}
        sx={{
            position:"absolute",
            left: "-180px",
            right: "0px",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        >
            {
                children
            }
        </Popover>
    );
}


export const PopOverTipoReportes = ({open, anchorEl, setOpen, children}) =>{

    const handleClose = () => {
        setOpen(null);
    } 

    return(
        <Popover
        id={'simple-popover'}
        slotProps={{
            paper:{
                sx: {
                    backgroundColor:"#fff",
                    borderRadius:"16px"
                }
            }
        }}
        sx={{
            position:"absolute",
            left: "-140px",
            right: "0px",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        >
            {
                children
            }
        </Popover>
    );
}


export const PopOverTipoTarjetaDetalleEventos = ({open, anchorEl, setOpen, children}) =>{

    const handleClose = () => {
        setOpen(null);
    } 

    return(
        <Popover
        id={'simple-popover'}
        slotProps={{
            paper:{
                sx: {
                    backgroundColor:"#fff",
                    borderRadius:"16px"
                }
            }
        }}
        sx={{
            position:"absolute",
            left: "-290px",
            right: "0px",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        >
            {
                children
            }
        </Popover>
    );
}


export const PopOverNuevoEvento = ({open, anchorEl, setOpen, children}) =>{

    const handleClose = () => {
        setOpen(null);
    } 

    return(
        <Popover
        id={'simple-popover'}
        slotProps={{
            paper:{
                sx: {
                    backgroundColor:"#fff",
                    borderRadius:"16px"
                }
            }
        }}
        sx={{
            position:"absolute",
            left: "-21px",
            right: "0px",
            bottom: "0px",
            top: "-80px",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        >
            {
                children
            }
        </Popover>
    );
}


export const PopOverTipoTres= ({open, anchorEl, setOpen, children}) =>{

    const handleClose = () => {
        setOpen(null);
    } 

    return(
        <Popover
        id={'simple-popover'}
        slotProps={{
            paper:{
                sx: {
                    backgroundColor:"#fff",
                    borderRadius:"16px"
                }
            }
        }}
        anchorOrigin={
            {
                vertical: "top",
                horizontal: "right",
            }
        }
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        >
            {
                children
            }
        </Popover>
    );
}


export const PopOverTipoEventosDetalleAgenda = ({open, anchorEl, setOpen, children}) =>{

    const handleClose = () => {
        setOpen(null);
    } 

    return(
        <Popover
        id={'simple-popover'}
        slotProps={{
            paper:{
                sx: {
                    backgroundColor:"#fff",
                    borderRadius:"16px"
                }
            }
        }}
        sx={{
            position: "absolute",
            top: "-150px",
            left: "50px",
            right: "0px",
            bottom: "0px",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        >
            {
                children
            }
        </Popover>
    );
}