import { Box, IconButton, Modal, Typography } from "@mui/material";
import "./MiembroInformacion.css";
import { CerrarModalNuevoUsuario } from "../../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import { BotonTipo2 } from "../../../Botones/BotonesTabla/Botones";
import { TextFieldTres } from "../../../TextField/TextFieldTres";

const style = {
    width: "1024px",
    height: "680px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};

export const MiembroInformacion = ({open, setOpen, usuario}) => {
    const nombreCompleto = usuario.nombres + " " + usuario.apellidos
    const tipoUsuario = usuario.tipo ?? "No encontrado"
    const pasaporte = usuario.pasaporte ?? "-"
    const rut = usuario.rut ?? "-"
    const correo = usuario.correo ?? "-"
    const telefono = usuario.telefono ?? "-"


    const handlerClose = () => {
        setOpen(false);
    }

    return(
        <Modal open={open} onClose={handlerClose}>
            <Box sx={{...style}}>
                <Box id="BoxCabeceraMiembrosUbicacion">
                    <IconButton onClick={handlerClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>                
                <Box id="BoxContenidoMiembrosUbicacion">
                    <Box id="BoxTituloInformacionMiembros">
                        <Typography id="TxtTituloOpcionNuevaUbicacion">
                            {
                                "Información del usuario"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxInformacionMiembros">
                        <Box id="BoxInformacionPersonalContacto">
                            <Box id="BoxInformacionPersonalUsuario">
                                <Box id="BoxTituloInformacionPersonalMiembro">
                                    <Typography id="TituloInformacionMiembro">
                                        {
                                            "Información personal"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxTextFieldsInformacionPersonalMiembro">
                                    <Box id="BoxNombreCompletoTipoInformacionPersonalMiembro">
                                        <Box id="BoxNombreCompletoInformacionPersonalMiembro">
                                            <TextFieldTres
                                                id={"nombreUsuarioInformacionPersonal"}
                                                label={"Nombre Completo"}
                                                placeholder={"Automatismos LAU"}
                                                icon={null}
                                                helper={null}
                                                value={nombreCompleto}
                                                name={"nombreUsuarioInformacionPersonal"}
                                                handlerChange={null}
                                                handlerClick={null} 
                                                disabled={true}                                            
                                            />
                                        </Box>
                                        <Box id="BoxTipoUsuarioInformacionPersonalMiembro">
                                            <TextFieldTres
                                                id={"nombreUsuarioInformacionPersonal"}
                                                label={"Tipo de Usuario"}
                                                placeholder={"Automatismos LAU"}
                                                icon={null}
                                                helper={null}
                                                value={tipoUsuario}
                                                name={"nombreUsuarioInformacionPersonal"}
                                                handlerChange={null}
                                                handlerClick={null} 
                                                disabled={true}                                            
                                            />
                                        </Box>
                                    </Box>
                                    <Box id="BoxRutPasaporteInformacionPersonalMiembro">
                                        <Box id="BoxRutInformacionPersonalMiembro">
                                            <TextFieldTres
                                                id={"nombreUsuarioInformacionPersonal"}
                                                label={"RUT"}
                                                placeholder={"11111111-1"}
                                                icon={null}
                                                helper={null}
                                                value={rut}
                                                name={"nombreUsuarioInformacionPersonal"}
                                                handlerChange={null}
                                                handlerClick={null} 
                                                disabled={true}                                            
                                            />
                                        </Box>
                                        <Box id="BoxPasaporteInformacionPersonalMiembro">
                                            <TextFieldTres
                                                id={"nombreUsuarioInformacionPersonal"}
                                                label={"Pasaporte"}
                                                placeholder={"000000000000000"}
                                                icon={null}
                                                helper={null}
                                                value={pasaporte}
                                                name={"nombreUsuarioInformacionPersonal"}
                                                handlerChange={null}
                                                handlerClick={null} 
                                                disabled={true}                                            
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box id="BoxInformacionContactoUsuario">
                                <Box id="BoxTituloInformacionContactoMiembro">
                                    <Typography  id="TituloInformacionMiembro">
                                        {
                                            "Información de contacto"
                                        }
                                    </Typography>
                                </Box>
                                <Box id="BoxTextFieldsInformacionContactoMiembro">
                                    <Box id="BoxCorreoInformacionContactoMiembro">
                                        <TextFieldTres
                                        id={"nombreUsuarioInformacionPersonal"}
                                        label={"Correo"}
                                        placeholder={"ejemplo@ejemplo.cl"}
                                        icon={null}
                                        helper={null}
                                        value={correo}
                                        name={"nombreUsuarioInformacionPersonal"}
                                        handlerChange={null}
                                        handlerClick={null} 
                                        disabled={true}                                            
                                        />
                                    </Box>
                                    <Box id="BoxTelefonoInformacionContactoMiembro">
                                        <TextFieldTres
                                        id={"nombreUsuarioInformacionPersonal"}
                                        label={"Telefono"}
                                        placeholder={"ejemplo@ejemplo.cl"}
                                        icon={null}
                                        helper={null}
                                        value={telefono}
                                        name={"nombreUsuarioInformacionPersonal"}
                                        handlerChange={null}
                                        handlerClick={null} 
                                        disabled={true}                                            
                                        />
                                    </Box>
                                </Box>
                            </Box>  
                        </Box>

                        <Box id="BoxBotonesAccionInformacionUsuario">
                            <Box id="BoxBotonNuevoUsuario">
                                <BotonTipo2
                                    textoBoton={"Aceptar"}
                                    event={handlerClose}
                                />
                            </Box>
                        </Box>                      
                    </Box> 
                </Box>
            </Box>
        </Modal>
    );
}