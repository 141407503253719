import { Box, IconButton, Typography } from "@mui/material";
import { IconoAgregarRowEnGU } from "./Icons";
import "./LayoutMenuGU.css";
import TooltipTipoUno from "../../ToolTip/Tooltip";

export const LayoutMenuOpcion = ({titulo, children, showIcon, handleIcon}) => {

    const handleCreate = () =>{
        handleIcon(true);
    }

    return (
        <Box id="BoxLayoutMenuOpcionGU">
            <Box id="BoxLayoutSinCSS">
                <Box id="BoxTextTituloLayoutMenuOpcionGU">
                    <Typography id="TxtLayoutTituloGU">
                        {
                            titulo
                        }
                    </Typography>
                    {   
                        showIcon ?
                        <TooltipTipoUno titulo={"Agregar Nuevo"} ubicacion={"bottom"}>
                            <IconButton id="IconEnTitulo" onClick={handleCreate}>
                                <IconoAgregarRowEnGU id="IconoAgregarNuevo" />
                            </IconButton>
                        </TooltipTipoUno>
                        
                        :
                        null
                    }
                </Box>
            </Box>
            <Box id="BoxTablaAccesosGU">
            {
                children
            }
            </Box>
        </Box>
    );
}