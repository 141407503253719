import { Box, CardMedia, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import "./Huella.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import HuellaAH from "../../../../../assets/imagenes/Huella.png";
import { Dedos } from "./Dedos";
import { useFormik } from "formik";
import { ValidationNuevaHuella } from "./Validation";
import useHuellas from "../../../../../Hooks/Huellas/useHuellas";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";



export const NuevaHuella =  ({setOpen, setRows, actuadores, user}) => {
    const {loading, setLoading, CrearNueva, response} = useHuellas();
    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const handleClose = () => {
        setOpen(false);
    }

    const handleCloseLoading = () => {
        setOpenLoading(false);
    }

    const AgregarRow = (values) => {
        const dedoEscogio = Dedos.find((d)=> d.id === values.registro)

        setRows((oldRows) => [...oldRows, 
            {
                idHuella: values.idHuella, 
                registro: dedoEscogio.dedo,
                codigo: values.codigo,
                isNew: true 
            }
        ]);

        setTimeout(() => {
            handleClose()
            handleCloseLoading()
        }, 3000);
    }


    const formik = useFormik({
        initialValues:{
            dedo: 0, 
            lector: ''
        },
        validationSchema:ValidationNuevaHuella,
        onSubmit:
        async () => {
            setLoading(true);
            setOpenLoading(true);
            await CrearNueva(formik.values, user)
            .then(
                (resolve) => {
                    AgregarRow(resolve)
                },
                (reject) => {
                    console.log(reject);
                }
            )
        }
    })
    

    return (
        <LayoutCrearNuevo titulo={"Agregar huella"}>
            <Box id="BoxLayoutAgregarHuellaGU">
                <Box id="BoxImagenContenidoAgregarHuellaGU">
                    <Box id="BoxImgAgregarHuellaGU">
                        <CardMedia id="ImgAgregarHuellaGU" component={"img"} src={HuellaAH} />
                    </Box>
                    <Box id="BoxContenidoAgregarHuellaGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <Box id="BoxDedoLectorAgregarHuellaGU">
                            <Box id="BoxSelectDedoAgregarHuellGU">
                                <FormControl fullWidth>
                                    <InputLabel id="LblDedo">
                                    {
                                        "Seleccionar dedo"
                                    }
                                    </InputLabel>
                                    <Select 
                                    fullWidth 
                                    variant="outlined"
                                    labelId="LblDedo"
                                    label="Seleccionar dedo"
                                    value={formik.values.dedo}
                                    onChange={formik.handleChange}
                                    name="dedo"
                                    >
                                    {
                                        Dedos.map((item, index) => {
                                            return (
                                                <MenuItem disableRipple key={index} value={item.id} id="MiDedoGU" >
                                                    <Box id="BoxDedoGU">
                                                        <Typography>
                                                            {
                                                                item.dedo
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                             );
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box id="BoxSelectLectorAgregarHuellGU">
                                <FormControl fullWidth>
                                    <InputLabel id="LblLectorHuella">
                                    {
                                        "Seleccione Lector"
                                    }
                                    </InputLabel>
                                    <Select 
                                    fullWidth 
                                    variant="outlined"
                                    labelId="LblLectorHuella"
                                    label="Seleccione Lector"
                                    name="lector"
                                    value={formik.values.lector}
                                    onChange={formik.handleChange}
                                    >
                                    {
                                        actuadores.map((item, index) => {
                                            return (
                                                <MenuItem disableRipple key={index} value={item.idActor} id="MiDedoGU" >
                                                    <Box id="BoxDedoGU">
                                                        <Typography>
                                                            {
                                                                item.nombreActor
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                             );
                                        })
                                    }
                                    </Select>
                                    <FormHelperText>
                                        {
                                            formik.errors.lector
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesAgregarHuellaGU">
                    <Box id="BoxBtnCancelarAgregarHuellaGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAgregarAgregarHuellaGU">
                        <BotonTipo2 textoBoton={"Capturar y Guardar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCinco
                        id={"LoadingMasRespuestaCincoNuevaHuella"}
                        open={openLoading}
                        icono={
                            response === 201 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={response === 201 ?
                            "Aceptar" 
                            :
                            "Reintentar"
                        }
                        setOpen={setOpenLoading}
                        msj={"Se está agregando la nueva huella al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        tituloLoading={"Agregando huella..."}
                        loading={loading}
                        titulo={response === 201 ? "Huella agregada":"Huella no agregada"}
                        mensajeRespuesta={response === 201 ? 
                            "El nuevo registro de huella dactilar ha sido agregado exitosamente"
                            :
                            "No se ha podido agregar el nuevo registro de huella dactilar debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia"}
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}