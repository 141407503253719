import axios from "axios";
import { useState } from "react";
import { BuscarEstadoVisitante, EditarVisitante } from "../../Service/API/Api";


function useInvitados()
{
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();
    
    const ActualizarInformacionInvitado = (visitante, idVisitante) => {
        return new Promise(async (resolve, reject) => {
            const visitanteNewData = {
                "idVisitante": idVisitante,
                "rut": visitante.rut === "-" || visitante.rut === ""? null: visitante.rut,
                "pasaporte": visitante.pasaporte === "-" || visitante.pasaporte === "" ? null: visitante.pasaporte,
                "nombres": visitante.nombres,
                "apellidos": visitante.apellidos,
                "correo": visitante.correo,
                "telefono": visitante.telefono === "-" || visitante.telefono === ""? null: visitante.telefono,
                "patenteDigitos": visitante.patente === "-" || visitante.patente === "" ? null : visitante.patente
            }

            axios.put(EditarVisitante, visitanteNewData)
            .then(
                (response) => {
                    resolve(true)
                    setResponse(response.status)
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setResponse(error.response.status)
                    setLoading(false);
                }
            )
        });
    }

    const ActualizarEstado = (idInvitado, nuevoEstado, eventos) => {

        return new Promise(async (resolve, reject) => {
            let lista = []
            lista.push(eventos)
            const estado = {
                "idInvitado": parseInt(idInvitado),
                "estadoInvitado": nuevoEstado,
                "idEventos": lista
            }
            axios.put(BuscarEstadoVisitante, estado)
            .then(
                (response) => {
                    resolve(true)
                    setResponse(response.status)
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setResponse(error.response.status)
                    setLoading(false);
                }
            )
        });
    }


    return {
        setLoading,
        loading,
        ActualizarInformacionInvitado,
        ActualizarEstado,
        response
    }
}

export default useInvitados;