import { SvgIcon } from "@mui/material";

export function IconoUsuarios(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="0 0 32 28" fill="none">
                <path d="M24.9091 18C27.7207 18 30 20.3878 30 23.3333V26H27.4545M21.0909 12.4986C23.2867 11.9066 24.9091 9.81844 24.9091 7.33332C24.9091 4.8482 23.2867 2.76007 21.0909 2.16801M17.2727 7.33333C17.2727 10.2789 14.9934 12.6667 12.1818 12.6667C9.37019 12.6667 7.09091 10.2789 7.09091 7.33333C7.09091 4.38781 9.37019 2 12.1818 2C14.9934 2 17.2727 4.38781 17.2727 7.33333ZM7.09091 18H17.2727C20.0844 18 22.3636 20.3878 22.3636 23.3333V26H2V23.3333C2 20.3878 4.27928 18 7.09091 18Z" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}


export function IconoOpcionesUsuarios(props){
    return(
        <SvgIcon {...props} className="icono-opciones-usuario">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_4602_129485)">
                <rect x="4" y="3" width="40" height="40" rx="20" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M24 19C25.1 19 26 18.1 26 17C26 15.9 25.1 15 24 15C22.9 15 22 15.9 22 17C22 18.1 22.9 19 24 19ZM24 21C22.9 21 22 21.9 22 23C22 24.1 22.9 25 24 25C25.1 25 26 24.1 26 23C26 21.9 25.1 21 24 21ZM22 29C22 27.9 22.9 27 24 27C25.1 27 26 27.9 26 29C26 30.1 25.1 31 24 31C22.9 31 22 30.1 22 29Z" fill="currentColor"/>
                <rect x="4" y="3" width="40" height="40" rx="20" className="fondo-icono-opciones-usuario"/>
                <path className="trazo-icono-opciones-usuario" d="M24 19C25.1 19 26 18.1 26 17C26 15.9 25.1 15 24 15C22.9 15 22 15.9 22 17C22 18.1 22.9 19 24 19ZM24 21C22.9 21 22 21.9 22 23C22 24.1 22.9 25 24 25C25.1 25 26 24.1 26 23C26 21.9 25.1 21 24 21ZM22 29C22 27.9 22.9 27 24 27C25.1 27 26 27.9 26 29C26 30.1 25.1 31 24 31C22.9 31 22 30.1 22 29Z"/>
                </g>
                <defs>
                <filter id="filter0_dd_4602_129485" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4602_129485"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4602_129485"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_4602_129485" result="effect2_dropShadow_4602_129485"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4602_129485" result="shape"/>
                </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
}

export function IconoEditarUsuario(props) {
    return (
        <SvgIcon {...props} className="icono-editar-usuario">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_1271_19891)">
                    <rect x="4" y="3" width="40" height="40" rx="20" className="fondo-icono-editar-usuario" />
                    <path d="M30 21L33 18L29 14L26 17M30 21L20 31H16V27L26 17M30 21L26 17"
                        className="trazo-icono-editar-usuario" />
                </g>
                <defs>
                    <filter id="filter0_dd_1271_19891" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1271_19891" />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="1.5" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1271_19891" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                        <feBlend mode="normal" in2="effect1_dropShadow_1271_19891" result="effect2_dropShadow_1271_19891" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1271_19891" result="shape" />
                    </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
}



export function IconoGuardarCambiosUsuario(props){
    return(
        <SvgIcon {...props}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_3127_100617)">
                <rect x="4" y="3" width="40" height="40" rx="20" fill="white"/>
                <path d="M32.0001 18L21.0001 29L16 24" stroke="#1175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <filter id="filter0_dd_3127_100617" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_3127_100617"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3127_100617"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_3127_100617" result="effect2_dropShadow_3127_100617"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3127_100617" result="shape"/>
                </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
}

export function IconoCancelarCambiosUsuario(props){
    return(
        <SvgIcon {...props}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_3127_100619)">
                <rect x="4" y="3" width="40" height="40" rx="20" fill="white"/>
                <path d="M31 16L17 30M17 16L31 30" stroke="#CC261A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <filter id="filter0_dd_3127_100619" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_3127_100619"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3127_100619"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="1"/>
                <feGaussianBlur stdDeviation="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_3127_100619" result="effect2_dropShadow_3127_100619"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3127_100619" result="shape"/>
                </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
}


export function IconoAlerta(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                <path d="M48 72.3956V72.4444M48 23.5556V57.7778M92 48C92 72.3005 72.3005 92 48 92C23.6995 92 4 72.3005 4 48C4 23.6995 23.6995 4 48 4C72.3005 4 92 23.6995 92 48Z" stroke="#CC261A" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}