import { Box, CardMedia, Dialog, DialogActions, Typography } from '@mui/material';
import { useState } from 'react';
import { BotonTipo2 } from '../../Components/Botones/BotonesTabla/Botones';
import "./useConfirmDialog.css";

const useConfirmDos = () => {
    const [promise, setPromise] = useState(null);
  
    const confirmDos = () => new Promise((resolve, reject) => {
        setPromise({ resolve });
    });
  
    const handleClose = () => {
      setPromise(null);
    };
  
    const handleConfirm = () => {
      promise?.resolve(true);
      handleClose();
    };
  
    const handleCancel = () => {
      promise?.resolve(false);
      handleClose();
    };

    const ConfirmationDialogDos = ({img, titulo, mensaje, usuario}) => (
        <Dialog
        open={promise !== null}
        fullWidth
        PaperProps={{
            sx:{
            width: "324px",
            height: "404px",
            margin: "0px",
            overflowY: 'unset',
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            borderRadius:"16px",
            padding: "80px var(--5, 40px) var(--5, 40px) var(--5, 40px)",
            gap: "var(--4, 32px)",
        }}}
        >   
            <Box id="BoxContenedorConfirmDos">
                <Box id="BoxIMGTituloMensaje">
                    <Box id="BoxImagenConfigmDos">
                        <CardMedia id="IMGConfirmDos" src={img} component={"img"} />
                    </Box>
                    <Box id="BoxTituloMensajeConfirmDos">
                        <Box id="BoxTituloConfirmDos">
                            <Typography id="TituloConfirmDos">
                                {
                                    "¿"+ titulo +"?"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxMensajeConfirmDos">
                            <Typography id="MensajeConfirmDos">
                                {
                                    "¿" + usuario + ", " + mensaje + "?"
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box id="BoxBotonesConfirmDos">
                        <DialogActions sx={{ width:"100%",height:"100%", padding:"0px"}}>
                            <Box id="BoxBotonesAccionesConfirmDos">
                                <Box id="BoxBotonConfirmDos">
                                    <BotonTipo2 textoBoton={"Cancelar"} event={handleCancel} />
                                </Box>
                                <Box id="BoxBotonConfirmDos">
                                    <BotonTipo2 textoBoton={"Aceptar"} event={handleConfirm} />
                                </Box>
                            </Box>
                        </DialogActions>
                    </Box>
                </Box>
            </Box>
            
      </Dialog>
    );
    return [ConfirmationDialogDos, confirmDos];
  };
  
  export default useConfirmDos;