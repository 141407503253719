import axios from "axios";
import { useEffect } from "react";
import { useState } from "react"
import { ActuadorSegunTipo } from "../../Service/API/Api";

export default function useActuadoresTipo(idTipo){
    const [actuadores, setActuadrores] = useState([]);

    useEffect(() => {
        axios.get(ActuadorSegunTipo + idTipo)
        .then(
            (response) => {
                setActuadrores(response.data);
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
            }
        )
    }, [idTipo])
    


    return {
        actuadores
    }
}