import * as Yup from 'yup';

export const esquemaValidacion = Yup.object({
    nombres: Yup.string()
      .required('* Campo requerido'),
    apellidos: Yup.string()
      .required('* Campo requerido'),
    rut: Yup.string()
      .nullable(),
    pasaporte: Yup.string()
      .nullable(),
    telefono: Yup.string()
      .required('* Campo requerido'),
  })