import { Box, CardMedia, IconButton, List, Typography } from "@mui/material";
import "./LeftBar.css";
import lcp2 from "../../assets/imagenes/cp2Menu.png"
import { AbrirMenu, CerrarMenu, CerrarSesion } from "./IconosMenu";
import { MenuIzquierdo } from "./MenuFormado";
import { useLocation } from "react-router-dom";
import useAuth from "../../Hooks/Auth/useAuth";
import { BotonTipo4Menu } from "../Botones/BotonesTabla/Botones";


export const LeftBar = ({NombreUsuarioConectado, handleLeftMenu, isOpen, AnchoActual}) =>{
    const { pathname } = useLocation();
    const {Salir} = useAuth();

    return(
        <Box id="LeftMenu" sx={{ width: `${AnchoActual}px` }}>
            {
                isOpen ?
                <>
                <Box id="ContenedorLogoIconoCierre" sx={{ height: "60px"}}>
                    <Box id="LogoMenu">
                        <CardMedia component={"img"} src={lcp2} id="ImgLogoMenu" />
                    </Box>
                    <Box id="BoxCerrar">
                        <IconButton onClick={handleLeftMenu}>
                            <CerrarMenu id="CerrarMenuIcon" />
                        </IconButton>
                    </Box>
                </Box>
                <Box id="BoxSaludoNombre" sx={{ height: "60px"}}>
                    <Box id="Contendor">
                        <Typography id="MensajeSaludo">
                            {
                                "¡Bienvenido/a!"
                            }
                        </Typography>
                        <Typography id="MensajeNombreUsuario">
                            {
                            NombreUsuarioConectado
                            }
                        </Typography>
                    </Box>
                </Box>
                </>
                :
                <>
                <Box id="ContenedorLogoIconoAbierto" sx={{ height: "60px"}}>
                    <Box id="LogoMenuAbrir">
                        <CardMedia component={"img"} src={lcp2} id="ImgLogoMenu" />
                    </Box>
                    <Box id="BoxAbrir">
                        <IconButton onClick={handleLeftMenu}>
                            <AbrirMenu id="AbriMenuIcon" />
                        </IconButton>
                    </Box>
                </Box>
                </>
            }
           
            <List id="ListaMenu" >
                <MenuIzquierdo  
                pathSelected={pathname} 
                isOpen={isOpen} 
                />
            </List>
            <Box id="BoxCerrarSesion" sx={{ width: `${AnchoActual}px`, display:"flex", justifyContent:"center"}}>
                <BotonTipo4Menu 
                open={isOpen}
                textoBoton={isOpen ? "Cerrar sesión" : null} 
                startIcon={<CerrarSesion />}
                event={Salir}
                />
            </Box>
        </Box>
    );
}

