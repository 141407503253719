import axios from "axios";
import { useEffect, useState } from "react";
import { ListaNegraUsuario } from "../../Service/API/Api";

export default function useListaNegraUsuario(rut,empresa)
{
    const [listaNegra, setListaNegra] = useState({idListaNegra:null, fechaIngreso:null, nombreCreador: null, motivoIngreso: null });

    useEffect(() => {
        const buscar = {
            "rutUsuario": rut,
            "idEmpresa": empresa
        }
        axios.post(ListaNegraUsuario, buscar)
        .then(
            (response) => {
                setListaNegra({
                    idListaNegra: response.data.idListaNegra,
                    fechaIngreso: response.data.fechaIngreso,
                    nombreCreador: response.data.nombreCreador,
                    motivoIngreso: response.data.motivoIngreso
                });
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
            }
        )
    }, [empresa, rut])

    return {
        listaNegra
    }
    
}