import { Box, Input, TextField, Typography } from "@mui/material";
import "./Ubicacion.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { NoData } from "../../../../Box/NoDataMenuGestionUsuario/NoData";

export const DetalleUbicacion = ({informacion, setOpen, openEditar, setEditarInfo}) => {

     
    const handleEditarUbicacion = () => {
        openEditar(true);
        setEditarInfo({
            idPersonaUbicacion: informacion.idPersonaUbicacion,
            idUbicacion: informacion.idUbicacion,
        });
    }

    return(
        informacion.idPersonaUbicacion === null || informacion.idPersonaUbicacion === undefined ?
        <NoData 
        mensaje={"Actualmente, no hay una Ubicación asignada al Usuario, Agregar una Ubicación de destino mejorará la seguridad y eficiencia en el control de accesos. Utiliza el botón Asignar Ubicación para comenzar."}
        textoBtn={"Asignar ubicación"}
        event={setOpen}
        /> 
        :
        <Box id="BoxLayoutConDetalleUbicacionGU">
            <Box id="BoxContenidoConDetalleUbicacionGU">
                <Box id="BoxTituloConDetalleUbicacionGU">
                    <Typography id="TxtTituloConDetalleUbicacionGU">
                        {
                            "Ubicación"
                        }
                    </Typography>
                </Box>
                <Box id="BoxContenidoConDetalleUbicacioGU">
                    <Box id="BoxContenidoNombreTipoConDetalleUbicacionGU">
                        <Box id="BoxNombreConDetalleUbicacionGU">
                            <Input value={informacion.idUbicacion} hidden readOnly sx={{display:"none"}} />
                            <TextField readOnly variant="filled" fullWidth label="Nombre de la Ubicación" value={informacion.nombreUbicacion} />
                        </Box>
                        <Box id="BoxTipoConDetalleUbicacionGU">
                            <TextField readOnly variant="filled" fullWidth label="Tipo de Ubicación" value={informacion.tipoUbicacion}/>
                        </Box>
                    </Box>
                    <Box id="BoxContenidoPisoNumeracionConDetalleUbicacionGU">
                        <Box id="BoxPisoConDetalleUbicacionGU">
                            <TextField readOnly variant="filled" fullWidth label="Piso" value={informacion.piso}/>
                        </Box>
                        <Box id="BoxNumeracionConDetalleUbicacionGU">
                            <TextField readOnly variant="filled" fullWidth label="Numeración" value={informacion.numeracion} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box id="BoxAccionesConDetalleUbicacionGU">
                <Box id="BoxBtnConDetalleUbicacionGU">
                    <BotonTipo2 textoBoton={"Cambiar ubicación"} event={handleEditarUbicacion} />
                </Box>
            </Box>
        </Box>
    );
}