import { Box, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';

const BoxTituloGraficoLinea = ({valorGrafico, setGrafico}) => {

    const handleChange = ()=> {
        setGrafico(valorGrafico? 0 : 1)       
    }
    
    return (
        <Box id='BoxTituloGraficoIzquierda'>
            <Typography id='TituloGraficoIzquierda'>Accesos - Entradas y Salidas</Typography>
            <Box sx={{width:'112px', height:'42px'}}> 
                <Select className='ReportesNavegacion'
                    value={valorGrafico}
                    onChange={handleChange}
                    >
                        <MenuItem key={'Semana'} value={0}>Semana</MenuItem>
                        <MenuItem key={'Dia'} value={1}>Dia</MenuItem>
                </Select>
            </Box>
        </Box>
    );
}

export default BoxTituloGraficoLinea;
