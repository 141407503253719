import axios from "axios";
import { useEffect, useState } from "react"
import { TiposUbicacionesEmpresa } from "../../Service/API/Api";

export default function useTiposUbicaciones() {
    const [tiposUbicaciones, setTiposUbicaciones] = useState([]);
    

    useEffect(() => {
        axios.get(TiposUbicacionesEmpresa)
        .then(
            (response) =>{ 
                var vacio = 
                {
                  "id": -1,
                  "tipo": "Seleccione tipo"
                }
                var lista = []
                lista.push(vacio)
                response.data.forEach(element => {
                    var elemento = 
                    {
                    "id": element.idTipoUbicacion,
                    "tipo": element.tipo
                    }
                    lista.push(elemento)
                });
                setTiposUbicaciones(lista)
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
            }
        )
    }, [])
    

    return {
        tiposUbicaciones
    }
}