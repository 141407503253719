import { Box } from '@mui/material';
import { LineChart } from '@mui/x-charts';
import React from 'react';

const GraficoLineas = ({datos, EjeX}) => {

    return (
        <Box id='idBoxGraficoIzquierdo'>
            <Box sx={{width:'927px', height:'436px'}}>
                <LineChart
                    xAxis={EjeX}
                    series={datos}
                    grid={{ horizontal: true }}
                />
            </Box>
        </Box>
    );
}

export default GraficoLineas;
