import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import "./style.css";
import SearchIcon from '@mui/icons-material/Search';


const Buscador = () => {
    return (
        <Box id="SearchBar" >
            <TextField variant="filled" name='Buscar' id='Buscar'
            placeholder="Buscar..."
            inputProps={
                {
                    style: { 
                        display: 'flex', 
                        fontFamily: 'inherit',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                        letterSpacing:' 0.5px',
                        color: "#626262",
                        width: 'inherit',
                        padding: 0,
                        height: '56px'
                    }
                }
            }
            sx={{
                "& .MuiInputBase-input": {
                    width: '100%',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center"
                }
            }}
            fullWidth
            InputProps={{
                disableUnderline: true,
                style: { borderRadius:'28px', paddingLeft: '40px'},
                endAdornment: 
                <InputAdornment position="end" >
                    <IconButton aria-label="Buscar" edge="end">
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            }}
            >  
            </TextField>
        </Box>
        
    );
}


export default Buscador;