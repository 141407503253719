import { Link, Collapse, List, Box } from "@mui/material";
import "./Menu.css";
import { SubMenuTipo1, SubMenuTipo2 } from "../Botones/BotonesMenu/Boton";
import { ActuadoresLeftBar, CalendarioPrueba, CamarasLeftBar, Configuracion, Inicio, ListaNegraIcon } from "./IconosMenu";
import { ItemMenu } from "../Botones/BotonesMenu/BotonMenu";
import { v4 as uuidv4 } from 'uuid';
import { ReportesIcon } from "../Reportes/ReportesIcons";
import { useEffect, useState } from "react";
import { ConfiguracionSM, ReporteSubMenu } from "./OpcionesMenu";
import { MenuTipoUno } from "../Menu/MenuTipoUno";

export const MenuIzquierdo = ({pathSelected, isOpen }) => {
    const [openSMReporte, setOpenSMReporte] = useState(false);
    const [openSMConfig, setOpenSMConfig] = useState(false);
    const [isSelectReporteSM, setIsSelectReporteSM] = useState(null);
    const [isSelectConfigSM, setIsSelectConfigSM] = useState(null);

    const [anchorElSMReporte, setAnchorElSMReporte] = useState(null);
    const [anchorElSMConfiguracion, setAnchorElSMConfiguracion] = useState(null);

    const openClosedSMReporte = Boolean(anchorElSMReporte);
    const openClosedSMConfiguracion = Boolean(anchorElSMConfiguracion);

    const handleOpenSMReporte = () => {
        setOpenSMReporte(true)
        setOpenSMConfig(false)
    }
    const handleCloseSMReporte = () => {
        setOpenSMReporte(false)
    }
    
    const handleOpenSMConfig = () => {
        setOpenSMConfig(true)
        setOpenSMReporte(false)
    }

    const handleCloseSMConfig = () => {
        setOpenSMConfig(false)
    }

    const handleClosedSMReporte = (e) => {
        setAnchorElSMReporte(e.currentTarget);
    }

    const handleClosedSMConfiguracion = (e) => {
        setAnchorElSMConfiguracion(e.currentTarget);
    }

    useEffect(() => {
        var isSubMenuReportePath = ReporteSubMenu.find(o => o.path === pathSelected)
        var isSubMenuConfigPath = ConfiguracionSM.find(o => o.path === pathSelected)
        if(isSubMenuReportePath !== undefined){
            setIsSelectReporteSM(true)
            setOpenSMReporte(true)
        }
        if(isSubMenuReportePath === undefined){
            setIsSelectReporteSM(false)
            setOpenSMReporte(false)
        }

        if(isSubMenuConfigPath !== undefined){
            setIsSelectConfigSM(true)
            setOpenSMConfig(true)
        }
        if(isSubMenuConfigPath === undefined){
            setIsSelectConfigSM(false)
            setOpenSMConfig(false)
        }
        
    }, [pathSelected])
    


    return (
        <Box >
            <ItemMenu
            openM={isOpen}
            id={uuidv4()}
            to={"/inicio"}
            isSelected={pathSelected === "/inicio" ? true : false}
            icono={<Inicio />}
            textoBoton={"Inicio"}
            openSM={false}
            submenu={false}
            handleClick={null}
            />
            <ItemMenu
            openM={isOpen}
            id={uuidv4()}
            to={"/camaras/live"}
            isSelected={pathSelected === "/camaras/live" ? true : false}
            icono={<CamarasLeftBar />}
            textoBoton={"Ver Camaras"}
            openSM={false}
            submenu={false}
            handleClick={null}
            />
            <ItemMenu
            openM={isOpen}
            id={uuidv4()}
            to={"/Actuadores"}
            isSelected={pathSelected === "/Actuadores" ? true : false}
            icono={<ActuadoresLeftBar />}
            textoBoton={"Actuadores"}
            openSM={false}
            submenu={false}
            handleClick={null}
            />
            <ItemMenu
            openM={isOpen}
            id={uuidv4()}
            to={"/ListaNegra"}
            isSelected={pathSelected === "/ListaNegra" ? true : false}
            icono={<ListaNegraIcon />}
            textoBoton={"Lista Negra"}
            openSM={false}
            submenu={false}
            handleClick={null}
            />
            <ItemMenu
            openM={isOpen}
            id={uuidv4()}
            to={null}
            isSelected={isSelectReporteSM}
            icono={<ReportesIcon />}
            textoBoton={"Reportes"}
            openSM={openSMReporte}
            submenu={true}
            handleClick={isOpen ? 
                            openSMReporte === false ? 
                                handleOpenSMReporte
                                :
                                handleCloseSMReporte 
                            :
                            (e) => handleClosedSMReporte(e)
                        }
            />
            {
                isOpen ?
                <Collapse id="SubMenuReporte" in={openSMReporte} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        <ListaSubMenu items={ReporteSubMenu} selected={pathSelected} open={isOpen} />
                    </List>
                </Collapse> 
                :
                null
            }
            
            <ItemMenu
            openM={isOpen}
            id={uuidv4()}
            to={"/Agenda"}
            isSelected={pathSelected === "/Agenda" ? true : false}
            icono={<CalendarioPrueba />}
            textoBoton={"Agenda"}
            openSM={false}
            submenu={false}
            handleClick={null}
            />
            <ItemMenu
            openM={isOpen}
            id={uuidv4()}
            to={null}
            isSelected={isSelectConfigSM}
            icono={<Configuracion />}
            textoBoton={"Configuracion"}
            openSM={openSMConfig}
            submenu={true}
            handleClick={isOpen ? 
                            openSMConfig === false ? 
                                handleOpenSMConfig
                                :
                                handleCloseSMConfig
                            :
                            (e) => handleClosedSMConfiguracion(e)
                        }
            />
            {
                isOpen ?
                <Collapse id="SubMenuConfig" sx={{ maxHeight: "250px"}} in={openSMConfig} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        <ListaSubMenu items={ConfiguracionSM} selected={pathSelected} open={isOpen} />
                    </List>
                </Collapse> 
                :
                null
            }
            
            <MenuTipoUno 
            open={openClosedSMReporte}
            anchorEl={anchorElSMReporte}
            setOpen={setAnchorElSMReporte}
            children={
                <ListaSubMenuClosed items={ReporteSubMenu} selected={pathSelected}/>
            }
            />

            <MenuTipoUno 
            open={openClosedSMConfiguracion}
            anchorEl={anchorElSMConfiguracion}
            setOpen={setAnchorElSMConfiguracion}
            children={
                <ListaSubMenuClosed items={ConfiguracionSM} selected={pathSelected} />
                }
            />
        </Box>
    );
}


const ListaSubMenu = ({items, selected, open}) => items.map((item) => {
    
    return (
        <SubMenuTipo1
        key={item.id}
        openM={open}
        itemId={item.id}
        linkComp={Link}
        to={item.path}
        isSelected={item.path === selected}
        textButon={item.title}
        submenu={null}
        smState={null}
        openSM={null}
        closeSM={null} />
    );
});


const ListaSubMenuClosed = ({items, selected}) => items.map((item) => {
    
    return (
        <SubMenuTipo2
        key={item.id}
        itemId={item.id}
        linkComp={Link}
        to={item.path}
        isSelected={item.path === selected}
        textButon={item.title}
        />
    );
});