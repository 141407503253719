import * as Yup from 'yup';

export const ValidationNuevoAcceso = Yup.object({  
    idZona: Yup.number()
    .test("min", "Debe seleccionar una zona", function(value){
        if(value === 0){
            return (false);
        }
        else{
            return (true);
        }
    })
    .required("debe seleccionar uno")
})