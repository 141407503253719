import { Autocomplete, Box, FormControl, FormHelperText,TextField, Typography } from "@mui/material";
import { LayoutCrearNuevo } from "../../../../Box/CrearNuevoMenuGestionUsuario/LayoutCrearNuevo";
import "./Ubicacion.css";
import { BotonTipo2 } from "../../../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { ValidationAsignarUbicacion } from "./Validation";
import useUbicacionesEvento from "../../../../../Hooks/Ubicaciones/useUbicaciones";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../../RespuestaServidor/RespuestaServidorIcon";

export const CambiarUbicacion = ({setOpen, ubicacionesEvento, setInfo, user}) =>{
    const {loading, setLoading, response, GuardarAsignacionUbicacion, BuscarUbicacionInfo} = useUbicacionesEvento();
    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const handleClose = () => {
        setOpen(false);
    }


    const AgregarInfo = (nuevaId, idSeleccionada) => {
        BuscarUbicacionInfo(idSeleccionada)
        .then(
            (resolve) => {
                var info = {
                    idPersonaUbicacion: nuevaId,
                    idUbicacion: idSeleccionada,
                    nombreUbicacion: resolve.nombre,
                    piso: resolve.piso,
                    numeracion: resolve.numeracion,
                    tipoUbicacion: resolve.tipoUbicacion
                }
                setTimeout(() => {
                    setInfo(info);
                    setOpen(false);
                    setOpenLoading(false);
                }, 3000);
            }
            ,
            (reject) => {
                console.log(reject);
            }

        );
    }

    const formik = useFormik({
        initialValues:{idUbicacion:-1, nombreUbicacion: "Destinos"},
        validationSchema:ValidationAsignarUbicacion,
        onSubmit:
        async () => {
            setLoading(true);
            setOpenLoading(true);
            await GuardarAsignacionUbicacion(user, formik.values.idUbicacion)
            .then(
                (resolve) => {
                    AgregarInfo(resolve, formik.values.idUbicacion);
                }
                ,
                (reject) => {
                    console.log(reject);
                }
            )
        }
    })

    return(
        <LayoutCrearNuevo titulo={"Asignar Ubicación"}>
            <Box id="BoxLayoutCambiarUbicacionGU">
                <Box id="BoxTituloContenidoCambiarUbicacionGU">
                    <Box id="BoxTituloCambiarUbicacionGU">
                        <Typography id="TxtTituloCambiarUbicacionGU">
                            {
                                "Listado de Ubicaciones"
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxContenidoCambiarUbicacionGU" component={"form"} onSubmit={formik.handleSubmit}>
                        <Box id="BoxSelectCambiarUbicacionGU">
                            <FormControl id="FormControlAutocompleteUbicacionGU">
                                <Autocomplete                                   
                                disablePortal
                                options={ubicacionesEvento}
                                fullWidth  
                                sx={{ 
                                    "& .MuiFilledInput-input":{ height: "20px", borderBottom: "0px"}, 
                                    "& .MuiOutlinedInput-input":{ height: "20px", borderBottom: "0px"}}}
                                id="idUbicacion"
                                componentname="idUbicacion"
                                freeSolo={true}                                        
                                getOptionKey={(o) => o.idUbicacion}
                                isOptionEqualToValue={(o, v) => o.nombreUbicacion === v}
                                onChange={(e, v) => {
                                    if(v === null)
                                    {
                                        formik.setFieldValue("idUbicacion", -1) 
                                                                                            
                                    }
                                    else
                                    {
                                        formik.setFieldValue("idUbicacion", v.idUbicacion)
                                    }
                                }}
                                getOptionLabel={(option) =>  option.nombreUbicacion ?? option}
                                inputValue={formik.values.nombreUbicacion}
                                value={formik.values.nombreUbicacion}
                                onInputChange={(e,v) => formik.setFieldValue("nombreUbicacion", v)}   
                                renderInput={(params) => 
                                    <TextField
                                    InputLabelProps={{shrink: true}}
                                    {...params}
                                    sx={{ height: "100%"}}
                                    label="Ubicaciones"
                                    />
                                }
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                            <Box
                                            key={key}
                                            component="li"
                                            {...optionProps}>
                                                {option.nombreUbicacion}
                                            </Box>
                                    );
                                }}
                                />
                                <FormHelperText>
                                    {
                                        formik.errors.idUbicacion
                                    }
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxAccionesCambiarUbicacionGU">
                    <Box id="BoxBtnCancelarCambiarUbicacionGU">
                        <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                    </Box>
                    <Box id="BoxBtnAgregarCambiarUbicacionGU">
                        <BotonTipo2 textoBoton={"Guardar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCinco
                        open={openLoading}
                        setOpen={setOpenLoading}
                        msj={"Se está asignado a la ubicacion. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                        id={"LoadingAgregaListaNegraGU"}
                        tituloLoading={"Asignando..."}
                        loading={loading}
                        titulo={response === 201 ? 
                            "Usuario agregado"
                            :
                            "Usuario no agregado"
                        }
                        icono={response === 201 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={response === 201 ?
                            "Aceptar" 
                            :
                            "Reintentar"
                        }
                        mensajeRespuesta={response === 201 ? 
                            "El usuario ha sido agregado exitosamente a la ubicacion, restringiendo su acceso según las políticas de seguridad."
                            :
                            "No se ha podido agregar el usuario a la ubicacion debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                        }
                        />
                    </Box>
                </Box>
            </Box>
        </LayoutCrearNuevo>
    );
}