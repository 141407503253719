import axios from 'axios';
import { useEffect, useState } from 'react';
import { ReportesGraficos } from '../../Service/API/Api';
import { horaEvento, FechaHoyGraficas, GetDiaSemana } from './LogicaDayjs';

function useGetDatosGraficos (valorGrafico, setEjeX) {

    const [datosCircular, setDatosCircular] = useState(initialStateCircular);
    const [datosLineas, setDatoslineas] = useState([]);
      
    useEffect(()=>{
  
       let valores = {
            "fecha": FechaHoyGraficas(),
            "tipoInformacion": valorGrafico
        }
        axios.post(ReportesGraficos,  valores)
        .then((response) => {
            setDatosCircular(DatosGraficoCircularDia(response.data))
            if (valorGrafico===1){
                setEjeX(xAxisDia)
                setDatoslineas(DatosGraficoLineasDia(response.data))
            }else{
                setEjeX(xAxisSemana)
                setDatoslineas(DatosGraficoLineasSemana(response.data))
            }

            
        })
        .catch((e) => {console.error("Error: ", e)
            return([])}
        )
    }, [valorGrafico, setEjeX])

    return {
        datosCircular,
        datosLineas
    }
}
export default useGetDatosGraficos

const DatosGraficoLineasSemana = (data) =>{
    const resultado = []
    const arrayRespuesta = [0, 0, 0, 0, 0, 0, 0] 
    let actuadores = GraficoLineasGetActuadores(data)
    
    actuadores.forEach((actuador)=>{
        let datosActuador= arrayRespuesta.map(x=> x)
        let i 
        for (i=0; i<=data.length - 1; i++) { 

            if(data[i].metodoIngreso === actuador){
                let dia = GetDiaSemana(data[i].fechaRegistro)
                datosActuador[dia] = datosActuador[dia] + 1
            }
        }
        resultado.push({
            'data':datosActuador, 
            'tipo':actuador,
            'color':GetColor(actuador)
        })
     
    })
    return resultado
}

const DatosGraficoLineasDia = (data) => {
    const resultado = []
    const arrayRespuesta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] 
    let actuadores = GraficoLineasGetActuadores(data)

    actuadores.forEach((actuador)=>{
        let datosActuador= arrayRespuesta.map(x=> x)
        let i 
        for (i=0; i<=data.length - 1; i++) { 

            if(data[i].metodoIngreso === actuador){
                let hora= horaEvento(data[i].fechaRegistro)
                datosActuador[hora] = datosActuador[hora] + 1
            }
        }
        resultado.push({
            'data':datosActuador, 
            'tipo':actuador,
            'color':GetColor(actuador)
        })
    })
    return resultado 
}

const GraficoLineasGetActuadores = (data)=>{

    let result = []

    data.forEach((dato)=>{

        if (!result.includes(dato.metodoIngreso)){
           result.push(dato.metodoIngreso)
        }
    })
    return result
}

const constOrdenaInfoGraficoCircular = (data) =>{
    let total = 0
    let datos = []
    
    let claves = Object.keys(data)

    for(let i=0; i< claves.length; i++){
        total += data[claves[i]]

        // let color= GetColor(claves[i])
        datos.push({ 
            'nombre': claves[i], 'value': data[claves[i]], 'color': GetColor(claves[i])
        })
    }
    return {'data' : datos,
            'totales':total
            }
}

const DatosGraficoCircularDia = (data) =>{
    let result = {}

    data.forEach((dato)=>{

        if (dato.metodoIngreso in result){
            result[dato.metodoIngreso] = result[dato.metodoIngreso] + 1
        }else{
            result[dato.metodoIngreso] = 1
        }
    })

    return(constOrdenaInfoGraficoCircular(result))
} 

export function GetColor (actuador)  {

    switch (actuador.toLowerCase()) {
        case 'torniquete':
        return ('#BAD3DF')

        case 'rfid':
        return ('#0D2F40')

        case 'tag':
        return ('#16506C')

        case 'huella':
        return ('#1F7098')

        case 'facial':
            return ('#699FBA')

        case 'porton':
            return ('#56100b')
        
        case 'puerta':
            return ('#70150e')

        default:
            return ('#ECE2C6')
    }

}

const initialStateCircular = {
    'data':[
        {'nombre':'noData', 'value' :1, 'color':'#0D2F40'}
    ],
    'totales': 0
}

const xAxisDia = [{ 
    scaleType: 'point', 
    data: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'] 
}]
const xAxisSemana = [{ 
    scaleType: 'point', 
    data: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'] 
}]


// export const DatosGraficoCircularSemana = () =>{
//     let data = [                
//         {'nombre':'rfid',   'value' :297 },
//         {'nombre':'torniquete', 'value':200 },
//         {'nombre':'tag',    'value':60},
//         {'nombre':'huella', 'value': 30},
//         {'nombre':'facial', 'value':10 },
//     ]

//     // for (let i = 0; i < data.length ; i++) {
//     //     data[i].color=GetColor(data[i].nombre)
//     //     console.log(data[i])
//     // }
//     // console.log(data)


//     return({'datos':data,
//             'totales':597
//     })
// }


// export const DatosGraficoLineasSemana = () => {
//     return [    
//         {   
//             data: [4, 5, 6, 5, 4, 0, 0],
//             tipo: 'torniquete'
//         },
//         {
//             data: [5, 6, 7, 5, 4, 0, 0],
//             tipo: 'rfid'
//         }
//     ]
// }

