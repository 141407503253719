import axios from "axios";
import { useState } from "react";
import { EditarVisitante } from "../../Service/API/Api";



function useVisitante()
{
    const [loading, setLoading] = useState();
    
    const EditarVisitanteData = (visitante) => {
        return new Promise(async (resolve, reject) => {
            const visitanteNewData = {
                "idVisitante": visitante.idVisitante,
                "rut": visitante.rut,
                "nombres": visitante.nombres,
                "apellidos": visitante.apellidos,
                "correo": visitante.correo,
                "telefono": null,
                "patenteDigitos": visitante.patente === "-" ? null : visitante.patente
            }

            axios.put(EditarVisitante, visitanteNewData)
            .then(
                (response) => {
                    resolve(true)
                    setLoading(false);
                }
            )
            .catch(
                (response) => {
                    reject(false)
                    setLoading(false);
                }
            )
        });
    }


    return {
        setLoading,
        loading,
        EditarVisitanteData
    }
}

export default useVisitante;