import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { VisitasCalendario } from "../../Service/API/Api";


export default function useEventosData() {
    const [listaEventos, setListaEventos] = useState([]);
    

    useEffect(() => {
       
        let hoy = dayjs(new Date());

        const fechaActualISO = {
            fecha : hoy.toISOString()
        }
        
        axios.post(VisitasCalendario, fechaActualISO)
        .then(
            (response) => {
                
                setListaEventos(response.data);
            }
        )
        .catch(
            (error) => {
                console.log("fallo al obtener eventos");                
            }
        )

    }, [])

    
    
    return {
        listaEventos
    }
}