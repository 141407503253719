import { Avatar, Box, IconButton, Typography } from "@mui/material";
import "./AvatarUsuario.css";
import TooltipTipoUno from "../../../ToolTip/Tooltip";
import { IconoCamara, IconoEditar } from "./AvatarIcon";

export const AvatarUsuario =  ({titulo, img, nombre, rol, disableState, disabledHandler }) => {

    const handleEdit = () =>{
        disabledHandler(false)
    }


   
    return (
        <Box id="BoxAvatar">
            <Box id="BoxTituloAvatar">
                <Typography id="TituloAvatarUsuario">
                    {
                        titulo
                    }
                </Typography>
            </Box>
            <Box id="BoxContenidoAvatar">
                <Box id="BoxImagenAvatar">
                    <Avatar alt="Usuario" id="ImgUsuarioAvatar" src={img} />
                    <Box id="IconosEnAvatar">
                        <TooltipTipoUno titulo={disableState === true ? "Editar" : "Nueva imagen"}>
                            {
                                disableState === true ? 
                                <Box >
                                    <IconButton sx={{padding: "0px"}} onClick={handleEdit}>
                                        <IconoEditar id="IconoEditarInfoPerfil" />
                                    </IconButton>
                                </Box>
                                :
                                <Box sx={{ width:"40px", height:"40px", padding:"4px"}}>
                                    <IconButton sx={{padding: "0px"}} onClick={null}>  
                                        <IconoCamara id="IconoNuevaImagenAvatar" />
                                    </IconButton>
                                </Box>
                            }
                        </TooltipTipoUno>
                    </Box>
                </Box>
                <Box id="BoxInformacionUsuarioAvatar">
                    <Box id="BoxNombreUsuarioAvatar">
                        <Typography id="NombreUsuarioAvatar">
                            {
                                nombre
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxRolUsuarioAvatar">
                        <Typography id="RolUsuarioAvatar">
                            {
                                rol
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}


export const AvatarUsuario2 =  ({titulo, img, nombre, rol }) => {

    return (
        <Box id="BoxAvatar">
            <Box id="BoxTituloAvatar">
                <Typography id="TituloAvatarUsuario">
                    {
                        titulo
                    }
                </Typography>
            </Box>
            <Box id="BoxContenidoAvatar">
                <Box id="BoxImagenAvatar">
                    <Avatar alt="Usuario" id="ImgUsuarioAvatar" src={img} />
                </Box>
                <Box id="BoxInformacionUsuarioAvatar">
                    <Box id="BoxNombreUsuarioAvatar">
                        <Typography id="NombreUsuarioAvatar">
                            {
                                nombre
                            }
                        </Typography>
                    </Box>
                    <Box id="BoxRolUsuarioAvatar">
                        <Typography id="RolUsuarioAvatar">
                            {
                                rol
                            }
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
