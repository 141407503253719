import { Box, Modal } from "@mui/material";

import { RespuestaServidor } from "../RespuestaServidor/RespuestaServidor";
import { ModalLoading } from "../ModalLoding/ModalLoading";




const ModalEnvioInvitados = ({open, setOpen, msj, id, loading, respuestaMsj, respuestTitulo, icono, textoBoton}) => {

    const handleClose = () => 
    { 
        setOpen(false);
    };

    return(
        <>
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading ?
                    <Box>
                        <ModalLoading msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaServidor 
                        icono={icono}
                        textoBoton={textoBoton}
                        setOpen={setOpen} 
                        msj={respuestaMsj} 
                        titulo={respuestTitulo} 
                        />
                    </Box>
                    
            }
            </Modal>
        </>
    );
}


export default ModalEnvioInvitados;