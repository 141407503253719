import { Box } from "@mui/material";
import "./Agenda.css";
import CabeceraAgenda from "../Box/CabeceraAgenda/CabeceraAgenda";
import { CuerpoAgenda } from "../Box/CuerpoAgenda/CuerpoAgenda";
import useAgenda from "../../Hooks/Agenda/useAgenda";


export const Agenda = () => {
    const {opcion, 
        setOpcion, 
        fechaVista, 
        AddDayToDate, 
        RestDayToDate, 
        handleHoy, 
        AddMonthToDate, 
        RestMonthToDate, 
        setMonthToDate, 
        setYearToDate,
        AddYearToDate,
        RestYearToDate,
        addWeekToDate,
        restWeekToDate, 
        setNewDate} = useAgenda();
    
    return(
        <Box id="BoxLayoutAgendaVisitas">
            {/* agenda cabecera */}
            <CabeceraAgenda 
            opcion={opcion} 
            setOpcion={setOpcion} 
            fechaActual={fechaVista} 
            handleHoy={handleHoy}
            addMonth={AddMonthToDate}
            restMonth={RestMonthToDate}
            setMonth={setMonthToDate}
            setYear={setYearToDate}
            addYear={AddYearToDate}
            restYear={RestYearToDate}            
            />
            {/* agenda body */}
            <CuerpoAgenda 
            opcion={opcion} 
            setOpcion={setOpcion}
            fechaActual={fechaVista} 
            addDay={AddDayToDate} 
            restDay={RestDayToDate} 
            addWeek={addWeekToDate}
            restWeek={restWeekToDate}
            setNewDate={setNewDate}
            />
        </Box>
    );
}