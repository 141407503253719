import { useEffect, useState } from "react";
import { PreviewReporteEmpresa } from "../../Service/API/Api";
import axios from "axios";

function useReportes(){
    const [loading, setLoading] = useState();
    const [reportes, setReportes] = useState([]);

    useEffect(() => {
        axios.get(PreviewReporteEmpresa)
        .then(
            (response) =>
            {
                setReportes(response.data)
                setLoading(false);
            }
        )
        .catch(
            (response) => {
                setReportes([])
                setLoading(false);
            }
        );
    }, [])
    
    return {
        loading,
        setLoading,
        reportes
    }
}

export default useReportes;