import { IconoAlerta, IconoCancelarCambiosUsuario, IconoEditarUsuario, IconoGuardarCambiosUsuario, IconoOpcionesUsuarios, IconoUsuarios } from "./UsuariosIcon";
import "./Usuarios.css";
import { Box } from "@mui/material";
import CabeceraTablasGestion from "../CabeceraTablasGestion/CabecerasTablasGestion";
import {FiltroSelect} from "../CabeceraTablasGestion/FiltrosCabecera/FiltrosCabecera";
import { useEffect, useState } from "react";
import useUsuarios from "../../Hooks/Usuarios/useUsuarios";
import { DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from "@mui/x-data-grid";
import { SwitchTablas } from "../Switch/switch";
import { SnackBarTipoUno } from "../snackBar/SnackBar";
import useConfirm from "../../Hooks/ConfirmDialog/useConfirmDialog";
import { MenuGestionUsuario } from "../Modal/MenuGestionUsuario/MenuGestionUsuario";
import TooltipTipoUno from "../ToolTip/Tooltip";
import { HandleOpcionesUsuario } from "./OpcionesUsuario/OpcionesUsuario";


const listaTest = [
    {
        id:0, tipo:"Activos/Inactivos"
    }
    ,
    {
        id:1, tipo:"Solo activos"
    }
    ,
    {
        id:2, tipo:"Solo inactivos"
    }
]

const GestionUsuarios = () => {
    const {loading, usuarios, ActivarDesactivarUsuario, ActivarDesactivarAccesoAPPMovil} = useUsuarios();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [mensajeConfirm, setMensajeConfirm] = useState({ titulo: "", mensaje:""});
    const [opcion, setOpcion] = useState(null);
    const [openOpcionesUsuario, setOpenOpcionesUsuario] = useState(false);
    const [dataUsuario, setDataUsuario] = useState({});
    const [openMGU, setOpenMGU] = useState(Boolean(false));
    const [searchText, setSearchText] = useState('');
    const [opcionSeleccionEstado, setOpcionSeleccionEstado] = useState(0);

    const handleSelectorEstado = (e) => {
        setOpcionSeleccionEstado(e.target.value);
        let filteredRows = '';
        if(e.target.value === 0){
            filteredRows = usuarios;
        }

        if(e.target.value === 1){
            filteredRows = usuarios.filter((row) => row.estado ===  true )
        }

        if(e.target.value === 2){
            filteredRows = usuarios.filter((row) => row.estado ===  false )
        }

        setRows(filteredRows);
    }

    useEffect(() => {
        setRows(usuarios);
    }, [usuarios])

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = usuarios.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const HandlerOpenNE = () => {
        setOpcion(1)
        setOpenOpcionesUsuario(true);
    }
    
    const handleEditClick = (row) => () => {
        //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        setDataUsuario(row);
        setOpcion(2)
        setOpenOpcionesUsuario(true);
    };


    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = rows.find((row) => row.idPersona === id);

        if (editedRow.isNew) {
           setRows(rows.filter((row) => row.idPersona !== id));
        }
    };

    
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.idPersona === newRow.idPersona ? updatedRow : row)));
        return updatedRow;
    };
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) 
        {
            event.defaultMuiPrevented = true;
        }
    };

    const handleActiveMovil = (id) => async() => {
        const rowToEdit = rows.find((row) => row.idPersona === id);
        if(rowToEdit.estadoMovil === true){
            setMensajeConfirm({
                titulo: "¿Desactivar acceso?",
                mensaje: "Estás a punto de desactivar el acceso a la aplicacion movil de este usuario. Esto restringirá su ingreso. ¿Deseas continuar?"
            })
        }
        else
        {
            setMensajeConfirm({
                titulo: "¿Activar acceso?",
                mensaje: "Estás a punto de activar el acceso a la aplicacion movil para este usuario. Esto permitira su ingreso. ¿Deseas continuar?"
            })
        }

        let response = await confirm();

        if(response === true){
            ActivarDesactivarAccesoAPPMovil(id, rowToEdit.estadoMovil)
            .then(
                (result) => {
                    rowToEdit.estadoMovil = !rowToEdit.estadoMovil
                    setRows(rows.map((row) => (row.idPersona === rowToEdit.idPersona ? rowToEdit : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(rowToEdit.estadoMovil === true ? "acceso habilitado exitosamente!" : "acceso deshabilitado exitosamente!")
                    setSnackbar(true);
                    },
                (error) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true);                        
                    }
                )
                
        }else
        {
            console.log("rechaza el cambio cambio")
        }
    }

    const handleActiveUser = (id) => async () => {
        const rowToEdit = rows.find((row) => row.idPersona === id);
        if(rowToEdit.estado === true)
        {
            setMensajeConfirm({
                titulo: "¿Desactivar usuario?",
                mensaje: "Estás a punto de desactivar este usuario. Esto restringirá sus accesos. ¿Deseas continuar?"
            })
        }
        else 
        {
            setMensajeConfirm({
                titulo: "¿Activar usuario?",
                mensaje: "Estás a punto de activar este usuario. Una vez activado, el usuario tendrá acceso según sus permisos asignados. ¿Deseas continuar?"
            })
        }
        let response = await confirm();
        if(response === true){
            ActivarDesactivarUsuario(id, rowToEdit.estado)
            .then(
                (result) => {
                    rowToEdit.estado = !rowToEdit.estado
                    setRows(rows.map((row) => (row.idPersona === rowToEdit.idPersona ? rowToEdit : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(rowToEdit.estado === true ? "el usuario ha sido activado exitosamente!" : "el usuario ha sido desactivado exitosamente!")
                    setSnackbar(true);
                    },
                (error) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true);                        
                    }
                )
                
        }else
        {
            console.log("rechaza el cambio cambio")
        }

    }


    const HandleOpenMenu = (id) => () => {
        const data = rows.find((row) => row.idPersona === id);
        setDataUsuario(data);
        setOpenMGU(true);
    }
    const colName = [
        // {
        //     field: 'idPersona', headerName: 'ID', type: 'string', width: 110, headerAlign: 'center', align:'center', sortable: false, editable: false
        // }
        // ,
        {
            field: 'rut', headerName: 'Rut', type: 'string', width: 110, valueFormatter: params => params?.value ?? "-", headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'pasaporte', headerName: 'Pasaporte', type: 'string', width: 110, valueFormatter: params => params?.value ?? "-",headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'nombres', headerName: 'Nombres', type: 'string', minWidth: 160, flex: 1, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'apellidos', headerName: 'Apellidos', minWidth: 160, flex: 1, type: 'string',  headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'telefono', headerName: 'Telefono', type: 'string', width: 120, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'correo', headerName: 'Correo', type: 'string', minWidth: 320, flex: 1, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'tipo', headerName: 'Tipo de Usuario', type: 'string', width: 168, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'estado', headerName: 'Estado', type: 'bool', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false,
            renderCell: ({id, row}) => 
            (
                <SwitchTablas 
                value={row.estado}
                disabled={rowModesModel[id]?.mode === GridRowModes.Edit ? true : false}
                handler={handleActiveUser(id)} 
                />
            )
        }
        ,
        {
            field: 'estadoMovil', headerName: 'Acceso APP', type: 'bool', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false,
            renderCell: ({id, row}) => 
            (
                <SwitchTablas 
                value={row.estadoMovil}
                disabled={rowModesModel[id]?.mode === GridRowModes.Edit ? true : false}
                handler={handleActiveMovil(id)} 
                />
            )
        },
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 95, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        
                if (isInEditMode) {
                  return [
                    <TooltipTipoUno titulo={"Guardar"} ubicacion={"right-start"}>
                        <GridActionsCellItem
                        icon={<IconoGuardarCambiosUsuario  id='iconoEnTablaUsuarios'/>}
                        label="Guardar"
                        onClick={null}
                        />
                    </TooltipTipoUno>
                  ];
                }
                else{
                    return [
                        <TooltipTipoUno titulo={"Editar"} ubicacion={"right-start"}>
                            <GridActionsCellItem
                            icon={<IconoEditarUsuario id='iconoEnTablaUsuarios'/>}
                            label="Editar"
                            onClick={handleEditClick(row)}
                            />
                        </TooltipTipoUno>
                    ];
                }
            }
        },
        {
            field: 'opciones', headerName: 'Opciones', type: 'actions', headerAlign: 'center', width: 95, align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        
                if (isInEditMode) {
                  return [
                    <GridActionsCellItem
                        icon={<IconoCancelarCambiosUsuario  id='iconoEnTablaUsuarios'/>}
                        label="Cancelar"
                        onClick = {handleCancelClick(id)}
                    />,
                  ];
                }
                else{
                    return [
                        <TooltipTipoUno titulo={"Gestionar Usuario"} ubicacion={"right-start"}>
                            <GridActionsCellItem
                            icon={<IconoOpcionesUsuarios  id='iconoEnTablaUsuarios'/>}
                            label="Opciones"
                            onClick = {HandleOpenMenu(id)}
                            />
                        </TooltipTipoUno>
                    ];
                }
            }
        },

    ];


   

    return(
        <Box id="GestionUsuariosDataGrid">
            <CabeceraTablasGestion
                icono1={<IconoUsuarios />}
                titulo={"Gestión de Usuarios"}
                data={rows}
                agregarNuevoIcon={true}
                handlerAN={HandlerOpenNE}
                cargaMasivaIcon={null}
                descargarIcon={null}
                handlerCM={null}
                handlerDownload={null}
                value={searchText}
                onChange={handleSearch}
                clearSearch={clearSearch}
            >
            {
                <Box id="BoxSelectContainerCabeceras">
                    <FiltroSelect id="FiltroEstado"
                    nombre={"Estado"} 
                    listaOpciones={listaTest} 
                    value={opcionSeleccionEstado} 
                    handler={handleSelectorEstado} />                
                </Box>
            }
            </CabeceraTablasGestion>
            <DataGrid id="tablaGestionUsuarios"  
            style={{ width:'inherit', minHeight:"520px" }}
            columns={colName}
            rows={rows}
            sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                color:'#175676',
            },
                '& .MuiDataGrid-columnHeader': {
                borderBottom: '2px solid #94B1C0',
            },
            '& .MuiDataGrid-row':{
                borderBottom:'1px solid #94B1C0'
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                width: "7.024px",
                background: "transparent"
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                background: "transparent"
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                background: "#C9C9C9",
                borderRadius: "100px"
            }
            }}
            getRowId={(row) => row.idPersona}
            setRowModesModel={setRowModesModel} 
            loading={loading}
            initialState={{
                pagination:{
                paginationModel: {  
                    page: 0,
                    pageSize: 10,
                    }
                },
            }}
            editMode="row"
            rowSelection
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            processRowUpdate={processRowUpdate}
            onRowEditStop={handleRowEditStop}
            pageSizeOptions={[10, 20]}
            disableRowSelectionOnClick
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            />
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />
          
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={mensajeConfirm.titulo}
            mensaje={mensajeConfirm.mensaje}
            />
            <HandleOpcionesUsuario
            open={openOpcionesUsuario}
            setOpen={setOpenOpcionesUsuario} 
            setRows={setRows}
            rows={rows}
            opcion={opcion}
            dataEdit={dataUsuario}
            />
            <MenuGestionUsuario
            id={"ModalMenuGestionRapidaUsuario"}
            open={openMGU}
            setOpen={setOpenMGU}
            dataUsuario={dataUsuario}
            rows={rows}
            setRows={setRows}
            />
        </Box>
    );
}

export default GestionUsuarios;