import * as Yup from 'yup';

export const ValidationAsignarUbicacion = Yup.object({  
    idUbicacion: Yup.number().required("Debe seleccionar una Ubicacion")
    .test("min", "Debe seleccionar una ubicacion valida", function(value){
        if(value === -1){
            return false;
        }
        else
        {
            return true;
        }
    }),
})