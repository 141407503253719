
import { Box, Typography } from "@mui/material";
import "./Style.css";


const AppFooter = ({openMenuState}) => {
    return (
        <Box id="FooterBar" height={"16px"}>
            <Box  width={openMenuState ? "269px" : "112px"}>

            </Box>
            <Box id="FooterApp" component="footer"  >
                <Typography id="TextFooterApp" sx={{marginLeft:openMenuState ? "-269px" : "-112px"}}  >
                    Copyright © 2024 Automatismos LAU. All rights reserved.
                </Typography>
            </Box>
        </Box>

    )

}

export default AppFooter;