import { Box, Button, IconButton } from "@mui/material";

import {AvatarUsuario} from "../Avatar/AvatarUsuario"
import { IconoBorrarCampo, IconoCerrar, IconoEditarCorreo } from "./DataUsuarioIcon";
import "./DataUsuario.css";
import { useFormik } from "formik";
import { esquemaValidacion } from "./validationSchema";
import { TextFieldUno } from "../../../TextField/TextFieldUno";
import { BotonConLoading } from "../../../Botones/BotonesTabla/Botones";
import usePerfil from "../../../../Hooks/OpcionesPerfil/usePerfil";
import { useState } from "react";
import {LoadingMasRespuesta} from "../../LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../RespuestaServidor/RespuestaServidorIcon";

const style = {
    display: "flex",
    position: 'absolute',
    flexDirection: "column",
    justifyContent: "center",
    aligItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};


export const DataUsuario = ({informacionUsuario, handle, disableState, disabledHandler, handleOpenConfirmacion}) => {
    const {respuesta, ActualizarDataUsuario, loading, setLoading} = usePerfil();
    const [open, setOpen] = useState(Boolean(false));

    var idPersona = informacionUsuario.idPersona;
    var img = informacionUsuario.urlImagenPerfil;
    var nombreCompleto = informacionUsuario.nombres.split(" ")[0] + " " + informacionUsuario.apellidos.split(" ")[0]
    var rol = informacionUsuario.tipo;
    var correoUsuario = informacionUsuario.correo.toLowerCase();
    const formik = useFormik(
        {
            initialValues: {
                nombres: informacionUsuario.nombres,
                apellidos: informacionUsuario.apellidos,
                rut: informacionUsuario.rut,
                pasaporte: informacionUsuario.pasaporte ?? "-",
                telefono: informacionUsuario.telefono
            },
            validationSchema: esquemaValidacion,
            onSubmit: () => {
                setLoading(true);
                ActualizarDataUsuario(formik.values, idPersona);
                setOpen(true);
            },
            onReset: () => {
                formik.resetForm();
            }
        }
    );


    return (
        <Box id="BoxModalPerfilUsuario" sx={{...style, width:"800px", height: disableState ? "484px" : "584px"}}>
            <Box id="BoxCabeceraDataUsuario">
                <IconButton onClick={handle}>
                    <IconoCerrar id="IconoCerrar" />
                </IconButton>
            </Box>
            <Box id="BoxBodyDataUsuario" sx={{height: "490px", padding:"0px 60px 48px 60px"}}>
                <AvatarUsuario 
                titulo={"Mi Informacion"} 
                img={img}
                nombre={nombreCompleto} 
                rol={rol} 
                disableState={disableState}
                disabledHandler={disabledHandler}
                />
                <Box id="BoxDataDelUsuario" sx={{ height: disableState === true ? "380px":"440px",gap: disableState === true ? "16px":"24px"}} component={"form"} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <Box id="BoxContenedorTextField">
                        <TextFieldUno label={"Nombres"} 
                        id={"NombresPerfil"}
                        placeholder={"Nombres"}
                        name={"nombres"}
                        helper={formik.errors.nombres !== null ? formik.errors.nombres : null} 
                        icon={<IconoBorrarCampo id="IconoEnTFUno" />} 
                        handlerChange={formik.handleChange}
                        disabled ={disableState}
                        formHandle={() => formik.setFieldValue("nombres", "")}
                        value={formik.values.nombres}
                        focus={false} 

                        />
                    </Box>
                    <Box id="BoxContenedorTextField">
                        <TextFieldUno label={"Apellidos"}
                        id={"ApellidosPerfil"}
                        placeholder={"Apellidos"}
                        name={"apellidos"}
                        helper={formik.errors.apellidos !== null ? formik.errors.apellidos : null} 
                        icon={<IconoBorrarCampo id="IconoEnTFUno" />} 
                        disabled ={disableState}
                        handlerChange={formik.handleChange}
                        formHandle={() => formik.setFieldValue("apellidos", "")}
                        value={formik.values.apellidos}
                        focus={false} 
                        />
                    </Box>
                    <Box id="BoxContenedorTextField">
                        <TextFieldUno label={"Rut"}
                        id={"RutPerfil"}
                        placeholder={"Rut"}
                        name={"rut"}
                        helper={formik.errors.rut !== null ? formik.errors.rut : null} 
                        icon={<IconoBorrarCampo id="IconoEnTFUno" />} 
                        disabled ={disableState}
                        handlerChange={formik.handleChange}
                        formHandle={() => formik.setFieldValue("rut", "")}
                        value={formik.values.rut}  focus={false} />
                    </Box>
                    <Box id="BoxContenedorTextField">
                        <TextFieldUno label={"Pasaporte"}
                        id={"PasaportePerfil"}
                        placeholder={"Pasaporte"}
                        name={"pasaporte"}
                        helper={formik.errors.pasaporte !== null ? formik.errors.pasaporte : null} 
                        icon={<IconoBorrarCampo id="IconoEnTFUno" />} 
                        disabled ={disableState}
                        handlerChange={formik.handleChange}
                        formHandle={() => formik.setFieldValue("pasaporte", "")}
                        value={formik.values.pasaporte}  focus={false} />
                    </Box>
                    <Box id="BoxContenedorTextField">
                        <TextFieldUno label={"Telefono"}
                        placeholder={"Telefono"}
                        id={"TelefonoPerfil"}
                        name={"telefono"}
                        helper={formik.errors.telefono !== null ? formik.errors.telefono : null} 
                        icon={<IconoBorrarCampo id="IconoEnTFUno" />} 
                        disabled ={disableState}
                        handlerChange={formik.handleChange}
                        formHandle={() => formik.setFieldValue("telefono", "")}
                        value={formik.values.telefono} 
                        focus={false} />
                    </Box>
                    <Box id="BoxCorreoPerfil">
                        <TextFieldUno label="Correo"
                        placeholder="Correo"
                        id="CorreoPerfil"
                        name="telefono"
                        helper={null}
                        icon={<></>} 
                        disabled ={true}
                        handlerChange={null}
                        formHandle={null}
                        value={correoUsuario} 
                        focus={false}
                        /> 
                        <Button id="BotonEditarCorreoPerfilPerfil"
                        onClick={handleOpenConfirmacion}
                        startIcon={
                        <IconoEditarCorreo  id="IconoEditarCorreoPerfil"/>}
                        >
                            Editar Correo
                        </Button>
                    </Box>
                    {
                    disableState === true ?
                    null
                    :
                    <Box id="BoxBotonEnviarInformacion">
                        <BotonConLoading textoBoton={"Guardar cambios"} loadingStatus={false} />
                    </Box>
                    }
                </Box>
            </Box>
            <LoadingMasRespuesta
            open={open} 
            setOpen={setOpen} 
            msj={"Actualizando informacion del usuario..."} 
            id={"RespuestaGuardarPerfil"} 
            loading={loading} 
            respuesta={respuesta} 
            icono={
                respuesta === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={ 
                respuesta === 200 ?
                "Aceptar"
                :
                "Reintentar"
            }
            titulo={respuesta === 200 ? "Cambios Guardados":"Cambios no guardados"}
            mensajeRespuesta={respuesta === 200 ? "Tu perfil ha sido actualizado con éxito":"Oops! Algo salió mal al intentar guardar los cambios, por favor intenta de nuevo"}/>
        </Box>
    );
}