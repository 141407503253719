import axios from "axios";
import {  useSelector } from "react-redux";
//import useConfirmInterceptor from "../../Hooks/ConfirmInterceptor/useConfirmInterceptor";

export function InterceptorRequest(){
    const data = useSelector(action => action.user.authenticated);
    console.log("ingresamos al interceptor");
    axios.interceptors.request.use(
        (config) => {
          
          const token = data.token;
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          Promise.reject(error);
        }
    );
}

export function InterceptorResponse(){

  //const [ConfirmationInterceptor, confirm ]= useConfirmInterceptor();
  //console.log("ingresamos al interceptor response");

  axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // Caso 1: El cliente no tiene conexión a internet o el servidor no responde
        if (!error.response) {
            console.error("Error de red o sin conexión a internet: ", error.message);
            return Promise.reject("No se pudo conectar con el servidor. Verifique su conexión a internet.");
        }

        // Caso 2: La respuesta del servidor tiene un código de error (4xx, 5xx)
        const status = error.response.status;
        if (status >= 400 && status < 500) {
            console.error(`Error en la solicitud: Código ${status} - `, error.response.data);
            return Promise.reject({
                message: `Error del cliente: Código ${status} - ${error.response.data.message || "Error en la petición."}`
            });
        }

        // Caso 3: Error del servidor (5xx)
        if (status >= 500) {
            console.error(`Error en el servidor: Código ${status} - `, error.response.data);
            return Promise.reject({
                message: `Error del servidor: Código ${status} - ${error.response.data.message || "Problema en el servidor."}`
            });
        }
        // Rechazar el error si no cae en los casos anteriores
        return Promise.reject(error);
    }
  );
}



// export function InterceptorResponse(){
//   console.log("ingresamos al interceptor response")
//   axios.interceptors.response.use(
//     response => {
//         return response
//     },
//     error => {
//         if (!error.response) {
//             console.log("Verifique su conexion, el error es ",error);
//         }

