import { Box, Typography } from "@mui/material";
import "./LayoutCrearNuevo.css";


export const LayoutCrearNuevo = ({titulo, children}) =>{

    return (
        <Box id="BoxLayoutCrearNuevo">
            <Box id="BoxTituloSubTituloLayourCrearNuevo">
                <Box id="BoxTituloLayoutCrearNuevo">
                    <Typography id="TxtTituloLayoutCrearNuevo">
                        {
                            titulo
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloLayoutCrearNuevo">
                    <Typography id="TxtSubTituloLayoutCrearNuevo">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            {
                children
            }
        </Box>
    );
}