import { Box, CardMedia, IconButton, Typography } from "@mui/material";
import "./LoginVentanaUno.css"
import { CerrarMenu } from "../../LeftBar/IconosMenu";
import logocp from "./../../../assets/imagenes/CP2.png";
import { BotonConLoading } from "../../Botones/BotonesTabla/Botones";
import { useFormik } from "formik";
import { TextFieldDosPuntoUno } from "../../TextField/TextFieldDos";
import { EmailIcon } from "../LoginIcons";
import { EsquemaRecuperar } from "./Esquemas";
import useRecuperar from "../../../Hooks/Recuperar/useRecuperar";
import { useState } from "react";
import { LoadingMasRespuestaCinco } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { useNavigate } from "react-router-dom";

export const RecuperarForm = ({handleOpcion}) => {
    const [openLoading, setOpenLoading] = useState(false);
    const navigate = useNavigate();
    const { loading, setLoading, response, Recuperar} = useRecuperar();
    const handlerToLogin = () => {
        handleOpcion(1)
    }

    const formik = useFormik({
        initialValues: { correo: ''},
        enableReinitialize:true,
        validationSchema: EsquemaRecuperar,
        onSubmit: () => {
            setLoading(true);
            setOpenLoading(true)
            Recuperar()
            .then(
                (resolve) => {
                    setTimeout(() => {
                        navigate("/Login", { replace: true})
                    }, 5000);
                }
                ,
                (reject) => {
                    
                }
            );
        }
    })

    return(
        <Box id="BoxRecuperarFormVentanUno">
            <Box id="BoxHandlerGoLogin">
                <IconButton id="IconoRetornarRecuperarFormVentanaUno" onClick={handlerToLogin}>
                    <CerrarMenu />
                </IconButton>
            </Box>
            <Box id="BoxLayoutRecuperarFormVentanaUno">
                <Box id="BoxContenidoRecuperarFormVentanaUno">
                    <Box id="BoxContenidoImagenRecuperarFormVentanaUno">
                        <CardMedia id="ImgLogoContenidoRecuperarFormVentanaUno" component={"img"} src={logocp} alt="CP" />
                    </Box>
                    <Box id="BoxContenidoFormularioRecuperarFormVentanaUno" component={"form"} onSubmit={formik.handleSubmit}>
                        <Box id="BoxTituloFormularioRecuperarFormVentanaUno">
                            <Typography id="TxtTituloRecuperarContrasenaFormVentanaUno">
                                {
                                    "¿Olvidaste tu contraseña?"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxNotaFormularioRecuperarFormVentanaUno">
                            <Typography id="TxtNotaRecuperarContrasenaFormVentanaUno">
                                {
                                    "¡No te preocupes! Si tu correo esta registrado, recibirás un correo con las instrucciones para restablecerla."
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxTextFieldFormularioRecuperarFormVentanaUno">
                            <TextFieldDosPuntoUno
                            id={"TxtFieldCorreoLoginFormVentanaUno"}
                            label="Correo electronico"
                            placeholder={"admin@admin.cl"}
                            icon={<EmailIcon id="IconoBorrarCorreoLoginFormVentanaUno" />}
                            disabled={false}
                            helper={formik.errors.correo}
                            value={formik.values.correo}
                            name={"correo"}
                            handlerChange={formik.handleChange}
                            handlerClick={() => formik.setFieldValue("correo", "")}
                            type={"email"}
                            />
                        </Box>
                    </Box>
                    <Box id="BoxContenidoBtnAccionFormVentanaUno">
                        <BotonConLoading
                        textoBoton={"Reestablecer contraseña"} 
                        loadingStatus={false}
                        event={formik.submitForm}
                        />
                    </Box>
                </Box>
            </Box>
            <LoadingMasRespuestaCinco
            open={openLoading}
            setOpen={setOpenLoading}
            msj={"estamos validando tu informacion, por favor espera un momento."}
            id={"LoadingMasRespuestaTresRecuperarContrasena"}
            tituloLoading={"Validando..."}
            loading={loading}
            titulo={response === 200 ? "Validacion exitosa": "Validacion fallida"}
            mensajeRespuesta={response === 200 ? 
                "Te hemos enviado un correo con las instrucciones para reestablecer tu contrasena, seras redirigido al login en 5 segundos."
                :
                "Oops ha fallado la solicitud, reintenta nuevamente o contacte a un administrador"}
            icono={
                response === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                response === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            />
        </Box>
    );
}