import { GestionUsuariosAccesos } from "./Accesos/Accesos"
import { EditarInformacion } from "./EditarInformacion/EditarInformacion"
import { GestionUsuariosFacial } from "./Facial/Facial"
import { GestionUsuariosHorario } from "./Horario/Horario"
import { GestionUsuariosHuella } from "./Huella/Huella"
import { ListaNegra } from "./ListaNegra/ListaNegra"
import { GestionUsuariosPases } from "./Pases/Pases"
import { GestionUsuariosPatentes } from "./RegistroPatentes/RegistroPatentes"
import { Ubicacion } from "./Ubicacion/Ubicacion"

export const SeleccionadorOpcionesMenu = ({opcion, userData, rows, setRows, handleOpen, }) =>{

    if(opcion === 0)
    {
        return <EditarInformacion
        userData={userData} 
        setRows={setRows} 
        rows={rows} 
        handleOpen={handleOpen}
        />
    }
    if(opcion === 1)
    {
        return <GestionUsuariosAccesos userData={userData} />
       
    }

    if(opcion === 2)
    {
        return <GestionUsuariosHorario userData={userData} />
    }

    if(opcion === 3)
    {
        return <GestionUsuariosFacial userData={userData} />
    }
    if(opcion === 4)
    {
        return <GestionUsuariosHuella userData={userData} />
    }

    if(opcion === 5)
    {
        return <GestionUsuariosPatentes userData={userData} />
    }
    if(opcion === 6)
    {
        return <GestionUsuariosPases userData={userData} />
    }

    if(opcion === 7)
    {
        return <ListaNegra userData={userData} />
    }
    if(opcion === 8)
    {
        return <Ubicacion userData={userData} />
    }
}