import { SvgIcon } from "@mui/material";

export function IconoGestionEstacionamientos (props){
    return(
        <SvgIcon {...props}>           
        <svg height="48px" width="48px" version="1.1" xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 628.007 628.007" space="preserve">
                <g>
                    <path d="M314.008,44.738c84.404,0,153.059,68.704,153.059,153.108s-68.674,153.05-153.059,153.05
                        c-84.444,0-153.118-68.655-153.118-153.05S229.565,44.738,314.008,44.738 M314.008,0C204.729,0,116.162,88.557,116.162,197.836
                        c0,109.24,197.846,430.171,197.846,430.171s197.836-320.93,197.836-430.171C511.854,88.557,423.268,0,314.008,0z
                        M364.686,127.882c-10.669-9.614-27.532-14.997-50.492-14.997c-18.72,0-33.394,1.632-44.64,3.507v177.983h23.263v-72.182
                        c5.354,1.368,11.744,1.612,18.7,1.612c22.735,0,42.5-6.693,55.045-19.775c9.125-9.35,13.952-22.198,13.952-38.475
                        C380.484,149.503,374.3,136.411,364.686,127.882z M312.054,205.095c-7.758,0-14.186-0.557-19.257-1.876v-70.003
                        c4.025-1.084,11.744-1.895,21.905-1.895c25.393,0,42.5,11.49,42.5,35.554C357.212,191.202,340.104,205.095,312.054,205.095z"/>
                </g>
        </svg>
        </SvgIcon>
    )
}

