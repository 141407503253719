import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import "./ModalGestionActuador.css"
import { useFormik } from "formik";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import useTipoActuador from "../../../Hooks/TipoActuador/useTipoActuador";
import useGestZonasEmpresa from "../../../Hooks/Zona/useGetZonasEmpresa";
import { TextFieldCuatroMask } from "../../TextField/TextFieldCuatro";
import { EsquemaNuevoActuador } from "./schemasGestionOpcionesActuadores";
import { TextMaskCustomMAC } from "../../../Helper/Mask";

const sentidos = [
    { id: 0 , tipo: "Entrada"},
    { id: 1 , tipo: "Salida"},
    //{ id: 0 , tipo: "Encendido"},
    //{ id: 0 , tipo: "Apagado"}
]


export const VerInformacion = ({ handler, row }) => {
    const { tipoActuadores } = useTipoActuador ();
    const {zonasFormadas} = useGestZonasEmpresa();
    const handlerClose = () => {
        handler(false);
    }


    const formik = useFormik({
        initialValues: { 
            nombreActuador: row.nombre,
            idTipoActuador: tipoActuadores.length > 0 ? row.idTipoActor:0,
            idSentido: row.sentido === "Entrada" ? 0 : 1, 
            mac: row.mac, 
            serial: row.serial, 
            clave:row.clave, 
            ip:row.ip, 
            puerto: row.puerto, 
            idZona: zonasFormadas.length > 0 ? row.idZona : 0,
        },
        enableReinitialize: true,
        validationSchema: EsquemaNuevoActuador,
        onSubmit: () => {
            console.log("no deberia ejecutar nada")
        }
    })
    
    

    return(
        <Box id="BoxAgregarNuevoActuador">
            <Box id="BoxTituloSubtituloOpcionGestionActuador">
                <Box id="BoxTituloOpcionGestionActuador">
                    <Typography id="TxtTituloOpcionGestionActuador">
                        {
                            "Información del actuador"
                        }
                    </Typography>
                </Box>
                {/* <Box id="BoxSubTituloOpcionGestion">
                    <Typography id="TxtSubTituloOpcionGestionActuador">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box> */}
            </Box>
            <Box id="BoxFormularioOpcionGestionActuador" >
                <Box id="BoxContenidoFormularioOpcionGestionActuador" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxInformacionOpcionGestionActuador">
                        <Box id="BoxTituloInformacionOpcionGestionActuador">
                            <Typography id="TxtTitutlosFormulariosOpcionGestionActuador">
                                {
                                    "Información de actuador"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxOpcionesFormularioGestionActuador">
                            <Box id="BoxTextFieldOpcionesFormularioGestionActuador">
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                <TextFieldTres
                                    
                                    id={"nombreActuador"}
                                    label={"Nombre"}
                                    placeholder={"Porton entrada A"}
                                    icon={null}
                                    helper={formik.errors.nombreActuador}
                                    value={formik.values.nombreActuador}
                                    name={"nombreActuador"}
                                    handlerChange={formik.handleChange}
                                    handlerClick={null} 
                                    disabled={true}
                                />
                                </Box>
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                    <FormControl fullWidth sx={{borderBottom:"2px solid #8A8A8A"}}>
                                        <Select 
                                        sx={{
                                           ".Mui-disabled": {
                                            color: "red"
                                           } 
                                        }}
                                        readOnly
                                        id={"idTipoActuador"}
                                        disableUnderline 
                                        variant="filled" 
                                        fullWidth
                                        slotProps={{
                                            input:{
                                                disabled:true,
                                                sx:{
                                                    backgroundColor: "rgb(248, 248, 248)",
                                                }
                                            }
                                        }}
                                        disabled={true}
                                        name={"idTipoActuador"}
                                        value={formik.values.idTipoActuador}
                                        onChange={formik.handleChange}
                                        >
                                            {
                                                tipoActuadores.length > 0 ?
                                                tipoActuadores.map((item, index) => {
                                                    return(
                                                        <MenuItem
                                                        disableRipple
                                                        key={index}
                                                        value={item.id}
                                                        id=""
                                                        >
                                                        <Box id="">
                                                            <Typography>{item.tipo}</Typography>
                                                        </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem
                                                disableRipple
                                                key={0}
                                                value={0}
                                                >
                                                    <Typography>{"obteniendo..."}</Typography>
                                                </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box id="BoxTextFieldOpcionesFormularioGestionActuador">
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                    <FormControl fullWidth sx={{borderBottom:"2px solid #8A8A8A"}}>
                                        <Select 
                                        disabled={true}
                                        slotProps={{
                                            input:{
                                                disabled:true,
                                                sx:{
                                                    backgroundColor: "rgb(248, 248, 248)"
                                                }
                                            }
                                        }}
                                        disableUnderline 
                                        sx={{height:"56px"}} 
                                        variant="filled" 
                                        fullWidth
                                        id={"idSentido"}
                                        name={"idSentido"}
                                        value={formik.values.idSentido}
                                        onChange={formik.handleChange}
                                        >
                                            {
                                                sentidos.length > 0 ?
                                                sentidos.map((item, index) => {
                                                    return(
                                                        <MenuItem
                                                        disableRipple
                                                        key={index}
                                                        value={item.id}
                                                        id=""
                                                        >
                                                        <Box id="">
                                                            <Typography>{item.tipo}</Typography>
                                                        </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem
                                                disableRipple
                                                key={0}
                                                value={0}
                                                id=""
                                                >
                                                    <Box id="">
                                                        <Typography>{"obteniendo..."}</Typography>
                                                    </Box>
                                                </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box id="BoxOpcionTextFielFormularioGestionActuador">
                                    <TextFieldCuatroMask
                                    id={"macActuador"}
                                    mask={TextMaskCustomMAC}
                                    label={"MAC"}
                                    placeholder={"A0-A1-A2-B0-B1-B2"}
                                    icon={null}
                                    helper={formik.errors.mac}
                                    value={formik.values.mac.toUpperCase()}
                                    name={"mac"}
                                    handlerChange={formik.handleChange}
                                    handlerClick={null}
                                    disabled={true}
                                    />
                                </Box>
                            </Box>
                            <Box id="BoxTextFieldOpcionesFormularioGestionActuador">
                                <Box id="BoxOpcionTipoDosTextFielFormularioGestionActuador">
                                    <Box id="BoxOpcionTipoDos">
                                        <TextFieldTres
                                        id={"serial"}
                                        label={"Serial"}
                                        placeholder={"1234656789A"}
                                        icon={null}
                                        helper={formik.errors.serial}
                                        value={formik.values.serial}
                                        name={"serial"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={true}
                                        />
                                    </Box>
                                    <Box id="BoxOpcionTipoDos">
                                        <TextFieldTres
                                        id={"clave"}
                                        label={"Clave"}
                                        placeholder={"ABCDEF45@"}
                                        icon={null}
                                        helper={formik.errors.clave}
                                        value={formik.values.clave}
                                        name={"clave"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={true}
                                        />
                                    </Box>
                                </Box>
                                <Box id="BoxOpcionTipoDosTextFielFormularioGestionActuador">
                                    <Box id="BoxOpcionTipoDos">
                                    <TextFieldTres
                                        id={"ip"}
                                        label={"IP"}
                                        placeholder={"255.255.255.255"}
                                        icon={null}
                                        helper={formik.errors.ip}
                                        value={formik.values.ip}
                                        name={"ip"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={true}
                                        />
                                    </Box>
                                    <Box id="BoxOpcionTipoTres">
                                        <TextFieldTres
                                        id={"puerto"}
                                        label={"Puerto"}
                                        placeholder={"4370"}
                                        icon={null}
                                        helper={formik.errors.puerto}
                                        value={formik.values.puerto}
                                        name={"puerto"}
                                        handlerChange={formik.handleChange}
                                        handlerClick={null}
                                        disabled={true}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxSegundaInformacionOpcionGestionActuador">
                        <Box id="BoxTituloSegundaInformacionOpcionGestionActuador">
                            <Typography id="TxtTitutlosFormulariosOpcionGestionActuador">
                                {
                                    "Información de zona"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxSegundasOpcionesFormularioGestionActuador">
                            <FormControl fullWidth sx={{borderBottom:"2px solid #8A8A8A"}}>
                                <Select 
                                id={"idZona"}
                                name={"idZona"}                                
                                disableUnderline 
                                slotProps={{
                                    input:{
                                        disabled:true,
                                        sx:{
                                            backgroundColor: "rgb(248, 248, 248)"
                                        }
                                    }
                                }}
                                sx={{height:"56px" }} 
                                variant="filled" 
                                fullWidth
                                disabled={true}
                                value={formik.values.idZona}
                                onChange={formik.handleChange}
                                >
                                {
                                    zonasFormadas.length > 0 ?
                                    zonasFormadas.map((item, index) => {
                                            return(
                                                        <MenuItem
                                                        disableRipple
                                                        key={index}
                                                        value={item.id}
                                                        id=""
                                                        >
                                                        <Box id="">
                                                            <Typography>{item.tipo}</Typography>
                                                        </Box>
                                                        </MenuItem>
                                                    )
                                                })
                                        :
                                        <MenuItem
                                            disableRipple
                                            key={0}
                                            value={0}
                                            id=""
                                            >
                                            <Box id="">
                                                <Typography>{"obteniendo..."}</Typography>
                                            </Box>
                                        </MenuItem>
                                }
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box id="BoxHandlerFormularioOpcionGestionActuador">
                    <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Volver"} event={handlerClose} />
                    </Box>
                    {/* <Box id="BoxTamanoBotonHandler">
                        <BotonTipo2 textoBoton={"Agregar"} event={formik.submitForm} />
                        <LoadingMasRespuestaCuatro
                            id={"LoadingMasRespuestaDosNuevoActuador"}
                            open={openLoading}
                            setOpen={setOpenLoading}
                            msj={
                            "Se está agregando un nuevo actuador al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                            }
                            tituloLoading={"Agregando actuador..."}
                            loading={loading}
                            respuesta={response}
                            titulo={
                                response === 201
                                ? "Actuador agregado"
                                : "Actuador no agregado"
                            }
                            mensajeRespuesta={
                                response === 201
                                ? "El actuador ha sido agregado exitosamente al sistema. Ahora puedes gestionar sus configuraciones o añadir otro actuador."
                                : "No se ha podido agregar el actuador debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                            }
                            txtBotonUno={"Agregar nuevo actuador"}
                            eventBotonUno={handleAgregarOtro}
                        />
                    </Box> */}
                </Box>
            </Box>
        </Box>
    );
}