import { Box, CardMedia, Typography } from "@mui/material";
import "./NotFound.css"
import CP2IMG from "./../../assets/imagenes/CP2.png"

const NotFound = () => {
    return(
        <Box id="BoxPaginaNoEncontrada">
            <Box id="BoxTituloError">
                <Box id="BoxContenedorIMGCP2">
                    <CardMedia id="CMImagenCP2" src={CP2IMG} component={"img"} />
                </Box>
                <Box id="BoxTituloPaginaNoEncontrada">
                    <Typography id="TxtTituloPaginaNoEncontrada">
                        {
                            "Error"
                        }
                    </Typography>
                    <Typography id="TxtCodigoPaginaNoEncontrada">
                        {
                            " 404"
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxTituloMensaje">
                <Typography id="TxtMensajePaginaNoEncontrada">
                    {
                        "no se ha encontrado la pagina que estas buscando..."
                    }
                </Typography>
            </Box>
        </Box>
    );
}


export default NotFound;