import { useEffect, useState } from "react";
import { CambiarUbicacion } from "./CambiarUbicacion";
import { LayoutMenuOpcion } from "../../../../Box/OpcionMenuGestionUsuario/LayoutMenuGU";
import { DetalleUbicacion } from "./DetalleUbicacion";
import useUbicacionUsuario from "../../../../../Hooks/Ubicaciones/useUbicacionUsuarios";
import useUbicacionesEvento from "../../../../../Hooks/Ubicaciones/useUbicaciones";
import { EditarUbicacion } from "./EditarUbicacion";

export const Ubicacion = ({userData}) => {
    const { ubicacion } = useUbicacionUsuario(userData.idPersona);
    const [cambiar, setCambiar] = useState(Boolean(false));
    const [editar, setEditar] = useState(Boolean(false));
    const [editarInfo, setEditarInfo] = useState({});
    const [info, setInfo] = useState({});
    const { ubicacionesEvento } = useUbicacionesEvento();

    useEffect(() => {
        setInfo(ubicacion)
    }, [ubicacion])
    


    return (
        cambiar ?
        <CambiarUbicacion setOpen={setCambiar} ubicacionesEvento={ubicacionesEvento} setInfo={setInfo} user={userData.idPersona}/>
        :
        editar ? 
        <EditarUbicacion setEditar={setEditar} ubicacionesEvento={ubicacionesEvento} setInfo={setInfo} user={userData.idPersona} editarInfo={editarInfo} />
        :
        <LayoutMenuOpcion titulo={"Ubicación del usuario"} showIcon={ubicacion.idPersonaUbicacion === null ? true : false} handleIcon={setCambiar}>
            <DetalleUbicacion informacion={info} setOpen={setCambiar} openEditar={setEditar} setEditarInfo={setEditarInfo}/>
        </LayoutMenuOpcion>
    );
}