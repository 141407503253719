import { useState } from "react";
import { CambioContrasena, CambioCorreo, CompletarCambioCorreo, EditarUsuario } from "../../Service/API/Api";
import axios from "axios";

function usePerfil() {
    const [loading, setLoading] = useState(null);
    const [respuesta, setRespuesta] = useState(null);
    const [respuestaCC, setRespuestaCC] = useState(null); 
    const [respuestaCorreo, setRespuestaCorreo] = useState(null); 
    const [respuestaCambioCorreo, setRespuestaCambioCorreo] = useState(null); 

   
    

    const GuardarRespuesta = (res) => 
    {
        setLoading(false);
        setRespuesta(res.status);
    }

    const ActualizarDataUsuario = (valores, idPersona) => {
        var data = {
            "idPersona": idPersona,
            "rut": valores.rut,
            "pasaporte": valores.pasaporte,
            "nombres": valores.nombres,
            "apellidos": valores.apellidos,
            "telefono": valores.telefono
          }

        axios.put(EditarUsuario, data)
        .then((data) => {GuardarRespuesta(data, idPersona)})
        .catch((error) => { 
            console.log(error.response)
        })
    }

    const ActualizarPassWordUsuario = (valores) => {
        var data = {
            "correo": valores.correoEditarPass,
            "contrasenaActual":  valores.passActual,
            "contrasenaNueva":  valores.passNuevaAgregar
        }

        axios.put(CambioContrasena, data)
        .then((data) => {RespuestaActualizar(data)})
        .catch((error) => { console.log(error.response)})
    }

    const RespuestaActualizar = (data) => {
        setRespuestaCC(data.status);
        setLoading(false);
    }

    const CrearCambioCorreo = (correoActual, correoNuevo) =>{
        const data = {
            correoActual: correoActual,
            nuevoCorreo: correoNuevo
        };


        axios.post(CambioCorreo, data)
        .then((data) => {GuardarRespuestaCorreo(data)})
        .catch((error) => { console.log(error.response)})
    }

    const GuardarRespuestaCorreo = (data) => {
        setRespuestaCorreo(data.status);
        setLoading(false);
    };

    const CambiarCorreo = (correoActual, correoNuevo, idEmpresa, token) =>{

        const dataToChange = {
            "correoActual": correoActual,
            "nuevoCorreo": correoNuevo.toUpperCase(),
            "idEmpresa": idEmpresa,
            "token": token
        };
        console.log(dataToChange);

        axios.put(CompletarCambioCorreo, dataToChange)
        .then((data) => {GuardarRespuestaCambioCorreo(data)})
        .catch((error) => { console.log(error.response)})
    }

    const GuardarRespuestaCambioCorreo = (data) => {
        setRespuestaCambioCorreo(data.status);
        setLoading(false);
    };



    return {
        respuesta,
        ActualizarDataUsuario,
        loading,
        setLoading,
        respuestaCC,
        ActualizarPassWordUsuario,
        CrearCambioCorreo,
        respuestaCorreo,
        CambiarCorreo,
        respuestaCambioCorreo
    }
}

export default usePerfil;