import { Box, Typography } from "@mui/material";
import "./RespuestaEvento.css";
import { IconoEventoCreadoCorrectamente, IconoEventoCreadoFallido } from "./RespuestaEventoIcon";
import { ModalInvitar } from "../../Invitados/Invitar";
import { useState } from "react";
import { BotonTipo1, BotonTipo2 } from "../../../Botones/BotonesTabla/Botones";


const style = {
    display: "flex",
    width: "324px",
    height: "316px",
    padding: "80px 40px 40px 40px",
    position: 'absolute',
    flexDirection: "column",
    justifyContent: "center",
    aligItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
  };

export const RespuestaCreacionEvento = ({setOpen, respuesta, titulo, msj, nuevoEvento}) =>{
    const [openInvitacion, setOpenInvitacion] = useState(Boolean(false));
    function handleClose() {
        setOpen(false);
    };

    function handleInvitacion () {
        setOpenInvitacion(true);
    };

    return (
        <Box sx={{ ...style}}> 
            <Box sx={{height:"100%", borderRadius:"16px"}}>
                <Box id="ContentRespuestaNuevoEvento" >
                    <Box id="IconoRespuestaNuevoEvento">
                        {
                            respuesta === 201 ?
                            <IconoEventoCreadoCorrectamente id="IconoRespuestaNuevoEvento" />
                            :
                            <IconoEventoCreadoFallido id="IconoRespuestaNuevoEvento" />
                        }
                    </Box>
                    <Box id="TipoRespuesta">
                        <Typography id="TituloRespuestaNE">
                        {
                            "¡" + titulo + "!"
                        }
                        </Typography>
                        <Typography id="TextoRespuestaNE">
                        {
                            msj
                        }
                        </Typography>
                    </Box>
                    {
                        respuesta === 201 ?
                        <Box id="BoxAceptarRespuesta">
                            <Box id="BoxBotonRespuestaCreacion">
                                <BotonTipo2 textoBoton={"Más tarde"}  event={handleClose} />
                            </Box>
                            <Box id="BoxBotonRespuestaCreacion">
                                <BotonTipo2 textoBoton={"Invitar ahora"}  event={handleInvitacion} />
                            </Box>
                            <ModalInvitar openInvitacion={openInvitacion} setOpenInvitacion={setOpenInvitacion} idVisita={nuevoEvento.listaId[0]} urlVisita={nuevoEvento.urlVisita} />
                        </Box>
                        :
                        <Box id="BoxAceptarRespuestaNE">
                            <BotonTipo1 textoBoton={"Reintentar"}  event={handleClose} />
                        </Box>
                    }
                </Box>
            </Box>
        </Box>        
    );
};
