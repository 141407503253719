import * as Yup from 'yup';

export const EsquemaValidacionEditarTarjeta = Yup.object({
    idTipoTarjeta: Yup.number()
    .test('min', "Valor no permitido", function(value){
        if(value > 0 ){
            return true;
        }
        else{
            return false;
        }
    })
    ,
    codigo: Yup.string()
    .required('* Campo requerido'),
})