import { Box, Typography } from '@mui/material';
import React from 'react';

const LeyendaGraficoCircular = ({dato, totales}) => {
    const width = ( (400 / totales)  * 1.5 *  dato.value ).toString() +'px'
    
    return (
        <>
            <Box id='componenteLeyenda'> 
                <Box id='CentrarLeyendaDerecha'> 
                    <Typography id='LetraLeyendaDerecha'>{dato.nombre} </Typography> 
                </Box>

                <Box id='CentrarLeyendaDerecha'> 
                    <Typography id='LetraLeyendaDerecha' >{dato.value} personas </Typography> 
                </Box> 
            </Box>
            <Box id='boxlineasGraficoCircular'>
                <Box sx={{ borderRadius:'4px', height:'4px', width: width, backgroundColor: dato.color, marginRight:'-3px', zIndex:'1'}}></Box>
                {/* <Box sx={{ borderRadius:'4px', height:'4px', width: width, marginRight:'-3px', zIndex:'1'}}></Box> */}
                <Box sx={{ borderRadius:'4px', height:'4px', width:'100%', backgroundColor:'#DBDBDB', }} ></Box>
            </Box>
        </>
    );
}

export default LeyendaGraficoCircular;
