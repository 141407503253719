import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import "./RespuestaServidor.css";
import { IconoExito, IconoFallo } from "./RespuestaServidorIcon";
import { BotonTipo1, BotonTipo2 } from "../../Botones/BotonesTabla/Botones";

const styleRespuesta = {
    display: "flex",
    width: "404px",
    height: "436px",
    position: 'absolute',
    flexDirection: "column",
    justifyContent: "center",
    aligItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
  };

export const RespuestaServidor = ({setOpen, msj, titulo, icono, textoBoton }) =>{
   
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ ...styleRespuesta}}> 
            <Card sx={{height:"100%", borderRadius:"16px"}} >
                <CardContent id="ContentRespuesta"  >
                    <Box id="BoxRespuesta">
                        <Box id="BoxIconoRespuesta">
                            <CardMedia id="MediaRespuestaServidor" >
                                {
                                    icono
                                }
                            </CardMedia>
                        </Box>
                        <Box id="TipoRespuestaServidor">
                            <Box id="BoxTituloRespuesta">
                                <Typography id="TituloRespuestaServidor">
                                    {
                                        "¡"+ titulo +"!"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTextoRespuesta">
                                <Typography id="TextoRespuestaServidor">
                                    {
                                        "¡"+ msj +"!"
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxAccionesRespuestaServidor">
                        <BotonTipo1 textoBoton={textoBoton} event={handleClose} />
                    </Box>
                </CardContent>
            </Card>
        </Box>
           
    );
};

const styleRespuestaDos = {
    display: "flex",
    width: "404px",
    position: 'absolute',
    flexDirection: "column",
    justifyContent: "center",
    aligItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
};

export const RespuestaServidorDos = ({setOpen, msj, titulo, respuesta, eventBotonUno, txtBotonUno, eventoBotonDos, txtBotonDos }) =>{
   
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ ...styleRespuestaDos}}> 
            <Card sx={{height:"100%", borderRadius:"16px"}} >
                <CardContent id="ContentRespuestaDos" sx={{ heigth: respuesta === 201 ? "464px":"358px"}}  >
                    <Box id="BoxRespuesta">
                        <Box id="BoxIconoRespuesta">
                            <CardMedia id="MediaRespuestaServidor">
                                {
                                respuesta === 201 ?
                                    <IconoExito id="IconoRespuestaServidor" />
                                    :
                                    <IconoFallo id="IconoRespuestaServidor" />
                                }
                            </CardMedia>
                        </Box>
                        <Box id="TipoRespuestaServidor">
                            <Box id="BoxTituloRespuesta">
                                <Typography id="TituloRespuestaServidor">
                                    {
                                        "¡"+ titulo +"!"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTextoRespuesta">
                                <Typography id="TextoRespuestaServidor">
                                    {
                                        "¡"+ msj +"!"
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxAccionesRespuestaServidorDos" height={respuesta === 201 ? "176px":"48px"}>
                        {
                            respuesta === 201 ?
                            <>
                                <Box id="BoxBotonUno">
                                    <BotonTipo2 textoBoton={txtBotonUno} event={eventBotonUno}/>

                                </Box>
                                <Box id="BoxBotonDos">
                                    <BotonTipo2 textoBoton={txtBotonDos} event={eventoBotonDos}/>
                                </Box>
                            </>
                            :
                            null
                        }
                        <Box id="BoxBotonDos">
                            <BotonTipo2 textoBoton={respuesta === 201 ? "Aceptar" : "Reintentar"} event={handleClose} />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
           
    );
};

const styleRespuestaTres = {
    display: "flex",
    width: "404px",
    position: 'absolute',
    flexDirection: "column",
    justifyContent: "center",
    aligItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
};

export const RespuestaServidorTres = ({setOpen, msj, titulo, respuesta, eventBotonUno, txtBotonUno }) =>{
   
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ ...styleRespuestaTres}}> 
            <Card sx={{height:"100%", borderRadius:"16px"}} >
                <CardContent id="ContentRespuestaTres" sx={{ heigth: respuesta === 201 ? "400px":"324px"}}  >
                    <Box id="BoxRespuesta">
                        <Box id="BoxIconoRespuesta">
                            <CardMedia id="MediaRespuestaServidor">
                                {
                                respuesta === 201 ?
                                    <IconoExito id="IconoRespuestaServidor" />
                                    :
                                    <IconoFallo id="IconoRespuestaServidor" />
                                }
                            </CardMedia>
                        </Box>
                        <Box id="TipoRespuestaServidor">
                            <Box id="BoxTituloRespuesta">
                                <Typography id="TituloRespuestaServidor">
                                    {
                                        "¡"+ titulo +"!"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTextoRespuesta">
                                <Typography id="TextoRespuestaServidor">
                                    {
                                        "¡"+ msj +"!"
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxAccionesRespuestaServidorDos" height={respuesta === 201 ? "112px":"48px"}>
                        {
                            respuesta === 201 ?
                            <Box id="BoxBotonUno">
                                <BotonTipo2 textoBoton={txtBotonUno} event={eventBotonUno}/>
                            </Box>
                            :
                            null
                        }
                        <Box id="BoxBotonDos">
                            <BotonTipo2 textoBoton={respuesta === 201 ? "Aceptar" : "Reintentar"} event={handleClose} />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
           
    );
};

export const RespuestaServidorCuatro = ({ msj, titulo, icono, textoBoton, onclickButton }) =>{

    return (
        <Box sx={{ ...styleRespuesta}}> 
            <Card sx={{height:"100%", borderRadius:"16px"}} >
                <CardContent id="ContentRespuesta"  >
                    <Box id="BoxRespuesta">
                        <Box id="BoxIconoRespuesta">
                            <CardMedia id="MediaRespuestaServidor" >
                                {
                                    icono
                                }
                            </CardMedia>
                        </Box>
                        <Box id="TipoRespuestaServidor">
                            <Box id="BoxTituloRespuesta">
                                <Typography id="TituloRespuestaServidor">
                                    {
                                        "¡"+ titulo +"!"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTextoRespuesta">
                                <Typography id="TextoRespuestaServidor">
                                    {
                                        "¡"+ msj +"!"
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxAccionesRespuestaServidor">
                        <BotonTipo1 textoBoton={textoBoton} event={onclickButton} />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

//Igual al tres con handle close personalizado
export const RespuestaServidorCinco = ({setOpen, msj, titulo, respuesta, eventBotonUno, txtBotonUno, handleClose }) =>{

    return (
        <Box sx={{ ...styleRespuestaTres}}> 
            <Card sx={{height:"100%", borderRadius:"16px"}} >
                <CardContent id="ContentRespuestaTres" sx={{ heigth: respuesta === 201 ? "400px":"324px"}}  >
                    <Box id="BoxRespuesta">
                        <Box id="BoxIconoRespuesta">
                            <CardMedia id="MediaRespuestaServidor">
                                {
                                respuesta === 201 ?
                                    <IconoExito id="IconoRespuestaServidor" />
                                    :
                                    <IconoFallo id="IconoRespuestaServidor" />
                                }
                            </CardMedia>
                        </Box>
                        <Box id="TipoRespuestaServidor">
                            <Box id="BoxTituloRespuesta">
                                <Typography id="TituloRespuestaServidor">
                                    {
                                        "¡"+ titulo +"!"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTextoRespuesta">
                                <Typography id="TextoRespuestaServidor">
                                    {
                                        "¡"+ msj +"!"
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxAccionesRespuestaServidorDos" height={respuesta === 201 ? "112px":"48px"}>
                        {
                            respuesta === 201 ?
                            <Box id="BoxBotonUno">
                                <BotonTipo2 textoBoton={txtBotonUno} event={eventBotonUno}/>
                            </Box>
                            :
                            null
                        }
                        <Box id="BoxBotonDos">
                            <BotonTipo2 textoBoton={respuesta === 201 ? "Aceptar" : "Reintentar"} event={handleClose} />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
           
    );
};

//Igual a respuesta servidor con handle close personalizado
export const RespuestaServidorSeis = ({setOpen, msj, titulo, icono, textoBoton, handleClose }) =>{
    return (
        <Box sx={{ ...styleRespuesta}}> 
            <Card sx={{height:"100%", borderRadius:"16px"}} >
                <CardContent id="ContentRespuesta"  >
                    <Box id="BoxRespuesta">
                        <Box id="BoxIconoRespuesta">
                            <CardMedia id="MediaRespuestaServidor" >
                                {
                                    icono
                                }
                            </CardMedia>
                        </Box>
                        <Box id="TipoRespuestaServidor">
                            <Box id="BoxTituloRespuesta">
                                <Typography id="TituloRespuestaServidor">
                                    {
                                        "¡"+ titulo +"!"
                                    }
                                </Typography>
                            </Box>
                            <Box id="BoxTextoRespuesta">
                                <Typography id="TextoRespuestaServidor">
                                    {
                                        "¡"+ msj +"!"
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box id="BoxAccionesRespuestaServidor">
                        <BotonTipo1 textoBoton={textoBoton} event={handleClose} />
                    </Box>
                </CardContent>
            </Card>
        </Box>
           
    );
};