import { SvgIcon } from "@mui/material";

export function CerrarPerfilIcon(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M11 1L1 11M1.00001 1L11 11" stroke="currentColor" color="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    );
}