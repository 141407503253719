import * as Yup from 'yup';

export const EsquemaNuevaPatente = Yup.object({
    nombreEstacionamiento: Yup.string()
    .required('* Campo requerido')
    .max(30, "maximo de 30 caracteres")
    , 
    idZonaEstacionamiento: Yup.number()
    .test('min', "Debe seleccionar una zona", function(value){
        if(value > 0 ){
          return true;
        }
        else{
          return false;
        }
      })
    , 
    // idPersona: Yup.number()
    // .nullable(), 
    // idInvitado: Yup.number()
    // .nullable()
    // ,
})