import { useEffect, useState } from "react";
import "./ModeloTablaInvitados.css";
import { DataGrid, GridActionsCellItem, GridRowModes, GridRowEditStopReasons} from "@mui/x-data-grid";
import { IconoCancelarCambios, IconoEditar, IconoEliminarInvitado, IconoGuardarCambios} from "./ModeloTablaIcons";
import { Box, Typography } from "@mui/material";
import IMGConfirmacionIndividual from "../../../assets/imagenes/ImgMCESingle.png";
import { BotonTipo1 } from "../../Botones/BotonesTabla/Botones";
import CabeceraInvitados from "./CabeceraTabla/CabeceraInvitados";
import ModalEnvioInvitados from "../../Modal/EnvioConLoading/ModalEnvioInvitados";
import { IconoExito, IconoFallo } from "../../Modal/RespuestaServidor/RespuestaServidorIcon";
import { IconoOpcionesUsuarios } from "../../Usuarios/UsuariosIcon";
import TooltipTipoUno from "../../ToolTip/Tooltip";
import useInvitados from "../../../Hooks/Eventos/useInvitados";
import useConfirmDos from "../../../Hooks/ConfirmDialog/useConfirmDialogDos";
import { useSelector } from "react-redux";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";
import useVisitante from "../../../Hooks/Visitante/useVisitante";
import { MenuTipoUno } from "../../Menu/MenuTipoUno";
import { MenuCorreoInvitado } from "../../Box/MenuCorreoInvitado/MenuCorreoInvitado";
import useInvitadosEvento from "../../../Hooks/Eventos/useInvitadosEvento";

 
export const TablaInvitadosEvento = ({idVisita }) => {
    const {setLoading, loading, respuesta, EnviarInvitacionesMasivas, EliminarInvitado} = useInvitados();
    const {invitados, setReload, loadingRow, setLoadingRow} = useInvitadosEvento(idVisita);
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const [selectedRows, setSelectedRows] =  useState([]);
    const [ConfirmationDialogDos, confirmDos]= useConfirmDos();
    const data = useSelector(action => action.user.authenticated)
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const { EditarVisitanteData } = useVisitante();
    const [anchorEl, setAnchorEl] = useState(null);
    const [infoEnviarCorreo, setInfoEnviarCorreo] = useState({idVisita: null, idVisitante: null});

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleActualizar = () => {
        console.log("actualizar");
        setLoadingRow(true)

        console.log("loadignRow", loadingRow);
        setReload(true);
    }

    useEffect(() => {
        setRows(invitados);
    }, [invitados])
    

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
    const handleCancelClick = (id) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow =  rows.find((row) => row.idVisitaVisitante === id);
        if (editedRow.isNew) {
          setRows(rows.filter((row) => row.idVisitaVisitante !== id));
        }
    };

    
    const processRowUpdate = (newRow, oldRow) => {
        EditarVisitanteData(newRow)
        .then(
            (resolve) => {
                const updatedRow = { ...newRow };
                setRows(rows.map((row) => (row.idVisitaVisitante === newRow.idVisitaVisitante ? updatedRow : row)));
                setRowModesModel({
                    ...rowModesModel,
                    [newRow.idVisitaVisitante]: { mode: GridRowModes.View, ignoreModifications: true },
                });
                setSnackbarSeverity("success")
                setSnackMsg("Invitado actualizado exitosamente!")
                setSnackbar(true);
                return updatedRow;
            }
            ,
            (reject) => {
                const noUpdatedRow = { ...oldRow };
                setRows(rows.map((row) => (row.idVisitaVisitante === noUpdatedRow.idVisitaVisitante ? noUpdatedRow : row)));
                setRowModesModel({
                    ...rowModesModel,
                    [noUpdatedRow.idVisitaVisitante]: { mode: GridRowModes.View, ignoreModifications: true },
                });
                setSnackbarSeverity("error")
                setSnackMsg("fallo la actualizacion revirtiendo cambios!")
                setSnackbar(true);
                return noUpdatedRow;
            }
        )
    };
    
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const HandleDelete = (id) => async () => {
        let response = await confirmDos();
        if(response === true)
            {
                EliminarInvitado(id)
                .then(
                    (resolve) => {
                        setRows(rows.filter((row) => row.idVisitaVisitante !== id ));
                        setSnackbarSeverity("success")
                        setSnackMsg("Invitado eliminado exitosamente!")
                        setSnackbar(true);
                    }
                    ,
                    (reject) => {
                        setSnackbarSeverity("error")
                        setSnackMsg("error en la eliminacion, reintente!")
                        setSnackbar(true);
                    }
                )
            }
        else
            {
                console.log("usuario rechaza la eliminacion");
            }
    };
    const handleEnviar = () => {
      	setLoading(true);
      	setOpenLoading(true)
      	EnviarInvitacionesMasivas(idVisita, rows)
        .then(
            (resolve) => {
                setSnackbarSeverity("success")
                setSnackMsg("Invitaciones enviadas exitosamente!")
                setSnackbar(true);
            }
            ,
            (reject) => {
                setSnackbarSeverity("error")
                setSnackMsg("fallo el envio, reintente!")
                setSnackbar(true);
            }
        )
    };

    const open = Boolean(anchorEl);
    
    const handleMenuCorreo = (e, row) =>{
        setInfoEnviarCorreo({
            idVisita: idVisita,
            idVisitante: row.idVisitante
        })
        setAnchorEl(e.currentTarget);
    }
    const colName = [
        {
            field: 'nombres', headerName: 'Nombres', type: 'string', width: 130, headerAlign: 'center', align:'center', sortable: false, editable: true
        },
        {
            field: 'apellidos', headerName: 'Apellidos', type: 'string', width: 140, headerAlign: 'center', align:'center', sortable: false, editable: true
        },
        {
            field: 'rut', headerName: 'Rut', width: 150, type: 'string',  valueFormatter: (params) => params?.value === null ? "-":params?.value, headerAlign: 'center', align:'center', sortable: false, editable: true
        },
        {
            field: 'pasaporte', headerName: 'Pasaporte', width: 190, type: 'string',  valueFormatter: (params) => params?.value === null ? "-":params?.value, headerAlign: 'center', align:'center', sortable: false, editable: true
        },
        {
            field: 'correo', headerName: 'Correo', type: 'string', width: 220, headerAlign: 'center', align:'center', sortable: false, editable: true
        },
        {
            field: 'patente', headerName: 'Patente', type: 'string', valueFormatter: (params) => params?.value === null ? "-":params?.value, width: 100, headerAlign: 'center', align:'center', sortable: false, editable: true
        }
        ,
        {
            field: 'estadoAceptacion', headerName: 'Invitacion', type: 'string', width: 140, headerAlign:'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 80, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', 
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        
                if (isInEditMode) {
                  return [
                    <TooltipTipoUno titulo={"Guardar cambios"} ubicacion={"right-start"}>
                        <GridActionsCellItem
                        icon={<IconoGuardarCambios id='iconoEnTabla' />}
                        label="Guardar"
                        onClick={handleSaveClick(id, row)}
                        />
                    </TooltipTipoUno>,
                  ];
                }
                else{
                    return [
                      <TooltipTipoUno titulo={"Editar invitado"} ubicacion={"right-start"}>
                        <GridActionsCellItem
                          icon={<IconoEditar  id='iconoEnTabla' />}
                          label="Editar"
                          onClick={handleEditClick(id)}
                        />
                      </TooltipTipoUno>,
                    ];
                }
            }
        },
        {
            field: 'eliminar', headerName: 'Eliminar', type: 'actions', headerAlign: 'center', width: 80, align:'center', sortable: false, cellClassName: 'actions',
            getActions: ({ id }) => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      
                if (isInEditMode) {
                    return [
                    <TooltipTipoUno titulo={"Cancelar edicion"} ubicacion={"right-start"}>
                        <GridActionsCellItem
                        icon={<IconoCancelarCambios  id='iconoEnTabla' />}
                        label="Cancelar"
                        onClick={handleCancelClick(id)}
                        />
                    </TooltipTipoUno>,
                    ];
                }
                else{
                  return [
                    <TooltipTipoUno titulo={"Eliminar invitado del evento"} ubicacion={"right-start"}>
                      <GridActionsCellItem
                        icon={<IconoEliminarInvitado  id='iconoEnTabla'/>}
                        label="Eliminar"
                        onClick={HandleDelete(id)}
                      />
                    </TooltipTipoUno>,
                  ];
              }
            }
        }
        ,
        {
            field: 'opciones', headerName: 'Opciones', type: 'actions', headerAlign: 'center', width: 90, align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({row}) => {
              return [
                <TooltipTipoUno titulo={"Gestionar Correos"} ubicacion={"right-start"}>
                    <GridActionsCellItem
                    icon={<IconoOpcionesUsuarios  id='iconoEnTablaUsuarios'/>}
                    label="Opciones"
                    onClick = {(e) => handleMenuCorreo(e, row)}
                    />
                </TooltipTipoUno>
              ];
            }
        },

    ];

    return (
      <Box>
        <Box id="BoxConTenidoInvitaciones">
            <Box id="BoxLabelTituloModal">
                <Typography id="TextoTituloModal">
                    {
                        "Invitados"
                    }
                </Typography>
            </Box>
            <Box id="BoxManejoNuevoInvitado">
                <CabeceraInvitados 
                setRows={setRows} 
                selectedRows={selectedRows} 
                idVisita={idVisita}
                actualizar={handleActualizar}
                />
                <Box id="BoxListaInvitados">
                    <DataGrid id="TablaInvitados"  
                        style={{ width:'inherit'}}
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                            color:'#175676',
                        },
                            '& .MuiDataGrid-columnHeader': {
                            borderBottom: '2px solid #94B1C0',
                        },
                        '& .MuiDataGrid-row':{
                            borderBottom:'1px solid #94B1C0'
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                            width: "7.024px",
                            background: "transparent"
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                            background: "transparent"
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                            background: "#C9C9C9",
                            borderRadius: "100px"
                        }
                        }}
                        columns={colName}
                        rows={rows}
                        getRowId={(row) => row.idVisitaVisitante}
                        initialState={{
                            pagination:{
                            paginationModel: {
                                page: 0,
                                pageSize: 10,
                                }
                            },
                        }}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={(error) => console.log("Error row update", error)}
                        pageSizeOptions={[10, 20]}
                        checkboxSelection
                        loading={loadingRow}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        disableVirtualization={true}
                        showCellVerticalBorder={false}
                        showColumnVerticalBorder={false}
                        hideFooterSelectedRowCount={true}
                        rowSelection
                        onRowSelectionModelChange={(newSelection) => {
                            setSelectedRows(newSelection);
                        }}
                        >
                    </DataGrid>
                </Box>
            </Box>
        </Box>
        <Box id="BoxBotonEnviarInvitacion">
          <Box id="BoxPosicionBotonEnviar" >
            <BotonTipo1 textoBoton={"Enviar"} event={handleEnviar} />
          </Box> 
        </Box>

        <ConfirmationDialogDos
        img={IMGConfirmacionIndividual}
        titulo={"Eliminar invitado"}
        mensaje={"estas seguro(a) de eliminar a este invitado de la lista"}
        usuario={data.usuario.split(' ')[0]}
        />

        <SnackBarTipoUno
        open={snackbar}
        handler={handleCloseSnackbar}
        duration={6000}
        severity={snackbarSeverity}
        msg={snackMsg}
        />

        <ModalEnvioInvitados 
        open={openLoading} 
        setOpen={setOpenLoading} 
        msj={"Enviando tu invitación! Este proceso puede tomar unos minutos. Agradecemos tu espera"} 
        id={"ModalEnvioInvitados"} 
        loading={loading}
        respuestaMsj={respuesta === 200 ? "Su invitación ha sido enviada con éxito a su lista de invitados":"Error al enviar invitación! Intente nuevamente más tarde"}
        respuestTitulo={respuesta === 200 ? "Invitación enviada":"Error al enviar"}
            icono={respuesta === 200 ?
          <IconoExito id="IconoRespuestaServidor" />
          :
          <IconoFallo id="IconoRespuestaServidor" />
        }
        textoBoton={respuesta === 200 ?
          "Aceptar"
          :
          "Reintentar"
        }
        />
        <MenuTipoUno
        open={open}
        anchorEl={anchorEl}
        setOpen={setAnchorEl}
        children={<MenuCorreoInvitado infoEnviarCorreo={infoEnviarCorreo} />}
        />
      </Box>
    );
}