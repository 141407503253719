import Dashboard from "../../Components/Dashboard/Dashboard";
import React from 'react';
import { Patentes } from "../../Components/GestionPatentes/Patentes";

export default function GestionPatentesPage() {
  return (
    <Dashboard>
        <Patentes />
    </Dashboard>
  )
}
