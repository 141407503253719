export const OpcionesGA =[
    {
        id: 1,
        titulo: "Acceso a Zonas",
        icon: null,
        opcion: 1
    }
    ,
    {
        id: 3,
        titulo: "Horario de Acceso",
        icon: null,
        opcion: 2
    }
]

export const Registros = [
    {
        id: 1,
        titulo: "Facial",
        opcion: 3,
        icon: null,
    }
    ,
    {
        id: 2,
        titulo: "Huellas",
        icon: null,
        opcion: 4
    }
    ,
    {
        id: 3,
        titulo: "Patentes",
        icon: null,
        opcion: 5
    },
    {
        id: 4,
        titulo: "TAG/RFID",
        icon: null,
        opcion: 6
    }
]