import { useEffect, useState } from "react";
import { LayoutMenuOpcion } from "../../../../Box/OpcionMenuGestionUsuario/LayoutMenuGU";
import "./ListaNegra.css";
import { AgregarListaNegra } from "./AgregarListaNegra";
import { ExisteEnListaNegra } from "./DetalleListaNegra";
import useListaNegraUsuario from "../../../../../Hooks/ListaNegra/useListaNegraUsuario";
import { useSelector } from "react-redux";

export const ListaNegra = ({userData}) =>{
    const data = useSelector(action => action.user.authenticated);
    const [info, setInfo] = useState({});
    const [agregar, setAgregar] = useState();
    const {listaNegra} = useListaNegraUsuario(userData.rut, data.idEmpresa);
    
    useEffect(() => {
        setInfo(listaNegra)
    }, [listaNegra])
    
    return (
        agregar ?
        <AgregarListaNegra setOpen={setAgregar} userData={userData} setInfo={setInfo}/>
        :
        <LayoutMenuOpcion titulo={"Lista Negra"} showIcon={listaNegra.idListaNegra === null || listaNegra.idListaNegra === undefined ? true : false} handleIcon={setAgregar}>
            <ExisteEnListaNegra informacion={info} setOpenAgregar={setAgregar} setInfo={setInfo} />
        </LayoutMenuOpcion>
    );
}