import { useState } from "react";
import "./Facial.css";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { IconoEliminarRow } from "../../MenuGestionUsuarioIcon";
import useFacial from "../../../../../Hooks/Facial/useFacial";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { Box } from "@mui/material";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import { LoadingMasRespuestaCuatro } from "../../../LoadingMasRespuesta/LoadingMasRespuesta";


export const FacialTablaGU = ({facial, setRows}) =>{
    const {EliminarRegistro, loading, setLoading, response} = useFacial();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [openLoading, setOpenLoading] = useState(Boolean(false));

    const handleCloseLoading = () => () =>{
        setOpenLoading(false);
    }

    const handleEliminar = (id) => async () =>{
        let responseConfirm = await confirm();
        if(responseConfirm === true)
        {
            setLoading(true);
            setOpenLoading(true);
            await EliminarRegistro(id)
            .then(
                (resolve) => {
                    setRows(facial.filter((row) => row.idFacial !== id ));
                }
                ,
                (reject) => {
                    console.log("sin reject")
                }
            )
        
        }
        else
        {
            console.log("sin cambios")
        }
    }


    const columnNames = [
        {
            field: 'codigo', headerName: 'Codigo', type: 'string', width: 790, headerAlign: 'left', align:'left', sortable: false, editable: false
        }
        ,
        {
            field: 'eliminar', headerName: 'Eliminar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id}) => {
                return [
                    <GridActionsCellItem
                      icon={<IconoEliminarRow  id='iconoEnTablaFacial'/>}
                      label="Eliminar"
                      onClick={handleEliminar(id)}
                    />,
                  ];
            }
        },

    ];


    return(
        <Box>
            <DataGrid
            id="DataGridFacialGU"
            rows={facial}
            columns={columnNames}
            sx={{
                width:"894px",
                height:"424px",
                '& .MuiDataGrid-columnHeaderTitle': {
                    color:'#175676',
                    },
                        '& .MuiDataGrid-columnHeader': {
                        borderBottom: '2px solid #94B1C0',
                    },
                    '& .MuiDataGrid-row':{
                        borderBottom:'1px solid #94B1C0'
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                        width: "7.024px",
                        background: "transparent"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                        background: "transparent"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                        background: "#C9C9C9",
                        borderRadius: "100px"
                    }
                }}
            getRowId={(row) => row.idFacial} //reeemplazar luego por el id definido idMetodos y agregar snackbar
            initialState={{
                pagination:{
                paginationModel: {  
                    page: 0,
                    pageSize: 10,
                    }
                },
            }}
            rowSelection
            pageSizeOptions={[10, 20]}
            disableRowSelectionOnClick
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            />

            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={"¿Eliminar Registro?"}
            mensaje={"¿Estás seguro de querer eliminar el registro facial? Esta acción no se puede deshacer y eliminará permanentemente el registro asociado al usuario. ¿Deseas continuar?"}
            />
            <LoadingMasRespuestaCuatro
                id={"LoadingMasRespuestaTablaFacial"}
                open={openLoading}
                setOpen={setOpenLoading}
                msj={"Se esta procesando la eliminación del registro facial. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."}
                tituloLoading={"Eliminando registro..."}
                loading={loading}
                respuesta={response}
                titulo={response === 200 ? "Registro Eliminado":"Error al Eliminar"}
                mensajeRespuesta={response === 200 ? 
                    "El registro se ha eliminado exitosamente y el usuario ya no tiene acceso a través de este. Ahora tienes la opción de actualizar los accesos del usuario agregando un nuevo registro."
                    :
                    "Ha ocurrido un error y no se pudo eliminar el regitro facial. Por favor, intenta de nuevo más tarde y si el problema persiste, contáctanos para asistencia."}
                txtBotonUno={"Agregar nuevo registro"}
                txtBotonDos={null}
                eventBotonUno={handleCloseLoading}
                eventoBotonDos={null} 
                />
        </Box>
        
    );
}