import { Box, Card, CardContent, CardMedia, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, {useState } from 'react';
import { useFormik } from "formik";
import * as Yup from 'yup';
import CP2 from  "..//..//../assets/imagenes/CP2.png"
import "./stylesCambiarEmail.css"
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { BotonConLoading } from '../../../Components/Botones/BotonesTabla/Botones';
import usePerfil from '../../../Hooks/OpcionesPerfil/usePerfil';
import {LoadingMasRespuesta} from '../../../Components/Modal/LoadingMasRespuesta/LoadingMasRespuesta';
import { useLocation } from "react-router-dom";
import { IconoExito, IconoFallo } from '../../../Components/Modal/RespuestaServidor/RespuestaServidorIcon';

const ValidationSchema = Yup.object({
    correoActual: Yup.string().email(),
    nuevoCorreo: Yup.string().email('Ingresa con el formato: ejemplo@dominio.cl').required('* Campo requerido'),
    confirmacionCorreo: Yup.string().email('Ingresa con el formato: ejemplo@dominio.cl').required('* Campo requerido').oneOf([Yup.ref("nuevoCorreo"), null], "*El nuevo correo y el de confirmación no coinciden. Por favor inténtelo de nuevo."),

})


const CambiarEmail = () => {
    const {loading, CrearCambioCorreo, setLoading, respuestaCorreo } = usePerfil();
    const location = useLocation();
    const [abrir, setAbrir] = useState(Boolean(false));

    let correoActual = location.state === null ? "" : location.state.correoActual; 
    const formik = useFormik(
        {
        initialValues: {
            correoActual: '',
            nuevoCorreo: '',
            confirmacionCorreo: '',
        },
        validationSchema: ValidationSchema,
        onSubmit: () => 
            {
                setLoading(true);
                CrearCambioCorreo(correoActual, formik.values.nuevoCorreo);
                setAbrir(true);
            }
        }
    );

    const setcorreoNuevo = () =>{
        formik.setFieldValue('nuevoCorreo','')
        formik.setFieldValue('nuevoCorreo2','')
    }


    return (
        <Box component="div" id="boxRestablecerCorreo">
            <Card id="CardRecuperarCorreo">
                <CardContent id="CardContenidoRecuperarCorreo">

                    <Box id="BoxImagenCambiarCorreo">
                        <CardMedia id="imagen" image={CP2}></CardMedia> 
                    </Box>
                    <Box id="BoxTituloCambiarMail">
                        <Typography id="TextoTituloCambiarMail">
                            {
                                "Cambio de correo electrónico"
                            }
                        </Typography>
                        <Typography id="ComentarioRecuperacion">
                            {
                                "Por favor complete su informacion y confirme el cambio."
                            }
                        </Typography>
                    </Box>
                    <Box id="FormContrasenas" component={"form"} onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
                        <Box component="div" sx={{ mb:"20px" }}>
                            <TextField 
                            sx={{mb:'8px'}}
                                variant="filled" 
                                inputProps={{
                                    style: {
                                        color: "#433",
                                        font: "Roboto",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px",
                                    }
                                }}
                                InputLabelProps={{
                                        style: {
                                            color: "#626262",
                                            font: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            fontStyle: "normal",
                                            lineHeight: "24px",
                                            letterSpacing: "0.5px",
                                            }
                                    }}
                                fullWidth id='correoActual'
                                label='Correo Actual' name='correoActual'
                                value={correoActual}
                                disabled={true}
                                >
                            </TextField>
                        </Box>

                        <Box component="div" sx={{ mb :"24px" }}>
                            <TextField
                                variant="filled" 
                                inputProps={{
                                    style: {
                                        color: "#433",
                                        font: "Roboto",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px",
                                    }
                                }}
                                InputLabelProps={{
                                        style: {
                                            color: "#626262",
                                            font: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            fontStyle: "normal",
                                            lineHeight: "24px",
                                            letterSpacing: "0.5px",
                                            }
                                    }}
                                required fullWidth id='nuevoCorreo'
                                label='Correo nuevo' name='nuevoCorreo'
                                value={formik.values.nuevoCorreo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={loading}
                                InputProps={{ 
                                    style: { backgroundColor: "#f8f8f8"},
                                    endAdornment: 
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                sx={{color:'#8A8A8A'}}    
                                                onClick={setcorreoNuevo}                        
                                                >
                                                <CancelOutlinedIcon   />
                                            </IconButton> 
                                        </InputAdornment>
                                }}
                                helperText= 
                                {
                                    formik.errors.nuevoCorreo !== null ?
                                    <span id="errorSpanCambiarMail">{formik.errors.nuevoCorreo}</span>
                                    :
                                    null
                                }
                                >
                            </TextField>

                        </Box>

                        <Box component="div">
                            <TextField
                                variant="filled" 
                                inputProps={{
                                    style: {
                                        color: "#433",
                                        font: "Roboto",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        letterSpacing: "0.5px",
                                    }
                                }}
                                InputLabelProps={{
                                        style: {
                                            color: "#626262",
                                            font: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            fontStyle: "normal",
                                            lineHeight: "24px",
                                            letterSpacing: "0.5px",
                                            }
                                    }}
                                required fullWidth 
                                id='nuevoCorreo2'
                                label='Confirmar correo nuevo' 
                                name='confirmacionCorreo'
                                value={formik.values.confirmacionCorreo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={loading}
                                InputProps={{ 
                                    style: { backgroundColor: "#f8f8f8"},
                                    endAdornment: 
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                                sx={{color:'#8A8A8A'}}    
                                                onClick={setcorreoNuevo}                        
                                                >
                                                <CancelOutlinedIcon    />
                                            </IconButton> 
                                        </InputAdornment>
                                }}
                                helperText= 
                                {
                                    formik.errors.confirmacionCorreo !== null ?
                                    <span id="errorSpanCambiarMail">{formik.errors.confirmacionCorreo}</span>
                                    :
                                    null
                                }
                                >
                            </TextField>
                        </Box>

                        <Box id="BoxBotonCambiarEmail">
                            <BotonConLoading textoBoton={"Cambiar Correo Electrónico"} loadingStatus={false}/>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            {/* Modal */}
        
            <LoadingMasRespuesta
            open={abrir}
            setOpen={setAbrir}
            icono={
                respuestaCorreo === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                respuestaCorreo === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            msj={"Creando solicitud..."}
            id={"LoadingRespuestaCambioCorreo"}
            loading={loading}
            respuesta={respuestaCorreo}
            titulo={respuestaCorreo === 200 ? "Solicitud exitosa": "Solicitud fallida"}
            mensajeRespuesta={respuestaCorreo === 200 ? "Te hemos enviado un correo electrónico con las instrucciones para modificar tu correo": "Oops ha fallado la solicitud, reintenta nuevamente..."}
            />

        </Box>
    );
}

export default CambiarEmail;
