import axios from "axios";
import { useEffect, useState } from "react"
import { CrearUbicacion, EditarUbicacion, UbicacionesEnEmpresa } from "../../Service/API/Api";

export default function useUbicacionesGestion() {
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();
    const [ubicaciones, setUbicaciones] = useState([]);
    

    useEffect(() => {
        setLoading(true);
        axios.get(UbicacionesEnEmpresa)
        .then(
            (response) => {
                setUbicaciones(response.data)
                setLoading(false);
            }
        ).catch(
            (error) => {
                console.log(error.response);
                setLoading(false);
            }
        )
    }, [])

    const GuardarUbicacion = (values) => {
        return new Promise(async (resolve, reject) => {
            let nuevaUbicacion = {
                "idZona": values.idZona,
                "idTipoUbicacion": values.idTipoUbicacion,
                "nombre": values.nombreUbicacion,
                "piso": values.piso,
                "numeracion": values.numeracion
            }
            await axios.post(CrearUbicacion, nuevaUbicacion)    
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            );
            
        });
    }

    const EditarUbicacionExistente = (idUbicacion, values) => {
        return new Promise(async (resolve, reject) => {
            let nuevaUbicacion = {
                "idUbicacion": idUbicacion,
                "idZona": values.idZona,
                "idTipoUbicacion": values.idTipoUbicacion,
                "nombre": values.nombreUbicacion,
                "piso": values.piso,
                "numeracion": values.numeracion
            }
            await axios.put(EditarUbicacion, nuevaUbicacion)    
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            );
            
        });
    }
   
    

    return {
        ubicaciones,
        loading,
        setLoading,
        response,
        GuardarUbicacion,
        EditarUbicacionExistente
    }
}