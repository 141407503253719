import * as Yup from 'yup';

export const EsquemaNuevaAsignacion = Yup.object({
    codigo: Yup.number(),
    tipo: Yup.string(),
    idPersona: Yup.number()
    .test("min", "Debe seleccionar un usuario", function(value) {
        if(value === -1){
            return false;
        }
        else {
            return true;
        }
    })
    ,
    idPatente: Yup.number()
    .test("min", "Debe seleccionar una patente", function(value) {        
        if(value === -1){
            return false;
        }
        else {
            return true;
        }
    })
    .nullable(),
    
})


export const EsquemaEditarAsignacion = Yup.object({
    codigo: Yup.number(),
    tipo: Yup.string(),
    idPersona: Yup.number(),
    idPatente: Yup.number() 
    .nullable(),   
})