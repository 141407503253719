import { Box, FormControl, IconButton, MenuItem, Select } from "@mui/material";
import useAgenda from "../../../../Hooks/Agenda/useAgenda";
import { Mayorque, Menorque } from "../../../Calendario/CalendarioIcons";
import dayjs from "dayjs";
import "./HandlerOpciones.css"


export const CabeceraAnio = ({fechaActual, setYear, addYear, restYear}) => {
    const {Anios} = useAgenda();

    var anioFinded = Anios.find(m => m.anio === dayjs(fechaActual).year())
    

    return (
        <Box id="BoxCabeceraOpcionAnio">
            <Box id="BoxContenedorHandlerAnio">
                <Box>
                    <IconButton onClick={restYear}>
                        <Menorque id="iconoAccionVistaSemanaAgenda" />
                    </IconButton>
                </Box>
                <Box id="BoxContenedorSelectAnioAgenda">
                    <FormControl fullWidth>
                        <Select
                        variant="standard"
                        disableUnderline
                        value={anioFinded.anio}
                        onChange={(e) => setYear(e.target.value)}
                        >   
                        {
                            Anios.map((item, index) => {
                                return(
                                    <MenuItem key={index} value={item.anio}>
                                        {
                                            item.anio
                                        }
                                    </MenuItem>
                                )
                            })
                        }
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <IconButton onClick={addYear} >
                        <Mayorque id="iconoAccionVistaSemanaAgenda" />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}