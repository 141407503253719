import React from 'react';
import Dashboard from '../../Components/Dashboard/Dashboard';
import Graficos from '../../Components/Graficos/Graficos';


const GraficosC = () => {
    return (
        <Dashboard>
            <Graficos/>
        </Dashboard>
    );
}

export default GraficosC;
