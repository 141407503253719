import Dashboard from "../../Components/Dashboard/Dashboard";
import Accesos from "../../Components/GestionAccesos/Accesos";


const GestionDeAccesos = () => {
    return (
        <Dashboard>
            <Accesos />
        </Dashboard>
    );
}

export default GestionDeAccesos;
