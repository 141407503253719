import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function SeleccionTiempo({ timeSet, handleTime, namePicker, name, helper }) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker 
        name={name} 
        ampm={false} 
        slotProps={{
        textField: {
          variant: "standard",
          InputProps: {
            disableUnderline: true
          },
          sx: {
            borderBottom: "1px solid black",
            width: "100%",
            height: "100%",
            "& .MuiButtonBase-root": { marginRight: "0px" },
            "& .MuiInputBase-input": { marginLeft: "10px" },
            "& .MuiFormLabel-root": { marginLeft: "10px" },
          },
          helperText:helper
        }
      }} views={["hours", "minutes"]}
        format='HH:mm'
        value={timeSet}
        onChange={handleTime}
        label={namePicker} />
    </LocalizationProvider>
  );
}



