import axios from "axios";
import { useEffect, useState } from "react"
import { BuscarPatentePersona } from "../../Service/API/Api";


export default function usePatentesUsuario(idPersona){
    const [patentes, setPatentes] = useState([]);

    useEffect(() => {
        axios.get(BuscarPatentePersona + idPersona)
        .then((response) => {
            setPatentes(response.data);
        })
        .catch((error) =>{
            console.log(error.response)
        });
    }, [idPersona])
    
    

    return{
        patentes,
    }
}