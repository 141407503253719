const dayjs = require('dayjs')

export const horaEvento = (fechaEvento) =>{
    let date = dayjs(fechaEvento).format('H')
    date = parseInt(date)
    return date
}

export const FechaHoyGraficas = (fechaEvento) =>{
    let date = dayjs(fechaEvento).format('YYYY-MM-DD')
    return date
}

export const GetDiaSemana = (fechaEvento) =>{
    let date = dayjs(fechaEvento).format('d')
    date = parseInt(date)  
    console.log(date)
    // if(date===7){
    //     date = 0
    // }
    return date
}