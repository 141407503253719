import { SvgIcon } from "@mui/material";

export function IconoEditar(props){
    return(
        <SvgIcon {...props}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dd_4141_52474)">
            <rect x="4" y="3" width="40" height="40" rx="20" fill="white"/>
            <path d="M30 21L33 18L29 14L26 17M30 21L20 31H16V27L26 17M30 21L26 17" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <filter id="filter0_dd_4141_52474" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4141_52474"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="1.5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4141_52474"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_4141_52474" result="effect2_dropShadow_4141_52474"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4141_52474" result="shape"/>
            </filter>
            </defs>
            </svg>


        </SvgIcon>
    );
}


export function IconoCamara(props){
    return(
        <SvgIcon {...props}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_4101_104251)">
                    <rect x="4" y="3" width="40" height="40" rx="20" fill="white"/>
                    <path d="M33 28V20C33 18.8954 32.1046 18 31 18H28.5C27.9477 18 27.5 17.5523 27.5 17C27.5 16.4477 27.0523 16 26.5 16H21.5C20.9477 16 20.5 16.4477 20.5 17C20.5 17.5523 20.0523 18 19.5 18H17C15.8954 18 15 18.8954 15 20V28C15 29.1046 15.8954 30 17 30H31C32.1046 30 33 29.1046 33 28Z" stroke="#175676" strokeWidth="1.5"/>
                    <path d="M27 24C27 25.6569 25.6569 27 24 27C22.3431 27 21 25.6569 21 24C21 22.3431 22.3431 21 24 21C25.6569 21 27 22.3431 27 24Z" stroke="#175676" strokeWidth="1.5"/>
                </g>
                <defs>
                    <filter id="filter0_dd_4101_104251" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_4101_104251"/>
                        <feOffset dy="1"/>
                        <feGaussianBlur stdDeviation="1.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4101_104251"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="1"/>
                        <feGaussianBlur stdDeviation="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                        <feBlend mode="normal" in2="effect1_dropShadow_4101_104251" result="effect2_dropShadow_4101_104251"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4101_104251" result="shape"/>
                    </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
}