import axios from "axios";
import { useState } from "react";
import { EliminarFacial } from "../../Service/API/Api";

export default function useFacial(){
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();

    const CrearNuevoFacial = (lector, idPersona) =>{
        return new Promise((resolve, reject) => {
            // const nuevoFacial = {
            //     idActor: lector,
            //     idPersona: idPersona
            // }
            setTimeout(() => {
                resolve({idFacial:1, codigo:"565565s65df65f6s56f5s6fsdf"});
                setLoading(false);
                setResponse(201);//response.status
            }, 5000);
            // axios.post("CrearHuellaEnDispo", nuevaHuella)
            // .then(
            //     (response) => {
            //         resolve(response.data);
            //         setLoading(false);
            //         setResponse(response.status);
            //     }
            // )
            // .catch(
            //     (error) => {
            //         reject(error.response);
            //         setLoading(false);
            //         setResponse(response.status);
            //     }
            // )
        }) 
    }



    const EliminarRegistro = (idFacial) =>{
        return new Promise((resolve, reject) => {
           
            axios.delete(EliminarFacial + idFacial)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    reject(false);
                    setLoading(false);
                    setResponse(error.status);
                }
            )
        }) 
    }

    return{
        loading,
        setLoading,
        response,
        CrearNuevoFacial,
        EliminarRegistro
    }
}