import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import "./CuerpoAnio.css";
import { useEffect, useState } from "react";

export const CuerpoAnio = ({fechaActual, setNewDate, setOpcion}) => {
    const [vistaYear, setVistaYear] = useState([]);

    useEffect(() => {
        var mesesAnio = [];

        for (let index = 0; index <= 11; index++) { //11 
            var mes = dayjs(fechaActual).set("month", index);
            
            var listaSemanasMes = ObtenerDiasOrdenados(mes);
            
            listaSemanasMes.forEach(semanas => {
                var fechaInicioSemana = dayjs(semanas.fechaInicio).set("h", 0).set("m", 0).set("s", 0);
                var fechaTerminoSemana = dayjs(semanas.fechaFin).set("h", 23).set("m", 59).set("s", 59)
            
                var listaDias = [{ id:0, fecha: fechaInicioSemana, eventos: []}, 
                                { id:1, fecha: fechaInicioSemana.add(1, 'days'), eventos: []},
                                { id:2, fecha: fechaInicioSemana.add(2, 'days'), eventos: []},
                                { id:3, fecha: fechaInicioSemana.add(3, 'days'), eventos: []},
                                { id:4, fecha: fechaInicioSemana.add(4, 'days'), eventos: []},
                                { id:5, fecha: fechaInicioSemana.add(5, 'days'), eventos: []},
                                { id:6, fecha: fechaTerminoSemana, eventos: []}]
                semanas.diasEnSemana = listaDias
            });

            var agregarMes = {
                id: index,
                month: mes.month(),
                diasMes: listaSemanasMes
            }

            mesesAnio.push(agregarMes)
        }

        setVistaYear(mesesAnio);

    }, [fechaActual])
    

    return(
        <Box id="BoxLayoutVistaAnio">
            <Box id="BoxVistaAniosCalendarios">
                <Grid container id="BoxContenedorMesesVistaAnios">
                    {
                        vistaYear.map((anio, index) => {
                            return(
                                <BoxDetalleMes key={index} listaMeses={anio} setDay={setNewDate} setOpcion={setOpcion} />
                            );
                        })
                    }
                </Grid>
            </Box>
        </Box>
    );
}

const Meses = [
    {id:0, nombre:"Enero"},
    {id:1, nombre:"Febrero"},
    {id:2, nombre:"Marzo"},
    {id:3, nombre:"Abril"},
    {id:4, nombre:"Mayo"},
    {id:5, nombre:"Junio"},
    {id:6, nombre:"Julio"},
    {id:7, nombre:"Agosto"},
    {id:8, nombre:"Septiembre"},
    {id:9, nombre:"Octubre"},
    {id:10, nombre:"Noviembre"},
    {id:11, nombre:"Diciembre"},
]

const Semana = [
    {id:0, nombre:"Do"},
    {id:1, nombre:"Lu"},
    {id:2, nombre:"Ma"},
    {id:3, nombre:"Mi"},
    {id:4, nombre:"Ju"},
    {id:5, nombre:"Vi"},
    {id:6, nombre:"Sa"},
]

const BoxDetalleMes = ({listaMeses, setDay, setOpcion}) => {
    
    var fechaMes = Meses.find(m => m.id === listaMeses.month).nombre
    var mesCargando = listaMeses.month

    const HandlerToDay = (nuevaFecha) => () => {
        setDay(nuevaFecha);
        setOpcion(2)
    }

    return(
        <Box id="BoxGeneralContenedorMesVistaAnio">
            <Box id="BoxContenedorNombreMes">
                <Typography id="TxtNombreMesVistaAnio">
                    {
                        fechaMes
                    }
                </Typography>
            </Box>
            <Divider orientation="vertical" sx={{height: "100%" }} />
            <Box id="BoxContenedorSemanasDias">
                <Box id="BoxContenedorGridDiasSemanaVistaAnio">
                    <Grid container spacing={0} id="BoxNombreDiasSemanaOrdenadosVistaAnio">
                        {
                            Semana.map((item, index) => {
                                return(
                                    <Grid item key={index} id="DiaSemanaCortosVistaAnio">
                                        <Typography id="TxtNombreDiaSemanaVistaAnio">
                                            {
                                                item.nombre
                                            }
                                        </Typography>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Box>
                <Box id="BoxContenedoMesCompletoVistaAnio">
                    {
                        listaMeses.diasMes.map((item, index) => {
                            return(
                                <Grid container id="BoxRowDias" spacing={0} key={index} >
                                    {
                                        item.diasEnSemana.map((item, index) => {
                                            var diaIgual = dayjs(item.fecha).format("DD/MM/YYYY") === dayjs(new Date()).format("DD/MM/YYYY")
                                            var mesIgual = dayjs(item.fecha).month() === mesCargando
                                                
                                            return(
                                                <Button key={index} id="btnToDiaView" onClick={HandlerToDay(item.fecha)} sx={{ textTransform: "none", borderRadius: "25px", border: diaIgual ? "1px solid #103D54": null}}>
                                                    <Typography id="TxtItemDiaGridVistaAnio" color={mesIgual ? "#103D54":"#94B1C0" } padding={"0px"}>
                                                        {
                                                            dayjs(item.fecha).format("DD")
                                                        }
                                                    </Typography>
                                                </Button>
                                            );
                                        })
                                    }
                                </Grid>
                            );
                        })
                    }
                </Box>
            </Box>
        </Box>
    );
}

const ObtenerDiasOrdenados = (fechaActual) => {
    let ListaSemana = [
        {id: 0, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 1, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 2, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 3, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 4, fechaInicio: '', fechaFin: '', diasEnSemana: []},
        {id: 5, fechaInicio: '', fechaFin: '', diasEnSemana: []},
    ]

    var diaInicioEnSemana = dayjs(fechaActual).set('D', 1).day();
    var fechaInicio = dayjs(fechaActual).set('D', 1);

    if(diaInicioEnSemana === 0)
    {
        var primero0 = ListaSemana.find(d => d.id === 0)
        primero0.fechaInicio = dayjs(fechaInicio);
        primero0.fechaFin = dayjs(fechaInicio).add(6, 'day');

        var segunda0 = ListaSemana.find(d => d.id === 1)
        segunda0.fechaInicio = dayjs(fechaInicio).add(7, 'day');
        segunda0.fechaFin = dayjs(fechaInicio).add(13, 'day');

        var tercera0 = ListaSemana.find(d => d.id === 2)
        tercera0.fechaInicio = dayjs(fechaInicio).add(14, 'day');
        tercera0.fechaFin = dayjs(fechaInicio).add(20, 'day');
        
        var cuarta0 = ListaSemana.find(d => d.id === 3)
        cuarta0.fechaInicio = dayjs(fechaInicio).add(21, 'day');
        cuarta0.fechaFin = dayjs(fechaInicio).add(27, 'day');

        var quinta0 = ListaSemana.find(d => d.id === 4)
        quinta0.fechaInicio = dayjs(fechaInicio).add(28, 'day');
        quinta0.fechaFin = dayjs(fechaInicio).add(34, 'day');

        var sexta0 = ListaSemana.find(d => d.id === 5)
        sexta0.fechaInicio = dayjs(fechaInicio).add(35, 'day');
        sexta0.fechaFin = dayjs(fechaInicio).add(41, 'day');
    }

    if(diaInicioEnSemana === 1)
    {
        var primero1 = ListaSemana.find(d => d.id === 0)
        primero1.fechaInicio = dayjs(fechaInicio).add(-1, 'day');;
        primero1.fechaFin = dayjs(fechaInicio).add(5, 'day');
    
        var segunda1 = ListaSemana.find(d => d.id === 1)
        segunda1.fechaInicio = dayjs(fechaInicio).add(6, 'day');
        segunda1.fechaFin = dayjs(fechaInicio).add(12, 'day');

        var tercera1 = ListaSemana.find(d => d.id === 2)
        tercera1.fechaInicio = dayjs(fechaInicio).add(13, 'day');
        tercera1.fechaFin = dayjs(fechaInicio).add(19, 'day');

        var cuarta1 = ListaSemana.find(d => d.id === 3)
        cuarta1.fechaInicio = dayjs(fechaInicio).add(20, 'day');
        cuarta1.fechaFin = dayjs(fechaInicio).add(26, 'day');
        
        var quinta1 = ListaSemana.find(d => d.id === 4)
        quinta1.fechaInicio = dayjs(fechaInicio).add(27, 'day');
        quinta1.fechaFin = dayjs(fechaInicio).add(33, 'day');
        
        var sexta1 = ListaSemana.find(d => d.id === 5)
        sexta1.fechaInicio = dayjs(fechaInicio).add(34, 'day');
        sexta1.fechaFin = dayjs(fechaInicio).add(40, 'day');
    }

    if(diaInicioEnSemana === 2)
    {
        var tercero2 = ListaSemana.find(d => d.id === 0)
        tercero2.fechaInicio = dayjs(fechaInicio).add(-2, 'day');;
        tercero2.fechaFin = dayjs(fechaInicio).add(4, 'day');
        
        var segunda2 = ListaSemana.find(d => d.id === 1)
        segunda2.fechaInicio = dayjs(fechaInicio).add(5, 'day');
        segunda2.fechaFin = dayjs(fechaInicio).add(11, 'day');
        
        var tercera2 = ListaSemana.find(d => d.id === 2)
        tercera2.fechaInicio = dayjs(fechaInicio).add(12, 'day');
        tercera2.fechaFin = dayjs(fechaInicio).add(18, 'day');
                
        var cuarta2 = ListaSemana.find(d => d.id === 3)
        cuarta2.fechaInicio = dayjs(fechaInicio).add(19, 'day');
        cuarta2.fechaFin = dayjs(fechaInicio).add(25, 'day');
        
        var quinta2 = ListaSemana.find(d => d.id === 4)
        quinta2.fechaInicio = dayjs(fechaInicio).add(26, 'day');
        quinta2.fechaFin = dayjs(fechaInicio).add(32, 'day');
        
        var sexta2 = ListaSemana.find(d => d.id === 5)
        sexta2.fechaInicio = dayjs(fechaInicio).add(33, 'day');
        sexta2.fechaFin = dayjs(fechaInicio).add(39, 'day');
    }

    if(diaInicioEnSemana === 3)
    {
        var primera3 = ListaSemana.find(d => d.id === 0)
        primera3.fechaInicio = dayjs(fechaInicio).add(-3, 'day');;
        primera3.fechaFin = dayjs(fechaInicio).add(3, 'day');
            
        var segunda3 = ListaSemana.find(d => d.id === 1)
        segunda3.fechaInicio = dayjs(fechaInicio).add(4, 'day');
        segunda3.fechaFin = dayjs(fechaInicio).add(10, 'day');
            
        var tercera3 = ListaSemana.find(d => d.id === 2)
        tercera3.fechaInicio = dayjs(fechaInicio).add(11, 'day');
        tercera3.fechaFin = dayjs(fechaInicio).add(17, 'day');
                    
        var cuarta3 = ListaSemana.find(d => d.id === 3)
        cuarta3.fechaInicio = dayjs(fechaInicio).add(18, 'day');
        cuarta3.fechaFin = dayjs(fechaInicio).add(24, 'day');
            
        var quinta3 = ListaSemana.find(d => d.id === 4)
        quinta3.fechaInicio = dayjs(fechaInicio).add(25, 'day');
        quinta3.fechaFin = dayjs(fechaInicio).add(31, 'day');
            
        var sexta3 = ListaSemana.find(d => d.id === 5)
        sexta3.fechaInicio = dayjs(fechaInicio).add(32, 'day');
        sexta3.fechaFin = dayjs(fechaInicio).add(38, 'day');
    }

    if(diaInicioEnSemana === 4)
    {
        var primero4 = ListaSemana.find(d => d.id === 0)
        primero4.fechaInicio = dayjs(fechaInicio).add(-4, 'day');;
        primero4.fechaFin = dayjs(fechaInicio).add(2, 'day');
                
        var segunda4 = ListaSemana.find(d => d.id === 1)
        segunda4.fechaInicio = dayjs(fechaInicio).add(3, 'day');
        segunda4.fechaFin = dayjs(fechaInicio).add(9, 'day');
                
        var tercera4 = ListaSemana.find(d => d.id === 2)
        tercera4.fechaInicio = dayjs(fechaInicio).add(10, 'day');
        tercera4.fechaFin = dayjs(fechaInicio).add(16, 'day');
                        
        var cuarta4 = ListaSemana.find(d => d.id === 3)
        cuarta4.fechaInicio = dayjs(fechaInicio).add(17, 'day');
        cuarta4.fechaFin = dayjs(fechaInicio).add(23, 'day');
                
        var quinta4 = ListaSemana.find(d => d.id === 4)
        quinta4.fechaInicio = dayjs(fechaInicio).add(24, 'day');
        quinta4.fechaFin = dayjs(fechaInicio).add(30, 'day');
                
        var sexta4 = ListaSemana.find(d => d.id === 5)
        sexta4.fechaInicio = dayjs(fechaInicio).add(31, 'day');
        sexta4.fechaFin = dayjs(fechaInicio).add(37, 'day');
    }

    if(diaInicioEnSemana === 5)
    {
        var primero5 = ListaSemana.find(d => d.id === 0)
        primero5.fechaInicio = dayjs(fechaInicio).add(-5, 'day');
        primero5.fechaFin = dayjs(fechaInicio).add(1, 'day');;

        var segunda5 = ListaSemana.find(d => d.id === 1)
        segunda5.fechaInicio = dayjs(fechaInicio).add(2, 'day');
        segunda5.fechaFin = dayjs(fechaInicio).add(8, 'day');

        var tercera5 = ListaSemana.find(d => d.id === 2)
        tercera5.fechaInicio = dayjs(fechaInicio).add(9, 'day');
        tercera5.fechaFin = dayjs(fechaInicio).add(15, 'day');
        
        var cuarta5 = ListaSemana.find(d => d.id === 3)
        cuarta5.fechaInicio = dayjs(fechaInicio).add(16, 'day');
        cuarta5.fechaFin = dayjs(fechaInicio).add(22, 'day');

        var quinta5 = ListaSemana.find(d => d.id === 4)
        quinta5.fechaInicio = dayjs(fechaInicio).add(23, 'day');
        quinta5.fechaFin = dayjs(fechaInicio).add(29, 'day');

        var sexta5 = ListaSemana.find(d => d.id === 5)
        sexta5.fechaInicio = dayjs(fechaInicio).add(30, 'day');
        sexta5.fechaFin = dayjs(fechaInicio).add(36, 'day');
    }

    if(diaInicioEnSemana === 6)
    {
        var primero6 = ListaSemana.find(d => d.id === 0)
        primero6.fechaInicio = dayjs(fechaInicio).add(-6, 'day');
        primero6.fechaFin = dayjs(fechaInicio);
    
        var segunda6 = ListaSemana.find(d => d.id === 1)
        segunda6.fechaInicio = dayjs(fechaInicio).add(1, 'day');
        segunda6.fechaFin = dayjs(fechaInicio).add(7, 'day');

        var tercera6 = ListaSemana.find(d => d.id === 2)
        tercera6.fechaInicio = dayjs(fechaInicio).add(8, 'day');
        tercera6.fechaFin = dayjs(fechaInicio).add(14, 'day');
        
        var cuarta6 = ListaSemana.find(d => d.id === 3)
        cuarta6.fechaInicio = dayjs(fechaInicio).add(15, 'day');
        cuarta6.fechaFin = dayjs(fechaInicio).add(21, 'day');

        var quinta6 = ListaSemana.find(d => d.id === 4)
        quinta6.fechaInicio = dayjs(fechaInicio).add(22, 'day');
        quinta6.fechaFin = dayjs(fechaInicio).add(28, 'day');

        var sexta6 = ListaSemana.find(d => d.id === 5)
        sexta6.fechaInicio = dayjs(fechaInicio).add(29, 'day');
        sexta6.fechaFin = dayjs(fechaInicio).add(35, 'day');
    }

    return ListaSemana
}

