import {Box, FormControl, IconButton, MenuItem, Select} from "@mui/material";
import "./HandlerOpciones.css"
import { Mayorque, Menorque } from "../../../Calendario/CalendarioIcons";
import useAgenda from "../../../../Hooks/Agenda/useAgenda";
import dayjs from "dayjs";



const Meses = [
    {id:0, nombre:"Enero"},
    {id:1, nombre:"Febrero"},
    {id:2, nombre:"Marzo"},
    {id:3, nombre:"Abril"},
    {id:4, nombre:"Mayo"},
    {id:5, nombre:"Junio"},
    {id:6, nombre:"Julio"},
    {id:7, nombre:"Agosto"},
    {id:8, nombre:"Septiembre"},
    {id:9, nombre:"Octubre"},
    {id:10, nombre:"Noviembre"},
    {id:11, nombre:"Diciembre"},
]




export const CabeceraSemana = ({fechaActual, addMonth, restMonth, setMonth, setYear, addYear, restYear}) => {
    const {Anios} = useAgenda();
    var monthFinded = Meses.find(m => m.id === dayjs(fechaActual).month())
    var anioFinded = Anios.find(m => m.anio === dayjs(fechaActual).year())
    

    return (
        <Box id="BoxCabeceraOpcionSemana">
            <Box id="BoxContenedorHandlerMes">
                <Box>
                    <IconButton onClick={restMonth}>
                        <Menorque id="iconoAccionVistaSemanaAgenda" />
                    </IconButton>
                </Box>
                <Box id="BoxContenedorSelectMesesAgenda">
                    <FormControl fullWidth>
                        <Select
                        variant="standard"
                        disableUnderline 
                        sx={{
                            borderRadius: "100px"
                        }}                     
                        value={monthFinded.id}
                        onChange={(e) => setMonth(e.target.value)}
                        >   
                        {
                            Meses.map((item, index) => {
                                return(
                                    <MenuItem key={index} value={item.id}>
                                        {
                                            item.nombre
                                        }
                                    </MenuItem>
                                )
                            })
                        }
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <IconButton onClick={addMonth}>
                        <Mayorque id="iconoAccionVistaSemanaAgenda" />
                    </IconButton>
                </Box>
            </Box>
            <Box id="BoxContenedorHandlerYear">
                <Box>
                    <IconButton onClick={restYear}>
                        <Menorque id="iconoAccionVistaSemanaAgenda" />
                    </IconButton>
                </Box>
                <Box id="BoxContenedorSelectAnioAgenda">
                    <FormControl fullWidth>
                        <Select
                        variant="standard"
                        disableUnderline
                        value={anioFinded.anio}
                        onChange={(e) => setYear(e.target.value)}
                        >   
                        {
                            Anios.map((item, index) => {
                                return(
                                    <MenuItem key={index} value={item.anio}>
                                        {
                                            item.anio
                                        }
                                    </MenuItem>
                                )
                            })

                        }
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <IconButton onClick={addYear} >
                        <Mayorque id="iconoAccionVistaSemanaAgenda" />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}