import { SvgIcon } from "@mui/material";

export function FechaIconVerificarEvento (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M19 9V17C19 18.1046 17.9926 19 16.75 19H3.25C2.00736 19 1 18.1046 1 17V5C1 3.89543 2.00736 3 3.25 3H16.75C17.9926 3 19 3.89543 19 5V9ZM19 9H10H1M13.375 1V5M6.625 1V5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function HoraIconVerificarEvento (props){
    return(
        <SvgIcon {...props}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.363 6.85715C11.8896 6.85715 11.5059 7.2409 11.5059 7.71429V11.9792C11.5059 12.4571 11.677 12.9192 11.9882 13.2818L14.3001 15.9753C14.6423 16.374 15.2515 16.3972 15.6231 16.0256C15.9591 15.6896 15.977 15.1507 15.6641 14.7931L13.715 12.5655C13.396 12.201 13.2201 11.733 13.2201 11.2485V9.42858V7.71429C13.2201 7.2409 12.8364 6.85715 12.363 6.85715Z" fill="#333333"/>
                <path d="M12.3633 21C17.3338 21 21.3633 16.9706 21.3633 12C21.3633 7.02944 17.3338 3 12.3633 3C7.39272 3 3.36328 7.02944 3.36328 12C3.36328 16.9706 7.39272 21 12.3633 21Z" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function UbicacionIconVerificarEvento (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
                <path d="M9 12.1111C10.6569 12.1111 12 10.7544 12 9.08081C12 7.40722 10.6569 6.05051 9 6.05051C7.34314 6.05051 6 7.40722 6 9.08081C6 10.7544 7.34314 12.1111 9 12.1111Z" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 21C13.4183 18.9798 17 13.5437 17 9.08081C17 4.6179 13.4183 1 9 1C4.58172 1 1 4.6179 1 9.08081C1.45 15.0909 6 19.6364 9 21Z" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function UbicacionIconVerificarDelete (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14 6L6 14M6.00001 6L14 14M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#8A8A8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}

export function UbicacionIconVerificarAdmiracion (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 14.99V15M10 5V12M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#CC261A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        </SvgIcon>
    )
}

export function UbicacionIconVerificarFlecha (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
            <path d="M1 1L9 9L17 1" stroke="#8A8A8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
        )
}

export function UbicacionIconVerificarAgendado (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
                <g filter="url(#filter0_dd_6035_270684)">
                    <path d="M76.4444 36.3333L42.2221 70.5557L27.5553 55.8889M96 51C96 75.3005 76.3005 95 52 95C27.6995 95 8 75.3005 8 51C8 26.6995 27.6995 7 52 7C76.3005 7 96 26.6995 96 51Z" stroke="#338333" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <filter id="filter0_dd_6035_270684" x="0" y="0" width="104" height="104" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_6035_270684"/>
                        <feOffset dy="1"/>
                        <feGaussianBlur stdDeviation="1.5"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6035_270684"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="1"/>
                        <feGaussianBlur stdDeviation="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                        <feBlend mode="normal" in2="effect1_dropShadow_6035_270684" result="effect2_dropShadow_6035_270684"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6035_270684" result="shape"/>
                    </filter>
                </defs>
            </svg>
        </SvgIcon>
        )
}

export function UbicacionIconVerificarError (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                <path d="M67.5555 28.4444L28.4444 67.5556M28.4445 28.4444L67.5556 67.5556M92 48C92 72.3005 72.3005 92 48 92C23.6995 92 4 72.3005 4 48C4 23.6995 23.6995 4 48 4C72.3005 4 92 23.6995 92 48Z" stroke="#CC261A" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
        )
}

export function ErrorVerificarError (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                <path d="M67.5555 28.4444L28.4444 67.5556M28.4445 28.4444L67.5556 67.5556M92 48C92 72.3005 72.3005 92 48 92C23.6995 92 4 72.3005 4 48C4 23.6995 23.6995 4 48 4C72.3005 4 92 23.6995 92 48Z" stroke="#CC261A" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
        )
}

export function IconoCedulaVerificarEvento (props){
    return(
        <SvgIcon {...props}>
            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 5C2.44772 5 2 5.44771 2 6V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V6C22 5.44772 21.5523 5 21 5H3ZM0 6C0 4.34315 1.34314 3 3 3H21C22.6569 3 24 4.34315 24 6V18C24 19.6569 22.6569 21 21 21H3C1.34315 21 0 19.6569 0 18V6ZM6 10.5C6 9.67157 6.67157 9 7.5 9C8.32843 9 9 9.67157 9 10.5C9 11.3284 8.32843 12 7.5 12C6.67157 12 6 11.3284 6 10.5ZM10.1756 12.7565C10.69 12.1472 11 11.3598 11 10.5C11 8.567 9.433 7 7.5 7C5.567 7 4 8.567 4 10.5C4 11.3598 4.31002 12.1472 4.82438 12.7565C3.68235 13.4994 3 14.7069 3 16C3 16.5523 3.44772 17 4 17C4.55228 17 5 16.5523 5 16C5 15.1145 5.80048 14 7.5 14C9.19952 14 10 15.1145 10 16C10 16.5523 10.4477 17 11 17C11.5523 17 12 16.5523 12 16C12 14.7069 11.3177 13.4994 10.1756 12.7565ZM13 8C12.4477 8 12 8.44772 12 9C12 9.55228 12.4477 10 13 10H19C19.5523 10 20 9.55228 20 9C20 8.44772 19.5523 8 19 8H13ZM14 12C13.4477 12 13 12.4477 13 13C13 13.5523 13.4477 14 14 14H18C18.5523 14 19 13.5523 19 13C19 12.4477 18.5523 12 18 12H14Z" fill="#000000"/>
            </svg>
        </SvgIcon>
        )
}

export function IconoPasaporteVerificarEvento (props){
    return(
        <SvgIcon {...props}>
            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="3" width="16" height="18" rx="1" stroke="#200E32" strokeWidth="2" strokeLinecap="round"/>
                <path d="M9 17H15" stroke="#200E32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <circle cx="12" cy="10" r="4.25" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.25 10C13.25 11.3097 13.0363 12.4609 12.7179 13.257C12.5578 13.6571 12.386 13.9306 12.2336 14.0917C12.0837 14.2502 12.0046 14.25 12.0001 14.25H12H11.9999C11.9954 14.25 11.9163 14.2502 11.7664 14.0917C11.614 13.9306 11.4422 13.6571 11.2821 13.257C10.9637 12.4609 10.75 11.3097 10.75 10C10.75 8.69028 10.9637 7.53908 11.2821 6.74301C11.4422 6.34289 11.614 6.06943 11.7664 5.90826C11.9163 5.7498 11.9954 5.74999 11.9999 5.75L12 5.75L12.0001 5.75C12.0046 5.74999 12.0837 5.7498 12.2336 5.90826C12.386 6.06943 12.5578 6.34289 12.7179 6.74301C13.0363 7.53908 13.25 8.69028 13.25 10Z" stroke="#200E32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 10H16" stroke="#200E32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
        )
}

export function IconoTelefonoVerificarEvento (props){
    return(
        <SvgIcon {...props}>
            <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3308 15.9402L15.6608 14.6101C15.8655 14.403 16.1092 14.2384 16.3778 14.1262C16.6465 14.014 16.9347 13.9563 17.2258 13.9563C17.517 13.9563 17.8052 14.014 18.0739 14.1262C18.3425 14.2384 18.5862 14.403 18.7908 14.6101L20.3508 16.1702C20.5579 16.3748 20.7224 16.6183 20.8346 16.887C20.9468 17.1556 21.0046 17.444 21.0046 17.7351C21.0046 18.0263 20.9468 18.3146 20.8346 18.5833C20.7224 18.8519 20.5579 19.0954 20.3508 19.3L19.6408 20.02C19.1516 20.514 18.5189 20.841 17.8329 20.9541C17.1469 21.0672 16.4427 20.9609 15.8208 20.6501C10.4691 17.8952 6.11008 13.5396 3.35083 8.19019C3.03976 7.56761 2.93414 6.86242 3.04914 6.17603C3.16414 5.48963 3.49384 4.85731 3.99085 4.37012L4.70081 3.65015C5.11674 3.23673 5.67937 3.00464 6.26581 3.00464C6.85225 3.00464 7.41488 3.23673 7.83081 3.65015L9.40082 5.22021C9.81424 5.63615 10.0463 6.19871 10.0463 6.78516C10.0463 7.3716 9.81424 7.93416 9.40082 8.3501L8.0708 9.68018C8.95021 10.8697 9.91617 11.9926 10.9608 13.04C11.9994 14.0804 13.116 15.04 14.3008 15.9102L14.3308 15.9402Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
        )
}
