import axios from "axios";
import { useEffect, useState } from "react";
import { AsignacionesUsuario } from "../../Service/API/Api";
export default function useAsignacionesUsuario(idPersona)
{
    const [asignaciones, setAsignaciones] = useState([]);

    useEffect(() => {
        axios.get(AsignacionesUsuario + idPersona)
        .then(
            (response) => {
                console.log(response.data)
                let h = [];

                response.data.forEach(element => {
                    var dtInicio = new Date(element.inicio);
                    var dtTermino = new Date(element.termino);

                    element.inicio = dtInicio;
                    element.termino = dtTermino;
                    h.push(element);
                });
                setAsignaciones(h);
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
            }
        )
    }, [idPersona])

    return {
        asignaciones
    }
    
}