import axios from "axios";
import { useState } from "react";
import { CrearPase, DesactivarPase, EditarPase, EliminarPase, ReActivarPase } from "../../Service/API/Api";

export default function usePase(){
    const [loading, setLoading] = useState(null);
    const [response, setResponse] = useState(null);


    function CrearNuevo(metodo, persona, patente, tarjeta){
        return new Promise(async (resolve, reject) => {
            let createPaseTag = {
                "idPersona": persona,
                "idTarjeta": tarjeta,
                "idPatente": patente
            }

            let createPaseRfid = {
                "idPersona": persona,
                "idTarjeta": tarjeta,
                "idPatente": null
            }

            await axios.post(CrearPase, metodo === 1 ? createPaseRfid :  createPaseTag )
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            );
            
        });
    }

    function DesactivarActivarPase(metodo, idPase){
        return new Promise(async (resolve, reject) => {
            await axios.patch(metodo === true ? DesactivarPase + idPase : ReActivarPase + idPase )
            .then(
                (response) =>{
                    resolve(true);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                }
            );
            
        });
    }

    const EliminacionDelPase = (idPase) => {
        return new Promise(async (resolve, reject) => {
            await axios.delete(EliminarPase + idPase )
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false)
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(error.response.status);

                    setLoading(false)
                }
            );
            
        });
    }

    //completar actualizacion
    const ActualizarPase = (idPase, idPersona, idPatente, idTarjeta) => {
        return new Promise(async (resolve, reject) => {
            var toEdit = {
                "idPase": idPase,
                "idPersona": idPersona,
                "idTarjeta": idTarjeta,
                "idPatente": idPatente === "" ? null : idPatente
              }
            await axios.put(EditarPase, toEdit)
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false)
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            );
            
        });
    }


    return {
        loading, 
        setLoading,
        CrearNuevo,
        response,
        DesactivarActivarPase,
        EliminacionDelPase,
        ActualizarPase
    }
}