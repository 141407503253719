import { Box } from "@mui/material";
import "./Login.css"
import { HandlerVentanaUno } from "./LoginVentanaUno/HandlerVentanaUno";
import { useState } from "react";
import { HandlerVentanaDos } from "./LoginVentanaDos/HandlerVentanaDos";

export const Login = () => {
    const [opcionVentanaUno, setOpcionVentanaUno] = useState(1);
    const [opcionVentanaDos, setOpcionVentanaDos] = useState(1);


    return(
        <Box id="LoginLayout">
            <Box id="BoxContenidoImagenContactoInicioSesion">
                <HandlerVentanaDos 
                opcion={opcionVentanaDos} 
                handleOpcion={setOpcionVentanaDos} 
                />
            </Box>
            <Box id="BoxContenidoLoginRecuperacionesInicioSesion">
                <HandlerVentanaUno 
                opcion={opcionVentanaUno}
                handleOpcion={setOpcionVentanaUno} 
                />
            </Box>
            
        </Box>
    );
}