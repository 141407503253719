import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Navbar from "../../Components/navbar/NaviBar";
import { Main } from '../../Components/Main/Main'
import AppFooter from '../../Components/Footer/AppFooter';
import { LeftBar } from '../../Components/LeftBar/LeftBar';
import TiempoReal from '../../Components/TiempoReal/TiempoReal';
import HistorialEventos from '../../Components/Eventos/HistorialEventos';
import { Box } from '@mui/material';
import { InterceptorRequest, InterceptorResponse } from '../../Service/API/Interceptor';

const Dashboard = ({children}) => {
    InterceptorResponse();
    InterceptorRequest();
    const data = useSelector(action => action.user.authenticated);

    const [isOpen, setIsOpen] = useState(Boolean(true));
    const handleLeftMenu = () => { setIsOpen(!isOpen) };
    const [trOpen, setTROpen] = useState(Boolean(false));
    const handleTiempoReal = () => { setTROpen(!trOpen) };
    const [heOpen, setHEOpen] = useState(Boolean(false));
    const AnchoActualMenu = isOpen ? 248 : 96;
    const AnchoActualTiempoReal = trOpen ? 324 : 316 // ancho tr
    const AlturaActualTiempoReal = trOpen ? 624 : 48 //altura tr
    const AnchoActualHistorialEvento = heOpen ? 324 : 316 // ancho he
    const AlturaActualHistorialEvento = heOpen ? 316 : 48 //altura he

    return (
        <Box>
            <Navbar />
            <LeftBar NombreUsuarioConectado={data.usuario} handleLeftMenu={handleLeftMenu} isOpen={isOpen} AnchoActual={AnchoActualMenu} />
            <Main open={isOpen} leftWidth={AnchoActualMenu} >
                {
                    children
                }
            </Main>
            <TiempoReal trOpen={trOpen} handleTiempoReal={handleTiempoReal} trWidth={AnchoActualTiempoReal} trHeight={AlturaActualTiempoReal} />
            <HistorialEventos heOpen={heOpen} heWidth={AnchoActualHistorialEvento} heHeight={AlturaActualHistorialEvento} setHEOpen={setHEOpen} />
            <AppFooter openMenuState={isOpen} />
        </Box>
    );
}

export default Dashboard;