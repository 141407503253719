import { IconButton } from "@mui/material";
import { IconoCamara, IconoCamaraSeguridad, IconoGOS, IconoLectorCI, IconoLectorFacial, IconoLectorHuella, IconoLuz, IconoPinPass, IconoPorton, IconoPuerta, IconoQR, IconoRFID, IconoSensorMagnetico, IconoSensorProximidad, IconoTAG } from "./IconosParaBotones";
import TooltipTipoUno from "../ToolTip/Tooltip";

export const ObtenerIconoAsociado = (idTipoActor) => {
    var ObtenerIcono = '';

    if (idTipoActor === 1) {
        ObtenerIcono = <IconoCamara id="IconoEnMapa" />; //Camara NVR 
    }
    if (idTipoActor === 2) {
        ObtenerIcono = <IconoCamaraSeguridad id="IconoEnMapa" />; //Camara Seguridad
    }
    if (idTipoActor === 3) {
        ObtenerIcono = <IconoLectorCI id="IconoEnMapa" />; //Lector CI //nada
    }
    if (idTipoActor === 4) {
        ObtenerIcono = <IconoSensorProximidad id="IconoEnMapa" />; //Sensor Proximidad //nada
    }
    if (idTipoActor === 5) {
        ObtenerIcono = <IconoSensorMagnetico id="IconoEnMapa" />; //Sensor Magnetico //nada
    }
    if (idTipoActor === 6) {
        ObtenerIcono = <IconoTAG id="IconoEnMapa" />; //Tag //acceso
    }
    if (idTipoActor === 7) {
        ObtenerIcono = <IconoRFID id="IconoEnMapa" />; //Lector RFID //acceso
    }
    if (idTipoActor === 8) {
        ObtenerIcono = <IconoQR id="IconoEnMapa" />; //Icono QR //acceso
    }
    if (idTipoActor === 9) {
        ObtenerIcono = <IconoGOS id="IconoEnMapa" />; //IconoGOS //nada
    }
    if (idTipoActor === 10) {
        ObtenerIcono = <IconoLectorFacial id="IconoEnMapa" />; //Lector Facial //camara
    }
    if (idTipoActor === 11) {
        ObtenerIcono = <IconoPinPass id="IconoEnMapa"/>; //Pinpass //acceso 
    }
    if (idTipoActor === 12) {
        ObtenerIcono = <IconoPorton id="IconoEnMapa" />; // Icono Porton
    }
    if (idTipoActor === 13) {
        ObtenerIcono = <IconoPuerta id="IconoEnMapa" />; //Icono Puerta
    }
    if (idTipoActor === 14) {
        ObtenerIcono = <IconoLuz id="IconoEnMapa" />; //Icono Luz //switch encender apagar, no filtrar
    }
    if (idTipoActor === 15) {
        ObtenerIcono = <IconoLectorHuella id="IconoEnMapa" />; //Lector Huella //acceso
    }

    return (
        ObtenerIcono
    );
}


export const BotonesConSusFormatos = ({ dataBotones, handlePopoverOpen, handleChangeBotonSeleccionado }) => {
    return dataBotones.map((item, index) => {
        let iconoAsociado = ObtenerIconoAsociado(item.idTipoActor);
        return (
            <TooltipTipoUno key={index} titulo={item.tipoActor + " - " + item.nombreActor} ubicacion={"bottom"} >
                <IconButton
                    id="PruebaButton"
                    onClick={(event) => {
                        handlePopoverOpen(event);
                        handleChangeBotonSeleccionado(item);
                    }}
                    sx={{
                        position: "absolute",
                        marginLeft: `${item.marginLeft}px`,
                        marginTop: `${item.marginTop}px`
                    }} >
                    {iconoAsociado}
                </IconButton>
            </TooltipTipoUno>

        );
    });
}

