import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function SeleccionTiempoHorario({ timeSet, handleTime, name, label, helper }) {
    
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker name={name} ampm={false} 
            slotProps={{
              textField: {
                  InputLabelProps:{
                    shrink: true,
                  },
                  helperText: helper,
                  fullWidth: true,
                  variant: "outlined",
                  sx: { 
                      "& fieldset": { borderTop: "none", borderLeft: "none", borderRight: "none", borderRadius:"unset"},
                      padding: "0px",
                      borderRadius: "0px",
                      border: "none",
                      "& .MuiInputBase-root": { height: "38px",  border: "none",},
                  }}
            }}
            views={["hours", "minutes"]}
            format='HH:mm'
            value={timeSet}
            label={label}
            onChange={handleTime}
            />
        </LocalizationProvider>
      );
    }