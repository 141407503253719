import { SvgIcon } from "@mui/material";

export function TiempoRealIcon(props){
    return(
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.51018 15.4907C4.15862 17.331 5.38765 18.9108 7.01208 19.992C8.63652 21.0732 10.5684 21.5972 12.5165 21.4851C14.4647 21.373 16.3237 20.6308 17.8133 19.3704C19.303 18.11 20.3426 16.3996 20.7756 14.497C21.2086 12.5944 21.0115 10.6026 20.214 8.82177C19.4165 7.04091 18.0617 5.56746 16.3539 4.62343C14.6461 3.67941 12.6777 3.31593 10.7454 3.58779C7.48292 4.04676 5.32746 6.41142 3 8.5M3 8.5V2.5M3 8.5H9" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.7859 8.57144C11.4309 8.57144 11.1431 8.85926 11.1431 9.2143V12.6725C11.1431 13.2029 11.3538 13.7116 11.7289 14.0867L13.6032 15.961C13.8904 16.2482 14.3634 16.2223 14.6176 15.9055C14.8353 15.6341 14.8139 15.2423 14.5679 14.9963L13.0146 13.4429C12.6395 13.0679 12.4288 12.5592 12.4288 12.0287V9.2143C12.4288 8.85926 12.141 8.57144 11.7859 8.57144Z" fill="#333333"/>
            </svg>
        </SvgIcon>
    );
}


export function AbrirTiempoRealIcon(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
                <path d="M17 9L9 1L1 9" stroke="#626262" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}



export function CerrarTiempoRealIcon(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                <path d="M1 17L9 9L1 1" stroke="#626262" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function FalloIconTable(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M9.66666 4.33333L4.33333 9.66667M4.33334 4.33333L9.66667 9.66667M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z" stroke="#CC261A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function ExitoIconTable(props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M10.3333 5L5.66665 9.66668L3.66663 7.66667M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z" stroke="#338333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}