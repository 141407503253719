import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import ControlPass from './ControlPass';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { persistor, store } from './store/store';
import { MisRouter } from './routes/Routes';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
//< ControlPass />
root.render(
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <RouterProvider router={ MisRouter } />
    </PersistGate>
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
