import { Box } from "@mui/material";
import useAcceso from "../../../../../Hooks/Acceso/useAcceso";
import useConfirm from "../../../../../Hooks/ConfirmDialog/useConfirmDialog";
import { SwitchTablas } from "../../../../Switch/switch";
import { IconoAlerta } from "../../../../Usuarios/UsuariosIcon";
import { SnackBarTipoUno } from "../../../../snackBar/SnackBar";
import "./Accessos.css";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";

export const AccesosTablaGU = ({dataAccesos, setRows}) =>{
    const {DeshabilitarActivarAcceso} = useAcceso();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [isTrue, setIsTrue] = useState();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);

    const HandleEstado = (id) => async () => {
        const rowToEdit = dataAccesos.find((row) => row.idAcceso === id);
        setIsTrue(rowToEdit.estado === true ? true:false);
        let responseConfirm = await confirm();
        if(responseConfirm === true){
            await DeshabilitarActivarAcceso(id, rowToEdit.estado)
            .then(
                (result) => {
                    rowToEdit.estado = !rowToEdit.estado
                    setRows(dataAccesos.map((row) => (row.idAcceso === id ? rowToEdit : row)));
                    setSnackbarSeverity("success")
                    setSnackMsg(isTrue ? "el Acceso ha sido Activado exitosamente!":"el Acceso ha sido Desactivado exitosamente!")
                    setSnackbar(true);
                }
                ,
                (error) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true); 
                }
            )
        }
        else
        {
            console.log("rechaza el cambio")
        }
    }    

    const columnNames = [
        {
            field: 'nombreZona', headerName: 'Zona', type: 'string', width: 619, headerAlign: 'center', align:'center', sortable: false, editable: false
        }
        ,
        {
            field: 'estado', headerName: 'Estado', type: 'bool', width: 272, headerAlign: 'center', align:'center', sortable: false, editable: false,
            renderCell: ({id, row}) => 
            (
                <SwitchTablas 
                id="switchTablaUsuario" 
                value={row.estado}
                disabled={false}
                handler={HandleEstado(id)} 
                />
            )
        },

    ];


    return(
        <Box>
            <DataGrid
            id="DataGridAccesosGU"
            rows={dataAccesos}
            columns={columnNames}
            sx={{
                width:"894px",
                height:"424px",
                    '& .MuiDataGrid-columnHeaderTitle': {
                    color:'#175676',
                    },
                        '& .MuiDataGrid-columnHeader': {
                        borderBottom: '2px solid #94B1C0',
                    },
                    '& .MuiDataGrid-row':{
                        borderBottom:'1px solid #94B1C0'
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                        width: "7.024px",
                        background: "transparent"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                        background: "transparent"
                    },
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                        background: "#C9C9C9",
                        borderRadius: "100px"
                    }
                }}
            getRowId={(row) => row.idAcceso} //reeemplazar luego por el id definido idMetodos y agregar snackbar
            initialState={{
                pagination:{
                paginationModel: {  
                    page: 0,
                    pageSize: 10,
                    }
                },
            }}
            rowSelection
            pageSizeOptions={[10, 20]}
            disableRowSelectionOnClick
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            />
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />
            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={isTrue ? "¿Desactivar Acceso?" : "¿Activar Acceso?"}
            mensaje={
                isTrue ? 
                    "Estás a punto de desactivar este Acceso. El usuario no podra acceder por esta zona. ¿Deseas continuar?" 
                    : 
                    "Estás a punto de activar este Acceso. Una vez activado, el usuario tendrá acceso según sus permisos asignados. ¿Deseas continuar?" 
            }
            />
        </Box>
        
        
    );
}