import { Box, Modal } from "@mui/material";
import "./Informacion.css";
import { DataUsuario } from "../../Modal/Perfil/DataUsuario/DataUsuario";
import { useState } from "react";
import EditarContrasenaPerfil from "../../Modal/Perfil/EditarContrasena/EditarContrasena";
import usePerfilUsuario from "../../../Hooks/OpcionesPerfil/usePerfilUsuario";
import { useSelector } from "react-redux";


const Informacion = ({openInfo, handle, tipo, handleOpenConfirmacion}) => {
    const data = useSelector(action => action.user.authenticated);
    const { user} = usePerfilUsuario(data.idPersona);
    const [disabled, setDisabled ] = useState(true);

   
    const handleClose = () => {
        handle();
        setDisabled(true);
    }
    
    return (
        <Modal id="ModalInformacion" open={openInfo} onClose={handleClose}>
            {
                tipo === 1 ?
                <Box>
                    <DataUsuario informacionUsuario={user} handle={handleClose} disableState={disabled} disabledHandler={setDisabled} handleOpenConfirmacion={handleOpenConfirmacion} />
                </Box>
                :
                <Box >
                    <EditarContrasenaPerfil informacionUsuario={user} handle={handleClose} />
                </Box>
            }
        </Modal>
    );
}

export default Informacion;