import axios from 'axios';
import { CrearVisitante, EliminarTodosVisitantes, EliminarVisitaVisitante, EliminarVisitanteSeleccionados, EnviarCorreoAccesoIndividual, EnviarInvitacionIndividual, EnviarTodaslasInvitaciones,  } from '../../Service/API/Api';
import { useState } from 'react';


function useInvitados(){
    const [loading, setLoading] = useState();
    const [respuesta, setRespuesta] = useState();

    const GuardarInvitado = (invitado, idVisita) => {
        return new Promise((resolve, reject) => {
            const nuevoInvitado = {
                "idVisita": idVisita,
                "rut": invitado.rut,
                "nombres": invitado.nombres,
                "apellidos": invitado.apellidos,
                "correo": invitado.correo,
                "telefono": invitado.telefono,
                "Patente": invitado.patente === "" ? null : invitado.patente
              }
            axios.post(CrearVisitante, nuevoInvitado)
            .then(
                (response) => {
                    resolve(response.data);
                    setRespuesta(response.status);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setRespuesta(error.response.status);
                }
            )
        });
    }

    const EnviarInvitacionesMasivas = (idVisita, invitados) => {
        return new Promise((resolve, reject) => {
            var listaInvitar = []

            invitados.forEach(element => {
                var invitado = {
                    "idVisitante": element.idVisitante,
                    "correo": element.correo
                }
                listaInvitar.push(invitado)
            });

            var enviar = {
                "idVisita": idVisita,
                "invitados": listaInvitar
            }
            axios.post(EnviarTodaslasInvitaciones, enviar)
            .then(
                (response) => {
                    resolve(response.data);
                    setRespuesta(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setRespuesta(error.response.status);
                    setLoading(false);
                }
            )
        });
    }

    const EnviarInvitacionUnica = (idVisita, idVisitante) => {
        return new Promise((resolve, reject) => {            
            const data ={
                "idVisita": idVisita,
                "idInvitado": idVisitante
            }
            axios.post(EnviarInvitacionIndividual, data)
            .then(
                (response) => {
                    resolve(response.data);
                    setRespuesta(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setRespuesta(error.response.status);
                    setLoading(false);
                }
            )
        });
    }

    const EnviarAccesoIndividual = (idVisita, idVisitante) => {
        return new Promise((resolve, reject) => {
            const data ={
                "idVisita": idVisita,
                "idInvitado": idVisitante
            }
            axios.post(EnviarCorreoAccesoIndividual, data)
            .then(
                (response) => {
                    resolve(response.data);
                    setRespuesta(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                    setRespuesta(error.response.status);
                    setLoading(false);
                }
            )
        });
    }


    const EliminarInvitado = (idVisitaVisitante) => {
        return new Promise((resolve, reject) => {
            console.log("entramos a eliminar uno : " + idVisitaVisitante)
            axios.delete(EliminarVisitaVisitante + idVisitaVisitante)
            .then(
                (response) => {
                    resolve(true);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                }
            )
        });
    }

    const EliminarTodosInvitados = (idVisita) => {
        return new Promise((resolve, reject) => {
          
            axios.delete(EliminarTodosVisitantes + idVisita)
            .then(
                (response) => {
                    resolve(true);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                }
            )
        });
    }


    const EliminarInvitadosSeleccionados = (idVisita, invitados) => {
        return new Promise((resolve, reject) => {
            var invitadosEliminar = []

            invitados.forEach(element => {
                invitadosEliminar.push(element)
            });

            const data = {
                "idVisita": idVisita,
                "invitados": invitadosEliminar
            }
            axios.post(EliminarVisitanteSeleccionados, data)
            .then(
                (response) => {
                    resolve(true);
                }
            )
            .catch(
                (error) => {
                    reject(false)
                }
            )
        });
    }

    return {
        loading, 
        setLoading,
        GuardarInvitado,
        respuesta,
        EnviarInvitacionesMasivas,
        EnviarInvitacionUnica,
        EnviarAccesoIndividual,
        EliminarInvitado,
        EliminarTodosInvitados,
        EliminarInvitadosSeleccionados
    };
}


export default useInvitados;