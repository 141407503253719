import { Link, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import "./Boton.css";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import TooltipTipoUno from "../../ToolTip/Tooltip";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const ItemMenu = ({
    openM, 
    id, 
    to, 
    isSelected, 
    icono, 
    textoBoton,
    openSM,
    submenu,
    handleClick
    }) => {
    return(
        <TooltipTipoUno titulo={textoBoton} ubicacion={"right"}>
            <ListItem id="ListItemMenu"
                disablePadding 
                sx={{
                    marginBottom:"4px",
                    color: "#626262",
                    backgroundColor: "#fff",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px", /* 150% */
                    letterSpacing: "0.5px",
                    "& .MuiListItemIcon-root": {
                        color: "#626262"
                    },
                    ":hover":{
                        borderRadius: "4px",
                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                        color: "#FFF",
                        backgroundColor: "#1F7098",
                        fontWeight: 500,
                        "& .MuiListItemIcon-root": {
                            color: "#B7CBD5",
                        }
                    }

                }}
                >
                <ListItemButton itemID={id} 
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    "&.MuiListItemButton-root.Mui-selected":{
                        borderRadius: "4px",
                        color: "#FFF",
                        backgroundColor: "#154E6B",
                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                        "& .MuiListItemIcon-root": {
                            color: "#FFF",
                        }
                    }
                }}
                onClick={handleClick}
                LinkComponent={Link}
                href={to}
                selected={isSelected}            
                >
                    <ListItemIcon sx={{ minWidth: "35px", justifyContent:"center"}}>
                    {
                        icono
                    }
                    </ListItemIcon>
                    {
                        openM ?
                        <ListItemText  sx={{ width:"130px", WebkitFlex:"none", display:"flex", justifyContent:"left", paddingLeft:"7px"}}>
                            {
                                textoBoton
                            }
                        </ListItemText>
                        :
                        null
                    }
                    {
                    
                    openM   ?
                        submenu ?
                            openSM  ? <ExpandLess id ="iconoExpandirCerrar"/> : <ExpandMore id ="iconoExpandirCerrar" />
                            :
                            null
                        :
                        submenu ?
                            <KeyboardArrowRightIcon id ="iconoExpandirCerrar" />
                            :
                            null
                    }
                </ListItemButton>
            </ListItem>
        </TooltipTipoUno>
    );
}