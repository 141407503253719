import { CabeceraAnio } from "./CabeceraAnio";
import { CabeceraHoy } from "./CabeceraHoy";
import { CabeceraSemana } from "./CabeceraSemana";

export const HandlerOpciones = ({opcion, setOpcion, fechaActual, addMonth, restMonth, setMonth, setYear, addYear, restYear}) => {

    return(
        opcion === 1 ?
        <CabeceraHoy fechaActual={fechaActual} />
        :
        opcion === 2 ?
        <CabeceraSemana fechaActual={fechaActual} addMonth={addMonth} restMonth={restMonth} setMonth={setMonth} setYear={setYear} addYear={addYear} restYear={restYear}/>
        :
        opcion === 3 ?
        <CabeceraSemana fechaActual={fechaActual} addMonth={addMonth} restMonth={restMonth} setMonth={setMonth} setYear={setYear} addYear={addYear} restYear={restYear}/>
        :
        <CabeceraAnio fechaActual={fechaActual} addYear={addYear} restYear={restYear} setYear={setYear}  />
    );

}