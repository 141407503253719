import * as Yup from 'yup';

let correoRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/

export const EsquemaLogin = Yup.object({
    correo: Yup.string().matches(correoRegex, "Formato de correo no valido")
    .required('* Campo requerido'),
    contrasena: Yup.string()
    .required('* Campo requerido'),
    recordarme: Yup.bool()
})

export const EsquemaRecuperar = Yup.object({
    correo: Yup.string().matches(correoRegex, "Formato de correo no valido")
    .required('* Campo requerido'),
})