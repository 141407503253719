import Dashboard from "../../Components/Dashboard/Dashboard";
import React from 'react';
import TarjetasTabla from "../../Components/GestionTarjetas/Tarjetas";

export default function TarjetasPage() {
  return (
    <Dashboard>
        <TarjetasTabla />
    </Dashboard>
  )
}
