import axios from "axios";
import { useEffect, useState } from "react";
import {BuscarUsuario } from "../../Service/API/Api";

function useGetUsuario(idUsuario) {
    const [usuario, setUsuario] = useState();


    useEffect(() => {
        axios.get(BuscarUsuario + idUsuario)
        .then((response) => 
            {
                setUsuario(response.data)
            }
        )
        .catch((error) => 
            {
                setUsuario(null);
            }
        );
    }, [idUsuario])
    

    return {
        usuario
    }
}


export default useGetUsuario;