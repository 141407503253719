import { SvgIcon } from "@mui/material";

export function IconoGraficos (props){
    return(
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path d="M20 12.5V21.5M14 6.5L14 21.5M8 17L8 21.5M2 2H26V26H2V2Z" stroke="#333333" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    )
}
