import axios from "axios";
import { useEffect, useState } from "react";
import { ActivarAccesoAPPMovil, BuscarUsuario, BuscarUsuariosEmpresa, CrearAdmin, CrearSA, CrearUsuario, DesactivarAccesoAPPMovil, DesactivarUsuario, EditarUsuarioTabla, ReactivarUsuario } from "../../Service/API/Api";


function useUsuarios()
{
    const [loading, setLoading] = useState(true);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [requestResponse, setRequestResponse] = useState();
    const [usuarios, setUsuarios] = useState([]);
    const [usuario, setUsuario] = useState({});
    const [error, setError] = useState();  
    

    useEffect(() => {
        async function Obtener() {
            setLoading(true);
            setError(null); // Limpiar el error antes de la nueva solicitud

            try {
                const response = await axios.get(BuscarUsuariosEmpresa);
                setUsuarios(response.data);
                setLoading(false);
            } catch (error) {
                if (error.response) {
                    // Error en la respuesta del servidor (404, 500, etc.)
                    console.error("Error en la respuesta del servidor:", error.response.data);
                    setError("Error en la respuesta del servidor.");
                } else if (error.request) {
                    // No se recibió respuesta del servidor
                    console.error("No se pudo conectar con el servidor.");
                    setError("No se pudo conectar con el servidor.");
                } else {
                    // Error en la configuración de la solicitud
                    console.error("Error desconocido:", error.message);
                    setError("Error desconocido.");
                }
                setLoading(false);
            }
        }

        Obtener();
    }, []);

    

    const Actualizar = () =>{
        setLoading(true);
    }
    
    async function ActivarDesactivarUsuario (id, value){
        return new Promise(async (resolve, reject) => {
            await axios.patch(value ? DesactivarUsuario + id : ReactivarUsuario + id)
            .then(
                (response) =>{
                    resolve(true);
                }
               
            )
            .catch (
                (error) =>{
                    reject(false);
                }
            );
            
        });
    }

    function ActivarDesactivarAccesoAPPMovil (id, estadoActual){
        return new Promise(async (resolve, reject) => {
            await axios.patch(estadoActual ? DesactivarAccesoAPPMovil + id : ActivarAccesoAPPMovil + id)
            .then(
                (response) =>{
                    resolve(true);
                }
               
            )
            .catch (
                (error) =>{
                    reject(false);
                }
            );
            
        });
    }

    function CrearNuevo(values, empresa){
        return new Promise(async (resolve, reject) => {
            let nuevo = {
                "rut": values.rut === null || values.rut === "" ? null : values.rut,
                "pasaporte": values.pasaporte === null || values.pasaporte === "" ? null : values.pasaporte,
                "nombres": values.nombres,
                "apellidos": values.apellidos,
                "telefono": values.telefono,
                "correo": values.correo,
                "idTipoPersona": values.idTipoPersona,
                "idEmpresa": empresa
            }
            if(values.idTipoPersona > 2 ){
                await axios.post(CrearUsuario, nuevo)
                .then(
                    (response) =>{
                        setRequestResponse(response.status);
                        setLoadingRequest(false);
                        resolve(response.data);
                    }
                   
                )
                .catch (
                    (error) =>{
                        reject(error);
                        setRequestResponse(error.response.status);
                        setLoadingRequest(false);
                    }
                );
            }
            else
            {///este esamos probando
                if(values.idTipoPersona === 1){
                    await axios.post(CrearSA, nuevo)
                    .then(
                        (response) =>{
                            setRequestResponse(response.status);
                            setLoadingRequest(false); 
                            resolve(response.data);            
                        }
                    )
                    .catch (
                        (error) =>{
                            reject(false);
                            setRequestResponse(error.response.status);
                            setLoadingRequest(false);
                            
                        }
                    );
                }
                if(values.idTipoPersona === 2){
                    await axios.post(CrearAdmin, nuevo)
                    .then(
                        (response) =>{
                            resolve(response.data);
                            setRequestResponse(response.status);
                            setLoadingRequest(false);
                        }
                    )
                    .catch (
                        (error) =>{
                            reject(false);
                            setRequestResponse(error.response.status);
                            setLoadingRequest(false);
                        }
                    );
                }
            }
        });
    }

    function EditarExistente(values, idPersona){
        return new Promise(async (resolve, reject) => {
            let toEdit = {
                "idPersona": idPersona,
                "rut": values.rut,
                "pasaporte": values.pasaporte,
                "nombres": values.nombres,
                "apellidos": values.apellidos,
                "telefono": values.telefono,
                "idTipoUsuario": values.idTipoPersona,
            }
            await axios.put(EditarUsuarioTabla, toEdit )
            .then(
                (response) =>{
                    resolve(true);
                    setRequestResponse(response.status);
                    setLoadingRequest(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setRequestResponse(error.response.status);
                    setLoadingRequest(false);
                }
            );
            
        });
    }

    async function BuscarUsuarioExistente(idPersona){
        await axios.get(BuscarUsuario + idPersona )
            .then(
                (response) =>{
                    setUsuario(response.data)
                }
            )
            .catch (
                (error) =>{
                    console.log("fallo la busqueda");
                }
        );
    }

    



    return {
        usuarios,
        setLoading,
        setUsuarios,
        error,
        loading,
        Actualizar,
        ActivarDesactivarUsuario,
        CrearNuevo,
        setLoadingRequest,
        loadingRequest,
        requestResponse,
        EditarExistente,
        ActivarDesactivarAccesoAPPMovil,
        BuscarUsuarioExistente,
        usuario,
    }
}

export default useUsuarios;