import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import TooltipTipoUno from '../ToolTip/Tooltip';
import { SwitchTablas } from '../Switch/switch';
import StyleIcon from '@mui/icons-material/Style';
import { IconoAlerta, IconoEditarUsuario } from '../Usuarios/UsuariosIcon';
import { useTarjetasGestion } from '../../Hooks/Tarjetas/useTarjetasGestion';
import "./Tarjetas.css";
import { FiltroSelect } from '../CabeceraTablasGestion/FiltrosCabecera/FiltrosCabecera';
import { NuevaTarjeta } from './NuevaTarjeta/NuevaTarjeta';
import { EditarTarjeta } from './EditarExistente/EditarTarjeta';
import { useManejoTarjetas } from '../../Hooks/Tarjetas/useManejoTarjeta';
import useConfirm from '../../Hooks/ConfirmDialog/useConfirmDialog';
import { SnackBarTipoUno } from '../snackBar/SnackBar';
import { IconoAsignarUsuario, IconoEditarAsignacion } from './TarjetaIcons';
import { PapeleroIconA } from '../GestionUbicaciones/IconosGestionUbicaciones';
import usePase from '../../Hooks/Pase/usePase';
import { OpcionesAsignacion } from './Asignacion/OpcionesAsignacion';

const TiposTarjetas = [
    {
        id: 0, tipo: "RFID/TAG"
    }
    ,
    {
        id: 1, tipo: "Solo RFID"
    }
    ,
    {
        id: 2, tipo: "Solo TAG"
    }
]


const TiposAsignacion = [
    {
        id: 0, tipo: "Todas las tarjetas"
    }
    ,
    {
        id: 1, tipo: "Solo asignadas"
    }
    ,
    {
        id: 2, tipo: "Solo disponibles"
    }
]


export default function TarjetasTabla() {
    const {tarjetas, loading} = useTarjetasGestion();
    const {EliminacionDelPase} = usePase();
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const [tipoTarjeta, setTipoTarjeta] = useState(0);
    const [tiposAsignacion, setTiposAsignacion] = useState(0);
    const [nuevaTarjeta, setNuevaTarjeta] = useState(Boolean(false));
    const [editarTarjeta, setEditarTarjeta] = useState(Boolean(false));
    const [rowTarjeta, setRowTarjeta] = useState({});
    const {ManejoEstadoTarjeta } = useManejoTarjetas();
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [dialogText, setDialogText] = useState({titulo: '', mensaje: ''});
    const [openAsignacion, setOpenAsignacion] = useState(Boolean(false));
    const [opcionAsignacion, setOpcionAsignacion] = useState();
    
    const handleOpenEditarTarjeta = (row) => () => {
        setRowTarjeta(row);
        setEditarTarjeta(true); 
    }
    
    console.log(tarjetas);
    

    useEffect(() => {
        setRows(tarjetas);
    }, [tarjetas])
    
    const handleOpenNT = () => {
        setNuevaTarjeta(true); 
    }

    const handleDeletePase = (id) => async () => {
        var findRow = rows.find(t => t.idTarjeta === id);

        if(findRow.idPase !== null) {
            setDialogText({
                titulo: "¿Eliminar asignacion?",
                mensaje: "Estás a punto de quitar el acceso del usuario con esta tarjeta ¿Deseas continuar?"
            })
            var res = await confirm();
            if(res){
                await EliminacionDelPase(findRow.idPase)
                .then(
                    (resolve) => {
                        findRow.asignada = false;
                        findRow.idPase = null;
                        findRow.idPatente = null;
                        findRow.idPersona = null;
                        findRow.nombreCompleto = null;
                        findRow.pasaporte = null;
                        findRow.rut = null;
                        findRow.patenteDigitos = null;
                        
                        setRows(rows.map((r) => (r.idTarjeta === findRow.idTarjeta ? findRow : r)));
                        setSnackbarSeverity("success")
                        setSnackMsg("Asignacion eliminada exitosamente!")
                        setSnackbar(true);
                    }
                    ,
                    (reject) => {
                        setSnackbarSeverity("error")
                        setSnackMsg("Ocurrio un error al generar la solicitud, reintente nuevamente ")
                        setSnackbar(true);
                    }
                )
            }
            else{
                console.log("Rechaza modificacion");
            }
        }
        else{
            console.log("no tiene pase");
        }
    }


    const handleTipoTarjeta = (e) => {
        setTipoTarjeta(e.target.value);
        const listaFiltrada = e.target.value === 0 ? tarjetas: tarjetas.filter((row) => row.idTipoTarjeta === e.target.value);
        setRows(listaFiltrada);
    }

    const handleTipoAsignacion = (e) => {
        setTiposAsignacion(e.target.value);
        if(e.target.value === 0) {
            setRows(tarjetas);
        }
        if(e.target.value === 1)
        {
            const listaFiltrada = tarjetas.filter((row) => row.asignada === true);
            setRows(listaFiltrada);
        }
        if(e.target.value === 2)
        {
            const listaFiltrada = tarjetas.filter((row) => row.asignada === false);
            setRows(listaFiltrada);
        }
    }


    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = tarjetas.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handleEstado = (row) => async () => {
        //const findrow = rows.find((row) => row.idTarjeta === id);
        if(row.estado === true){
            setDialogText(
                {
                    titulo: "¿Desactivar Tarjeta?",
                    mensaje: "Estás a punto de desactivar esta tarjeta ¿Deseas continuar?"
                }
            );
        }
        else
        {
            setDialogText(
                {
                    titulo: "¿Activar tarjeta?",
                    mensaje: "Estás a punto de activar esta tarjeta ¿Deseas continuar?"
                }
            );
        }

        const responseConfirm = await confirm(); 
        if(responseConfirm === true) {
            ManejoEstadoTarjeta(row.idTarjeta, row.estado)
            .then(
                (result) => {
                    row.estado = !row.estado
                    setRows(rows.map((r) => (r.idTarjeta === row.idTarjeta ? row : r)));
                    setSnackbarSeverity("success")
                    setSnackMsg(row.estado === true ? 
                        "Tarjeta activada exitosamente!"
                        :
                        "Tarjeta desactivada exitosamente!"
                    )
                    setSnackbar(true);
                }
            )
            .catch(
                (error) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ocurrio un error al guardar, reintente nuevamente")
                    setSnackbar(true);   
                }
            )
        }        
        else
        {
            console.log("Rechaza la modificacion")
        }
        
    }

    const handleNuevaAsignacion = (row) => () => {
        setRowTarjeta(row);
        setOpcionAsignacion(1);
        setOpenAsignacion(true);
    }

    const handleEditarAsignacion = (row) => () => {
        setRowTarjeta(row);
        setOpcionAsignacion(2);
        setOpenAsignacion(true);
    }


    const columns = [
        { field: 'idTarjeta', headerName: 'ID', width: 80, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'codigo', headerName: 'Codigo', flex: 0.5, minWidth: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'tipoTarjeta', headerName: 'Tipo', type: 'string', width: 150, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'nombreCompleto', headerName: 'Pertenece A', type: 'string', flex: 0.5, minWidth: 200, valueFormatter: (params) => params?.value !== null ? params?.value:"-", headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'rut', headerName: 'Rut', type: 'string', width: 150, valueFormatter: (params) => params?.value !== null ? params?.value:"-", headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'pasaporte', headerName: 'Pasaporte', type: 'string', width: 150, valueFormatter: (params) => params?.value !== null ? params?.value:"-", headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'patenteDigitos', headerName: 'Patente', type: 'string', width: 180, valueFormatter: (params) => params?.value !== null ? params?.value:"-", headerAlign: 'center', align:'center', sortable: false, editable: false}
        ,
        { field: 'estado', headerName: 'E. Tarjeta', type:'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions', 
            getActions:({id, row})=>{
                return [
                    <SwitchTablas
                    id={"SwitchEstadoActuadorTabla"}
                    value={row.estado}
                    handler={handleEstado(row)}
                    disabled={false} />
                    ]
            }
        }
        ,
        { field: 'asignar', headerName: 'Asignacion', type:'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false, cellClassName: 'actions', 
            getActions:({id, row})=>{
                return [
                    row.asignada === false ? 
                    <TooltipTipoUno titulo={"Asignar a usuario"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              padding: '2px',
                              alignItems: 'center',
                              gap: '10px',
                              width: '32px',
                              height: '32px',
                              borderRadius: '25px',
                              background: '#FFF',
                              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                              '& .icono-asignar-usuario':{
                                fill: '#5f6368',
                              },
                              '& .icono-asignar-usuario .fondo-icono-asignar-usuario':{
                                fill: 'white',
                              },
                              '&:hover':  {
                                backgroundColor: '#E8EEF1',
                                '.icono-asignar-usuario': {
                                  fill: '#175676',
                                },
                              },
                              '&:active':  {
                                backgroundColor: '#1F7098',
                                '.icono-asignar-usuario': {
                                  fill: 'white',
                                },
                              }
                            }}
                          >
                            <IconoAsignarUsuario id='iconoEditar'/> 
                        </Box>}
                        label="Asignar a usuario"
                        onClick={handleNuevaAsignacion(row)}
                        />
                    </TooltipTipoUno>
                    :
                    <TooltipTipoUno titulo={"Editar Asignacion"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              padding: '2px',
                              alignItems: 'center',
                              gap: '10px',
                              width: '32px',
                              height: '32px',
                              borderRadius: '25px',
                              background: '#FFF',
                              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                              '& .icono-editar-asignacion':{
                                fill: '#5f6368',
                              },
                              '&:hover':  {
                                backgroundColor: '#E8EEF1',
                                '.icono-editar-asignacion': {
                                  fill: '#175676',
                                },
                              },
                              '&:active':  {
                                backgroundColor: '#1F7098',
                                '.icono-editar-asignacion': {
                                  fill: 'white',
                                },
                              }
                            }}
                          >
                            <IconoEditarAsignacion/> 
                        </Box>}
                        label="Asignar a usuario"
                        onClick={handleEditarAsignacion(row)}
                        />
                    </TooltipTipoUno>
                    ]
            }
        }
        , 
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <TooltipTipoUno titulo={"Editar tarjeta"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<IconoEditarUsuario id="iconoEnTablaTarjetas" />}
                        label="Editar"
                        onClick={handleOpenEditarTarjeta(row)}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
        ,
        {
            field: 'eliminar', headerName: 'Eliminar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <TooltipTipoUno titulo={row.idPase === null ?"No tiene asignacion":"Eliminar asignacion"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        label="Eliminar"
                        onClick={row.idPase === null ? null : handleDeletePase(id)}
                        icon={<Box
                            id="iconoEditarBox"
                            sx={{
                              display: 'flex',
                              padding: '6px',
                              alignItems: 'flex-start',
                              gap: '10px',
                              borderRadius: '25px',
                              background: '#FFF',
                              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                              '& .icono-papelero .fondo-icono-papelero':{
                                fill: 'white',
                              },
                              '& .icono-papelero .trazo-icono-papelero':{
                                stroke: '#626262',
                                strokeWidth: 1.5,
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                              },
                              '&:hover':  {
                                backgroundColor: '#FAE9E8',
                                '.icono-papelero .fondo-icono-papelero': {
                                  fill: '#FAE9E8',
                                },
                                '.icono-papelero .trazo-icono-papelero': {
                                  stroke: 'red',
                                },
                              },
                              '&:active':  {
                                backgroundColor: 'red',
                                '.icono-papelero .fondo-icono-papelero': {
                                  fill: 'red',
                                },
                                '.icono-papelero .trazo-icono-papelero': {
                                  stroke: 'white',
                                },
                              }
                            }}
                          >
                            <PapeleroIconA id='iconoEditar'/> 
                        </Box>}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
    ];


  return (
    <Box id="GestionUsuariosDataGrid">
        <CabeceraTablasGestion
            icono1={<StyleIcon />}
            titulo={"Gestión de Tarjetas"}
            data={rows}
            agregarNuevoIcon={true}
            handlerAN={handleOpenNT}
            cargaMasivaIcon={null}
            descargarIcon={false}
            handlerCM={null}
            handlerDownload={null}
            value={searchText}
            onChange={handleSearch}
            clearSearch={clearSearch}
            >
            {
                <Box id="BoxSelectContainerCabeceras">
                    <FiltroSelect id="FiltroEstado"
                    nombre={"Tipo"} 
                    listaOpciones={TiposTarjetas} 
                    value={tipoTarjeta} 
                    handler={handleTipoTarjeta} />      

                    <FiltroSelect id="FiltroAsignadas"
                    nombre={"Asignada"} 
                    listaOpciones={TiposAsignacion} 
                    value={tiposAsignacion} 
                    handler={handleTipoAsignacion} />              
                </Box>
            }
            </CabeceraTablasGestion>
            <DataGrid id='TablaGestionTarjetas'
            style={{ width:'100%', height:"100%"}}
            sx={{ 
                overflowX:"hidden",
                overflowY:"hidden",
                '& .MuiDataGrid-columnHeaderTitle': {
                color:'#175676',
                },
                    '& .MuiDataGrid-columnHeader': {
                    borderBottom: '2px solid #94B1C0',
                },
                '& .MuiDataGrid-row':{
                    borderBottom:'1px solid #94B1C0'
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                    width: "7.024px",
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                    background: "#C9C9C9",
                    borderRadius: "100px"
                }
            }}
            getRowId={(row) => row.idTarjeta}
            columns={columns} 
            rows={rows}
            initialState={{
                    pagination: {
                        paginationModel: {
                        page: 0,
                        pageSize: 10,
                        },
                    },
                }}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection
            loading={loading}
            disableRowSelectionOnClick 
            pageSizeOptions={[10, 20]}
            />

            <NuevaTarjeta
            open={nuevaTarjeta}
            handlerOpen={setNuevaTarjeta}
            setRows={setRows}
            />
            <EditarTarjeta
            open={editarTarjeta}
            handlerOpen={setEditarTarjeta}
            setRows={setRows}
            rows={rows}
            dataToEdit={rowTarjeta}
            />

            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />

            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={dialogText.titulo}
            mensaje={dialogText.mensaje}
            />

            <OpcionesAsignacion
            open={openAsignacion}
            setOpen={setOpenAsignacion}
            rowTarjeta={rowTarjeta}
            setRows={setRows}
            opcion={opcionAsignacion}
            rows={rows}
            />
    </Box>
  )
}
