import axios from "axios";
import { useEffect, useState } from "react";
import { PersonaEstacionamientoEmpresa, CrearEstacionamiento, EditarEstacionamiento, EliminarPersonaEstacionamiento, CrearPersonaEstacionamiento, EditarPersonaEstacionamiento, DesactivarEstacionamiento, ReactivarEstacionamiento } from "../../Service/API/Api";

export default function useGetEstacionamientos(){
    const [loading, setLoading] = useState();
    const [data, setData] = useState([]);
    const [response, setResponse] = useState();

    useEffect(() => {
        axios.get(PersonaEstacionamientoEmpresa)
        .then(
            (response) => {
                setLoading(true);
                setData(response.data);
                setLoading(false);
            }
        ).catch(
            (error) => {
                console.log(error.response);
                setLoading(false);
            }
        )
    }, [])


    const CreateEstacionamiento = (values) => {
        return new Promise(async (resolve, reject) => {
            let nuevoEstacionamiento = {
                "idZona": values.idZonaEstacionamiento,
                "numero": values.nombreEstacionamiento,
            }

            await axios.post(CrearEstacionamiento, nuevoEstacionamiento )
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            );
            
        });
    }


    const EditEstacionamiento = (values, idEstacionamiento) => {
        return new Promise(async (resolve, reject) => {
            const editData = {
                "idEstacionamiento": idEstacionamiento,
                "idZona": values.idZonaEstacionamiento,
                "numero": values.nombreEstacionamiento
            }
            await axios.put(EditarEstacionamiento, editData)
            .then(
                (response) =>{
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            );
            
        });
    }

    const EliminarAsociacion = (idPersonaEstacionamiento) => {
        return new Promise(async (resolve, reject) => { 
            await axios.delete(EliminarPersonaEstacionamiento + idPersonaEstacionamiento)
            .then(
                (response) =>{
                    resolve(true);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }


    const NuevaAsociacion = (values, idEstacinamiento) => {
        return new Promise(async (resolve, reject) => { 
            let nueva = {
                "idEstacionamiento": idEstacinamiento,
                "idPersona": values.idPersona === -1 ? null : values.idPersona,
                "idVisitante": values.idVisitante === -1 ? null : values.idVisitante,
            }
            console.log(nueva);
            await axios.post(CrearPersonaEstacionamiento, nueva)
            .then(
                (response) =>{
                    resolve(response.data);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            );
            
        });
    }

    const EditarAsociacion = (idPersonaEstacionamiento, idEstacionamiento, values) => {
        return new Promise(async (resolve, reject) => { 
            let editarAsignacion = {
                "idPersonaEstacionamiento": idPersonaEstacionamiento,
                "idEstacionamiento": idEstacionamiento,
                "idPersona": values.idPersona === -1 ? null : values.idPersona,
                "idVisitante": values.idVisitante === -1 ? null : values.idVisitante,
            }
            await axios.put(EditarPersonaEstacionamiento, editarAsignacion)
            .then(
                (response) =>{
                    resolve(true);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }

    const HandleEstadoEstacionamiento = (idEstacinamiento, estadoActual) => {
        return new Promise(async (resolve, reject) => {             
            await axios.patch(estadoActual === true ? DesactivarEstacionamiento + idEstacinamiento : ReactivarEstacionamiento + idEstacinamiento)
            .then(
                (response) =>{
                    resolve(true);
                    setLoading(false);
                }
            )
            .catch (
                (error) =>{
                    reject(false);
                    setLoading(false);
                }
            );
            
        });
    }
    

    return{
        loading,
        data,
        setLoading,
        response,
        CreateEstacionamiento,
        EditEstacionamiento,
        EliminarAsociacion,
        NuevaAsociacion,
        EditarAsociacion,
        HandleEstadoEstacionamiento
    }
}