import * as Yup from 'yup';

export const EsquemaNuevoActuador = Yup.object({
    nombreActuador: Yup.string()
    .required('* Campo requerido')
    , 
    idTipoActuador: Yup.number()
    .test('min', "Debe seleccionar un tipo", function(value){
        if(value > 0 ){
          return true;
        }
        else{
          return false;
        }
      })
    , 
    idSentido: Yup.number(), 
    mac: Yup.string()
    .required('* Campo requerido')
    , 
    serial: Yup.string()
    .required('* Campo requerido')
    , 
    clave: Yup.string()
    .nullable(), 
    ip: Yup.string()
    .required('* Campo requerido'), 
    puerto: Yup.number()
    .required('* Campo requerido'), 
    idZona: Yup.number()
    .required('* Campo requerido')
    .test('min', "Debe seleccionar una zona", function(value){
        if(value > 0 ){
          return true;
        }
        else{
          return false;
        }
    }) 
})