import { useState } from 'react';
import axios from 'axios';
import { OlvidoContrasena } from '../../Service/API/Api'


function useRecuperar() {
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();

    const Recuperar = async (email) => {
        return new Promise(async (resolve, reject) => {
            const recuperar = {
                correo: email,
            };
            
            await axios.post(OlvidoContrasena, recuperar)
            .then(
                (response) => 
                {
                    resolve(true);
                    setResponse(response.status)
                    setLoading(false)
                }
            )
            .catch(
                (response) => 
                {
                    reject(false);
                    setResponse(response.response.status)
                    setLoading(false)
                }
            )
            
        });       
    }

    return {
        loading,
        setLoading,
        Recuperar,
        response
    }
};

export default useRecuperar;
