import { Box, Typography } from "@mui/material";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import "./NoData.css";

export const NoData = ({ mensaje, textoBtn, event}) => {
    const handlerEvent = () => {
        event(true)
    }

    return(
        <Box id="BoxLayoutNoExisteEnListaNegraGU">
            <Box id="BoxMensajeNoExisteEnListaNegraGU">
                <Typography id="TxtMensajeNoExisteEnListaNegraGU">
                    {
                        mensaje
                    }
                </Typography>
            </Box>
            <Box id="BoxBotonRapidoAgregarListaNegraGU">
                <Box id="BoxBotonAgregarListaNegraGU">
                    <BotonTipo2 textoBoton={textoBtn} event={handlerEvent}/>
                </Box>
            </Box>
        </Box>
    );
};