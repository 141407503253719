import { useFormik } from "formik";
import { TextFieldDos } from "../../../TextField/TextFieldDos";
import { AvatarUsuario2 } from "../Avatar/AvatarUsuario";
import { IconoCerrar } from "../DataUsuario/DataUsuarioIcon";
import "./EditarContrasena.css";
import { validacionContrasenaPerfil } from "./validaction";
import { Box, IconButton } from "@mui/material";
import { BotonConLoading } from "../../../Botones/BotonesTabla/Botones";
import { IconoOjoAbierto, IconoOjoCerrado } from "./EditarContrasenaIcon";
import { useState } from "react";
import usePerfil from "../../../../Hooks/OpcionesPerfil/usePerfil";
import {LoadingMasRespuesta} from "../../LoadingMasRespuesta/LoadingMasRespuesta";
import { IconoExito, IconoFallo } from "../../RespuestaServidor/RespuestaServidorIcon";

const style = {
    width:"810px",
    height:"572px",
    display: "flex",
    position: 'absolute',
    flexDirection: "column",
    justifyContent: "center",
    aligItem: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};


const EditarContrasenaPerfil =  ({informacionUsuario, handle}) => {
    const {loading, setLoading, respuestaCC, ActualizarPassWordUsuario} = usePerfil();
    const [showActual, setShowActual] = useState(Boolean(false));
    const [showNuevo, setShowNuevo] = useState(Boolean(false));
    const [showConfirmacion, setShowConfirmacion] = useState(Boolean(false));
    const [open, setOpen] = useState(Boolean(false));
    
    const handleOpen =() => {
        setOpen(true);
    };

    var img = informacionUsuario.urlImagenPerfil;
    var nombreCompleto = informacionUsuario.nombres.split(" ")[0] + " " + informacionUsuario.apellidos.split(" ")[0]
    var rol = informacionUsuario.tipo;
    var correoUsuario = informacionUsuario.correo.toLowerCase();

    const handlerShowActual = () => {
        setShowActual(!showActual)
    }
    const handlerShowNuevo = () => {
        setShowNuevo(!showNuevo)
    }
    const handlerShowConfirmacion = () => {
        setShowConfirmacion(!showConfirmacion)
    }

    const formik = useFormik(
        {
            initialValues: { 
                correoEditarPass: correoUsuario,
                passActual: "",
                passNuevaAgregar:"",
                confirmarPassNuevaAgregar:""
            },
            validationSchema: validacionContrasenaPerfil,
            onSubmit: () => {
                setLoading(true);
                ActualizarPassWordUsuario(formik.values);
                handleOpen();
            },
            onReset: () => {
                formik.resetForm();
            }
        }
    );

    return(
        <Box id="BoxModalEditarContrasenaPerfil" sx={{...style}}>
             <Box id="BoxCabeceraEditarContrasena">
                <IconButton onClick={handle}>
                    <IconoCerrar id="IconoCerrar" />
                </IconButton>
            </Box>
            <Box id="BoxDataUsuarioContrasena">
                <AvatarUsuario2 
                titulo={"Editar Contraseña"} 
                img={img} 
                nombre={nombreCompleto} 
                rol={rol}
                />
                <Box id="BoxFormEditarPass" component={"form"} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <TextFieldDos
                    id={"CorreoEditarPass"}
                    blur={formik.handleBlur} 
                    label="Correo"
                    placeholder={null}
                    icon={null}
                    icon2={null}
                    disabled={true}
                    helper={null}
                    value={correoUsuario}
                    name={"correoEditarPass"}
                    handlerChange={null}
                    handlerClick={null}
                    type={"text"}
                    />

                    <TextFieldDos
                    id={"passActual"}
                    blur={formik.handleBlur} 
                    label="Contraseña actual"
                    placeholder={"Contraseña actual"}
                    icon={<IconoOjoAbierto id="IconoEnFormEditar" />}
                    icon2={<IconoOjoCerrado id="IconoEnFormEditar" />}
                    disabled={false}
                    helper={formik.errors.passActual}
                    value={formik.values.passActual}
                    name={"passActual"}
                    handlerChange={formik.handleChange}
                    handlerClick={handlerShowActual}
                    type={showActual === true ? "text" : "password"}
                    />

                    <TextFieldDos
                    id={"PassNuevaAgregar"}
                    blur={formik.handleBlur} 
                    label="Contraseña nueva"
                    placeholder={"Contraseña nueva"}
                    icon={<IconoOjoAbierto id="IconoEnFormEditar" />}
                    icon2={<IconoOjoCerrado id="IconoEnFormEditar" />}
                    disabled={false}
                    helper={formik.errors.passNuevaAgregar}
                    value={formik.values.passNuevaAgregar}
                    name={"passNuevaAgregar"}
                    handlerChange={formik.handleChange}
                    handlerClick={handlerShowNuevo}
                    type={showNuevo === true ? "text" : "password"}
                    />

                    <TextFieldDos
                    id={"ConfirmarPassNuevaAgregar"}
                    blur={formik.handleBlur} 
                    label="Confirmar contraseña"
                    placeholder={"Confirmar contraseña"}
                    icon={<IconoOjoAbierto id="IconoEnFormEditar" />}
                    icon2={<IconoOjoCerrado id="IconoEnFormEditar" />}
                    disabled={false}
                    helper={formik.errors.confirmarPassNuevaAgregar}
                    value={formik.values.confirmarPassNuevaAgregar}
                    name={"confirmarPassNuevaAgregar"}
                    handlerChange={formik.handleChange}
                    handlerClick={handlerShowConfirmacion}
                    type={showConfirmacion === true ? "text" : "password"}
                    />
                    <Box id="BoxBotonActualizarContrasena">
                        <BotonConLoading textoBoton={"Guardar cambios"} loadingStatus={loading}/>
                        <LoadingMasRespuesta 
                        open={open}
                        setOpen={setOpen}
                        msj={"Actualizando..."}
                        id={"ModalRespuestaEditarPass"}
                        loading={loading}
                        respuesta={respuestaCC}
                        icono={
                            respuestaCC === 200 ?
                            <IconoExito id="IconoRespuestaServidor" />
                            :
                            <IconoFallo id="IconoRespuestaServidor" />
                        }
                        textoBoton={ 
                            respuestaCC === 200 ?
                            "Aceptar"
                            :
                            "Reintentar"
                        }
                        titulo={respuestaCC === 200 ? "Cambios guardados":"Cambios no guardados"}
                        mensajeRespuesta={respuestaCC === 200 ? "Tu contraseña ha sido actualizada con éxito":"Oops! Algo salió mal al intentar guardar los cambios, por favor intenta de nuevo"}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}



export default EditarContrasenaPerfil;