import { Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import "./MenuFiltroTiempoReal.css"
import { CerrarPerfilIcon } from '../../Profile/IconProfile'
import { IconCerrarAccesos } from '../../GestionAccesos/IconsGestionAccesos';
import TooltipTipoUno from '../../ToolTip/Tooltip';
import { SnackBarTipoUno } from '../../snackBar/SnackBar';

export const MenuFiltroTiempoReal = ({ anchorEl, handleClose, desactivarFiltro, filtrosActivados }) => {

    //Estados y funciones para manejar el snackbar
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const handleEliminarFiltro = (data) => {
        // const nuevaData = dataFiltrada.filter(item => item.idActuador !== data.idActuador);
        desactivarFiltro(data);

        setSnackbarSeverity("success")
        setSnackMsg("Filtro eliminado con éxito!");
        setSnackbar(true);   
    }


  return (
    <Box>
        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }} 
        sx={{
            padding: "0px", margin: "0px", "& .MuiList-root": {
                padding: "0px", margin: "0px" 
            }
        }}
        >   
            <Box id="ContainerMenu">
                <Box id="Header">
                    <Typography id="TxtTituloFiltroTiempoReal" >
                       {
                            "Filtro en tiempo real"
                       }
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CerrarPerfilIcon id="CerrarMenuIcon"/>
                    </IconButton>
                </Box>
                <Box id="BoxMenuItem">
                    {filtrosActivados.map((data => (
                        <Box key={data.idActor} id="MenuItemFiltro">
                            <TooltipTipoUno titulo={`${data.tipoActor}: ${data.nombre}`}>
                                <MenuItem id='DescripcionFiltro'>
                                    <Typography id="TxtTipoFiltroNombreFiltroAplicado">
                                        {
                                            `${data.tipoActor}: ${data.nombre}`
                                        }
                                    </Typography>                                
                                </MenuItem>
                            </TooltipTipoUno>
                            
                            <TooltipTipoUno titulo={"Eliminar filtro"}>
                                <IconButton id="BorrarFiltroIcon" onClick={() => handleEliminarFiltro(data)}>
                                    <IconCerrarAccesos id="iconoEnTablaaFiltros" />
                                </IconButton>
                            </TooltipTipoUno>
                        </Box>
                    )))}
                </Box>
                <Divider orientation='horizontal' flexItem/>
                <Box id="BoxTotalFiltrosAplicados">
                    <Typography>
                        {
                            filtrosActivados.length + " filtros aplicados"
                        }
                    </Typography>
                </Box>
            </Box>
                <SnackBarTipoUno 
                open={snackbar}
                handler={handleCloseSnackbar}
                duration={6000}
                severity={snackbarSeverity}
                msg={snackMsg}
                />
        </Menu>
    </Box>
  )
}
