import { Box } from "@mui/material";
import CabeceraTablasGestion from "../CabeceraTablasGestion/CabecerasTablasGestion";
import { ListaNegraIcon } from "../LeftBar/IconosMenu";
import { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import TooltipTipoUno from "../ToolTip/Tooltip";
import { PapeleroIconA } from "../GestionUbicaciones/IconosGestionUbicaciones";
import dayjs from "dayjs";
import { AgregarAListaNegra } from "./Nuevo/AgregarAListaNegra";
import useListaNegra from "../../Hooks/ListaNegra/useListaNegra";
import useConfirm from "../../Hooks/ConfirmDialog/useConfirmDialog";
import { IconoAlerta } from "../Usuarios/UsuariosIcon";
import { IconoExito, IconoFallo } from "../Modal/RespuestaServidor/RespuestaServidorIcon";
import { LoadingMasRespuestaCinco } from "../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { SnackBarTipoUno } from "../snackBar/SnackBar";
import { PopOverTipoUno } from "../Popover/PopoverTipoUno";
import { VerMotivo } from "./Ver/VerMotivo";
import { ComentarioIcono } from "../Reportes/ReportesIcons";


const ListaNegra = () => {
    const { loading, response, setLoading, listaNegra, EliminarEnLN } = useListaNegra();
    const [rows, setRows] = useState([]);
    const [openAgregarALista, setOpenAgregarALista] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [ConfirmationDialog, confirm ]= useConfirm();
    const [confirmMessage, setConfirmMessage] = useState({titulo: "", mensaje: ""});
    const [openLoading, setOpenLoading] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState();
    const [snackMsg, setSnackMsg] = useState('');
    const [snackbar, setSnackbar] = useState(false);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [motivoIngreso, setMotivoIngreso] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setRows(listaNegra)
    }, [listaNegra])

    const handleOpenVerMas = (e, id) => {
        var rowFinded  = rows.find(ln => ln.idListaNegra === id);
        setMotivoIngreso(rowFinded.motivoIngreso)
        setAnchorEl(e.currentTarget);
    }
    
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = listaNegra.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const handlerNuevo = () => {
        setOpenAgregarALista(true);
    }

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.idListaNegra === newRow.idListaNegra ? updatedRow : row)));
        return updatedRow;
    };

    const handlerEliminar = (id) => async() => {
        setConfirmMessage({
            titulo: "¿Eliminar?", mensaje: "Estas a punto de eliminar el registro de lista negra, esto habilitara nuevamente al usuario ¿Continuar?"
        })
        var response = await confirm();
        if(response)
        {
            setOpenLoading(true);
            setLoading(true);
            EliminarEnLN(id)
            .then(
                (resolve) => {
                    setRows(rows.filter((row) => row.idListaNegra !== id));
                    listaNegra.filter((row) => row.idListaNegra !== id);
                    setSnackbarSeverity("success")
                    setSnackMsg("Registro eliminado correctamente...")
                    setSnackbar(true); 
                }
                ,
                (reject) => {
                    setSnackbarSeverity("error")
                    setSnackMsg("Ha fallado la eliminacion...")
                    setSnackbar(true); 
                }
            )
        }
        else{
            console.log("Rechaza la eliminacion");
        }
    }


    const columns = [
        { field: 'idListaNegra', headerName: 'ID', width: 100, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'fechaIngreso', headerName: 'Fecha de Ingreso', type: 'string', width: 200, headerAlign: 'center', valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY HH:mm:ss"), align:'center', sortable: false, editable: false},
        { field: 'nombreCreador', headerName: 'Ingresado por', type: 'string',  width: 180, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'nombre', headerName: 'Usuario afectado', type: 'string', width: 200, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'rutPersona', headerName: 'Rut Usuario', type: 'string', width: 150, valueFormatter: (params) => params?.value === null ? "-":params?.value, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'pasaporte', headerName: 'Pasaporte Usuario', type: 'string', width: 150, valueFormatter: (params) => params?.value === null ? "-":params?.value, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'motivoIngreso', headerName: 'Motivo', type: 'string', flex: 0.5, minWidth: 200,valueFormatter: (params) => params?.value === null ? "-":params?.value, headerAlign: 'center', align:'center', sortable: false, editable: false}
        ,
        {
            field: 'detalles', headerName: 'Ver Motivo ', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id}) => {
                return [
                    <TooltipTipoUno titulo={"Ver más"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<ComentarioIcono  id='iconoEnTablaUsuarios'/>}
                        label="Ver más"
                        onClick={(e) => handleOpenVerMas(e, id)}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
        ,
        {
            field: 'Eliminar', headerName: 'Eliminar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id}) => {
                return [
                    <TooltipTipoUno titulo={"Eliminar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<Box id='iconoEditarBox'> <PapeleroIconA id='iconoEditar'/> </Box>}
                        label="Eliminar"
                        onClick={handlerEliminar(id)}
                        />
                    </TooltipTipoUno>
                ];
            }
        }
    ];

    return (
        <Box id="BoxContenedorTablaActuadores">
            <CabeceraTablasGestion 
            icono1={<ListaNegraIcon />}
            titulo={"Lista negra"}
            rows={rows}
            setRows={setRows}
            agregarNuevoIcon={true}
            handlerAN={handlerNuevo}
            cargaMasivaIcon={null}
            handlerCM={null}
            descargarIcon={null}
            handlerDownload={null}
            onChange={handleSearch}
            value={searchText}
            clearSearch={clearSearch}
            >
                {/* <Box id="BoxSelectContainerCabeceras">
                    <FiltroSelect id="FiltroSelectTiposActores" 
                    nombre={"Tipos"} 
                    listaOpciones={tipoActuadores}
                    value={tipoActuador}
                    handler={handleTipoActuadores}
                    />
                    <FiltroSelect id="FiltroSelectZonasEmpresa" 
                    nombre={"Zonas"} 
                    listaOpciones={zonasFormadas}
                    value={opcionZona}
                    handler={handleZona}
                    />
                </Box> */}
            </CabeceraTablasGestion>
            <DataGrid id='TablaActuadores'
            style={{ width:'100%', height:"100%"}}
            sx={{ 
                '& .MuiDataGrid-columnHeaderTitle': {
                color:'#175676',
            },
                '& .MuiDataGrid-columnHeader': {
                borderBottom: '2px solid #94B1C0',
            },
            '& .MuiDataGrid-row':{
                borderBottom:'1px solid #94B1C0'
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                width: "7.024px",
                background: "transparent"
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                background: "transparent"
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                background: "#C9C9C9",
                borderRadius: "100px"
            }
            }}
            getRowId={(row) => row.idListaNegra}
            columns={columns} 
            rows={rows}
            initialState={{
                    pagination: {
                        paginationModel: {
                        page: 0,
                        pageSize: 10,
                        },
                    },
                }}
            processRowUpdate={processRowUpdate}            
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection
            loading={loading}
            disableRowSelectionOnClick 
            pageSizeOptions={[10, 20]}
            />
            
            <AgregarAListaNegra 
            open={openAgregarALista}
            setOpen={setOpenAgregarALista}
            setRows={setRows} 
            />

            
            <SnackBarTipoUno 
            open={snackbar}
            handler={handleCloseSnackbar}
            duration={6000}
            severity={snackbarSeverity}
            msg={snackMsg}
            />

            <ConfirmationDialog
            icono={<IconoAlerta id="iconoEnDialogConfirmacion" />}
            titulo={confirmMessage.titulo}
            mensaje={confirmMessage.mensaje}
            />
            <LoadingMasRespuestaCinco
            open={openLoading} 
            setOpen={setOpenLoading} 
            msj={"Se esta aplicando la eliminacion dle registro. Este proceso puede tardara solo un momento. Agradecemos tu paciencia."} 
            id={"RespuestaGuardarEditarEstacionamiento"} 
            tituloLoading={"Eliminando registro..."}
            loading={loading} 
            respuesta={response} 
            icono={
                response === 200 ?
                <IconoExito id="IconoRespuestaServidor" />
                :
                <IconoFallo id="IconoRespuestaServidor" />
            }
            textoBoton={
                response === 200 ? 
                "Aceptar"
                :
                "Reintentar"
            }
            titulo={response === 200 ? "Eliminacion exitosa":"Error al eliminar"}
            mensajeRespuesta={response === 200 ? 
                "Registro eliminado correctamente"
                :
                "Ha ocurrido un error y no se ha podido quitar al usuario de la lista negra, confirme e intente nuevamente o contactenos para asistencia"}
            />

            <PopOverTipoUno
            open={open}
            setOpen={setAnchorEl}
            anchorEl={anchorEl}
            children={<VerMotivo motivo={motivoIngreso} />}
            />
        </Box>
    );
}

export default ListaNegra;