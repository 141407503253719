import * as Yup from 'yup';
import { cleanRut, validateRut } from '../../../Helper/ValidarRut';

export const Esquema = Yup.object({
    nombres: Yup.string()
      .required('* Campo requerido'),
    apellidos: Yup.string()
      .required('* Campo requerido'),
    rut: Yup.string()
    .test("min", "rut no valido", function(value){
      return (validateRut(cleanRut(value)));
    })
    .nullable(),
    pasaporte: Yup.string()
      .nullable(),
    idTipoPersona: Yup.number()
    .test('min', "Debe seleccionar un tipo", function(value){
      if(value === -1 ){
        return false;
      }
      else{
        return true;
      }
    })
    .required('* Campo requerido'),
    correo: Yup.string().email("Debe ingresar un correo valido")
    .required('* Campo requerido'),
    telefono: Yup.string()
    .required('* Campo requerido'),
})