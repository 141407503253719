import { Contacto } from "./Contacto";
import { ImagenLAU } from "./Imagen";

export const HandlerVentanaDos = ({opcion, handleOpcion}) => {

    return(
        opcion === 1?
        <ImagenLAU handleOpcion={handleOpcion} />
        :
        <Contacto handlerOpcion={handleOpcion} />
    );
}