import { Autocomplete, Box, FormControl, FormHelperText, TextField } from "@mui/material";
import "./OpcionesAsignacion.css";
import usePatentesUsuarioAsignaciones from "../../../Hooks/Patentes/usePatentesUsuarioAsignacion";


export const PatentesUsuarioSeleccionado = ({formik}) => {
    const {patentesActivas} = usePatentesUsuarioAsignaciones(formik.values.idPersona);

    return(
        <FormControl fullWidth>
            <Autocomplete
            disablePortal
            id="idPatente"  
            ListboxProps={{ sx: { maxHeight: "150px"}}}                                  
            options={patentesActivas}
            fullWidth
            getOptionKey={(o) => o.idPatente}
            isOptionEqualToValue={(o, v) => o.patente === v}
            freeSolo={true}
            onChange={(e, v) => {
                if(v === null)
                {
                    formik.setFieldValue("idPatente", -1)                                           
                }
                else{
                    formik.setFieldValue("idPatente", v.idPatente)
                }
            }}
            
            renderInput={(params) => <TextField 
                InputLabelProps={{shrink: true}}
                {...params}                                        
                label="Patentes"                          
                />
            }
            value={formik.values.patenteDigitos}
            inputValue={formik.values.patenteDigitos}
            onInputChange={(e, v) => formik.setFieldValue("patenteDigitos", v)}
            componentname="idPatente"
            getOptionLabel={(option) => option.patente ?? option}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <Box
                    key={key}
                    component="li"
                    {...optionProps}>
                    {option.patente}
                    </Box>
                );
              }}
            />
            <FormHelperText>
                {
                    formik.errors.idPatente
                }
            </FormHelperText>
        </FormControl>
    );
}