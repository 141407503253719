import { Box, IconButton, Modal } from "@mui/material";
import { NuevaZona } from "./NuevaZona";
import { EditarZona } from "./EditarZona";
import "./OpcionesZona.css";
import { CerrarModalNuevoUsuario } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";

const style = {
    width: "1144px",
    height: "600px",
    display: "flex",
    position: 'absolute',
    flexDirection: "column",
    aligItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};



export const HandleOpcionesZona = ({ open, setOpen, setRows, rows, opcion, dataEdit}) => {
    const handlerClose = () => {
        setOpen(false);
    }
    return (
        <Modal open={open} onClose={handlerClose} sx={{border:"unset"}}>
            <Box sx={{ ...style}}>
                <Box id="BoxIconoCierreNuevaZona">
                    <Box id="BoxIconoNuevaZona">
                        <IconButton onClick={handlerClose}>
                            <CerrarModalNuevoUsuario />
                        </IconButton>
                    </Box>
                </Box>
                <Box id="BoxContenidoOpcionesZona">
                    {
                    opcion === 1 ?
                        <NuevaZona  setOpen={setOpen} setRows={setRows} />
                        :
                        <EditarZona setOpen={setOpen}  zonaEdit={dataEdit} rows={rows} setRows={setRows}/>
                    }
                </Box>
            </Box>
        </Modal>
    )
}