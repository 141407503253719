import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IconoGestionHorario } from './Iconos'
import CabeceraTablasGestion from '../CabeceraTablasGestion/CabecerasTablasGestion'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import useHorarios from '../../Hooks/Horarios/useHorario';
import TooltipTipoUno from '../ToolTip/Tooltip';
import { IconoEditarUsuario } from '../Usuarios/UsuariosIcon';
import { HandleOpcionesHorario } from './OpcionesHorario/ManejadorOpciones';

export default function GestionHorarios() {
    const {horarios} = useHorarios();
    const [rows, setRows] = useState([]);
    const [openModal, setOpenModal] = useState(Boolean(false));
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);
    const [dataEditar, setDataEditar] = useState(null);
    const [searchText, setSearchText] = useState('');

    
    useEffect(() => {
        setRows(horarios);
    }, [horarios])
    

    const handleOpenNuevoHorario = () => {
        setOpenModal(true)
        setOpcionSeleccionada(1)
    }

    const OpenEditarHorario = (value) => () => {
        setOpenModal(true)
        setOpcionSeleccionada(2)
        setDataEditar(value)
    }

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = horarios.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field]);
            });
          });
        setRows(filteredRows);
    };

    const handleSearch = (e) => {
        requestSearch(e.target.value)
    }

    const clearSearch = () => {
        requestSearch('')
    }

    const escapeRegExp =(value) => {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    const columns = [
        { field: 'idHorario', headerName: 'ID', flex: 0.5, minWidth: 50, headerAlign: 'center', align:'center', sortable: false, editable: false},
        { field: 'nombre', headerName: 'Nombre', flex: 0.5, minWidth: 145, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'lunes', headerName: 'Lunes', type: 'string',  flex: 0.5, minWidth: 145, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'martes', headerName: 'Martes', type: 'string',  flex: 0.5, minWidth: 145, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'miercoles', headerName: 'Miercoles', type: 'string', flex: 0.5, minWidth: 145, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value, align:'center', sortable: false, editable: false},
        { field: 'jueves', headerName: 'Jueves', type: 'string', flex: 0.5, minWidth: 145, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value,align:'center', sortable: false, editable: false},
        { field: 'viernes', headerName: 'Viernes', type: 'string', flex: 0.5, minWidth: 145, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value,align:'center', sortable: false, editable: false},
        { field: 'sabado', headerName: 'Sabado', type: 'string', flex: 0.5, minWidth: 145, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value,align:'center', sortable: false, editable: false},
        { field: 'domingo', headerName: 'Domingo', type: 'string', flex: 0.5, minWidth: 145, headerAlign: 'center', valueFormatter: (params) => params?.value === null ? "-":params?.value,align:'center', sortable: false, editable: false},
        {
            field: 'editar', headerName: 'Editar', type: 'actions', width: 100, headerAlign: 'center', align:'center', sortable: false, cellClassName: 'actions', editable: false,
            getActions: ({id, row}) => {
                return [
                    <TooltipTipoUno titulo={"Editar"} ubicacion={"bottom"}>
                        <GridActionsCellItem
                        icon={<IconoEditarUsuario  id='iconoEnTablaUsuarios'/>}
                        label="Editar"
                        onClick={OpenEditarHorario(row)}
                        />
                    </TooltipTipoUno>
                ];
            }
        },
    ];
    return (
    <Box id="BoxContenedorTablaActuadores">
        <CabeceraTablasGestion 
            icono1={<IconoGestionHorario/>}
            titulo={"Gestion Horarios"}
            rows={rows}
            setRows={setRows}
            agregarNuevoIcon={true}
            handlerAN={handleOpenNuevoHorario}
            cargaMasivaIcon={null}
            handlerCM={null}
            descargarIcon={null}
            handlerDownload={null}
            onChange={handleSearch}
            value={searchText}
            clearSearch={clearSearch}
            >
        </CabeceraTablasGestion>
        <DataGrid id="TablaHorarios"
            style={{ width:'100%', height:"100%"}}
            sx={{ 
                '& .MuiDataGrid-columnHeaderTitle': {
                color:'#175676',
                },
                    '& .MuiDataGrid-columnHeader': {
                    borderBottom: '2px solid #94B1C0',
                },
                '& .MuiDataGrid-row':{
                    borderBottom:'1px solid #94B1C0'
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                    width: "7.024px",
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                    background: "transparent"
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                    background: "#C9C9C9",
                    borderRadius: "100px"
                }
            }}
            getRowId={(row) => row.idHorario}
            columns={columns} 
            rows={rows}
            initialState={{
                    pagination: {
                        paginationModel: {
                        page: 0,
                        pageSize: 10,
                        },
                    },
                }}
            checkboxSelection={false}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableVirtualization={true}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            hideFooterSelectedRowCount={true}
            rowSelection
            loading={null}
            disableRowSelectionOnClick 
            pageSizeOptions={[10, 20]}
            />

            <HandleOpcionesHorario
            open={openModal}
            setOpen={setOpenModal}
            setRows={setRows}
            rows={rows}
            opcion={opcionSeleccionada}
            dataEdit={dataEditar}
            />
    </Box>
  )
}
