import { Box, Modal } from "@mui/material";
import "./OpcionesEventos.css";
import { DetalleEventoAgendado } from "./ConEvento/ConEvento";
import { SinEventos } from "./SinEvento/SinEvento";
import { MultipleEventos } from "./MultipleEvento/MultipleEvento";

const style = {
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};

export const HandlerOpcionesDetalleEventos = ({open, setOpen, eventoData, opcion, fechaSeleccionada, listaEventos}) => {
    const handlerClose = () => {
        setOpen(false);
    }

    return(
        <Modal slotProps={{backdrop: { sx: { background: "transparent"}}}} open={open} onClose={handlerClose}>
            <Box sx={{...style}}>
                {
                    opcion === 1 ?
                    <DetalleEventoAgendado eventoData={eventoData} setOpen={setOpen} />
                    :
                    opcion === 2 ?
                    <SinEventos fechaSeleccionada={fechaSeleccionada} setOpen={setOpen}/>
                    :
                    <MultipleEventos fechaSeleccionada={fechaSeleccionada} setOpen={setOpen} listaEventos={listaEventos}/>
                }
            </Box>
        </Modal>
    );
}