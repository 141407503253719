import { AppBar, Box, Toolbar } from "@mui/material";
import Buscador from "./Search/Search";
import "./navbar.css";
import MenuUsuarios from "./MenuUsuario/MenuUsuario";

const Navbar = () => {

  

  return (
    <Box id="BarraNavegacion" sx={{ width: '100%', height: "60px", marginTop: "42px", marginBottom: "42px" }}>
      <AppBar position="static" sx={{ background: "transparent", boxShadow: 'none' }}>
        <Toolbar id="TBBNavegacion" >
          <Box id="Buscador">
            <Buscador />
          </Box>
          <Box id="MenuUsuarios">
            <MenuUsuarios/>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;