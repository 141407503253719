import { Autocomplete, Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import "./ManejadorOpciones.css"
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { TextFieldTres } from "../../TextField/TextFieldTres";
import { useFormik } from "formik";
import { IconoBorrarCampoGU } from "../../Modal/MenuGestionUsuario/MenuGestionUsuarioIcon";
import { InitialValuesNE } from "./InitialValues";
import useGestZonasEmpresa from "../../../Hooks/Zona/useGetZonasEmpresa";
import { useState } from "react";
import { EsquemaNuevaPatente } from "./ValidationSchema";
import useGetEstacionamientos from "../../../Hooks/Estacionamientos/useGetEstacionamientos";

export const NuevoEstacionamiento = ({setOpen, setRows}) =>{
    const {zonasFormadas} = useGestZonasEmpresa();
    const {setLoading, loading, response, CreateEstacionamiento} = useGetEstacionamientos();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    const handlerClose = () => {
        formik.resetForm();
        setOpen(false);
    }

    const AddNewRow = (nuevaId) => {
        var findedZona = zonasFormadas.find(z => z.id === formik.values.idZonaEstacionamiento);

        var nuevoEstacionamiento = {
            idEstacionamiento: nuevaId,
            idPersona: null,
            idVisita: null,
            estado: true,
            fechaAsignacion: null,
            fechaTermino: null,
            idPersonaEstacionamiento: null,
            nombreZona: findedZona.tipo,
            numero: formik.values.nombreEstacionamiento,
            pasaporte: null,
            rut: null,
            nombreCompleto: null,
            isNew: true
        }
        setRows((oldRows) => [
            
            ...oldRows,
            nuevoEstacionamiento,
        ]);
    }

    const formik = useFormik({
        initialValues:InitialValuesNE,
        enableReinitialize: true,
        validationSchema: EsquemaNuevaPatente,
        onSubmit: async ()=> {
            setLoading(true);
            setOpenLoading(true);
            await CreateEstacionamiento(formik.values)
            .then(
                (resolve) => {
                    AddNewRow(resolve)
                }
                ,
                (reject) => {
                    console.log("fallo la creacion");
                }
            )
        }
    })

    const handleAgregarOtro = () => {
        formik.resetForm();
        setOpenLoading(false);
    }

    return (
        <Box id="BoxContenedorNuevoEstacionamiento">
            <Box id="BoxTituloSubTituloNuevoEstacionamiento">
                <Box id="BoxTituloOpcionGestionEstacionamientos">
                    <Typography id="TxtTituloGestionEstacionamiento">
                        {
                            "Agregar estacionamiento"
                        }
                    </Typography>
                </Box>
                <Box id="BoxSubTituloOpcionGestionEstacionamientos">
                    <Typography id="TxtSubTituloGestionEstacionamiento">
                        {
                            "Todos los campos son obligatorios para completar el registro."
                        }
                    </Typography>
                </Box>
            </Box>
            <Box id="BoxInformacionNuevoEstacionamiento">
                <Box id="BoxContenidoInformacionEstacionamiento" component={"form"} onSubmit={formik.handleSubmit}>
                    <Box id="BoxContenidoZonaNombreEstacionamiento">
                        <Box id="BoxTituloNuevoEstacionamientoFormato">
                            <Typography id="TxtOpcionTituloFormulario">
                                {
                                    "Información del estacionamiento"
                                }
                            </Typography>
                        </Box>
                        <Box id="BoxFormularioNuevoEstacionamiento">
                            <Box id="BoxContenedorOpcionesFormularioNuevoEstacionamiento">
                                <TextFieldTres
                                id="TFNuevoEstacionamientoNombre"
                                label={"Nombre Estacionamiento"}
                                placeholder={"Nombre del estacionamiento"}
                                icon={<IconoBorrarCampoGU id="IconoEnEstacionamiento" />}
                                helper={formik.errors.nombreEstacionamiento}
                                value={formik.values.nombreEstacionamiento}
                                name={"nombreEstacionamiento"}
                                handlerChange={formik.handleChange}
                                handlerClick={() => formik.setFieldValue("nombreEstacionamiento", "")}
                                disabled={false}
                                />
                            </Box>
                            <Box id="BoxContenedorOpcionesFormularioNuevoEstacionamiento">
                                <FormControl fullWidth>
                                    <Autocomplete
                                    disablePortal
                                    id="idZonaEstacionamiento"                                    
                                    options={zonasFormadas}
                                    ListboxProps={{ sx: { maxHeight: "150px"}}}
                                    fullWidth
                                    getOptionKey={(o) => o.id}
                                    isOptionEqualToValue={(o, v) => o.tipo === v}
                                    freeSolo={true}
                                    onChange={(e, v) => {
                                        if(v === null)
                                        {
                                            formik.setFieldValue("idZonaEstacionamiento", 0)                                           
                                        }
                                        else{
                                            formik.setFieldValue("idZonaEstacionamiento", v.id)
                                        }
                                    }}
                                    
                                    renderInput={(params) => <TextField 
                                        InputLabelProps={{shrink: true}}
                                        {...params}                                        
                                        label="Zonas"                          
                                        />
                                    }
                                    value={formik.values.nombreZona}
                                    inputValue={formik.values.nombreZona}
                                    onInputChange={(e, v) => formik.setFieldValue("nombreZona", v)}
                                    componentname="idZonaEstacionamiento"
                                    getOptionLabel={(option) => option.tipo ?? option}
                                    renderOption={(props, option) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <Box
                                            key={key}
                                            component="li"
                                            {...optionProps}>
                                            {option.tipo}
                                            </Box>
                                        );
                                      }}
                                    />
                                    <FormHelperText>
                                        {
                                            formik.errors.idZonaEstacionamiento
                                        }
                                    </FormHelperText>                                   
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>                   
                </Box>
                <Box id="BotoncesAccionModalNuevoUsuario">
                    <Box id="BoxBotones">
                        <Box id="BoxBotonNuevoUsuario">
                            <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                        </Box>
                        <Box id="BoxBotonNuevoUsuario">
                        <BotonTipo2
                            textoBoton={"Agregar"}
                            event={formik.submitForm}
                            disabled={loading}
                        />
                        <LoadingMasRespuestaCuatro
                            id={"LoadingMasRespuestaDosNuevoUsuario"}
                            open={openLoading}
                            setOpen={setOpenLoading}
                            msj={
                            "Se esta agregando el nuevo usuario al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia"
                            }
                            tituloLoading={"Agregando usuario..."}
                            loading={loading}
                            respuesta={response}
                            titulo={
                                response === 201
                                ? "Agregado exitosamente"
                                : "ha Fallado"
                            }
                            mensajeRespuesta={
                                response === 201
                                ? "Nuevo estacionamiento agregado exitosamente, ahora puede asignar un usuario o invitado al estacionamiento."
                                : "La creacion del estacionamiento ha fallado, ocurrio un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                            }
                            txtBotonUno={"Agregar otro estacionamiento"}
                            eventBotonUno={handleAgregarOtro}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}