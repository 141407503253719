import { useEffect, useState } from "react"
import { ActivarPatente, BuscarPatenteEmpresa, CrearPatente, DesactivarPatente, EditarPatenteUsuario } from "../../Service/API/Api";
import axios from "axios";

export default function usePatentes(){
    const [loading, setLoading] = useState();
    const [response, setResponse] = useState();
    const [patentes, setPatentes] = useState([])

    useEffect(() => {        
        axios.get(BuscarPatenteEmpresa)
        .then(
            (response) => {                
                setPatentes(response.data);
                setLoading(false);
            }
        )
        .catch(
            (error) => {
                console.log(error.response);
                setLoading(false);
            }
        )
    }, [])
    


    const CrearNuevo = (patente, usuario) => {
        return new Promise((resolve, reject) => {
            const nuevaPatente = {
                "patenteDigitos" : patente.toUpperCase(),
                "idPersona": usuario,
                "idVisitante": null
            }
            axios.post(CrearPatente,  nuevaPatente)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    reject(error.response.data);
                    setLoading(false);
                    setResponse(error.response.status);
                }
            )
        })
    }

    const EditarPatente = (patente, usuario, idPatente) => {
        return new Promise((resolve, reject) => {
            const editarPatente = {
                "idPatente": idPatente,
                "patenteDigitos": patente.toUpperCase(),
                "idPersona": usuario,
                "idVisitante": null
            }

            axios.put(EditarPatenteUsuario,  editarPatente)
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    reject(error.response.data);
                    setLoading(false);
                    setResponse(error.response.status);
                }
            )
        })
    }

    const ActivarDesactivarPatente = (idPatente, estado) => {
        return new Promise((resolve, reject) => {
            axios.patch(estado === true ? DesactivarPatente + idPatente : ActivarPatente + idPatente )
            .then(
                (response) => {
                    resolve(true);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    reject(error.response.data);
                    setLoading(false);
                    setResponse(error.response.status);
                }
            )
        })
    }

    

    return {
        loading,
        setLoading,
        CrearNuevo,
        response,
        EditarPatente,
        ActivarDesactivarPatente,
        patentes,
    }
}