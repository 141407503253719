import * as Yup from 'yup';

export const ValidationNuevoAcceso = Yup.object({
    idPersona: Yup.number()
        .moreThan(-1, 'Debe seleccionar un valor')
        .required('Usuario es requerida'),

    idZona: Yup.number()
        .positive('Debe seleccionar un valor')
        .required('Zona es requerida'),

})