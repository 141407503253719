import dayjs from 'dayjs';
import * as Yup from 'yup';

export const ValidationNuevaAsignacion = Yup.object({  
    idHorario: Yup.number(""),
    inicio: Yup.date()
    .test("min", "fecha no Valida debe ser mayor", function(value){
        const dn = Date.now()
        const fu = dayjs(value)
        const fv = dayjs(dn)

        if(fu.isSame(fv, 'day')){

            return true
        }

        if(fu.isAfter(fv, 'day')){

            return true
        }

        if(fu.isBefore(fv, 'day')){

            return false
        }

    })
    ,
    termino: Yup.date()
    .test("min", "fecha no Valida", function(value){
        const { inicio } = this.parent
        const fd = dayjs(value)
        const fu = dayjs(inicio)
        
        if(fd.isBefore(fu, 'day')){
            return false
        }
        if(fd.isSame(fu, 'day') || fd.isAfter(fu, 'day'))
        {
            return true
        }

    })
})


export const ValidationEditarAsignacion = Yup.object({  
    idHorario: Yup.number(""),
    inicioEditar: Yup.date()
    .test("min", "fecha no Valida", function(value){
        const dn = Date.now()
        const fu = dayjs(value)
        const fv = dayjs(dn)

        if(fu.isSame(fv, 'day')){

            return true
        }

        if(fu.isAfter(fv, 'day')){

            return true
        }

        if(fu.isBefore(fv, 'day')){

            return false
        }
    }),
    terminoEditar: Yup.date()
    .test("min", "fecha no Valida", function(value){
        const { inicio } = this.parent
        const fd = dayjs(value)
        const fu = dayjs(inicio)

        if(fd.isBefore(fu, 'day')){
            return false
        }
        if(fd.isSame(fu, 'day'))
        {
            return true
        }
        if(fd.isAfter(fu, 'day'))
        {
            return true
        }
    }),
   
})
