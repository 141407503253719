import { Box, MenuItem, Select } from '@mui/material';
import React from 'react';

const InputsBarraNavegacion = ( {opciones, setFiltro, filtro, handlerInputBarraNavegacion}) => {

    const handleChange = (e) => {
        setFiltro(e.target.value);
        // console.log("handleChange, el value es ",e.target.value);
        handlerInputBarraNavegacion(e.target.value);
    }

    return (
        <Box sx={{width:'100%',}}>
            {Object.entries(opciones).forEach(([key, value]) => (<h1> key {key}, value {value} </h1>)) }

            <Select className='ReportesNavegacion'
                value={filtro}
                onChange={handleChange}
                >
                    {opciones.map((opcion) => <MenuItem key={opcion.valor} value={opcion.valor}>{opcion.nombre}</MenuItem> )}
            </Select>
        </Box>
    );
}

export default InputsBarraNavegacion;
