import { useSelector } from "react-redux";
import useAuth from "../../Hooks/Auth/useAuth";
import dayjs from "dayjs";
import { Navigate } from "react-router-dom";


const RutaPrivada = ({children}) => {
    const data = useSelector(action => action.user.authenticated);
    const {Salir} = useAuth();


    if(data.authenticated === true)
    {
        const fechaHoy =  new Date(Date.now())


        if(dayjs(data.expiracion).isAfter(fechaHoy, 'day'))
        {
            return children;
        }else
        {
            alert("Su sesion ha expirado, ingrese nuevamente");
            Salir();
            
        }
    }
    else
    {
        return <Navigate to={"/login"} replace />; 
    }
}

export default RutaPrivada;


