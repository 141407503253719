import axios from "axios";
import { IconoDuranteElDia, IconoEvento, IconoRecurrente } from "./TiposEventosIcon";
const { useState, useEffect } = require("react");
const { TiposVisitasEmpresa } = require("../../Service/API/Api");

function useTiposEventos() {
    const [tiposVisitas, setTiposVisitas] = useState([]);

    useEffect(() => {
        axios.get(TiposVisitasEmpresa)
        .then(
            (response) => {
                var lista= [];
                var valor0 = {
                    idTipoVisita:-1,
                    tipoVisita: "Seleccione",
                    icono: null
                }
                lista.push(valor0);

                response.data.forEach(element => {
                    var item = {
                        idTipoVisita: element.idTipoVisita,
                        tipoVisita: element.tipo,
                        icono: element.idTipoVisita === 1 ? <IconoDuranteElDia id="iconoTipoVisita"  /> : element.idTipoVisita === 2 ? <IconoEvento id="iconoTipoVisita" /> : <IconoRecurrente id="iconoTipoVisita"  />
                    }
                    lista.push(item);
                });

                setTiposVisitas(lista)
            }
        )
        
        
    }, [])
    

    return { 
        tiposVisitas
    };
}

export default useTiposEventos;
