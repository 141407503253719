import { SvgIcon } from "@mui/material";

export function IconoUsuario(props) {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M16 15H8C5.79086 15 4 16.7909 4 19V21H20V19C20 16.7909 18.2091 15 16 15Z" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function IconoAgregarNuevo(props) {
    return (
        <SvgIcon {...props}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.75" y="0.75" width="38.5" height="38.5" rx="19.25" stroke="#175676" strokeWidth="1.5"/>
                <path d="M12 20H28M20 12V28" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

export function IconoCargaMasiva(props) {
    return (
        <SvgIcon {...props}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4.75" y="4.75" width="38.5" height="38.5" rx="19.25" stroke="#175676" strokeWidth="1.5"/>
                <path d="M32 25V30C32 31.1046 31.1046 32 30 32H18C16.8954 32 16 31.1046 16 30L16 25M28 20L24 16M24 16L20 20M24 16L24 28" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}

//ver si cambiar este nombre a borrar todos o seleccionados
export function IconoLimpiarNombreEvento(props) {
    return (
        <SvgIcon {...props}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.75" y="0.75" width="38.5" height="38.5" rx="19.25" stroke="#175676" strokeWidth="1.5"/>
                <path d="M18 18V24M22 18V24M26 14V26C26 27.1046 25.1046 28 24 28H16C14.8954 28 14 27.1046 14 26V14M12 14H28M23 14V13C23 11.8954 22.1046 11 21 11H19C17.8954 11 17 11.8954 17 13V14" stroke="#175676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
}