import { Box, IconButton, Modal } from "@mui/material";
import { CerrarModalNuevoUsuario } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import { NuevaUbicacion } from "./NuevaUbicacion";
import { EditarUbicacion } from "./EditarUbicacion";
import { VerMiembrosUbicacion } from "../VerMiembros/VerMiembros";

const style = {
    width: "1024px",
    height: "680px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};



export const ModalOpcionesGestionUbicacion = ({open, setOpen, setRows, opcion, dataEditar, rows, ubicacion}) => {
    const handlerClose = () => {
        setOpen(false)
    }

    return (
        <Modal id="modalOpcionesUbicaciones" open={open} onClose={handlerClose} sx={{border:"unset"}}>
            <Box sx={{...style}}>
                <Box id="BoxCabeceraNuevoActuador">
                    <IconButton onClick={handlerClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>
                <Box id="BoxContenidoOpcionGestionActuador">
                    {
                        opcion === 1 ?
                        <NuevaUbicacion setRows={setRows} setOpen={setOpen} />
                        :
                        opcion === 2 ?
                            <EditarUbicacion setOpen={setOpen} setRows={setRows} rows={rows} dataEditar={dataEditar}/>  
                            :
                            <VerMiembrosUbicacion setOpen={setOpen} idUbicacion={ubicacion} />
                    }
                </Box>
            </Box>
        </Modal>
    );
}