import Dashboard from "../../Components/Dashboard/Dashboard";
import React from 'react';
import GestionHorarios from "../../Components/GestionHorarios/GestionHorarios";

export default function GestionHorariosPage() {
    return (
      <Dashboard>
        <GestionHorarios />
      </Dashboard>
    )
  }