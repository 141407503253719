
import Dashboard from "../../Components/Dashboard/Dashboard";
import GestionUsuarios from "../../Components/Usuarios/Usuarios"

const GestionUsuariosPage = () => { 
    return(
        <Dashboard>
            <GestionUsuarios />
        </Dashboard>
    );
}

export default GestionUsuariosPage;