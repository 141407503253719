import Dashboard from "../../Components/Dashboard/Dashboard";
import React from 'react';
import GestionUbicaciones from "../../Components/GestionUbicaciones/GestionUbicaciones";


const Ubicaciones = () => {
    return (
        <Dashboard>
            <GestionUbicaciones />
        </Dashboard>
    );
}

export default Ubicaciones;
