import "./Facial.css"
import { FacialTablaGU } from "./TablaFacial";
import { LayoutMenuOpcion } from "../../../../Box/OpcionMenuGestionUsuario/LayoutMenuGU";
import { useEffect, useState } from "react";
import { NuevoFacial } from "./NuevoFacial";
import { NoData } from "../../../../Box/NoDataMenuGestionUsuario/NoData";
import useFacialUsuario from "../../../../../Hooks/Facial/useFacialUsuario";
import useActuadoresTipo from "../../../../../Hooks/Actuadores/useActuadoresTipo";

export const GestionUsuariosFacial= ({ userData}) =>{
    const {facial} = useFacialUsuario(userData.rut);
    const [rows, setRows] = useState([]);
    const [nuevo, setNuevo] = useState(Boolean(false));
    const {actuadores} = useActuadoresTipo(10);

    useEffect(() => {
        setRows(facial);
    }, [facial])
    
    

    return (
        nuevo ?
        <NuevoFacial setOpen={setNuevo} setRows={setRows} actuadores={actuadores} user={userData.idPersona}/>
        :
        <LayoutMenuOpcion titulo={"Registro Facial"} showIcon={true} handleIcon={setNuevo}>
            {
                rows.length === 0 ?
                <NoData 
                mensaje={"Actualmente, no hay un registro facial asociado a este usuario.Agregar un registro facial mejorará la seguridad y facilitará la verificación de identidad para el control de accesos.Utiliza el botón “Agregar registro facial' para comenzar."}
                textoBtn={"Agregar registro facial"}
                event={setNuevo}
                /> 
                :
                <FacialTablaGU facial={rows} setRows={setRows}/>
            }
        </LayoutMenuOpcion>
    );
}