import React from 'react';

import Dashboard from '../../Components/Dashboard/Dashboard';
import { Agenda } from '../../Components/Agenda/Agenda';

const AgendaPage = () => {
    return (
        <Dashboard>
            <Agenda />
        </Dashboard>
    );
}

export default AgendaPage;
