import { Box, IconButton, Modal } from "@mui/material"
import { CerrarModalNuevoUsuario } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import "./ManejadorOpciones.css";
import { CrearNuevoHorarioGestionHorario } from "./CrearHorario";
import { EditarHorarioGestionHorario } from "./EditarHorario";

const style = {
    width: "1108px",
    height: "680px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};


export const HandleOpcionesHorario = ({ open, setOpen, setRows, rows, opcion, dataEdit}) => {
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <Modal open={open} onClose={handleClose} sx={{border:"unset"}}>
            <Box sx={{...style}}>
                <Box id="BoxCabeceraNuevoHorario">
                    <IconButton onClick={handleClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>
                <Box id="BoxOpcionesGestionHorario">
                    {
                        opcion === 1 ?
                        <CrearNuevoHorarioGestionHorario setRows={setRows} setOpen={setOpen} />
                        :
                        <EditarHorarioGestionHorario setRows={setRows} setOpen={setOpen} dataEditar={dataEdit} rows={rows} />
                    }
                </Box>
            </Box>
        </Modal>
    )
}