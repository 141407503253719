import { Box, Modal } from "@mui/material";
import { RespuestaServidor, RespuestaServidorCinco, RespuestaServidorCuatro, RespuestaServidorDos, RespuestaServidorSeis, RespuestaServidorTres } from "../RespuestaServidor/RespuestaServidor";
import { ModalLoading, ModalLoadingDos } from "../ModalLoding/ModalLoading";


//usado mas que nada para una modificacion, loading sin mensaje
export const LoadingMasRespuesta = ({open, setOpen, msj, id, loading, respuesta, titulo, mensajeRespuesta, icono, textoBoton }) => {

    const handleClose = () => 
    { 
        setOpen(false);
    };

    return(
        <>
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading ?
                    <Box>
                        <ModalLoading msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaServidor 
                        icono={icono}
                        textoBoton={textoBoton}
                        setOpen={setOpen} 
                        msj={mensajeRespuesta} 
                        titulo={titulo} 
                        respuesta={respuesta}
                        />
                    </Box>
                    
            }
            </Modal>
        </>
    );
}


//usado mas que nada para una creacion
export const LoadingMasRespuestaDos = ({open, setOpen, msj, id, tituloLoading, loading, respuesta, titulo, mensajeRespuesta, eventBotonUno, txtBotonUno, eventoBotonDos, txtBotonDos}) => {

    const handleClose = () => 
    { 
        setOpen(false);
    };

    return(
        <>
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading ?
                    <Box>
                        <ModalLoadingDos tituloLoading={tituloLoading} msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaServidorDos 
                        setOpen={setOpen} 
                        msj={mensajeRespuesta} 
                        titulo={titulo} 
                        respuesta={respuesta}
                        eventBotonUno={eventBotonUno}
                        txtBotonUno={txtBotonUno}
                        txtBotonDos={txtBotonDos}
                        eventoBotonDos={eventoBotonDos}
                        />
                    </Box>
                    
            }
            </Modal>
        </>
    );
}

//este incluye el loading mas un mensaje y va a una opcion de respuesta de aceptar todo ok, fallo reintentar
export const LoadingMasRespuestaTres = ({open, setOpen, msj, id, tituloLoading, loading, respuesta, titulo, mensajeRespuesta}) => {

    const handleClose = () => 
    { 
        setOpen(false);
    };

    return(
        <>
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading ?
                    <Box>
                        <ModalLoadingDos tituloLoading={tituloLoading} msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaServidor 
                        setOpen={setOpen} 
                        msj={mensajeRespuesta} 
                        titulo={titulo} 
                        respuesta={respuesta}
                        />
                    </Box>
                    
            }
            </Modal>
        </>
    );
}


export const LoadingMasRespuestaCuatro = ({open, setOpen, msj, id, tituloLoading, loading, respuesta, titulo, mensajeRespuesta, eventBotonUno, txtBotonUno}) => {
    
    const handleClose = () => 
    { 
        setOpen(false);
    };
    return(
        <>
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading ?
                    <Box>
                        <ModalLoadingDos tituloLoading={tituloLoading} msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaServidorTres 
                            setOpen={setOpen} 
                            msj={mensajeRespuesta} 
                            titulo={titulo} 
                            respuesta={respuesta}
                            eventBotonUno={eventBotonUno}
                            txtBotonUno={txtBotonUno}
                        />
                    </Box>
                    
            }
            </Modal>
        </>
    );
}

//loading simple para respuesta por creacion, mas una opcon de aceptar o reintentar
export const LoadingMasRespuestaCinco = ({open, setOpen, msj, id, tituloLoading, loading, titulo, mensajeRespuesta, icono, textoBoton}) => {

    const handleClose = () => 
    { 
        setOpen(false);
    };

    return(
        <>
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading ?
                    <Box>
                        <ModalLoadingDos tituloLoading={tituloLoading} msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaServidor 
                        setOpen={setOpen} 
                        msj={mensajeRespuesta} 
                        titulo={titulo} 
                        icono={icono}
                        textoBoton={textoBoton}
                        />
                    </Box>
                    
            }
            </Modal>
        </>
    );
}

export const LoadingMasRespuestaSeis = ({open, setOpen, funcion1, msj, id, tituloLoading, loading, titulo, mensajeRespuesta, icono, textoBoton}) => {

    const handleClose = () => 
    { 
        setOpen(false);
    };

    return(
        <>
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading ?
                    <Box>
                        <ModalLoadingDos tituloLoading={tituloLoading} msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaServidorCuatro
                            onclickButton={funcion1}
                            msj={mensajeRespuesta} 
                            titulo={titulo} 
                            icono={icono}
                            textoBoton={textoBoton}
                        />
                    </Box>
                    
            }
            </Modal>
        </>
    );
}

//Igual al loading mas respuesta cuatro pero el handle Close personalizado 
export const LoadingMasRespuestaSiete = ({open, handleClose, setOpen, msj, id, tituloLoading, loading, respuesta, titulo, mensajeRespuesta, eventBotonUno, txtBotonUno}) => {
    
    return(
        <>
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading ?
                    <Box>
                        <ModalLoadingDos tituloLoading={tituloLoading} msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaServidorCinco
                        handleClose={handleClose} 
                        setOpen={setOpen} 
                        msj={mensajeRespuesta} 
                        titulo={titulo} 
                        respuesta={respuesta}
                        eventBotonUno={eventBotonUno}
                        txtBotonUno={txtBotonUno}
                        />
                    </Box>
                    
            }
            </Modal>
        </>
    );
}

//Igual al loading mas respuesta cinco pero el handle Close personalizado 
export const LoadingMasRespuestaOcho = ({open, setOpen, msj, id, tituloLoading, loading, titulo, mensajeRespuesta, icono, textoBoton, eventBotonUno}) => {

    const handleClose = () => 
    { 
        setOpen(false);
    };

    return(
        <>
            <Modal id={id} open={open} onClose={handleClose}>
            {
                loading ?
                    <Box>
                        <ModalLoadingDos tituloLoading={tituloLoading} msj={msj}/>
                    </Box>
                    :
                    <Box>
                        <RespuestaServidorSeis 
                        setOpen={setOpen} 
                        msj={mensajeRespuesta} 
                        titulo={titulo} 
                        icono={icono}
                        textoBoton={textoBoton}
                        handleClose={eventBotonUno}
                        />
                    </Box>
                    
            }
            </Modal>
        </>
    );
}
