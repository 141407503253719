import React from 'react';
import Dashboard from '../../Components/Dashboard/Dashboard';
import Zonas from '../../Components/GestionZonas/Zonas';

const ZonasPage = () => {
    return (
        <Dashboard>

            <Zonas />
            
        </Dashboard>
    );
}

export default ZonasPage;
