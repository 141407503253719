import axios from "axios";
import { useState } from "react"
import { CrearTarjeta, DesactivarTarjeta, EditarTarjeta, ReactivarTarjeta } from "../../Service/API/Api";

export function useManejoTarjetas(){
    const [loading, setLoading] = useState(null);
    const [response, setResponse] = useState(null);

    const CrearTarjetaRequest = (values, idEmpresa) => {
        return new Promise((resolve, reject) => {
            const nuevaTarjeta = {
                "codigo": values.codigo,
                "idTipoTarjeta": values.idTipoTarjeta,
                "idEmpresa": idEmpresa
            }
            axios.post(CrearTarjeta, nuevaTarjeta)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            )
        })
    }

    const EditarTarjetaRequest = (idTarjeta, values, idEmpresa) => {
        return new Promise((resolve, reject) => {
            const Tarjeta = {
                "idTarjeta": idTarjeta,
                "codigo": values.codigo,
                "idTipoTarjeta": values.idTipoTarjeta,
                "idEmpresa": idEmpresa
            }
            axios.put(EditarTarjeta, Tarjeta)
            .then(
                (response) => {
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            )
        })
    }
    
    const ManejoEstadoTarjeta = (idTarjeta, estadoActual) => {
        return new Promise((resolve, reject) => {
            axios.patch(estadoActual === true ? DesactivarTarjeta + idTarjeta : ReactivarTarjeta + idTarjeta)
            .then(
                (response) => {
                    resolve(true);
                    setResponse(response.status);
                    setLoading(false);
                }
            )
            .catch(
                (error) => {
                    reject(false);
                    setResponse(error.response.status);
                    setLoading(false);
                }
            )
        })
    }
    

    return{
        loading,
        setLoading,
        response,
        CrearTarjetaRequest,
        EditarTarjetaRequest,
        ManejoEstadoTarjeta
    }
}