import dayjs from "dayjs";
import { useState } from "react";

export default function useAgenda() {
    const [opcion, setOpcion] = useState(2); //opcion vista de agenda, 1 dia, 2 semana, 3 mes, 4 anio
    const [fechaVista, setFechaVista] = useState(new Date())
    
    const handleHoy = () => {
        const fechaActual = dayjs(new Date());
        setFechaVista(fechaActual);
    }

    const AddDayToDate = async () => {
        var nuevaFecha = dayjs(fechaVista).add(1, "day")
        setFechaVista(nuevaFecha)
    }

    const RestDayToDate = async () => { 
        var nuevaFecha = dayjs(fechaVista).add(-1, "day")
        setFechaVista(nuevaFecha)
    }

    const AddMonthToDate = async () => {
        var maxYear = Anios.find(a => a.id === 5)
        if(maxYear.anio === dayjs(fechaVista).year()){
            if(dayjs(fechaVista).month() === 11){
                setFechaVista(fechaVista)
            }
            else
            {
                var addFecha = dayjs(fechaVista).add(1, "month").set("D", 1);
                setFechaVista(addFecha)
            }
        }
        else{
            var nuevaFecha = dayjs(fechaVista).add(1, "month").set("D", 1);
            setFechaVista(nuevaFecha)
        }
        
    }
    const RestMonthToDate = async () => {
        var minYear = Anios.find(a => a.id === 0)
        if(minYear.anio === dayjs(fechaVista).year()){
            
            if(dayjs(fechaVista).month() === 0){
                setFechaVista(fechaVista)
            }
            else
            {
                var restFecha = dayjs(fechaVista).add(-1, "month").set("D", 1);
                setFechaVista(restFecha)
            }
        }
        else{
            var nuevaFecha = dayjs(fechaVista).add(-1, "month").set("D", 1);
            setFechaVista(nuevaFecha)
        }
        
    }

    const setMonthToDate = async (month) => { 
        var nuevaFecha = dayjs(fechaVista).set("month", month).set("D", 1);
        setFechaVista(nuevaFecha)
    }

    const AddYearToDate = async () => {
        var maxYear = Anios.find(a => a.id === 5)
        if(maxYear.anio === dayjs(fechaVista).year()){
            setFechaVista(fechaVista)
        }
        else{
            var nuevaFecha = dayjs(fechaVista).add(1, "y").set("MM", 0).set("D", 1);
            setFechaVista(nuevaFecha)
        }
    }
    const RestYearToDate = async () => {
        var minYear = Anios.find(a => a.id === 0)
        if(minYear.anio === dayjs(fechaVista).year()){
            setFechaVista(fechaVista)
        }
        else{
            var nuevaFecha = dayjs(fechaVista).add(-1, "y").set("MM", 0).set("D", 1);
            setFechaVista(nuevaFecha)
        }
    }

    const setYearToDate = async (year) => { 
        var nuevaFecha = dayjs(fechaVista).set("y", year).set("MM", 0).set("D", 1);
        setFechaVista(nuevaFecha)
    }

    const Anios = [
        {id: 0, anio: new Date().getFullYear() - 2},
        {id: 1, anio: new Date().getFullYear() - 1},
        {id: 2, anio: new Date().getFullYear()},
        {id: 3, anio: new Date().getFullYear() + 1},
        {id: 4, anio: new Date().getFullYear() + 2},
        {id: 5, anio: new Date().getFullYear() + 3}
    ]

    
    const addWeekToDate = async () => { 
        var nuevaFecha = dayjs(fechaVista).add(7, "day");
        setFechaVista(nuevaFecha)
    }

    const restWeekToDate = async () => { 
        var minYear = Anios.find(a => a.id === 0)
        if(minYear.anio === dayjs(fechaVista).year())
        
            {

        }
        var nuevaFecha = dayjs(fechaVista).add(-7, "day");
        setFechaVista(nuevaFecha)
    }

    const setNewDate = async (newDate) => {
        var nuevaFecha = dayjs(newDate);
        setFechaVista(nuevaFecha)
    }

    return {
        opcion,
        setOpcion,
        fechaVista,
        AddDayToDate,
        RestDayToDate,
        handleHoy,
        AddMonthToDate,
        RestMonthToDate,
        setMonthToDate,
        setYearToDate,
        AddYearToDate,
        RestYearToDate,
        addWeekToDate,
        restWeekToDate,
        setNewDate,
        Anios
    }
}