import Dashboard from "../../Components/Dashboard/Dashboard";
import React from 'react';
import ListaNegra from "../../Components/GestionListaNegra/ListaNegra";

const ListaNegraPage = () => {
    return (
        <Dashboard>
            <ListaNegra />
        </Dashboard>
    );
}

export default ListaNegraPage;
