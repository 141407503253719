import { HuellasTablaGU } from "./TablaHuella";
import { LayoutMenuOpcion } from "../../../../Box/OpcionMenuGestionUsuario/LayoutMenuGU";
import { NuevaHuella } from "./NuevaHuella";
import { useEffect, useState } from "react";
import { NoData } from "../../../../Box/NoDataMenuGestionUsuario/NoData";
import useActuadoresTipo from "../../../../../Hooks/Actuadores/useActuadoresTipo";
import useHuellasUsuario from "../../../../../Hooks/Huellas/useHuellasUsuario";

export const GestionUsuariosHuella = ({userData}) =>{
    const { huellas } = useHuellasUsuario(userData.idPersona);
    const [rows, setRows] = useState([]);
    const [nuevo, setNuevo] = useState(Boolean(false));
    const { actuadores } = useActuadoresTipo(15);
    //obtener los actuadores de tipo 15 (huella)

    useEffect(() => {
        setRows(huellas)
    }, [huellas])

    return (
        nuevo ? 
        <NuevaHuella setOpen={setNuevo} setRows={setRows} actuadores={actuadores} user={userData.idPersona}/>
        :
        <LayoutMenuOpcion titulo={"Registro de huellas dactilares"} showIcon={true} handleIcon={setNuevo} >
            {
                rows.length === 0 ?
                <NoData 
                mensaje={"Actualmente, no hay huellas dactilares asociadas a este usuario.Agregar una huella dactilar mejorará la seguridad y facilitará la verificación de identidad para el control de accesos.Utiliza el botón “Agregar huella” para comenzar."}
                textoBtn={"Agregar huella"}
                event={setNuevo}
                /> 
                :
                <HuellasTablaGU huellas={rows} setRows={setRows} />
            }
        </LayoutMenuOpcion>
    );
}