import {  Autocomplete, Box,  FormControl,  FormHelperText,  IconButton,TextField,  Typography,} from "@mui/material";
import "./NuevoUsuario.css";
import { useFormik } from "formik";
import { Initial } from "./Initial";
import { Esquema } from "./Esquema";
import {  CerrarModalNuevoUsuario, ClearIconTextField} from "./NuevoUsuarioIcons";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { useTiposUsuario } from "../../../Hooks/TiposUsuario/useTiposUsuario";
import useUsuarios from "../../../Hooks/Usuarios/useUsuarios";
import { useSelector } from "react-redux";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useState } from "react";
import { TextFieldUno } from "../../TextField/TextFieldUno";
import { SnackBarTipoUno } from "../../snackBar/SnackBar";

const style = {
  width: "1144px",
  height: "604px",
  display: "inline-flex",
  position: "absolute",
  flexDirection: "column",
  aligItems: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  backgroundColor: "#FFF",
  borderRadius: "16px",
  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};

export const NuevoUsuario = ({ handlerOpen, setRows }) => {
  const data = useSelector((action) => action.user.authenticated);
  const { tipos } = useTiposUsuario();
  const { CrearNuevo, loadingRequest, setLoadingRequest, requestResponse } =
    useUsuarios();
  const [openLoading, setOpenLoading] = useState(Boolean(false));
  const [snackMsg, setSnackMsg] = useState({severity: "", message: ""});
  const [snackbar, setSnackbar] = useState(false);
  const handleCloseSnackbar = () => setSnackbar(null);
  

  const handlerClose = () => {
    handlerOpen(false);
    formik.resetForm();
  };

  const AgregarNewRow = async (values, nuevaId) => {
    let tipoUsuario = tipos.find(
      (t) => t.idTipoPersona === values.idTipoPersona
    );
    setRows((oldRows) => [
      ...oldRows,
      {
        idPersona: nuevaId,
        apellidos: values.apellidos,
        correo: values.correo,
        estado: true,
        fechaCreacion: null,
        idTipoPersona: values.idTipoPersona,
        nombres: values.nombres,
        rut: values.rut === "" ? null:values.rut,
        pasaporte: values.pasaporte === "" ? null:values.pasaporte,
        estadoMovil: false,
        telefono: values.telefono,
        tipo: tipoUsuario.tipo,
        isNew: true,
      },
    ]);
  };

const handleAgregarOtro = () => {
    setOpenLoading(false);
    formik.resetForm();
  };

const formik = useFormik({
    initialValues: Initial,
    validationSchema: Esquema,
    onSubmit: async () => {
        if((formik.values.rut === null || formik.values.rut === "") 
            && 
        (formik.values.pasaporte === null || formik.values.pasaporte === ""))
        {
            setSnackMsg({severity: "error", message:"Debe completar el campo de identificacion (Rut o Pasaporte)"});
            setSnackbar(true);
        }
        else{
            setLoadingRequest(true);
            setOpenLoading(true);
            await CrearNuevo(formik.values, data.idEmpresa).then(
            (result) => {
            AgregarNewRow(formik.values, result);
            },
            (error) => {
            console.log("error" + error);
            }
            );
        }

        
    }
  });

  return (
        <Box id="BoxNuevoUsuario" sx={{ ...style }}>
          <Box id="CabeceraNuevoUsuario">
            <IconButton onClick={handlerClose}>
              <CerrarModalNuevoUsuario />
            </IconButton>
          </Box>
          <Box id="ContenidoModalNuevoUsuario">
            <Box id="BoxTitutloModalNuevoUsuario">
              <Typography id="TituloNuevoUsuario">{"Nuevo Usuario"}</Typography>
            </Box>
            <Box id="BoxSubtituloModalNuevoUsuario">
              <Typography id="SubtituloNuevoUsuario">
                {
                  "Todos los campos son obligatorios para completar el registro."
                }
              </Typography>
            </Box>
            <Box
              id="BoxFormNuevoUsuario"
              component={"form"}
              onSubmit={formik.handleSubmit}
              onReset={formik.handleReset}
            >
              <Box id="BoxInformacionPersonal">
                <Box id="TituloInformacionPersonal">
                  <Typography id="TxtTituloInformacionPersonal">
                    {"Información personal"}
                  </Typography>
                </Box>
                <Box id="BoxFormularioInformacionPersonal">
                    <Box id="BoxNombreApellido">
                    <TextFieldUno
                      id={"NombresEditarUsuario"}
                      label={"Nombres"}
                      placeholder={"Nombres"}
                      icon={
                        <ClearIconTextField id="IconoLimpiarTextFieldNuevoUsuario" />
                      }
                      helper={formik.errors.nombres}
                      value={formik.values.nombres}
                      name={"nombres"}
                      handlerChange={formik.handleChange}
                      formHandle={() => {
                        formik.setFieldValue("nombres", "");
                      }}
                      disabled={false}
                    />
                    <TextFieldUno
                      id={"ApellidosEditarUsuario"}
                      label={"Apellidos"}
                      placeholder={"Apellidos"}
                      icon={
                        <ClearIconTextField id="IconoLimpiarTextFieldNuevoUsuario" />
                      }
                      helper={formik.errors.apellidos}
                      value={formik.values.apellidos}
                      name={"apellidos"}
                      handlerChange={formik.handleChange}
                      formHandle={() => {
                        formik.setFieldValue("apellidos", "");
                      }}
                      disabled={false}
                    />
                  </Box>
                  <Box id="BoxRutTipo">
                    <Box id="BoxIdentificadorNuevoUsuario">
                      <Box id="BoxIdentificadorRutNuevoUsuario">
                        <TextFieldUno
                          id={"RutEditarUsuario"}
                          label={"Rut"}
                          placeholder={"Rut"}
                          icon={
                            <ClearIconTextField id="IconoLimpiarTextFieldNuevoUsuario" />
                          }
                          helper={formik.errors.rut}
                          value={formik.values.rut}
                          name={"rut"}
                          handlerChange={formik.handleChange}
                          formHandle={() => {
                            formik.setFieldValue("rut", "");
                          }}
                          disabled={false}
                        />
                      </Box>
                      <Box id="BoxIdentificadorPasaporteNuevoUsuario">
                        <TextFieldUno
                          id={"PasaporteditarUsuario"}
                          label={"Pasaporte"}
                          placeholder={"Pasaporte"}
                          icon={
                            <ClearIconTextField id="IconoLimpiarTextFieldNuevoUsuario" />
                          }
                          helper={formik.errors.pasaporte}
                          value={formik.values.pasaporte}
                          name={"pasaporte"}
                          handlerChange={formik.handleChange}
                          formHandle={() => {
                            formik.setFieldValue("pasaporte", "");
                          }}
                          disabled={false}
                        />
                      </Box>
                    </Box>
					<Box id="selectTipoUsuario" >
						<FormControl id="FormControlTipoUsuarioNuevo">
							<Autocomplete                                   
                            disablePortal
                            options={tipos}
                            fullWidth  
                            sx={{ "& .MuiFilledInput-input":{ height: "20px", borderBottom: "0px"}, 
                                   "& .MuiOutlinedInput-input":{ height: "20px", borderBottom: "0px"}}}
                            id="idTipoPersona"
                            componentname="idTipoPersona"
                            freeSolo={true}                                        
                            getOptionKey={(o) => o.idTipoPersona}
                            isOptionEqualToValue={(o, v) => o.tipo === v}
                            onChange={(e, v) => {
                                if(v === null)
                                {
                                    formik.setFieldValue("idTipoPersona", -1) 
                                    formik.setFieldValue("tipoPersona", "Seleccione")                                          
                                                                                         
                                }
                                else
                                {
                                    formik.setFieldValue("idTipoPersona", v.idTipoPersona)
                                    formik.setFieldValue("tipoPersona", v.tipo)
                                }
                            }}
                            getOptionLabel={(option) =>  option.tipo ?? option}
                            inputValue={formik.values.tipoPersona}
                            value={formik.values.tipoPersona}
                            onInputChange={(e,v) => formik.setFieldValue("tipoPersona", v)}   
                            renderInput={(params) => 
                                <TextField
                                InputLabelProps={{shrink: true}}
                                {...params}
								                variant="standard"
                                sx={{ height: "100%"}}
                                label="Tipo de Usuario"
                            	/>
                            }
                            renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                        <Box
                                        key={key}
                                        component="li"
                                        {...optionProps}>
                                            {option.tipo}
                                        </Box>
                                );
                            }}
                            />
							<FormHelperText>
                                {
                                	formik.errors.idTipoPersona
                                }
                            </FormHelperText>
						</FormControl>
					</Box>
                  </Box>
                </Box>
              </Box>
              <Box id="BoxInformacionContacto">
                <Box id="TituloInformacionContacto">
                  <Typography id="TxtTituloInformacionContacto">
                    {"Información de contacto"}
                  </Typography>
                </Box>
                <Box id="BoxFormularioInformacionContacto">
                  <TextFieldUno
                    id={"CorreoEditarUsuario"}
                    label={"Correo"}
                    placeholder={"Correo"}
                    icon={
                      <ClearIconTextField id="IconoLimpiarTextFieldNuevoUsuario" />
                    }
                    helper={formik.errors.correo}
                    value={formik.values.correo}
                    name={"correo"}
                    handlerChange={formik.handleChange}
                    formHandle={() => {
                      formik.setFieldValue("correo", "");
                    }}
                    disabled={false}
                  />
                  <TextFieldUno
                    id={"TelefonoEditarUsuario"}
                    label={"Telefono"}
                    placeholder={"Telefono"}
                    icon={
                      <ClearIconTextField id="IconoLimpiarTextFieldNuevoUsuario" />
                    }
                    helper={formik.errors.telefono}
                    value={formik.values.telefono}
                    name={"telefono"}
                    handlerChange={formik.handleChange}
                    formHandle={() => {
                      formik.setFieldValue("telefono", "");
                    }}
                    disabled={false}
                  />
                </Box>
              </Box>
            </Box>
            <Box id="BotoncesAccionModalNuevoUsuario">
              <Box id="BoxBotones">
                <Box id="BoxBotonNuevoUsuario">
                  <BotonTipo2 textoBoton={"Cancelar"} event={handlerClose} />
                </Box>
                <Box id="BoxBotonNuevoUsuario">
                  <BotonTipo2
                    textoBoton={"Agregar"}
                    event={formik.submitForm}
                    disabled={loadingRequest}
                  />
                  <LoadingMasRespuestaCuatro
                    id={"LoadingMasRespuestaDosNuevoUsuario"}
                    open={openLoading}
                    setOpen={setOpenLoading}
                    msj={
                      "Se esta agregando el nuevo usuario al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia"
                    }
                    tituloLoading={"Agregando usuario..."}
                    loading={loadingRequest}
                    respuesta={requestResponse}
                    titulo={
                      requestResponse === 201
                        ? "Usuario agregado"
                        : "Usuario no agregado"
                    }
                    mensajeRespuesta={
                      requestResponse === 201
                        ? "El nuevo usuario ha sido agregado exitosamente a la lista de usuarios. Ahora puedes gestionar sus permisos o añadir otro usuario."
                        : "No se ha podido agregar el nuevo usuario debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                    }
                    txtBotonUno={"Agregar nuevo usuario"}
                    eventBotonUno={handleAgregarOtro}
                  />
                   <SnackBarTipoUno
                    open={snackbar}
                    handler={handleCloseSnackbar}
                    duration={6000}
                    severity={snackMsg.severity}
                    msg={snackMsg.message}
                    />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
  );
};
