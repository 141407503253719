import {Box } from "@mui/material";
import "./Main.css";


export const Main = ({ leftWidth, children }) =>{
  const left = leftWidth + 32;
  const right = 90;
  return(
    <Box id="MainCentral" 
    sx={{ 
    position:"absolute",
    left: `${left}px`, 
    right: `${right}px`, 
    top:"137px", 
    bottom:"104px", 
    }}>
      <Box sx={{ width:"100%", minHeight: "712px" }}>
        {
            children
        }
      </Box>
    </Box>
  );
}