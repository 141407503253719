import axios from "axios";
import { useEffect, useState } from "react";
import { BuscarListaNegraEmpresa, CrearListaNegra, EliminarDeListaNegra } from "../../Service/API/Api";

export default function useListaNegra(){
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState();
    const [listaNegra, setListaNegra] = useState([]);

    useEffect(() => {
        axios.get(BuscarListaNegraEmpresa)
            .then(
                (response) => {
                    setListaNegra(response.data);
                    setLoading(false);

                }
            )
            .catch(
                (error) => {
                    console.log("fallo al cargar lista negra");
                    setLoading(false);

                }
            )
	}, [])

    const AgregarLN = (rutAListaNegra, pasaporteAListaNegra, creador, motivo, empresa) =>{
        return new Promise((resolve, reject) => {
            const agregarListaNegra = {
                "rutPersona": rutAListaNegra,
                "pasaporte": pasaporteAListaNegra,
                "idPersona": creador,
                "motivoIngreso": motivo,
                "idEmpresa": empresa
            }
            axios.post(CrearListaNegra, agregarListaNegra)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    reject(false);
                    setLoading(false);
                    setResponse(error.response.status);
                }
            )
        })   
    }

    const EliminarEnLN = (idListaNegra) =>{
        return new Promise((resolve, reject) => {
            
            axios.delete(EliminarDeListaNegra + idListaNegra)
            .then(
                (response) => {
                    resolve(response.data);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
            .catch(
                (error) => {
                    reject(error.response);
                    setLoading(false);
                    setResponse(response.status);
                }
            )
        })   
    }

    return{
        loading,
        setLoading,
        response,
        AgregarLN,
        EliminarEnLN,
        listaNegra
    }
}