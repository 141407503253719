import { Box, FormControl, FormHelperText, IconButton, MenuItem, Modal, Select, Typography } from "@mui/material";
import { CerrarModalNuevoUsuario, ClearIconTextField } from "../../Usuarios/NuevoUsuario/NuevoUsuarioIcons";
import "./NuevaTarjeta.css";
import { BotonTipo2 } from "../../Botones/BotonesTabla/Botones";
import { TextFieldTres, TextFieldTresPuntoDos } from "../../TextField/TextFieldTres";
import { useFormik } from "formik";
import { EsquemaValidacionNuevaTarjeta } from "./Validacion";
import { useManejoTarjetas } from "../../../Hooks/Tarjetas/useManejoTarjeta";
import { useState } from "react";
import { LoadingMasRespuestaCuatro } from "../../Modal/LoadingMasRespuesta/LoadingMasRespuesta";
import { useSelector } from "react-redux";
  
const style = {
    width: "1024px",
    height: "680px",
    display: "inline-flex",
    position: "absolute",
    flexDirection: "column",
    aligItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    backgroundColor: "#FFF",
    borderRadius: "16px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.15)",
};

const TipoTarjeta = [
    {
        id: 0, tipo: "Tipo de tarjeta"
    }
    ,
    {
        id: 1, tipo: "RFID"
    }
    ,
    {
        id: 2, tipo: "TAG"
    }
]

  
export const NuevaTarjeta = ({ open, handlerOpen, setRows }) => {
    const data = useSelector((action) => action.user.authenticated)
    const { loading, setLoading, response, CrearTarjetaRequest} = useManejoTarjetas();
    const [openLoading, setOpenLoading] = useState(Boolean(false));
    
    const AgregarNuevaRow = async (nuevaId, values) => {
        const tipoT = TipoTarjeta.find(t => t.id === values.idTipoTarjeta);

        setRows((oldRows) => [
            
            ...oldRows,
            {
                idTarjeta: nuevaId,
                estado: true,
                asignada: false,
                codigo: values.codigo,
                idTipoTarjeta: tipoT.id,
                tipoTarjeta: tipoT.tipo,
                isNew: true,
            },
        ]);
    }
        
  
    const formik = useFormik({
        initialValues: {idTipoTarjeta: 0, codigo: ""},
        enableReinitialize: true,
        validationSchema:EsquemaValidacionNuevaTarjeta,
        onSubmit: async () => {
            setLoading(true);
            setOpenLoading(true);
            await CrearTarjetaRequest(formik.values, data.idEmpresa)
            .then(
              (result) => {
                    AgregarNuevaRow(result, formik.values);
                },
                (error) => {
                    console.log("error" + error);
                }
            );
        },
        onReset: () => {
            
        },
    });

    const handleAgregarOtro = () => {
        formik.resetForm();
        setOpenLoading(false);
    }

    const handleClose = () => {
        handlerOpen(false);
        formik.resetForm();
    }
  
    return (
      <>
        <Modal id="modalNuevaTarjeta" open={open} onClose={handleClose}>
            <Box id="BoxNuevaTarjeta" sx={{ ...style }}>
                <Box id="CabeceraNuevaTarjeta">
                    <IconButton onClick={handleClose}>
                        <CerrarModalNuevoUsuario />
                    </IconButton>
                </Box>
                <Box id="ContenidoModalNuevaTarjeta">
                    <Box id="BoxContenidoInternoModalNuevaTarjeta">
                        <Box id="BoxCabeceraContenidoModalNuevaTarjeta">
                            <Box id="BoxTitutloModalNuevaTarjeta">
                                <Typography id="TituloNuevaTarjeta">{"Nueva tarjeta"}</Typography>
                            </Box>
                            <Box id="BoxSubtituloModalNuevaTarjeta">
                                <Typography id="SubtituloNuevaTarjeta">
                                {
                                    "Todos los campos son obligatorios para completar el registro."
                                }
                                </Typography>
                            </Box>
                        </Box>
                        <Box id="BoxContenedorDataNuevaTarjeta">
                            <Box id="BoxTituloFormularioNuevaTarjeta"> 
                                <Box id="BoxTituloNuevaTarjeta">
                                    <Typography id="TxtTituloInformacionPersonal">
                                        {"Información de tarjeta"}
                                    </Typography>
                                </Box>
                                <Box id="BoxFormularioNuevaTarjeta" component={"form"} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                                    <Box id="BoxFormularioTipoTarjetaNueva">
                                        <FormControl fullWidth >
                                            <Select
                                            fullWidth
                                            id={"idTipoTarjeta"}
                                            name={"idTipoTarjeta"}
                                            required
                                            sx={{height:"56px", borderBottom:"2px solid #8A8A8A", backgroundColor: "rgb(248, 248, 248)"}}
                                            variant="filled"
                                            value={formik.values.idTipoTarjeta}
                                            onChange={formik.handleChange}
                                            disableUnderline={true}
                                            >
                                            {
                                                TipoTarjeta.map((item, index) => {
                                                return (
                                                    <MenuItem
                                                    disableRipple
                                                    key={index}
                                                    value={item.id}
                                                    >
                                                        <Typography>{item.tipo}</Typography>
                                                    </MenuItem>
                                                );
                                                })
                                            }
                                            </Select>
                                            <FormHelperText>
                                                {
                                                    formik.errors.idTipoTarjeta
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                    <Box id="BoxFormularioCodigoNuevaTarjeta">
                                        <TextFieldTresPuntoDos
                                            id={"codigo"}
                                            label={"Código"}
                                            placeholder={"Código"}
                                            icon={
                                                <ClearIconTextField id="IconoLimpiarTextFieldNuevoUsuario" />
                                            }
                                            name={"codigo"}
                                            value={formik.values.codigo}
                                            handlerChange={formik.handleChange}
                                            handlerClick={() => {
                                            formik.setFieldValue("codigo", "");
                                            }}
                                            helper={formik.errors.codigo}
                                            disabled={false}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box id="BotoncesAccionModalNuevaTarjeta">
                                <Box id="BoxBotonNuevaTarjeta">
                                    <BotonTipo2 textoBoton={"Cancelar"} event={handleClose} />
                                </Box>
                                <Box id="BoxBotonNuevaTarjeta">
                                    <BotonTipo2
                                    textoBoton={"Agregar"}
                                    event={formik.submitForm}
                                    disabled={loading}
                                    />
                                    <LoadingMasRespuestaCuatro
                                        id={"LoadingMasRespuestaDosNuevaTarjeta"}
                                        open={openLoading}
                                        setOpen={setOpenLoading}
                                        msj={
                                        "Se está agregando una nueva tarjeta al sistema. Este proceso puede tomar solo un momento. Agradecemos tu paciencia."
                                        }
                                        tituloLoading={"Agregando tarjeta..."}
                                        loading={loading}
                                        respuesta={response}
                                        titulo={
                                            response === 201
                                            ? "Tarjeta agregada"
                                            : "Tarjeta no agregada"
                                        }
                                        mensajeRespuesta={
                                            response === 201
                                            ? "La tarjeta ha sido agregada exitosamente al sistema"
                                            : "No se ha podido agregar la tarjeta debido a un error. Por favor, verifica los datos ingresados y vuelve a intentarlo. Si el problema persiste, contáctanos para asistencia."
                                        }
                                        txtBotonUno={"Agregar otra tarjeta"}
                                        eventBotonUno={handleAgregarOtro}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
      </>
    );
  };
  